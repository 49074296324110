import React, { Fragment, useEffect, useState, useCallback } from "react";
import "./brands.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";

import { set_brands } from "redux/actions/BrandsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { debounce } from "lodash";

const Brands = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [tabTitle, settabTitle] = useState("all");

  const [search, setSearch] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tabParam = (tab) => {
    switch (tab) {
      case "all":
        return "";
      case "uae":
        return "&with_country=UAE";
      case "ksa":
        return "&with_country=KSA";
      case "egypt":
        return "&with_country=Egypt";
      default:
        return "";
    }
  };

  const getBrands = (page, params) => {
    setData(false);
    apiJson.brands.getPaginatedBrands(page, params).then((response) => {
      if (response.success) {
        props.set_brands({
          brands: response.data.brands,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };
  useEffect(() => {
    document.title = "Brands";
    getBrands(1, "");
    apiJson.brands.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "storestab",
            tablist: [
              { id: "all", count: response.data.all },
              { id: "ksa", count: response.data.KSA },
              { id: "uae", count: response.data.UAE },
              { id: "egypt", count: response.data.Egypt },
            ],
          })
        : setHeaderList({
            id: "storestab",
            tablist: [
              { id: "all", count: 0 },
              { id: "ksa", count: 0 },
              { id: "uae", count: 0 },
              { id: "egypt", count: 0 },
            ],
          });
    });
    // eslint-disable-next-line
  }, []);

  const changeTab = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getBrands(
        1,
        tabParam(title) +
          (search?.length > 0 ? "&search_by_name=" + search : "")
      );
    }
  };

  const pageChange = (page) => {
    getBrands(
      page,
      tabParam(tabTitle) +
        (search?.length > 0 ? "&search_by_name=" + search : "")
    );
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab) => handleSearch(text, currTab), 1000),
      []
    );

  const handleSearch = (text, tab) => {
    getBrands(1, tabParam(tab) + "&search_by_name=" + text);
  };
  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Brands"
            subtext="Create, edit, and manage Brands on your site."
            search={true}
            handleSearch={(text) => {
              setSearch(text);
              debouncedCallback(text, tabTitle);
            }}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <div className="button-div">
              <button
                type="button"
                className="create-button"
                onClick={() => setRedirect(true)}
              >
                Create New Brand
              </button>
            </div>
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="brands-tab">
                <HeaderTab
                  onTabChoose={changeTab}
                  tabTitle={tabTitle}
                  tab={headerList}
                >
                  <TableList
                    List={props.brands}
                    onPageChange={pageChange}
                    pagination={props.pagination}
                  />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/create-brand", state: { name: "create" } }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.brand.pagination,
    brands: state.brand.brands,
  };
};

const mapDispatchToProps = { set_brands };

export default connect(mapStateToProsps, mapDispatchToProps)(Brands);
