import React, { Fragment } from "react";

import "./emails.scss";
import EmailCard from "./components/EmailCard";
import { CustomersEmails } from "../../../Extra/Emails";
import Header from "../../../components/header/Header";

const Emails = () => {
  const Data = CustomersEmails;

  return (
    <Fragment>
      <div className="right-content emails-page-desktop">
        <div className="header-row">
          <Header heading="Send Emails" subtext="" />
        </div>
        <div className="main-row page-container">
          <div className="content-wrapper">
            <div className="emails-row d-flex flex-wrap">
              {Data.map((customerEmail) => {
                return (
                  <div className="emails-card" key={customerEmail.id}>
                    <EmailCard email={customerEmail} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Emails;
