import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "./mainslider.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import XpertBanner_800x from "../../../../assets/images/homepage/banners/Xpert_Banner1_800x.jpeg";
import XpertBanner_1000x from "../../../../assets/images/homepage/banners/Xpert_Banner1_1000x.jpeg";
import XpertBanner_1200x from "../../../../assets/images/homepage/banners/Xpert_Banner1_1200x.jpeg";
import XpertBanner_1400x from "../../../../assets/images/homepage/banners/Xpert_Banner1_1400x.jpeg";
import NewArrivalsBanner_800x from "../../../../assets/images/homepage/banners/New_Arrivals_Banner_800x.jpeg";
import NewArrivalsBanner_1000x from "../../../../assets/images/homepage/banners/New_Arrivals_Banner_1000x.jpeg";
import NewArrivalsBanner_1200x from "../../../../assets/images/homepage/banners/New_Arrivals_Banner_1200x.jpeg";
import NewArrivalsBanner_1400x from "../../../../assets/images/homepage/banners/New_Arrivals_Banner_1400x.jpeg";
import AlGurgPaintsBanner_800x from "../../../../assets/images/homepage/banners/alGurgPaintsBanner_800x.jpeg";
import AlGurgPaintsBanner_1000x from "../../../../assets/images/homepage/banners/alGurgPaintsBanner_1000x.jpeg";
import AlGurgPaintsBanner_1200x from "../../../../assets/images/homepage/banners/alGurgPaintsBanner_1200x.jpeg";
import AlGurgPaintsBanner_1400x from "../../../../assets/images/homepage/banners/alGurgPaintsBanner_1400x.jpeg";

const mainSliderDataSet = [
  `${AlGurgPaintsBanner_800x} 768w, ${AlGurgPaintsBanner_1000x} 1000w, ${AlGurgPaintsBanner_1200x} 1200w, ${AlGurgPaintsBanner_1400x} 1400w`,
  `${XpertBanner_800x} 768w, ${XpertBanner_1000x} 1000w, ${XpertBanner_1200x} 1200w, ${XpertBanner_1400x} 1400w`,
  `${NewArrivalsBanner_800x} 768w, ${NewArrivalsBanner_1000x} 1000w, ${NewArrivalsBanner_1200x} 1200w, ${NewArrivalsBanner_1400x} 1400w`,
];
SwiperCore.use([Pagination, Navigation]);

const MainSlider = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      className="homepage-main-banner-swiper"
    >
      {mainSliderDataSet.map((data, index) => {
        return (
          <SwiperSlide>
            <img className="swiper-image" srcSet={data} alt="" key={index} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default MainSlider;
