import React, { Fragment, useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Redirect, useLocation } from "react-router-dom";
import Header from "components/header/Header";
import { getUniqueParents } from "Extra/GetUnqiueParents";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { set_countries } from "redux/actions/CountryAction";
import { set_categories } from "redux/actions/CategoriesAction";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import { getRandomInt, deleteS3Image } from "utils/utils";
import "./editbrand.scss";
import { type } from "components/Dropzone/type";
import { getClass } from "utils/utils";

const variantSchema = Yup.object().shape({
  country_id: Yup.number().required("Required"),
  name_en: Yup.string().required("Required"),
  image: Yup.string().required("Required").nullable("Required"),
});

const EditBrand = (props) => {
  const param = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const locationName = location.state.name;
  const [pageName, setPageName] = useState(locationName);
  const [initValues, setInitValues] = useState({
    country_name_ar: "",
    country_name_en: "",
    country_id: "",
    name_ar: "",
    name_en: "",
    image: "",
  });
  const [data, setData] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [isEditClick, setisEditClick] = useState(false);
  const [deletedImagesUrl, setDeletedImagesUrl] = useState([]);

  var [clist, setClist] = useState([]);

  useEffect(() => {
    document.title = "Brand";
  }, []);

  let countriesList = [];
  let message = "";
  const [UniqueParentCategoriesList, setUniqueParentCategoriesList] = useState(
    []
  );
  let tcatlist = [];

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    setDeletedImagesUrl([]);
    if (pageName === "edit" || pageName === "view") {
      setData(true);
      apiJson.brands.getBrandDetails(parseInt(param.id)).then((response) => {
        if (response.success) {
          setInitValues(response.data);
          setData(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          setData(false);
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            setData(false);
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            setData(false);
            showError();
          }
        }
      });
    } else {
      setInitValues({
        country_name_ar: "",
        country_name_en: "",
        country_id: "",
        name_ar: "",
        name_en: "",
        image: "",
      });
      setData(false);
    }
  }, []);

  useEffect(() => {
    props.countries.map((cou) => {
      countriesList.push({
        value: cou.id,
        label: cou.name_en,
      });
      return cou;
    });
    setClist(countriesList);
    setUniqueParentCategoriesList(
      getUniqueParents(props.forBrand, props.countries)
    );
    // eslint-disable-next-line
  }, [props.countries, props.forBrand]);

  const handleEdit = () => {
    setPageName("edit");
    location.state.name = "edit";
  };

  useEffect(() => {
    if (pageName === "create" || pageName === "edit") {
      setisEditClick(false);
    } else if (pageName === "view") {
      setisEditClick(true);
    }
  }, [pageName]);
  const heading = isEditClick ? "View Brand" : "Create/Edit a Brand";

  useEffect(() => {
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        props.set_countries({ countries: response.data });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
        }
      }
    });
  }, []);

  useEffect(() => {
    apiJson.categories.getCategoriesForBrand().then((response) => {
      if (response.success) {
        props.set_categories({ forBrand: response.data });

        response.data.map((category) => {
          if (
            location.state.name === "view" ||
            location.state.name === "edit"
          ) {
            if (
              location.state.brand.currentBrand.country_name_en ===
              category.country_name_en
            ) {
              tcatlist.push({
                value: category.id,
                label: category.title_en,
              });
            }
          }
          return category;
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const Update = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.brands
      .updateBrand(parseInt(param.id), {
        brand: {
          name_en: values.name_en,
          name_ar: values.name_ar.length < 1 ? values.name_en : values.name_ar,
          image: values.image,
          country_id: values.country_id,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Brand Updated";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Create = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.brands
      .createBrand({
        brand: {
          name_en: values.name_en,
          name_ar: values.name_ar.length < 1 ? values.name_en : values.name_ar,
          country_id: values.country_id,
          image: values.image,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Brand Created";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const handleFilesChange = (files, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        setFieldValue("image", response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleCountry = (e, setFieldValue) => {
    setFieldValue("country_id", e.value);
    setFieldValue("country_name_en", e.label);
    // eslint-disable-next-line
    UniqueParentCategoriesList.map((parentCat) => {
      if (parentCat.id === e.value) {
        parentCat.list.map((pcat) => {
          tcatlist.push({
            value: pcat.id,
            label: pcat.title_en,
          });
          return pcat;
        });
      }
    });
  };

  const handleSave = (values) => {
    if (pageName === "edit") {
      Update(values);
    } else if (pageName === "create") {
      Create(values);
    }
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this brand?")) {
      setLoading(true);
      apiJson.brands.removeBrand(parseInt(param.id)).then((response) => {
        if (response.success) {
          message = "Brand Deleted";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="right-content brand-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage the Brands on your site."
          />
        </div>
        {data ? (
          <div className="d-flex justify-content-center align-item-center w-100">
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <div className="main-row brand-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={initValues}
                validationSchema={variantSchema}
                onSubmit={(values) => handleSave(values)}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="brand-form mx-auto">
                    <fieldset disabled={isEditClick}>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Name
                            </div>
                            <div className="col-md-8 col-lg-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.name_en && errors.name_en
                                )}`}
                                name="name_en"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.name_en && errors.name_en && (
                                <div className="english-error">
                                  {errors.name_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="arabic-col col-6">
                          <div className="row justify-content-end mx-0">
                            <div className="col-md-8 col-lg-8 col-xl-7 input">
                              <Field
                                className="w-100"
                                name="name_ar"
                                type="text"
                                placeholder="أدخل الاسم"
                              />
                            </div>
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              اسم
                            </div>
                          </div>
                        </div>
                      </div>
                      {pageName !== "edit" && (
                        <div className="row mx-0">
                          <div className="english-col col-6">
                            <div className="row mx-0">
                              <div className="label col-md-4 col-lg-4 col-xl-3">
                                Country
                              </div>
                              <div className="col-md-8 col-lg-8 col-xl-7 input">
                                <div className="select">
                                  <Select
                                    value={
                                      values.country_id
                                        ? {
                                            value: values.country_id,
                                            label: values.country_name_en,
                                          }
                                        : ""
                                    }
                                    onChange={(value) =>
                                      handleCountry(value, setFieldValue)
                                    }
                                    onBlur={() =>
                                      setFieldTouched("country_name_en", true)
                                    }
                                    options={clist}
                                    className={`category-select ${getClass(
                                      touched.country_id && errors.country_id
                                    )}`}
                                    classNamePrefix="select"
                                    name="country"
                                    isSearchable={false}
                                    isDisabled={isEditClick}
                                  />
                                </div>
                                {touched.country_id && errors.country_id && (
                                  <div className="english-error">
                                    {errors.country_id}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0 mb-0 align-items-start">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Brand Image
                            </div>
                            {uploadingImages ? (
                              <div className="d-flex justify-content-center align-item-center w-100">
                                <ClipLoader color="#000" loading size={20} />
                              </div>
                            ) : values.image ? (
                              <ImagesGrid
                                name={location.state.name}
                                remove={(val, url) => {
                                  setFieldValue("image", "");
                                  setDeletedImagesUrl([
                                    ...deletedImagesUrl,
                                    url,
                                  ]);
                                }}
                                images={[values.image]}
                              />
                            ) : (
                              touched.image &&
                              errors.image && (
                                <div
                                  className="english-error"
                                  style={{
                                    width: "fit-content",
                                    color: "red",
                                    padding: "0",
                                  }}
                                >
                                  {errors.image}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      {location.state.name !== "view" && (
                        <div className="d-flex justify-content-center align-items-cenetr w-100">
                          <DropzoneElement
                            multiple={false}
                            placeholder="Click to select a file"
                            updateSelectedServiceFiles={(value) =>
                              handleFilesChange(value, setFieldValue)
                            }
                            type={type.IMAGE}
                          />
                        </div>
                      )}
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-12 mx-auto d-flex flex-column">
                        <div className="button-group d-flex justify-content-center align-items-center">
                          {!isEditClick || location.state.name === "create" ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                disabled={loading}
                                key={getRandomInt(1, 1000)}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={21}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => handleEdit()}
                              >
                                Edit
                              </button>
                              <button
                                className="blue-button"
                                type="button"
                                style={{ backgroundColor: "#cd0e0e" }}
                                key={getRandomInt(1, 1000)}
                                onClick={() => handleDelete()}
                                disabled={loading}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={22}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {redirectBack ? <Redirect push to="/brands" /> : ""}
                    {redirect ? <Redirect push to="/brands" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    categories: state.category.categories,
    parentCategories: state.category.parentCategories,
    forBrand: state.category.forBrand,
    topLevelCategories: state.category.topLevel,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = { set_categories, set_countries };

export default connect(mapStateToProsps, mapDispatchToProps)(EditBrand);
