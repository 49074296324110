import React, { Fragment, useEffect, useState } from "react";

import "./sectionItems.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { set_single_section } from "redux/actions/SingleSectionAction";
import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";

const SectionItems = (props) => {
  const param = useParams();
  const location = useLocation();
  useEffect(() => {
    document.title = "Section Items";
  }, []);
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const country_id = location.state.countryID.countryID;
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    apiJson.sections
      .getSingleSection(parseInt(param.id), country_id)
      .then((response) => {
        if (response.success) {
          props.set_single_section({ section: response.data });
          props.set_single_section({ products: response.data.products });
          props.set_single_section({ services: response.data.services });

          setData(true);
          setHeaderList({
            id: "storestab",
            tablist: [
              {
                id: "all",
                count:
                  response.data.products.length + response.data.services.length,
              },
            ],
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          setData(true);
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  }, []);

  return (
    <Fragment>
      <div className="right-content stores-page-container w-100">
        <div className="header-row">
          <Header
            heading="Section Items"
            subtext="Create, edit, and manage the Section Items on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="stores-tab">
                <HeaderTab tab={headerList}>
                  <TableList
                    List={props.section}
                    onDelete={() =>
                      setHeaderList({
                        ...headerList,
                        tablist: [
                          {
                            id: "all",
                            count: headerList.tablist[0].count - 1,
                          },
                        ],
                      })
                    }
                  />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    section: state.single_section.section,
    products: state.single_section.products,
    services: state.single_section.services,
  };
};

const mapDispatchToProps = { set_single_section };

export default connect(mapStateToProsps, mapDispatchToProps)(SectionItems);
