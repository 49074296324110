import { type } from "../types/types";

export const set_variants = (variants) => (dispatch) => {
  dispatch({
    type: type.SET_VARIANTS,
    variants: variants,
  });
};

export const set_variant_details = (variantDetails) => (dispatch) => {
  dispatch({
    type: type.SET_VARIANT_DETAIL,
    variantDetails: variantDetails,
  });
};

export const remove_variant = (variantId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_VARIANT,
    variantId: variantId,
  });
};
