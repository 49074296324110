import DropzoneElement from "components/Dropzone/DropzoneElement";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { type } from "components/Dropzone/type";
import "../assets/styles/product/variantCard.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import { useParams } from "react-router-dom";
import { set_deleted_images, set_variants } from "redux/actions/ProductsAction";
import { connect } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getClass, IsQuillEmpty } from "utils/utils";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

function VariantCard(props) {
  const { state } = useParams();
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadingSingleImage, setUploadingSingleImage] = useState(false);

  const resizeFileForDesktop = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const resizeFileForMob = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleFilesChange = (files, setFieldValue, images) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        setFieldValue(`variants.${props.index}.images`, [
          ...images,
          ...response.map((image) => image.location),
        ]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleSingleFileChange = async (files, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingSingleImage(true);
    const resizeImageDesktop = await resizeFileForDesktop(files[0]);
    const resizeImageMob = await resizeFileForMob(files[0]);

    files.push(resizeImageMob);
    files.push(resizeImageDesktop);
    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });
    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingSingleImage(false);
        setFieldValue(
          `variants.${props.index}.featured_image`,
          response[0].location
        );
        setFieldValue(`variants.${props.index}.featured_image_resized`, [
          response[1].location,
          response[2].location,
        ]);
      })
      .catch((err) => {
        setUploadingSingleImage(false);
      });
  };

  useEffect(() => {
    if (
      props.selected === props.variant.id &&
      props.variants[0].id !== props.selected
    ) {
      document
        .getElementById(`${props.selected}`)
        ?.previousSibling?.scrollIntoView();
    }
    // eslint-disable-next-line
  }, [props.selected]);
  return (
    <>
      {isNaN(props.variant._destroy) && (
        <div
          id={props.variant.id}
          className={`variant-card-desktop row g-0 ${!(
            typeof props.variant?.id === "string" ||
            props.selected === props.variant.id ||
            (props.variants_errors?.[props.index] !== undefined &&
              state !== "view")
          )
            ? "pointer collapse-card"
            : ""
            }`}
          style={{ marginTop: "20px" }}
          onClick={() => {
            if (
              !(
                typeof props.variant?.id === "string" ||
                props.selected === props.variant.id
              )
            ) {
              props.onUpdateVar(props.variant.id);
            }
          }}
        >
          <div className={`english-col row gx-0 justify-content-between`}>
            <div className="label col-2">Variant {props.index + 1}</div>
            {state !== "view" && (
              <div className="col-2 d-flex justify-content-end pointer delete-icon">
                <AiOutlineDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      window.confirm(
                        "Are you sure you want to delete this variant?"
                      )
                    ) {
                      props.onUpdate(props.variant.id);
                    }
                  }}
                  size={20}
                />
              </div>
            )}
          </div>

          {typeof props.variant?.id === "string" ||
            props.selected === props.variant.id ||
            (props.variants_errors?.[props.index] !== undefined &&
              state !== "view") ? (
            <div>
              <div className="english-col row gx-0">
                <label className="label col-2">Name</label>
                <div className="col-8 input">
                  <Field
                    className={`l-field-1 col-12 ${getClass(
                      props.variants_touched?.[props.index]?.name_en &&
                      props.variants_errors?.[props.index]?.name_en
                    )}`}
                    name={`variants.${props.index}.name_en`}
                    type="text"
                    placeholder="Enter name"
                  />
                  {props.variants_touched?.[props.index]?.name_en &&
                    props.variants_errors?.[props.index]?.name_en && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.name_en}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <div className="col-8 input offset-2">
                  <Field
                    className="w-100 arabic-direction"
                    placeholder="أدخل الاسم"
                    type="text"
                    name={`variants.${props.index}.name_ar`}
                  />
                </div>
                <label className="label col-2 text-end">اسم</label>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">Description</label>
                <div className="col-8 input">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.description_en`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.description_en &&
                          props.variants_errors?.[props.index]?.description_en
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className={getClass(
                      //     props.variants_touched?.[props.index]
                      //       ?.description_en &&
                      //     props.variants_errors?.[props.index]?.description_en
                      //   )}
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                  {props.variants_touched?.[props.index]?.description_en &&
                    props.variants_errors?.[props.index]?.description_en && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.description_en}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <div className="col-8 input offset-2">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.description_ar`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.description_ar &&
                          props.variants_errors?.[props.index]?.description_ar
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className="w-100 arabic-direction"
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                </div>
                <label className="label col-2 text-end">وصف</label>
              </div>
              <div className="english-col row gx-0">
                <label className="label col-2">Benefits</label>
                <div className="col-8 input">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.benefits_en`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.benefits_en &&
                          props.variants_errors?.[props.index]?.benefits_en
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className={getClass(
                      //     props.variants_touched?.[props.index]?.benefits_en &&
                      //     props.variants_errors?.[props.index]?.benefits_en
                      //   )}
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                  {props.variants_touched?.[props.index]?.benefits_en &&
                    props.variants_errors?.[props.index]?.benefits_en && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.benefits_en}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <div className="col-8 input offset-2">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.benefits_ar`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.benefits_ar &&
                          props.variants_errors?.[props.index]?.benefits_ar
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className="w-100 arabic-direction"
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                </div>
                <label className="label col-2 text-end">فوائد</label>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">Features</label>
                <div className="col-8 input">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.features_en`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.features_en &&
                          props.variants_errors?.[props.index]?.features_en
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className={getClass(
                      //     props.variants_touched?.[props.index]?.features_en &&
                      //     props.variants_errors?.[props.index]?.features_en
                      //   )}
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                  {props.variants_touched?.[props.index]?.features_en &&
                    props.variants_errors?.[props.index]?.features_en && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.features_en}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <div className="col-8 input offset-2">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.features_ar`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.features_ar &&
                          props.variants_errors?.[props.index]?.features_ar
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className="w-100 arabic-direction"
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                </div>
                <label className="label col-2 text-end">سمات</label>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">How to use</label>
                <div className="col-8 input">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.how_to_use_en`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.how_to_use_en &&
                          props.variants_errors?.[props.index]?.how_to_use_en
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className={getClass(
                      //     props.variants_touched?.[props.index]
                      //       ?.how_to_use_en &&
                      //     props.variants_errors?.[props.index]?.how_to_use_en
                      //   )}
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                  {props.variants_touched?.[props.index]?.how_to_use_en &&
                    props.variants_errors?.[props.index]?.how_to_use_en && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.how_to_use_en}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <div className="col-8 input offset-2">
                  <Field
                    className="w-100"
                    name={`variants.${props.index}.how_to_use_ar`}
                    type="text"
                  >
                    {({ field }) => (
                      <CKEditor
                        className={getClass(
                          props.variants_touched?.[props.index]
                            ?.how_to_use_ar &&
                          props.variants_errors?.[props.index]?.how_to_use_ar
                        )}
                        editor={ClassicEditor}
                        data={field.value ? field.value : ""}
                        disabled={state === "view"}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          props.setfieldvalue(field.name, data ? data : "");
                          // console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                          props.setfieldtouched(field.name, true)
                          // console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          // console.log( 'Focus.', editor );
                        }}
                      />
                      // <ReactQuill
                      //   className="w-100 arabic-direction"
                      //   readOnly={state === "view"}
                      //   value={field.value ? field.value : ""}
                      //   onChange={(e) => {
                      //     !IsQuillEmpty(e)
                      //       ? props.setfieldvalue(field.name, e)
                      //       : props.setfieldvalue(field.name, "");
                      //   }}
                      //   onBlur={() => props.setfieldtouched(field.name, true)}
                      // />
                    )}
                  </Field>
                </div>
                <label className="label col-2 text-end">كيف تستعمل</label>
              </div>

              {
                props.product_type == "wholesale" ?
                  <div>
                    <div className="row mx-0">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <div className="row english-col mx-0">
                          <div className="col-6 input">
                            <label className="label">Quantity Range:</label>
                            <div className="d-flex align-items-center">
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty1range_from`}
                              // value={props.variant.qty1range_from ? props.variant.qty1range_from : ""}
                              />
                              <p className="m-0">&nbsp;-&nbsp;</p>
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty1range_to`}
                              // value={props.variant.qty1range_to ? props.variant.qty1range_to : ""}
                              />
                            </div>
                          </div>
                          <div className="col-6 input">
                            <label className="label">Price</label>
                            <br />
                            <Field
                              className="w-25"
                              placeholder="0"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              min="0"
                              step="1"
                              name={`variants.${props.index}.qty1range_price`}
                              value={props.variant.qty1range_price ? props.variant.qty1range_price : ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row mx-0">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <div className="row mx-0 english-col">
                          <div className="col-6 input">
                            <label className="label">Quantity Range:</label>
                            <div className="d-flex align-items-center">
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty2range_from`}
                                value={props.variant.qty2range_from ? props.variant.qty2range_from : ""}
                              />
                              <p className="m-0">&nbsp;-&nbsp;</p>
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty2range_to`}
                                value={props.variant.qty2range_to ? props.variant.qty2range_to : ""}
                              />
                            </div>
                          </div>
                          <div className="col-6 input">
                            <label className="label">Price</label>
                            <br />
                            <Field
                              className="w-25"
                              placeholder="0"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              min="0"
                              step="1"
                              name={`variants.${props.index}.qty2range_price`}
                              value={props.variant.qty2range_price ? props.variant.qty2range_price : ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row mx-0">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <div className="row mx-0 english-col">
                          <div className="col-6 input">
                            <label className="label">Quantity Range:</label>
                            <div className="d-flex align-items-center">
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty3range_from`}
                                value={props.variant.qty3range_from ? props.variant.qty3range_from : ""}
                              />
                              <p className="m-0">&nbsp;-&nbsp;</p>
                              <Field
                                className="w-25"
                                placeholder="0"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                min="0"
                                step="1"
                                name={`variants.${props.index}.qty3range_to`}
                                value={props.variant.qty3range_to ? props.variant.qty3range_to : ""}
                              />
                            </div>
                          </div>
                          <div className="col-6 input">
                            <label className="label">Price</label>
                            <br />
                            <Field
                              className="w-25"
                              placeholder="0"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              min="0"
                              step="1"
                              name={`variants.${props.index}.qty3range_price`}
                              value={props.variant.qty3range_price ? props.variant.qty3range_price : ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                  :
                  <>
                    <div className="english-col row gx-0">
                      <label className="label col-2">Price</label>
                      <div className="col-8 input">
                        <Field
                          className={`w-100 ${getClass(
                            props.variants_touched?.[props.index]?.price &&
                            props.variants_errors?.[props.index]?.price
                          )}`}
                          placeholder="0.0"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          step="0.01"
                          name={`variants.${props.index}.price`}
                          value={props.variant.price ? props.variant.price : ""}
                        />
                        {props.variants_touched?.[props.index]?.price &&
                          props.variants_errors?.[props.index]?.price && (
                            <div className="en-error">
                              {props.variants_errors?.[props.index]?.price}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="english-col row gx-0">
                      <label className="label col-2">Discounted Price</label>
                      <div className="col-8 input">
                        <Field
                          className={`w-100 ${getClass(
                            props.variants_touched?.[props.index]?.discounted_price &&
                            props.variants_errors?.[props.index]?.discounted_price
                          )}`}
                          placeholder="0.0"
                          type="number"
                          value={
                            props.variant.discounted_price
                              ? props.variant.discounted_price
                              : ""
                          }
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          step="0.01"
                          name={`variants.${props.index}.discounted_price`}
                        />
                        {props.variants_touched?.[props.index]?.discounted_price &&
                          props.variants_errors?.[props.index]?.discounted_price && (
                            <div className="en-error">
                              {props.variants_errors?.[props.index]?.discounted_price}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="english-col row gx-0">
                      <label className="label col-2">Quantity</label>
                      <div className="col-8 input">
                        <Field
                          className={`w-100 ${getClass(
                            props.variants_touched?.[props.index]?.quantity &&
                            props.variants_errors?.[props.index]?.quantity
                          )}`}
                          placeholder="0"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          step="1"
                          name={`variants.${props.index}.quantity`}
                          value={
                            props.variant.quantity
                              ? Math.ceil(props.variant.quantity)
                              : ""
                          }
                        />
                        {props.variants_touched?.[props.index]?.quantity &&
                          props.variants_errors?.[props.index]?.quantity && (
                            <div className="en-error">
                              {props.variants_errors?.[props.index]?.quantity}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
              }

              <div className="english-col row gx-0">
                <label className="label col-2">Variation</label>
                <div className="col-8 input">
                  <Field
                    className={`w-100 ${getClass(
                      props.variants_touched?.[props.index]?.variation &&
                      props.variants_errors?.[props.index]?.variation
                    )}`}
                    placeholder="Enter variation"
                    type="text"
                    name={`variants.${props.index}.variation`}
                  />
                  {props.variants_touched?.[props.index]?.variation &&
                    props.variants_errors?.[props.index]?.variation && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.variation}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">SKU</label>
                <div className="col-8 input">
                  <Field
                    className={`w-100 ${getClass(
                      props.variants_touched?.[props.index]?.sku &&
                      props.variants_errors?.[props.index]?.sku
                    )}`}
                    placeholder="Enter sku"
                    type="text"
                    name={`variants.${props.index}.sku`}
                    value={props.variant.sku ? props.variant.sku : ""}
                  />
                  {props.variants_touched?.[props.index]?.sku &&
                    props.variants_errors?.[props.index]?.sku && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.sku}
                      </div>
                    )}
                </div>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">UPC Code</label>
                <div className="col-8 input">
                  <Field
                    className="w-100"
                    placeholder="Enter upc code"
                    type="text"
                    name={`variants.${props.index}.upc_code`}
                    value={props.variant.upc_code ? props.variant.upc_code : ""}
                  />
                </div>
              </div>

              <div className="english-col row gx-0">
                <label className="label col-2">Weight</label>
                <div className="col-8 input">
                  <Field
                    className={`w-100 ${getClass(
                      props.variants_touched?.[props.index]?.weight &&
                      props.variants_errors?.[props.index]?.weight
                    )}`}
                    placeholder="0.0"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="0"
                    step="0.01"
                    name={`variants.${props.index}.weight`}
                    value={props.variant.weight ? props.variant.weight : ""}
                  />
                  {props.variants_touched?.[props.index]?.weight &&
                    props.variants_errors?.[props.index]?.weight && (
                      <div className="en-error">
                        {props.variants_errors?.[props.index]?.weight}
                      </div>
                    )}
                </div>
              </div>
              <div className="images-cont">
                <div className="row g-0">
                  <div className="all-text col-2">Featured Image</div>
                  <div className="col-9">
                    {props.variant.featured_image !== "" ? (
                      <ImagesGrid
                        name={state}
                        remove={(temp, url) => {
                          let resize_urls = [];
                          props.setfieldvalue(
                            `variants.${props.index}.featured_image`,
                            ""
                          );
                          props?.variant?.featured_image_resized &&
                            props?.variant?.featured_image_resized.length > 0 &&
                            props?.variant?.featured_image_resized.map(
                              (url2) => {
                                if (url.includes("https")) {
                                  resize_urls.push(url2);
                                }
                                return url2;
                              }
                            );

                          props.set_deleted_images({
                            deletedImagesUrl: [
                              ...props.deletedImagesUrl,
                              ...resize_urls,
                              url,
                            ],
                          });
                        }}
                        images={[props?.variant?.featured_image]}
                      />
                    ) : (
                      props.variants_touched?.[props.index]?.featured_image &&
                      props.variants_errors?.[props.index]?.featured_image && (
                        <div className="en-error">
                          {props.variants_errors?.[props.index]?.featured_image}
                        </div>
                      )
                    )}
                    {uploadingSingleImage && (
                      <div className="d-flex justify-content-center align-item-center w-100">
                        <ClipLoader color="#000" loading size={20} />
                      </div>
                    )}
                  </div>
                </div>
                {state !== "view" && (
                  <div className="mt-5 d-flex justify-content-center">
                    <DropzoneElement
                      multiple={false}
                      placeholder="Click to select image"
                      updateSelectedServiceFiles={(files) =>
                        handleSingleFileChange(files, props.setfieldvalue)
                      }
                      type={type.IMAGE}
                    />
                  </div>
                )}
              </div>
              <div className="images-cont">
                <div className="row g-0">
                  <div className="all-text col-2">Variant Images</div>

                  <div className="col-9">
                    {props.variant?.images?.length > 0 ? (
                      <ImagesGrid
                        name={state}
                        remove={(val, url) => {
                          props.set_deleted_images({
                            deletedImagesUrl: [...props.deletedImagesUrl, url],
                          });
                          props.setfieldvalue(
                            `variants.${props.index}.images`,
                            val
                          );
                        }}
                        images={props.variant.images}
                      />
                    ) : (
                      props.variants_touched?.length > 0 &&
                      props.variants_touched[props.index]?.images &&
                      props.variants_errors?.length > 0 &&
                      props.variants_errors[props.index]?.images && (
                        <div className="en-error">
                          {props.variants_errors[props.index]?.images}
                        </div>
                      )
                    )}
                    {uploadingImages && (
                      <div className="d-flex justify-content-center align-item-center w-100">
                        <ClipLoader color="#000" loading size={20} />
                      </div>
                    )}
                  </div>
                </div>
                {state !== "view" && (
                  <div className="mt-5 d-flex justify-content-center">
                    <DropzoneElement
                      placeholder="Click to select images"
                      type={type.IMAGE}
                      updateSelectedServiceFiles={(files) =>
                        handleFilesChange(
                          files,
                          props.setfieldvalue,
                          props.variant.images
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="label col-8">{props.variant.name_en}</div>
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    variants: state.product.variants,
    deletedImagesUrl: state.product.deletedImagesUrl,
  };
};

const mapDispatchToProps = { set_variants, set_deleted_images };

export default connect(mapStateToProps, mapDispatchToProps)(VariantCard);
