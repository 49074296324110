const FilteredList = (List, id) => {
  return List.filter((item) => item.country_id === id);
};
export const getUniqueParents = (list, countries) => {
  var parent_country_list = [];
  var filtered = [];
  countries.map((country) => {
    filtered = FilteredList(list, country.id);
    parent_country_list.push({ id: country.id, list: filtered });
    return country;
  });
  return parent_country_list;
};
