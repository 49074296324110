import React, { Fragment, useState, useEffect } from "react";
import Header from "components/header/Header";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import { set_returns } from "redux/actions/ReturnsAction";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./returns.scss";

const Returns = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [tabTitle, settabTitle] = useState("requested");

  const tabParam = (tab) => {
    switch (tab) {
      case "requested":
        return "&with_status=requested";
      case "rejected":
        return "&with_status=rejected";
      case "approved":
        return "&with_status=approved";
      case "completed":
        return "&with_status=completed";
      default:
        return "&with_status=requested";
    }
  };
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getReturns = (page, params) => {
    setData(false);
    apiJson.returns.getReturns(page, params).then((response) => {
      if (response.success) {
        props.set_returns({
          returns: response.data.refunds,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getReturns(1, tabParam(title));
    }
  };

  const pageChange = (page) => {
    getReturns(page, tabParam(tabTitle));
  };

  useEffect(() => {
    document.title = "Returns";
    getReturns(1, "&with_status=requested");
    apiJson.returns.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "returntab",
            tablist: [
              { id: "requested", count: response.data.requested },
              { id: "rejected", count: response.data.rejected },
              { id: "approved", count: response.data.approved },
              { id: "completed", count: response.data.completed },
            ],
          })
        : setHeaderList({
            id: "returntab",
            tablist: [
              { id: "requested", count: 0 },
              { id: "rejected", count: 0 },
              { id: "approved", count: 0 },
              { id: "completed", count: 0 },
            ],
          });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="right-content returns-page-desktop">
        <div className="header-row">
          <Header
            heading="Returns"
            subtext="Create, edit, and manage Returns on your site."
          />
        </div>
        <div className="main-row returns-page-content-container">
          <div className="content-container">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="returns-tab">
                <HeaderTab
                  onTabChoose={ChangeTabList}
                  tabTitle={tabTitle}
                  tab={headerList}
                >
                  <TableList
                    List={props.returns}
                    onPageChange={pageChange}
                    pagination={props.pagination}
                  />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.return.pagination,
    returns: state.return.returns,
  };
};

const mapDispatchToProps = { set_returns };

export default connect(mapStateToProsps, mapDispatchToProps)(Returns);
