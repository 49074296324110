import S3 from "react-aws-s3";
require("dotenv").config();

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: "media/images",
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};
const DelConfig = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const ReactS3Client = new S3(config);
const ReactS3ClientDel = new S3(DelConfig);

export const FileUploadPromise = (file) => {
  return ReactS3Client.uploadFile(file);
};
export const FileDeletePromise = (filename) => {
  return ReactS3ClientDel.deleteFile(filename);
};
