import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import "./modal.scss";
import { set_stores } from "redux/actions/StoresAction";
import { connect } from "react-redux";
import { apiJson } from "api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

const Modal = (props) => {
  const [optionsList, setOptionsList] = useState([]);

  let message = "";
  const [data, setData] = useState(false);
  const [selectValue, setSelectValue] = useState({
    value: null,
    label: null,
  });
  let extra = [{ value: "allStores", label: "All Stores" }];
  const [isDisable, setIsDisable] = useState(true);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handlSelect = (e) => {
    setIsDisable(false);
    setSelectValue(e);
  };

  useEffect(() => {
    setData(false);
    apiJson.stores.getStores().then((response) => {
      if (response.success) {
        props.set_stores({ stores: response.data });
        response.data.map((store) => {
          extra.push({
            value: store.id.toString(),
            label: store.name_en,
          });
          return store;
        });
        setOptionsList(extra);
        setSelectValue(optionsList[0]);
        setData(true);
      } else if (!response.status) {
        setData(false);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setData(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="report-modal">
        <div className="header w-100 d-flex justify-center align-items-center">
          <div>
            <span>
              {props.title}
              {""} Report
            </span>
          </div>
        </div>
        <div className="boday-row w-100">
          {data ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="d-flex me-2 choose-txt">
                <span>Select store </span>
              </div>
              <div className="select">
                <Select
                  value={selectValue}
                  onChange={handlSelect}
                  options={optionsList}
                  className="category-select"
                  classNamePrefix="select"
                  name="category"
                  isSearchable={false}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          )}
        </div>
        <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
          <button
            className="cancel-button"
            type="button"
            onClick={props.onClose}
          >
            Cancle
          </button>
          <button
            className="generate-button"
            type="submit"
            onClick={() => props.onSubmit(selectValue.value)}
            disabled={isDisable}
          >
            Generate
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { stores: state.stores.stores };
};

const mapDispatchToProps = { set_stores };

export default connect(mapStateToProsps, mapDispatchToProps)(Modal);
