import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { connect } from "react-redux";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CouponModal from "components/modals/CouponModal";
import { set_coupon_details } from "../../../redux/actions/CouponsAction";
import history from "../../../history";

const TableList = (props) => {
  const today = new Date();
  const [currentProduct, setCurrentProduct] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const onEditClick = (currentCoupon) => {
    props.set_coupon_details({ coupanDetails: currentCoupon });
    setshowModal(true);
  };

  const cancelClick = () => {
    history.push("/coupons");
    setshowModal(false);
  };

  const createClick = () => {
    setshowModal(false);
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };

  const menuItems = [
    <MenuItem
      key="1"
      onClick={() => onEditClick(currentProduct)}
      style={{ cursor: "pointer" }}
    >
      Edit
    </MenuItem>,
  ];
  const menu = <Menu className="menu">{menuItems}</Menu>;

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="">Coupon number</td>
            <td>Coupon Code</td>
            <td className="">Starts at</td>
            <td className="">Expires On</td>
            <td className="">Status</td>
            <td>Store</td>
            <td className="">Action</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" &&
            values.map((coupon) => {
              return (
                <tr key={coupon.id}>
                  <td className="">{coupon.id}</td>
                  <td>{coupon.code}</td>
                  <td className="">
                    {moment(new Date(coupon.start_date)).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </td>
                  <td className="">
                    {moment(new Date(coupon.expiry)).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </td>
                  <td className="">
                    <div className="status-text">
                      {new Date(coupon.expiry) >= today
                        ? new Date(coupon.start_date) <= today
                          ? "Active"
                          : "Pending"
                        : "Expired"}
                    </div>
                  </td>
                  <td>{coupon.store_name_en ? coupon.store_name_en : "-"}</td>
                  <td className="">
                    <div className="action-icon">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        <button>
                          <BsThreeDots
                            onMouseEnter={() => setCurrentProduct(coupon)}
                          />
                        </button>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            forcePage={parseInt(props?.pagination?.page) - 1}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {showModal && (
        <div className="modal-container">
          <CouponModal
            heading="Edit Coupon"
            cancelClick={cancelClick}
            createClick={createClick}
            editPage
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coupanDetails: state.coupon.coupanDetails,
  };
};

const mapDispatchToProps = { set_coupon_details };

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
