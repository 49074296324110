import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../assets/styles/NotFound/NotFound.scss";
import { set_redirect_home } from "../../redux/actions/NotFoundAction";

function NotFound(props) {
  const history = useHistory();
  // eslint-disable-next-line
  useEffect(() => props.set_redirect_home(false), []);
  return (
    <div className="not-foun-container">
      <div className="heading">404</div>
      <div className="sub-heading">Not Found</div>
      <div className="text">
        The resource requested could not be found on the server!
      </div>
      <button
        onClick={() => {
          history.push("/dashboard");
        }}
        className="go-to-home "
      >
        Go to Home
      </button>
    </div>
  );
}
const mapDispatchToProps = { set_redirect_home };

export default connect(null, mapDispatchToProps)(NotFound);
