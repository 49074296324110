import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";

const TableList = (props) => {
  const [receipt, setReceipt] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const menuItems = [
    <MenuItem
      key="1"
      onClick={() => setVars(orderID)}
      c
      style={{ cursor: "pointer" }}
    >
      View Details
    </MenuItem>,
    <MenuItem
      key="2"
      onClick={() => !receipt && generaReceipt(orderID)}
      style={{ cursor: "pointer" }}
    >
      Generate Receipt
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={() => !receipt && generatePackingSlip(orderID)}
      style={{ cursor: "pointer" }}
    >
      Generate Packing Slip
    </MenuItem>,
  ];

  const menu = <Menu className="menu">{menuItems}</Menu>;

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  const [redirect, setRedirect] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");
  const setVars = (id) => {
    setCurrentOrderId(id);
    setRedirect(true);
  };

  const generaReceipt = (id) => {
    setReceipt(true);
    apiJson.orders.generateReceipt(id).then((response) => {
      if (response.success) {
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const popup = window.open(fileURL);
        if (!popup || popup.outerHeight === 0) {
          alert(
            "Popup Blocker is enabled! Please add this site to your exception list."
          );
        } else {
          window.open("", "_self");
          window.close();
        }
        setReceipt(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };
  const generatePackingSlip = (id) => {
    setReceipt(true);
    apiJson.orders.generatePackingSlip(id).then((response) => {
      if (response.success) {
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const popup = window.open(fileURL);
        if (!popup || popup.outerHeight === 0) {
          alert(
            "Popup Blocker is enabled! Please add this site to your exception list."
          );
        } else {
          window.open("", "_self");
          window.close();
        }
        setReceipt(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="header-text">Order #</td>
            <td className="header-text">Order Type</td>
            <td className="header-text">Items Count</td>
            <td className="header-text">Customer Email</td>
            <td className="header-text">Status</td>
            <td className="header-text">Total Price</td>
            <td className="header-text">Discount</td>
            <td className="header-text">Payment Method</td>
            <td className="header-text">Created at</td>
            <td className="header-text">Actions</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((variant) => {
              let id = variant.id;
              return (
                <tr
                  key={variant.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => setVars(id)}
                >
                  <td className="order-number">{variant.id}</td>
                  <td className="items-count">{variant.is_delivery == 0 ? "Pickup" : "Home Delivery"}</td>
                  <td className="items-count">{variant.item_count}</td>
                  <td className="email-text">{variant.customer_email}</td>
                  <td>
                    <div
                      className="status-text"
                      style={{ textTransform: "capitalize" }}
                    >
                      {variant.status && variant.status.includes("_")
                        ? variant.status.replace("_", " ")
                        : variant.status}
                    </div>
                  </td>
                  <td className="total-price-text">{variant.grand_total}</td>
                  <td className="discount-price-text">{variant.discount}</td>
                  <td>
                    {
                      variant.payment_methods ?
                        variant.payment_methods.cash_on_delivery ?
                          <div>Cash</div>
                          :
                          <></>
                        :
                        <></>
                    }
                    {
                      variant.payment_methods ?
                        variant.payment_methods.payment_card ?
                          <div>Card</div>
                          :
                          <></>
                        :
                        <></>
                    }
                    {
                      variant.payment_methods ?
                        variant.payment_methods.wallet ?
                          <div>Wallet</div>
                          :
                          <></>
                        :
                        <></>
                    }
                  </td>
                  <td className="created-at">
                    {variant.created_at
                      ? moment(new Date(variant.created_at)).format("LL")
                      : "--"}
                  </td>
                  <td
                    className="header-text"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown
                      className="dropdown-for-order-actions"
                      overlay={menu}
                      overlayClassName="overlay-class"
                    >
                      <BsThreeDots
                        onMouseEnter={() => !receipt && setOrderID(id)}
                        onClick={() => !receipt && setOrderID(id)}
                      />
                    </Dropdown>
                    {receipt && variant.id === orderID && (
                      <div className="d-flex justify-content-center align-items-center">
                        <ClipLoader color="#000" loading size={15} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            forcePage={parseInt(props?.pagination?.page) - 1}
            pageClassName={" ps-3 pe-3 pt-2 pb-2 "}
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `/order-details/${currentOrderId}`,
            state: { OrderId: { currentOrderId } },
          }}
        />
      )}
    </div>
  );
};

export default TableList;
