import React, { Fragment, useEffect, useState } from "react";
import "./createoffer.scss";
import Header from "components/header/Header";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import ProductPage from "../productCard/ProductPage";
import { Link } from "react-router-dom";

const CreateOffer = (props) => {
  const [expiry, setExpiry] = useState(new Date());
  const [offers_sections, setOffersSections] = useState([]);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Create Offer 2";

    apiJson.offer.getOffersSections(1)
      .then(response => {
        if (response.success) {
          console.log(response.data);
          var sections = [];

          response.data.sections.forEach(sec => {
            var section = {};
            section.name = sec.title_en;
            section.name_ar = sec.title_ar;

            var variants = [];
            sec.offers_section_variants.forEach(offers_variant => {
              variants = variants.concat({
                discounted_price: offers_variant.variant.discounted_price,
                price: offers_variant.variant.price,
                featured_image_resized: offers_variant.variant.featured_image_resized,
                id: offers_variant.variant.id,
                name_en: offers_variant.variant.name_en,
                name_ar: offers_variant.variant.name_ar,
                product_name_en: offers_variant.variant.product_name_en,
                product_name_ar: offers_variant.variant.product_name_ar,
                sku: offers_variant.variant.sku
              });
            });
            section.variants = variants;

            sections = sections.concat(section);
          });
          setOffersSections(sections);
        }
      })
  }, []);

  const setExpiryDate = (date) => {
    if (date >= new Date()) {
      setExpiry(date);
    } else {
      message = "Date cannot be in past.";
      showError();
    }
  };

  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      var skus = [];
      var sections = [];

      data.forEach((row, i) => {
        if (i > 0) {
          if (row[0] != undefined) {
            skus = skus.concat(row[0].toString());
            var find_section = sections.find(s => s.name == row[1].toString());

            if (find_section) {
              var section_variants = find_section.variants;
              section_variants.push(row[0].toString());
              find_section.variants = section_variants;
            }
            else {
              sections.push({
                name: row[1].toString(),
                name_ar: row[2].toString(),
                variants: [
                  row[0].toString()
                ]
              });
            }
          }
        }
      });

      apiJson.offer.getVariantsBySku({ skus })
        .then(response => {
          if (response.success) {
            sections.forEach(section => {
              section.variants.forEach((section_variant, i) => {
                var variant = response.data.find(variant => variant.sku == section_variant);
                if (variant) {
                  section.variants[i] = variant;
                }
                else {
                  console.log("Error in variant : " + section_variant);
                }
              });
            })
            setOffersSections(sections);
            // console.log(sections);
          }
          else {

          }
        })

    };
    reader.readAsBinaryString(file);
  };

  const onDeleteVariantClicked = (section_name, variant_id) => {
    var sections = Object.assign([], offers_sections);

    sections.forEach((section, i) => {
      if (section.name == section_name) {
        var section_variants = section.variants.filter(variant => variant.id != variant_id);
        sections[i].variants = section_variants;
      }
    });
    setOffersSections(sections);
  }

  const createOfferClicked = () => {
    var final_sections = [];
    offers_sections.forEach(section => {
      var variants_row = [];
      section.variants.forEach((variant, key) => {
        variants_row = variants_row.concat({
          id: variant.id,
          position: key
        });
      });
      final_sections = final_sections.concat({
        name_en: section.name,
        name_ar: section.name_ar,
        variants: variants_row
      });
    });

    // alert(expiry.toISOString());
    if (expiry >= new Date()) {
      apiJson.offer
        .createOffer({
          expiry: expiry,
        })
        .then((response) => {
          if (response.success) {
            console.log(JSON.stringify(final_sections));
            apiJson.offer.createOffersSections(1, {
              sections: final_sections
            })
              .then(response => {
                if (response.success) {
                  message = "Offers created successfully.";
                  showSuccess();
                }
              });
          }
        });
    } else {
      message = "Date cannot be in past.";
      showError();
    }
  };

  return (
    <Fragment>
      <div className="order-page-container right-content order-page-container w-100">
        <div className="header-row">
          <Header
            heading="Create Offer 2"
            subtext="Create offer 2 for your customers."
            filters={null}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content">
            <Link to="/offers/banners" className="btn btn-primary">Offers Banners</Link>
            <br /><br />
          </div>
          <div className="content mx-auto">
            <div className="products-section mx-auto">
              <h5>Create an offer</h5>
              <br />
              <div className="english-col row gx-0">
                <label className="label col-2">Offer expiry date</label>
                {/* <div className="col-8"> */}
                <DatePicker
                  selected={expiry}
                  onChange={(date) => setExpiryDate(date)}
                />
                {/* <input
                    type="datetime-local" value={expiry} /> */}
                {/* </div> */}
              </div>
              <label className="label col-2"><b>Import Excel File:</b></label>
              <div>
                <input type="file" onChange={onChange} />
              </div>
              <br />
              <input
                type="button"
                value="CREATE AN OFFER"
                className="btn"
                onClick={createOfferClicked}
              />
            </div>
          </div>
          <div className="row">
            {
              offers_sections.map(section => {
                return (
                  <div className="col-lg-6 card" style={{ marginTop: "10px" }}>
                    <p className="title">{section.name} ({section.name_ar})</p>
                    <hr />
                    {
                      section.variants.map((variant, i) => {
                        return (
                          <>
                            <div className="variant-box">
                              {
                                variant.featured_image_resized ?
                                  <img src={variant.featured_image_resized[0]} className="variant-img" />
                                  :
                                  <></>
                              }
                              <div>
                                <p className="product-title">{variant.product_name_en + " - " + variant.name_en}</p>
                                <p className="discounted-price">AED {variant.discounted_price}</p>
                              </div>
                              <span className="cross-icon" onClick={() => onDeleteVariantClicked(section.name, variant.id)}>x</span>
                            </div>
                            {
                              section.variants.length == i + 1 ?
                                <></>
                                :
                                <hr />
                            }
                          </>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOffer;
