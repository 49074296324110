import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";
import { BsThreeDots } from "react-icons/bs";
import { ProductTabData } from "../../../Extra/TabsData";
import "./details.scss";
import TableList from "./TableList";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_products } from "redux/actions/ProductsAction";
import Menu, { Item as MenuItem } from "rc-menu";
import { tabParam } from "utils/utils";
import { debounce } from "lodash";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Switch from "react-switch";

const EditCustomer = (props) => {
  let message = "";
  const [first_name_en, setFirstNameEn] = useState("");
  const [last_name_en, setLastNameEn] = useState("");
  const [first_name_ar, setFirstNameAr] = useState("");
  const [last_name_ar, setLastNameAr] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("inactive");

  useEffect(() => {
    apiJson.customers.getCustomer(props.match.params.id)
      .then(response => {
        if (response.success) {
          setFirstNameEn(response.data.first_name_en);
          setLastNameEn(response.data.last_name_en);
          setFirstNameAr(response.data.first_name_ar);
          setLastNameAr(response.data.last_name_ar);
          setEmail(response.data.email);
          setContactNumber(response.data.contact_number);
          setStatus(response.data.status);
        }
        else {
          message = response.message;
          showError();
        }
      })
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleValidation = () => {
    var isValid = true;

    if (!first_name_en) {
      message = "First name is required.";
      isValid = false;
    }

    if (!last_name_en) {
      message = "Last name is required.";
      isValid = false;
    }

    return isValid;
  }

  const onEditBtnClicked = () => {
    if (handleValidation()) {
      apiJson.customers
        .updateCustomer(props.match.params.id, {
          "user": {
            "first_name_en": first_name_en,
            "last_name_en": last_name_en,
            "first_name_ar": first_name_ar,
            "last_name_ar": last_name_ar,
            "contact_number": contact_number,
            "status": status
        }
        })
        .then(response => {
          if (response.success) {
            message = "Customer updated successfully.";
            showSuccess();
          }
          else {
            message = response.message;
            showError();
          }
        });
    }
    else {
      showError();
    }
  }

  return (
    <Fragment>
      <div className="right-content details-page">
        <div className="header-row">
          <Header
            // subtext=""
            heading="Edit Customer"
            search={false}
            sku={false}
            filters={null}
          // handleSearch={(text) => {
          //   setSearchText(text);
          //   debouncedCallback(text, tabTitle, SKUSearchText);
          // }}
          // handleSKUSearch={(text) => {
          //   setSKUSearchText(text);
          //   debouncedSKUCallback(text, tabTitle, searchText);
          // }}
          // searchText={searchText}
          // searchSKUText={SKUSearchText}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>First Name (English):</label>
                  <input name="first_name_en" className="form-control" value={first_name_en} onChange={(e) => setFirstNameEn(e.target.value)} placeholder="First Name" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Last Name (English):</label>
                  <input name="last_name_en" className="form-control" value={last_name_en}onChange={(e) => setLastNameEn(e.target.value)} placeholder="Last Name" />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>First Name (Arabic):</label>
                  <input name="first_name_ar" className="form-control" value={first_name_ar} onChange={(e) => setFirstNameAr(e.target.value)} placeholder="First Name" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Last Name (Arabic):</label>
                  <input name="last_name_ar" className="form-control" value={last_name_ar}  onChange={(e) => setLastNameAr(e.target.value)}placeholder="Last Name" />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Email:</label>
                  <div className="form-control" style={{ backgroundColor: "#e2e5f1" }}>
                    {email}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Contact:</label>
                  <input name="contact" className="form-control" value={contact_number} onChange={(e) => setContactNumber(e.target.value)} placeholder="Contact" />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <label>Status:&nbsp;</label>
                <Switch
                  onChange={() => setStatus(status == "active" ? "inactive" : "active")}
                  checked={status === "active"}
                  onHandleColor="#37B900"
                  offHandleColor="#dcdcdc"
                  onColor="#e9e9e9"
                  offColor="#e9e9e9"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  className="switch"
                  width={48}
                  height={20}
                />
              </div>
            </div>
            <br />
            <input type="button" className="btn btn-primary" onClick={onEditBtnClicked} style={{ width: "150px" }} value="Edit" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.product.pagination,
    products: state.product.products,
  };
};

const mapDispatchToProps = { set_products };

export default connect(mapStateToProsps, mapDispatchToProps)(EditCustomer);
