import React, { Fragment, useEffect, useState } from "react";

import "./shippingzones.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { set_zones } from "redux/actions/ShippingZoneAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import ShippingRuleForm from "./ShippingRuleForm";

const ShippingRules = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [shippingRule, setShippingRule] = useState(null);
  const [activeTab, setActiveTab] = useState("Standard");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Shipping Rules";
  }, []);

  useEffect(() => {
    getShipmentRule(1);
  }, []);

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      
      if (tab == "Standard") {
        getShipmentRule(1);
      }
      else if (tab == "Threshold") {
        getShipmentRule(2);
      }
      else if (tab == "Inclusive") {
        getShipmentRule(3);
      }
      else if (tab == "Bulk") {
        getShipmentRule(4);
      }
    }
  };

  const getShipmentRule = (id) => {
    apiJson.shippingRules.getShippingRule(id).then((response) => {
      if (response.success) {
        setShippingRule(response.data);
        setData(true);
        setHeaderList({
          id: "storestab",
          tablist: [
            { id: "Standard" },
            { id: "Threshold" },
            { id: "Inclusive" },
            { id: "Bulk" }
          ],
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }

  const onInputFieldChanged = (e) => {
    let tempShippingRule = Object.assign({}, shippingRule);

    if (e.target.name == "dubai_charges") {
      tempShippingRule.dubai_charges = e.target.value;
    }
    else if (e.target.name == "sharjah_charges") {
      tempShippingRule.sharjah_charges = e.target.value;
    }
    else if (e.target.name == "abu_dhabi_charges") {
      tempShippingRule.abu_dhabi_charges = e.target.value;
    }
    else if (e.target.name == "ajman_charges") {
      tempShippingRule.ajman_charges = e.target.value;
    }
    else if (e.target.name == "fujairah_charges") {
      tempShippingRule.fujairah_charges = e.target.value;
    }
    else if (e.target.name == "ras_al_khaimah_charges") {
      tempShippingRule.ras_al_khaimah_charges = e.target.value;
    }
    else if (e.target.name == "umm_al_quwain_charges") {
      tempShippingRule.umm_al_quwain_charges = e.target.value;
    }
    else if (e.target.name == "default_charges") {
      tempShippingRule.default_charges = e.target.value;
    }
    else if (e.target.name == "order_threshold_amount") {
      tempShippingRule.order_threshold_amount = e.target.value;
    }
    else if (e.target.name == "order_threshold_weight") {
      tempShippingRule.order_threshold_weight = e.target.value;
    }

    setShippingRule(tempShippingRule);
  }

  const saveBtnClicked = (e) => {
    e.preventDefault();
    apiJson.shippingRules.addShippingRule(shippingRule).then((response) => {
      if (response.success) {
        message = "Shipping rule updated successfully.";
        showSuccess();
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }

  return (
    <Fragment>
      <div className="right-content shipping-zones-page-container w-100">
        <div className="header-row">
          <Header
            heading="Shipping Rules"
            subtext="Create, edit, and manage Shipping Rules on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            {/* <div className="button-div">
              <button
                type="button"
                className="create-button"
                onClick={() => setRedirect(true)}
              >
                Create New Zone
              </button>
            </div> */}
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="shipping-zones-tab">
                <HeaderTab
                  onTabChoose={changeTab}
                  tabTitle={activeTab}
                  tab={headerList}
                >
                  <ShippingRuleForm
                    shippingRule={shippingRule}
                    onInputFieldChanged={onInputFieldChanged}
                    saveBtnClicked={saveBtnClicked}
                    activeTab={activeTab}
                  />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/create-zone", state: { name: "create" } }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { zones: state.zones.zones };
};

const mapDispatchToProps = { set_zones };

export default connect(mapStateToProsps, mapDispatchToProps)(ShippingRules);
