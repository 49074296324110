import React, { Fragment, useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./editsection.scss";
import Header from "components/header/Header";
import { Redirect, useLocation } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import { set_single_section } from "redux/actions/SingleSectionAction";
import { getClass, getRandomInt } from "utils/utils";

const variantSchema = Yup.object().shape({
  title_en: Yup.string().required("Required"),
  country_id: Yup.string().required("Required"),
});

const EditSection = (props) => {
  const param = useParams();
  const isEditClick = false;
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [countries, setCountries] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title_ar: "",
    title_en: "",
    country_id: "",
    country_name_en: "",
    position: undefined,
  });
  const [data, setData] = useState(false);

  useEffect(() => {
    document.title = "Section";
  }, []);

  let message = "";

  useEffect(() => {
    setLoading(true);
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        setCountries(response.data);
        setLoading(false);
      } else if (!response.status) {
        setLoading(false);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  useEffect(() => {
    if (param.state === "edit") {
      setData(true);
      apiJson.sections
        .getSingleSection(parseInt(param.id), location.state.country_id)
        .then((response) => {
          if (response.success) {
            setInitialValues({
              id: response.data?.id,
              title_ar: response.data?.title_ar,
              title_en: response.data?.title_en,
              country_id: response.data?.country_id,
              country_name_en: response.data?.country_name_en,
              position: response.data?.position,
            });
            props.set_single_section({ section: response.data });
            setData(false);
          } else if (!response.status) {
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
            setData(false);
          } else {
            setData(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    }
  }, []);

  const handleCountry = (value, setFieldValue) => {
    setFieldValue("country_id", value.value);
    setFieldValue("country_name_en", value.label);
  };
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const Create = (values) => {
    setCreating(true);
    apiJson.sections
      .createSection({
        section: {
          title_en: values.title_en,
          title_ar: values.title_ar ? values.title_ar : values.title_en,
          country_id: values.country_id,
          position: values.position,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Section Created";
          showSuccess();
          setCreating(false);
          setRedirect(true);
        } else if (!response.status) {
          setCreating(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setCreating(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Update = (values) => {
    setUpdating(true);
    apiJson.sections
      .UpdateSection(parseInt(param.id), values.country_id, {
        section: {
          title_en: values.title_en,
          title_ar: values.title_ar ? values.title_ar : values.title_en,
          position: values.position,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Section Updated";
          showSuccess();
          setUpdating(false);
          setRedirect(true);
        } else if (!response.status) {
          setUpdating(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setUpdating(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const handleSave = (values) => {
    if (param.state === "edit") {
      Update(values);
    } else {
      Create(values);
    }
  };

  return (
    <Fragment>
      <div className="right-content section-page-desktop">
        <div className="header-row">
          <Header
            heading="Create/Edit a Section"
            subtext="Create, edit, and manage the Sections on your site."
          />
        </div>
        <div className="main-row section-page-content-container">
          {loading && data ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#000" loading size={35} />
            </div>
          ) : (
            <div className="form-container">
              <Formik
                initialValues={initialValues}
                validationSchema={variantSchema}
                enableReinitialize
                onSubmit={(values) => handleSave(values)}
              >
                {({
                  errors,
                  touched,
                  setFieldTouched,
                  values,
                  setFieldValue,
                }) => (
                  <Form className="section-form mx-auto">
                    <fieldset>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Name
                            </div>
                            <div className="col-lg-8 col-md-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.title_en && errors.title_en
                                )}`}
                                name="title_en"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.title_en && errors.title_en && (
                                <div className="english-error">
                                  {errors.title_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="arabic-col col-6">
                          <div className="row justify-content-end mx-0">
                            <div className="col-lg-8 col-md-8 col-xl-7 input">
                              <Field
                                className="w-100"
                                name="title_ar"
                                type="text"
                                placeholder="أدخل الاسم"
                              />
                            </div>
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              اسم
                            </div>
                          </div>
                        </div>
                      </div>
                      {param?.state !== "edit" && (
                        <div className="row mx-0">
                          <div className="english-col col-6">
                            <div className="row mx-0">
                              <div className="label col-md-4 col-lg-4 col-xl-3">
                                Country
                              </div>
                              <div className="col-lg-8 col-md-8 col-xl-7 input">
                                <div className="select">
                                  <Select
                                    value={
                                      values.country_id
                                        ? {
                                            value: values.country_id,
                                            label: values.country_name_en,
                                          }
                                        : ""
                                    }
                                    onChange={(value) =>
                                      handleCountry(value, setFieldValue)
                                    }
                                    onBlur={() =>
                                      setFieldTouched("country_id", true)
                                    }
                                    options={countries?.map((country) => {
                                      return {
                                        value: country.id,
                                        label: country.name_en,
                                      };
                                    })}
                                    className={`category-select ${getClass(
                                      touched.country_id && errors.country_id
                                    )}`}
                                    classNamePrefix="select"
                                    name="category"
                                    isSearchable={false}
                                    placeholder="Select Country"
                                  />
                                  {touched.country_id && errors.country_id && (
                                    <div className="english-error">
                                      {errors.country_id}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mx-auto">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Position
                            </div>
                            <div className="col-lg-8 col-md-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.position && errors.position
                                )}`}
                                name="position"
                                type="number"
                                placeholder="0"
                                min="0"
                                step="1"
                                value={
                                  values.position
                                    ? Math.ceil(values.position)
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="button-group d-flex jsutify-content-center align-items-center">
                          {!isEditClick || location.state.name === "create" ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                key={getRandomInt(1, 1000)}
                                disabled={creating || updating}
                              >
                                {creating || updating ? (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={21}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Back
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    {redirectBack ? <Redirect push to="/sections" /> : ""}
                    {redirect ? <Redirect push to="/sections" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    countries: state.countries.countries,
    section: state.single_section.section,
    products: state.single_section.products,
    services: state.single_section.services,
  };
};

const mapDispatchToProps = { set_single_section };

export default connect(mapStateToProsps, mapDispatchToProps)(EditSection);
