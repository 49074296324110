import { type } from "../../types/types";

export default function reducer(
  state = { brands: [], pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_BRANDS:
      return { ...state, ...action.brands };
    case type.REMOVE_BRAND:
      return {
        ...state,
        brands: state.brands.filter((brand) => brand.id !== action.brandId),
      };
    default:
      return state;
  }
}
