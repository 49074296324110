import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format } from "date-fns";
import "./shipmentitem.scss";
import { getResizedImage } from "Extra/GetResizedImage";

const ShipmentItem = (props) => {
  return (
    <div>
      {props.shipmentItem && (
        <Row className="shipment-item-desktop g-0">
          <div className="image-container product-box-padding">
            <img
              style={{
                width: "auto",
                maxWidth: "100%",
                height: "100%",
              }}
              src={getResizedImage(
                props.shipmentItem?.orderable?.featured_image_resized,
                props.shipmentItem?.orderable?.featured_image
              )}
              alt=""
            />
          </div>

          <Col className="details py-2">
            <Row className="g-0 px-2 h-100">
              <Col md={10} className="h-100 position-relative">
                <Row className="g-0">
                  <Col md={12}>
                    <span className="brand gray-text">
                      {props.shipmentItem.orderable.brand_name_en &&
                        props.shipmentItem.orderable.brand_name_en}
                    </span>{" "}
                    <span className="title">
                      {props.shipmentItem?.orderable_type === "Service" &&
                        props.shipmentItem?.orderable.name_en}
                      {props.shipmentItem?.orderable_type === "Product" &&
                        props.shipmentItem?.orderable.product_name_en +
                        " " +
                        props.shipmentItem?.orderable.name_en}
                    </span>
                  </Col>
                  {props.shipmentItem?.status && (
                    <Col md={12}>
                      <span
                        className="brand gray-text"
                        style={{ fontWeight: "600" }}
                      >
                        Status:
                      </span>
                      <span className="text-uppercase ms-2">
                        {props.shipmentItem?.status}
                      </span>
                    </Col>
                  )}
                  {props.shipmentItem?.orderable_type === "Product" && (
                    <Col md={12}>
                      <span
                        className="brand gray-text"
                        style={{ fontWeight: "600" }}
                      >
                        Sku:
                      </span>
                      <span className="text-uppercase ms-2">
                        {props.shipmentItem?.orderable.sku}
                      </span>
                    </Col>
                  )}
                  {props.shipmentItem.start_at && (
                    <Col md={12}>
                      <span className="start-date me-1">Starts At</span>
                      <span className="date ms-1">
                        {format(
                          Date.parse(props.shipmentItem.start_at),
                          "dd MMM yyy p"
                        )}
                      </span>
                    </Col>
                  )}
                  {props.shipmentItem.end_at && (
                    <Col md={12}>
                      <span className="start-date me-1">Ends At</span>
                      <span className="date ms-1">
                        {format(
                          Date.parse(props.shipmentItem.end_at),
                          "dd MMM yyy p"
                        )}
                      </span>
                    </Col>
                  )}
                  <Col md={12} className="mt-2">
                    Sold by{" "}
                    <span className="fw-bold">
                      {props.shipmentItem.orderable.store_name_en}
                    </span>
                  </Col>
                  <Col md={12}>
                    <b>Vendor Details:</b>
                  </Col>
                  {
                    props.shipmentItem.orderable.vendor_name && (
                      <Col md={12}>
                        Name:{" "}
                        <span className="fw-bold">
                          {props.shipmentItem.orderable.vendor_name}
                        </span>
                      </Col>
                    )
                  }
                  {
                    props.shipmentItem.orderable.vendor_email && (
                      <Col md={12}>
                        Email:{" "}
                        <span className="fw-bold">
                          {props.shipmentItem.orderable.vendor_email}
                        </span>
                      </Col>
                    )
                  }
                  {
                    props.shipmentItem.orderable.vendor_contact && (
                      <Col md={12}>
                        Contact:{" "}
                        <span className="fw-bold">
                          {props.shipmentItem.orderable.vendor_contact}
                        </span>
                      </Col>
                    )
                  }
                </Row>
              </Col>

              <Col
                md={2}
                className="d-flex flex-column justify-content-between"
              >
                <Row className="g-0 text-end">
                  <Col md={12} className="price faded-black-2-text">
                    <span className="capitalize">{props.currency} </span>
                    <span className="fw-bold">{props.shipmentItem.price}</span>
                  </Col>

                  {props.shipmentItem?.orderable_type === "Product" && (
                    <Col md={12} className="quantity gray-text">
                      {props.shipmentItem.quantity}
                      {props.shipmentItem.quantity > 1 ? "units" : "unit"}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ShipmentItem;
