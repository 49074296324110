import { type } from "../../types/types";

export default function reducer(state = { variants: [] }, action) {
  switch (action.type) {
    case type.SET_VARIANTS:
      return { ...state, ...action.variants };
    case type.SET_VARIANT_DETAIL:
      return { ...state, ...action.variantDetails };
    case type.REMOVE_VARIANT:
      return { ...state, variants: state.variants.filter((variant) => variant.id !== action.variantId) };
    default:
      return { ...state };
  }
}
