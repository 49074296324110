import { type } from "../types/types";

export const set_banners = (banners) => (dispatch) => {
  dispatch({
    type: type.SET_BANNERS,
    banners: banners,
  });
};

export const set_banner_details = (bannerDetails) => (dispatch) => {
  dispatch({
    type: type.SET_BANNER_DETAILS,
    bannerDetails: bannerDetails,
  });
};
