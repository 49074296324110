import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import "./dropzoneelement.scss";

function BulkImportDropzoneElement(props) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.type,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      props.updateSelectedServiceFiles(acceptedFiles);
    },
    multiple: props.mulitple ? props.multiple : true,
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <section
      className="dropzone-element"
      style={{ width: "70%", margin: "auto" }}
    >
      <div className="" {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="placeh">
          {props.value
            ? props.value
            : "Drag 'n' drop some files here, or click to select files"}
        </div>
      </div>
    </section>
  );
}

<BulkImportDropzoneElement />;

export default BulkImportDropzoneElement;
