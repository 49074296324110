import React, { Fragment, useState, useEffect } from "react";

import "./sections.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Link } from "react-router-dom";

const CreateSection = (props) => {
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [name_en, setNameEn] = useState("");
  const [name_ar, setNameAr] = useState("");

  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);

  let message = "";

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Sections";

    apiJson.header.getCountries()
      .then(response => {
        if (response.success) {
          setCountries(response.data);
          setSelectedCountry(response.data[0].id);
        }
      });
  }, []);

  const handleValidation = () => {
    var isValid = true;

    if (!name_en) {
      isValid = false;
      alert("English Name is required.");
    }

    if (!name_ar) {
      isValid = false;
      alert("Arabic Name is required.");
    }

    return isValid;
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    
    if (handleValidation()) {
      apiJson.home_offers_sections.createSection({
        name_en: name_en,
        name_ar: name_ar,
        country_id: selectedCountry
      })
      .then(response => {
        if (response.success) {
          message = response.data.message;
          showSuccess();
        }
        else {
          message = response.data.message;
          showError();
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Sections"
            subtext="Create, edit, and manage Sections on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-lg-12">
                  <label>Country:</label>
                  <select className="form-control" onChange={(e) => setSelectedCountry(e.target.value)} defaultValue={selectedCountry}>
                    {
                      countries?.map(country => {
                        return (
                          <option value={country.id}>{country.name_en}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-lg-6">
                  <label>Name (English):</label>
                  <input type="text" value={name_en} onChange={(e) => setNameEn(e.target.value)} className="form-control" />
                </div>
                <div className="col-lg-6">
                  <label>Name (Arabic):</label>
                  <input type="text" value={name_ar} onChange={(e) => setNameAr(e.target.value)} className="form-control" />
                </div>
                <div className="col-lg-12">
                  <br />
                  <input type="submit" value="SAVE" className="btn btn-primary" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateSection;
