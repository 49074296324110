import React, { Fragment } from "react";
import { useState } from "react";
import ProductCard from "./ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./productlist.scss";

import { getRandomProductImage } from "../../../../utils/utils.js";

const products = [
  {
    id: 1,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4.5,
    count: 250,
  },
  {
    id: 2,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4.2,
    count: 250,
  },
  {
    id: 3,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 4,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 5,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 6,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 7,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 8,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 9,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 10,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 11,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 12,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 3.5,
    count: 250,
  },
  {
    id: 13,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
  {
    id: 14,
    img: getRandomProductImage(),
    badge: "Best Seller",
    promo: "EXTRA 10% OFF! USE CODE - HOME10",
    description:
      "Lorem ipsum dolor sit amet, as consectetur adipiscing elit, sedas",
    price_after_discount: 35.3,
    price_before_discount: 2900,
    rating: 4,
    count: 250,
  },
];

const screenBreakpoints = {
  md: 768,
  lg: 992,
  xl: 1024,
  xxl: 1200,
  xxxl: 1440,
};
SwiperCore.use([Pagination, Navigation]);

const ProductList = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  const productCountPerSlideView = () => {
    if (width >= screenBreakpoints.xxxl) {
      return 5;
    } else if (width >= screenBreakpoints.xxl) {
      return 5;
    } else if (width >= screenBreakpoints.xl) {
      return 4;
    } else if (width >= screenBreakpoints.lg) {
      return 3;
    } else if (width >= screenBreakpoints.md) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      <div className="product-list-desktop">
        <div className="product-list-container mx-auto">
          <Swiper
            spaceBetween={0}
            slidesPerView={productCountPerSlideView()}
            pagination={{
              clickable: true,
            }}
            navigation={true}
          >
            {products.map((product) => {
              return (
                <SwiperSlide>
                  <ProductCard key={product.id} product={product} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductList;
