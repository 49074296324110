import React, { Fragment, useEffect, useState, useCallback } from "react";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./brands.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";

import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { debounce } from "lodash";

const Media = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState({
    id: "bannerstab",
    tablist: [
      { id: "all", count: null }
    ],
  });
  const [currentMedia, setCurrentMedia] = useState({});
  const [currentMediaID, setCurrentMediaID] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectEdit, setRedirectEdit] = useState(false);
  const [tabTitle, settabTitle] = useState("all");
  const [files, setFiles] = useState([]);
  const [pagination, setPagination] = useState(null);

  const [search, setSearch] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const setVars = (file) => {
    setCurrentMedia(file);
    setCurrentMediaID(file.id);
    setRedirectEdit(true);
  };

  const getMedia = (page, params) => {
    setData(false);
    apiJson.gallery.getPaginatedGallery(page, params).then((response) => {
      if (response.success) {
        setFiles(response.data.galleries);
        setPagination(response.data.pagination);
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };
  useEffect(() => {
    document.title = "Media";
    getMedia(1, "");
    // eslint-disable-next-line
  }, []);

  const pageChange = (page) => {
    getMedia(
      page,
      // tabParam(tabTitle) +
      (search?.length > 0 ? "&search_by_name=" + search : "")
    );
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab) => handleSearch(text, currTab), 1000),
      []
    );

  const handleSearch = (text, tab) => {
    getMedia(1, "&search_by_name=" + text);
  };

  const handlePageChange = (pageNumber) => {
    pageChange(pageNumber.selected + 1);
  };

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Media"
            subtext="Create, edit, and manage files on your site."
            search={false}
            handleSearch={(text) => {
              setSearch(text);
              debouncedCallback(text, tabTitle);
            }}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <div className="button-div">
              <button
                type="button"
                className="create-button"
                onClick={() => setRedirect(true)}
              >
                Create New Media
              </button>
            </div>
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="brands-tab">
                <div className="row">
                  {
                    files.map((file, index) => {
                      return (
                        <div
                          className="col-md-3 col-lg-2 mt-3"
                        >
                          <div
                            style={{
                              border: "1px solid gray",
                              borderRadius: 5,
                              height: "150px",
                              backgroundColor: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              overflow: "hidden",
                              padding: "10px",
                              cursor: "pointer"
                            }}
                            onClick={() => setVars(file)}
                          >
                            <img
                              className="img img-fluid"
                              src={file.file}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain"
                              }}
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                {pagination?.total_pages && files?.length > 0 && (
                  <div
                    className="d-flex justify-content-center mb-4 w-100 mt-3"
                    style={{ direction: "ltr" }}
                  >
                    <Pagination
                      direction="ltr"
                      previousLabel={<FaChevronLeft color="#404553" />}
                      nextLabel={<FaChevronRight color="#404553" />}
                      pageCount={pagination?.total_pages}
                      forcePage={parseInt(pagination?.page) - 1}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={2}
                      containerClassName="pagination"
                      pageClassName="ps-3 pe-3 pt-2 pb-2"
                      activeClassName={
                        "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
                      }
                      nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                      previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/create-media", state: { name: "create" } }}
          />
        )}
        {redirectEdit && (
          <Redirect
            push
            to={{
              pathname: `/edit-media/${currentMediaID}`,
              state: { name: "view", media: { currentMedia } },
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProsps, mapDispatchToProps)(Media);
