import { type } from "../../types/types";

export default function reducer(
  state = { section: {}, products: [], services: [] },
  action
) {
  switch (action.type) {
    case type.SET_SINGLE_SECTION:
      return { ...state, ...action.single_section };
    default:
      return state;
  }
}
