import React, { Fragment } from "react";
import { Badge } from "@material-ui/core/";

import "./couponheadertab.scss";

const CouponHeaderTab = (props) => {
  const tab = props.tab;
  return (
    <Fragment>
      <div className="coupon-header-tab">
        <ul
          className="nav nav-tabs"
          id={Math.floor(Math.random() * (999 - 100 + 1) + 100)}
          role="tablist"
        >
          {tab?.tablist?.length === 1 && (
            <li className="nav-item" role="presentation" onClick={() => {}}>
              <button
                className={`nav-link d-flex align-items-center active`}
                id={tab.tablist[0].id}
                data-bs-toggle="tab"
                data-bs-target={`#${tab.tablist[0].id}`}
                type="button"
                role="tab"
                aria-controls={tab.tablist[0].id}
                aria-selected="true"
              >
                <span className="text">{tab.tablist[0].id}</span>
                <Badge className="count" badgeContent={tab.tablist[0].count} />
              </button>
            </li>
          )}
          {tab.tablist?.length > 1 &&
            tab.tablist.map((tabitem, index) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  key={tab.id + index}
                >
                  <button
                    className={`nav-link d-flex align-items-center ${
                      props.tabTitle === tabitem.id ? "active" : ""
                    }`}
                    id={tabitem.id}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tabitem.id}`}
                    type="button"
                    role="tab"
                    aria-controls={tabitem.id}
                    aria-selected="true"
                    onClick={() => {
                      props.onTabChoose(tabitem.id);
                    }}
                  >
                    <span className="text">{tabitem.id}</span>
                    <Badge className="count" badgeContent={tabitem.count} />
                  </button>
                </li>
              );
            })}
        </ul>
        <div className="tab-content" id={`${tab.id}Content`}>
          {tab?.tablist?.map((tabitem, index) => {
            var cls = index === 0 ? "show active" : "";
            return (
              <div
                className={`tab-pane fade ${cls}`}
                id={tabitem.id}
                key={tabitem.id}
                role="tabpanel"
                aria-labelledby={tabitem.id}
              >
                {props.children}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default CouponHeaderTab;
