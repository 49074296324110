import React, { Fragment, useState, useEffect } from "react";

import "./tabs.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Link } from "react-router-dom";

const AddProductToTab = (props) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const [position, setPosition] = useState(null);

  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);

  let message = "";

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Add Product To Tab";

    apiJson.header.getCountries()
      .then(response => {
        if (response.success) {
          setCountries(response.data);
          setSelectedCountry(response.data[0].id);
          apiJson.home_tabs.getTabs(response.data[0].id)
            .then(response => {
              if (response.success) {
                setTabs(response.data.tabs);
                setSelectedTab(response.data.tabs[0].id);
              }
            });
        }
      });
  }, []);

  const onCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    apiJson.home_tabs.getTabs(e.target.value)
      .then(response => {
        if (response.success) {
          setTabs(response.data.tabs);
          setSelectedTab(response.data.tabs[0].id);
        }
      });
  }

  const handleValidation = () => {
    var isValid = true;

    return isValid;
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      apiJson.home_tabs.addProductToTab({
        product: {
          product_id: props.match.params.id,
          position: position
        }
      }, selectedCountry, selectedTab)
        .then(response => {
          if (response.success) {
            message = response.data.message;
            if (message == "Product already exists in this tab.") {
              showError();
            }
            else {
              showSuccess();
            }
          }
          else {
            message = response.data.message;
            showError();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Tab"
            subtext="Create, edit, and manage Tabs on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-lg-6">
                  <label>Country:</label>
                  <select className="form-control" onChange={(e) => onCountryChange(e)} defaultValue={selectedCountry}>
                    {
                      countries?.map(country => {
                        return (
                          <option value={country.id}>{country.name_en}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Tab:</label>
                  <select className="form-control" onChange={(e) => setSelectedTab(e.target.value)} defaultValue={selectedTab}>
                    {
                      tabs?.map(tab => {
                        return (
                          <option value={tab.id}>{tab.title_en}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-12">
                  <input type="submit" value="SAVE" className="btn btn-primary w-50" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddProductToTab;
