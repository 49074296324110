import { type } from "../../types/types";

export default function reducer(
  state = { platformSettingId: null, platformSetting: {} },
  action
) {
  switch (action.type) {
    case type.SET_PLATFORM_SETTINGS_ID:
      return { ...state, ...action.platformSettingId };
    case type.SET_PLATFORM_SETTINGS:
      return { ...state, ...action.platformSetting };
    default:
      return { ...state };
  }
}
