import { Field, Form, Formik } from "formik";
import React, { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import Switch from "react-switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./platformsetting.scss";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import {
  set_platform_settings_id,
  set_platform_settings,
} from "../../../redux/actions/SettingsAction";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { getClass, getRandomInt, IsQuillEmpty } from "utils/utils";

const PlatformSetting = (props) => {
  const [isEditClick, setisEditClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const platform_settings = props.platform_settings;
  const [data, setData] = useState("");
  const [initialState, setInitialState] = useState({
    commission: platform_settings.commission || 0,
    contact_us: platform_settings.contact_us || "",
    contact_us_ar: platform_settings.contact_us_ar || "",
    shipping_policy: platform_settings.shipping_policy || "",
    shipping_policy_ar: platform_settings.shipping_policy_ar || "",
    vat: platform_settings.vat || 0,
    refund_time_limit: platform_settings.refund_time_limit || 0,
    dispute_time_limit: platform_settings.dispute_time_limit || 0,
    cash_on_delivery_payment_option:
      platform_settings.cash_on_delivery_payment_option || false,
    can_refund: platform_settings.can_refund || false,
    can_reject_order: platform_settings.can_reject_order || false,
    card_payment_option: platform_settings.card_payment_option || false,
    wallet_payment_option: platform_settings.wallet_payment_option || false,
    guest_user_checkout: platform_settings.guest_user_checkout || false,
  });

  const formSchema = Yup.object().shape({
    commission: Yup.number().required("Required"),
    contact_us: Yup.string().required("Required"),
    shipping_policy: Yup.string().required("Required"),
    vat: Yup.number().required("Required"),
    refund_time_limit: Yup.string().required("Required"),
    dispute_time_limit: Yup.string().required("Required"),
    cash_on_delivery_payment_option: Yup.string().required("Required"),
    can_refund: Yup.string().required("Required"),
    can_reject_order: Yup.string().required("Required"),
    card_payment_option: Yup.string().required("Required"),
    wallet_payment_option: Yup.string().required("Required"),
    guest_user_checkout: Yup.string().required("Required"),
  });

  const heading = isEditClick
    ? "Create/Edit Platform Settings"
    : "View Platform Settings";

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Settings";
  }, []);

  const fetchPlatformId = () => {
    setData(true);
    return apiJson.settings.getPlatformSettingID().then((response) => {
      if (response.success) {
        props.set_platform_settings_id({
          platformSettingId: response.data?.platform_id,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(false);
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
          setData(false);
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
      return response;
    });
  };

  const fetchPlatformSetting = (id) => {
    return apiJson.settings.getPlatformSetting(id).then((response) => {
      if (response.success) {
        props.set_platform_settings({ platformSetting: response.data });
        setInitialState({
          commission: response.data.commission || 0,
          contact_us: response.data.contact_us || "",
          contact_us_ar: response.data.contact_us_ar || "",
          shipping_policy: response.data.shipping_policy || "",
          shipping_policy_ar: response.data.shipping_policy_ar || "",
          vat: response.data.vat || 0,
          refund_time_limit: response.data.refund_time_limit || 0,
          dispute_time_limit: response.data.dispute_time_limit || 0,
          cash_on_delivery_payment_option:
            response.data.cash_on_delivery_payment_option || false,
          can_refund: response.data.can_refund || false,
          can_reject_order: response.data.can_reject_order || false,
          card_payment_option: response.data.card_payment_option || false,
          wallet_payment_option: response.data.wallet_payment_option || false,
          guest_user_checkout: response.data.guest_user_checkout || false,
        });
        setData(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
      return response;
    });
  };

  const updatePlatformSetting = (id, values) => {
    setLoading(true);
    return apiJson.settings
      .updatePlatformSetting(id, {
        platform: {
          ...values,
          contact_us_ar:
            values.contact_us_ar && values.contact_us_ar !== ""
              ? values.contact_us_ar
              : values.contact_us,
          shipping_policy_ar:
            values.shipping_policy_ar && values.shipping_policy_ar !== ""
              ? values.shipping_policy_ar
              : values.shipping_policy,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Successfully Updated";
          showSuccess();
          setLoading(false);
          setisEditClick(false);
          props.set_platform_settings({ platformSetting: response.data });
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
        return response;
      });
  };

  useEffect(() => {
    setData(true);
    fetchPlatformId().then((response) => {
      response?.data?.platform_id &&
        fetchPlatformSetting(response?.data?.platform_id);
    });
    // eslint-disable-next-line
  }, [isEditClick]);

  return (
    <Fragment>
      <div className="right-content platform-setting-page-desktop">
        <div className="header-row">
          <Header heading={heading} subtext="Manage platform settings." />
        </div>
        {data ? (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={35} />
          </div>
        ) : (
          <div className="main-row platform-setting-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={initialState}
                validationSchema={formSchema}
                onSubmit={(values) => {
                  updatePlatformSetting(props.platform_settings_id, values);
                }}
                enableReinitialize={true}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="platform-setting-form mx-auto">
                    <fieldset disabled={!isEditClick}>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-3">
                              Dispute Time Limit
                            </div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.dispute_time_limit &&
                                    errors.dispute_time_limit
                                )}`}
                                name="dispute_time_limit"
                                type="number"
                                onChange={handleChange}
                                value={values.dispute_time_limit}
                                placeholder="Enter Time limit"
                              />
                              {touched.dispute_time_limit &&
                                errors.dispute_time_limit && (
                                  <div className="english-error">
                                    {errors.dispute_time_limit}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-3">Refund Time Limit</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.refund_time_limit &&
                                    errors.refund_time_limit
                                )}`}
                                name="refund_time_limit"
                                onChange={handleChange}
                                value={values.refund_time_limit}
                                type="number"
                                placeholder="Enter Time Limit"
                              />
                              {touched.refund_time_limit &&
                                errors.refund_time_limit && (
                                  <div className="english-error">
                                    {errors.refund_time_limit}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-3">VAT</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.vat && errors.vat
                                )}`}
                                name="vat"
                                onChange={handleChange}
                                value={values.vat}
                                type="number"
                                placeholder="vat in %"
                              />
                              {touched.vat && errors.vat && (
                                <div className="english-error">
                                  {errors.vat}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-3">Commission</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.commission && errors.commission
                                )}`}
                                name="commission"
                                type="number"
                                onChange={handleChange}
                                value={values.commission}
                                placeholder="Enter Commission"
                              />
                              {touched.commission && errors.commission && (
                                <div className="english-error">
                                  {errors.commission}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-6 col-lg-3">
                              Cash On Delivery
                            </div>
                            <div className="col-md-6 col-lg-9 input">
                              <Switch
                                onChange={() =>
                                  setFieldValue(
                                    "cash_on_delivery_payment_option",
                                    !values.cash_on_delivery_payment_option
                                  )
                                }
                                checked={values.cash_on_delivery_payment_option}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-6 col-lg-3">
                              Vendor Can Reject Order
                            </div>
                            <div className="col-md-6 col-lg-9 input">
                              <Switch
                                onChange={() =>
                                  setFieldValue(
                                    "can_reject_order",
                                    !values.can_reject_order
                                  )
                                }
                                checked={values.can_reject_order}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-6 col-lg-3">
                              Wallet Payment
                            </div>
                            <div className="col-md-6 col-lg-9 input">
                              <Switch
                                onChange={() =>
                                  setFieldValue(
                                    "wallet_payment_option",
                                    !values.wallet_payment_option
                                  )
                                }
                                checked={values.wallet_payment_option}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-6 col-lg-3">
                              Card Payment
                            </div>
                            <div className="col-md-6 col-lg-9 input">
                              <Switch
                                onChange={() =>
                                  setFieldValue(
                                    "card_payment_option",
                                    !values.card_payment_option
                                  )
                                }
                                checked={values.card_payment_option}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-6 col-lg-3">
                              Guest User Checkout
                            </div>
                            <div className="col-md-6 col-lg-9 input">
                              <Switch
                                onChange={() =>
                                  setFieldValue(
                                    "guest_user_checkout",
                                    !values.guest_user_checkout
                                  )
                                }
                                checked={values.guest_user_checkout}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Shipping Policy</div>
                            <div className="col-8 input">
                              <Field
                                className="w-100"
                                name="shipping_policy"
                                type="text"
                                onChange={handleChange}
                                value={values.shipping_policy}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.shipping_policy &&
                                        errors.shipping_policy
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "shipping_policy",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched("shipping_policy", true)
                                    }
                                  />
                                )}
                              </Field>
                              {touched.shipping_policy &&
                                errors.shipping_policy && (
                                  <div className="english-error">
                                    {errors.shipping_policy}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="col-8 input offset-2">
                              <Field
                                className="w-100"
                                name="shipping_policy_ar"
                                type="text"
                                onChange={handleChange}
                                value={values.shipping_policy_ar}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "shipping_policy_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched(
                                        "shipping_policy_ar",
                                        true
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label col-2 text-end">
                              سياسة الشحن
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Contact Us</div>
                            <div className="col-8 input">
                              <Field
                                className="w-100"
                                name="contact_us"
                                type="text"
                                onChange={handleChange}
                                value={values.contact_us}
                                placeholder="Enter Contact Us Email"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.contact_us && errors.contact_us
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched("contact_us", true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "contact_us",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              {touched.contact_us && errors.contact_us && (
                                <div className="english-error">
                                  {errors.contact_us}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="col-8 input offset-2">
                              <Field
                                className="w-100"
                                name="contact_us_ar"
                                type="text"
                                onChange={handleChange}
                                value={values.contact_us_ar}
                                placeholder="Enter Contact Us Email"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched("contact_us_ar", true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "contact_us_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label col-2 text-end">اتصل بنا</div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-8 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          {isEditClick ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setisEditClick(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                key={getRandomInt(1, 1000)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-items-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={20}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => setisEditClick(true)}
                              >
                                Edit
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    platform_settings_id: state.setting.platformSettingId,
    platform_settings: state.setting.platformSetting,
  };
};

const mapDispatchTopProps = { set_platform_settings_id, set_platform_settings };

export default connect(mapStateToProps, mapDispatchTopProps)(PlatformSetting);
