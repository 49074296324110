import React, { Fragment, useState } from "react";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import DropzoneElement from "../../../components/Dropzone/BulkImportDropzoneElement";
import { type } from "../../../components/Dropzone/type";
import * as XLSX from "xlsx";
import ClipLoader from "react-spinners/ClipLoader";
import "./productbulkimportmodal.scss";
import { connect } from "react-redux";

const ProductBulkImportLiveModal = (props) => {
  const [file, setFile] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);


  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      var postData = [];
      
      data.forEach((row, i) => {
        if (i > 0 && row[0] != undefined) {
          postData.push({
            sku: row[0] == undefined ? null : row[0],
            product_name_en: row[1] == undefined ? null : row[1],
            product_name_ar: row[2] == undefined ? null : row[2],
            product_description_en: row[3] == undefined ? null : row[3],
            product_description_ar: row[4] == undefined ? null : row[4],
            product_benefits_en: row[5] == undefined ? null : row[5],
            product_benefits_ar: row[6] == undefined ? null : row[6],
            product_features_en: row[7] == undefined ? null : row[7],
            product_features_ar: row[8] == undefined ? null : row[8],
            product_how_to_use_en: row[9] == undefined ? null : row[9],
            product_how_to_use_ar: row[10] == undefined ? null : row[10],
            video: row[11] == undefined ? null : row[11],
            tool: row[12] == undefined ? null : row[12],
            is_inquiry_product: row[13] == undefined ? null : row[13],
            product_type: row[14] == undefined ? null : row[14],
            category_title_en: row[15] == undefined ? null : row[15],
            brand_name_en: row[16] == undefined ? null : row[16],
            store_name_en: row[17] == undefined ? null : row[17],
            keyword: row[18] == undefined ? null : row[18],
            unit: row[19] == undefined ? null : row[19],
            variant_name_en: row[20] == undefined ? null : row[20],
            variant_name_ar: row[21] == undefined ? null : row[21],
            variant_description_en: row[22] == undefined ? null : row[22],
            variant_description_ar: row[23] == undefined ? null : row[23],
            variant_benefits_en: row[24] == undefined ? null : row[24],
            variant_benefits_ar: row[25] == undefined ? null : row[25],
            variant_features_en: row[26] == undefined ? null : row[26],
            variant_features_ar: row[27] == undefined ? null : row[27],
            variant_how_to_use_en: row[28] == undefined ? null : row[28],
            variant_how_to_use_ar: row[29] == undefined ? null : row[29],
            quantity: row[30] == undefined ? null : row[30],
            price: row[31] == undefined ? null : row[31],
            discounted_price: row[32] == undefined ? null : row[32],
            qty1range_from: row[33] == undefined ? null : row[33],
            qty1range_to: row[34] == undefined ? null : row[34],
            qty1range_price: row[35] == undefined ? null : row[35],
            qty2range_from: row[36] == undefined ? null : row[36],
            qty2range_to: row[37] == undefined ? null : row[37],
            qty2range_price: row[38] == undefined ? null : row[38],
            qty3range_from: row[39] == undefined ? null : row[39],
            qty3range_to: row[40] == undefined ? null : row[40],
            qty3range_price: row[41] == undefined ? null : row[41],
            upc_code: row[42] == undefined ? null : row[42],
            weight: row[43] == undefined ? null : row[43],
            variation: row[44] == undefined ? null : row[44],
            images: row[45] == undefined ? null : row[45],
            featured_image: row[46] == undefined ? null : row[46],
            pickup_allowed: row[47] == undefined ? null : row[47]
          });
        }
      });
      setProducts(postData);
      setFile(e.target.files);
    };
    reader.readAsBinaryString(file);
  };

  const updateImportProducts = () => {
    if (file) {
      setLoading(true);

      apiJson.imports
        .bulkUpdateImport({
          products: products
        })
        .then((response) => {
          if (response.success) {
            message = "Products updated successfully.";
            showSuccess();
            props.cancleClick();
            setLoading(false);
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    }
    else {
      message = "Upload file to proceed.";
      showError();
    }
  };

  const createImportProducts = () => {
    if (file) {
      setLoading(true);

      apiJson.imports
        .bulkCreateImport({
          products: products
        })
        .then((response) => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
            props.cancleClick();
            setLoading(false);
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    }
    else {
      message = "Upload file to proceed.";
      showError();
    }
  };

  return (
    <Fragment>
      <div className="product-bulk-modal">
        <div className="product-bulk-header w-100">{props.heading}</div>
        <div className="boday-row w-100">
          <div className="input-group-row w-100 d-flex flex-column">
            {
              loading ?
                <div className="label">File is uploading. It may take time. Please wait...</div>
                :
                <div className="input-row d-flex align-items-center w-100">
                  <div className="label">Upload File</div>
                  <div className="value">
                    <input type="file" onChange={onChange} />
                  </div>
                </div>
            }
          </div>
          <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
            {/* <button
              className="cancel-button"
              type="button"
              onClick={props.cancleClick}
            >
              Cancel
            </button> */}
            <button
              className="create-button w-50"
              type="button"
              onClick={createImportProducts}
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#000" loading size={20} />
              ) : (
                "Import"
              )}
            </button>
            <button
              className="update-button mx-auto w-50"
              type="button"
              onClick={updateImportProducts}
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#000" loading size={20} />
              ) : (
                "Update Products"
              )}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return { storeId: state?.vendorStore?.vendorStore?.id };
};

export default connect(mapStateToProps)(ProductBulkImportLiveModal);
