import React, { Fragment } from "react";
import MainSlider from "./components/MainSlider";
import ProductCategoryList from "./components/ProductCategoryList";
import ProductList from "./components/ProductList";
import KidFashion from "../../../assets/images/Product_Home/1538127443KIDSBANNERMAIN.png";
import "./shop.scss";
import Header from "components/header/Header";

const Shop = (props) => {
  return (
    <Fragment>
      <div className="right-content shop-page-desktop">
        <div className="header-row">
          <Header
            heading="Store"
            subtext="View, edit, and manage the Shops on your site."
          />
        </div>
        <div className="main-row page-container">
          <div className="content-wrapper">
            <MainSlider />

            <div className="product-category-row">
              <ProductCategoryList />
            </div>
            <div className="product-list-row">
              <ProductList />
              <ProductList />
            </div>
            <div className="banner-row">
              <div className="banner-container">
                <img
                  src={KidFashion}
                  alt="Banner"
                  width="100%"
                  height="550px"
                />
              </div>
            </div>
            <div className="product-list-row">
              <ProductList />
              <ProductList />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Shop;
