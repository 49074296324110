import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as CashBW } from "assets/icons/CashBW.svg";
import "./paymentmethod.scss";

import MasterCardIcon from "../../../../assets/icons/mastercard.png";
import VisaCard from "../../../../assets/icons/visaCard.png";
const PaymentMethod = ({ paymentMethod }) => {
  return (
    <Row className="payment-method-desktop custom-card-2 g-0 mt-3 p-3">
      <Col md={12} className="title text-uppercase fw-600">
        Payment Method
      </Col>
      {paymentMethod ? (
        <Col md={12} className="pt-2">
          <Row className="g-0 align-items-center">
            <>
              {paymentMethod.wallet && (
                <>
                  <div className="method-name">Wallet</div>
                  <div>
                    <div className="card-number capitalize me-3">
                      {paymentMethod.currency}
                      <span
                        className="method-name green"
                        style={{ marginInlineStart: "5px" }}
                      >
                        {paymentMethod.wallet}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {paymentMethod.payment_card && (
                <div className="d-flex flex-column">
                  <div className="method-name green">Master Card</div>
                  <div className="d-flex align-items-center">
                    <div
                      className={` ${
                        paymentMethod?.payment_card_type === "Visa"
                          ? "logo-container-visa me-2"
                          : "logo-container me-3"
                      }`}
                    >
                      <img
                        src={
                          paymentMethod?.payment_card_type === "Visa"
                            ? VisaCard
                            : paymentMethod?.payment_card_type === "MasterCard"
                            ? MasterCardIcon
                            : MasterCardIcon
                        }
                        alt=""
                      />
                    </div>
                    <div className="card-number capitalize me-3">
                      {paymentMethod.currency}
                    </div>
                    <div className="card-number me-3">
                      {paymentMethod.payment_card}
                    </div>
                  </div>
                  <div className="card-number d-flex flex-direction-row">
                    <span>Card Number</span>
                    <div className="method-name green ms-2">
                      {paymentMethod.payment_card_number}
                    </div>
                  </div>
                </div>
              )}
              {paymentMethod.cash_on_delivery && (
                <>
                  <div className="method-name">Cash On Delivery</div>
                  <div className="d-flex align-items-center">
                    <div className="me-3 logo-container">
                      <CashBW />
                    </div>
                    <div className="card-number capitalize me-3">
                      {paymentMethod.currency}
                    </div>
                    <div className="card-number me-3">
                      {paymentMethod.cash_on_delivery}
                    </div>
                  </div>
                </>
              )}
            </>
          </Row>
        </Col>
      ) : (
        <h6>There are no payment methods yet</h6>
      )}
    </Row>
  );
};

export default PaymentMethod;
