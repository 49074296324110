import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import "./RefundModal.scss";

import ClipLoader from "react-spinners/ClipLoader";
import { apiJson } from "api/Api";

const CouponModal = (props) => {
  const [show, setShow] = useState(true);
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.onClose(false);
  };

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = (id) => {
    if (amount.length > 0) {
      setLoading(true);
      apiJson.returns
        .Refund(id, {
          refund: {
            amount: parseFloat(amount),
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.success) {
            handleClose();
            message = "Successfully Refunded!";
            showSuccess();
            window.location.reload();
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "please enter amount";
      showError();
    }
  };

  return (
    <Modal
      show={show}
      centered
      className="refund-modal-cont"
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Refund Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 d-flex justify-content-center">
          {" "}
          Refunded item price: {props.itemCurrency.toUpperCase()} {props.variant_price*props.quantity}{" "}
        </div>
        <div className="mt-3 mb-5 d-flex justify-content-center">
          <input
            placeholder={`Amount ( ${props.currency.toUpperCase()}  ) `}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
        </div>
        <div className=" w-100 d-fex justify-content-start align-items-center">
          <button className="cancel-button" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button
            onClick={() =>
              window.confirm(
                `Are you sure you want to transfer ${props.currency.toUpperCase()} ${amount}?`
              ) && handleSubmit(props.id)
            }
            className="create-button"
            type="submit"
          >
            {loading ? <ClipLoader color="#000" loading size={20} /> : "Create"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    stores: state.stores.stores_list,
    coupanDetails: state.coupon.coupanDetails,
  };
};

const mapDispatchTopProps = {};

export default connect(mapStateToProps, mapDispatchTopProps)(CouponModal);
