import React, { Fragment } from "react";

import "./login.scss";
import Logo from "../../../assets/images/logo.png";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <Fragment>
      <div className="login-container-desktop mx-auto mt-5">
        <div className="row g-0 login-form-row flex-md-column flex-lg-row justify-content-center align-items-center mx-auto">
          <div className="login-form-col col-12 col-sm-10 cold-md-8 col-lg-8 col-xl-8 col-xxl-6 d-flex flex-column align-items-center">
            <div className="logo-container">
              <img src={Logo} alt="" width="100%" height="auto" />
            </div>
            <div className="login-header mx-auto">Sign in to Admin Panel</div>
            <div className="login-form">
              <div className="form-inputs mx-auto">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
