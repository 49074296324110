import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./shipmentservice.scss";
import ShipmentItem from "./ShipmentItem";
import { GoPackage } from "react-icons/go";

const classname = ["status", "orange-status", "red-status"];

const pending = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);
const processing = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const shipped = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const delivered = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
  </Row>
);

const cancelled = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const rejected = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const returned = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const return_requested = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
  </Row>
);

const return_rejected = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const ShipmentService = ({ service, currency }) => {
  const [barStatus, setBarStatus] = useState(1);
  useEffect(() => {
    switch (service?.status.toLowerCase()) {
      case "cancelled":
        setBarStatus(2);
        break;
      case "rejected":
        setBarStatus(2);
        break;
      case "returned":
        setBarStatus(2);
        break;
      case "return_requested":
        setBarStatus(1);
        break;
      case "return_rejected":
        setBarStatus(2);
        break;
      default:
        setBarStatus(0);
    }
  }, [service?.status]);

  return (
    <Fragment>
      <div className="shipment-service-desktop custom-card-2 mt-3">
        <div className="wrapper">
          <div className="shipment-summary vertical-separator px-2 pt-3 pb-2">
            <Row className="g-0">
              <Col md={12} className="pb-2">
                <span className="shipment-title text-uppercase faded-black-2-text fw-600 mr-3">
                  <GoPackage className="me-2" />
                  <span className="me-2">Service</span>
                  {service.orderable.name_en}
                </span>
              </Col>
            </Row>

            {service?.status && (
              <>
                {service?.status === "pending" && pending}
                {service?.status === "cancelled" && cancelled}
                {service?.status === "rejected" && rejected}
                {service?.status === "processing" && processing}
                {service?.status === "shipped" && shipped}
                {service?.status === "delivered" && delivered}
                {service?.status === "returned" && returned}
                {service?.status === "return_requested" && return_requested}
                {service?.status === "return_rejected" && return_rejected}
              </>
            )}

            <div className={classname[barStatus]}>
              <div className="row no-gutters">
                <div className="col-md-6 fw-700 text-uppercase mt-1">
                  {service.status && service.status}
                </div>
              </div>
            </div>
          </div>
          <div className="shipment-items">
            <ShipmentItem shipmentItem={service} currency={currency} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orderDetail: state.order.orderDetail,
  };
};

export default connect(mapStateToProps, null)(ShipmentService);
