import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { set_zones } from "redux/actions/ShippingZoneAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TableList = (props) => {
  const [activePage, setActivePage] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);

  useEffect(() => {
    setMin(0);
    setMax(10);
    setActivePage(0);
  }, [props.List]);

  const handlePageChange = (pageNumber) => {
    setMin(pageNumber.selected * 10);
    setMax((pageNumber.selected + 1) * 10);
    setActivePage(pageNumber.selected);
  };
  let message = "";
  const currentZone = {};
  const [currentZoneID, setCurrentZoneID] = useState("");
  const [loading, setLoading] = useState(false);

  const onViewZone = (zoneID) => {
    setisViewClick(true);
  };
  const onEditZone = (zoneID) => {
    setisEditClick(true);
  };
  const menuItems = [
    <MenuItem
      key="1"
      onClick={() => onViewZone(currentZoneID)}
      style={{ cursor: "pointer" }}
    >
      View
    </MenuItem>,
    <MenuItem
      key="2"
      onClick={() => onEditZone(currentZoneID)}
      style={{ cursor: "pointer" }}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={() => handleDeleteClick()}
      style={{ cursor: "pointer" }}
    >
      Delete
    </MenuItem>,
  ];
  const menu = <Menu className="menu">{menuItems}</Menu>;

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [isEditClick, setisEditClick] = useState(false);
  const [isViewClick, setisViewClick] = useState(false);

  const handleDeleteClick = () => {
    if (window.confirm("Are you sure you want to delete this zone?")) {
      setLoading(true);
      apiJson.zone.deleteZone(currentZoneID).then((response) => {
        if (response.success) {
          setLoading(false);
          props.set_zones({
            singleZone: response.data,
            zones: props.zones.filter((zone) => zone.id !== response.data.id),
          });
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  };

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="w-10 header-text">Zone Id</td>
            <td className="w-10 header-text">Zone Name</td>
            <td className="w-10 header-text">Threshold</td>
            <td className="w-10 header-text">Price above threshold</td>
            <td className="w-10 header-text">Price below threshold</td>
            <td className="w-10 header-text">Base Price</td>
            <td className="w-10 header-text">Weight Unit</td>
            <td className="w-10 header-text">Price per unit weight</td>
            <td className="header-text">Actions</td>
          </tr>
        </thead>
        <tbody>
          {props.List &&
            props.List.slice(min, max).map((zone) => {
              return (
                <tr key={zone.id}>
                  <td className="w-10 header-text">{zone.id}</td>
                  <td className="w-10 header-text">{zone.name}</td>
                  <td className="w-10 header-text">{zone.threshold}</td>
                  <td className="w-10 header-text">
                    {zone.above_threshold_price}
                  </td>
                  <td className="w-10 header-text">
                    {zone.below_threshold_price}
                  </td>
                  <td className="w-10 header-text">{zone.base_price}</td>
                  <td className="w-10 header-text">{zone.weight_unit}</td>
                  <td className="w-10 header-text">{zone.weight_unit_price}</td>
                  <td className="" onClick={(e) => e.preventDefault()}>
                    <Dropdown
                      className="dropdown-for-send"
                      overlay={menu}
                      overlayClassName="overlay-class"
                    >
                      {loading ? (
                        <BsThreeDots style={{ cursor: "pointer" }} size={20} />
                      ) : (
                        <BsThreeDots
                          style={{ cursor: "pointer" }}
                          size={20}
                          onMouseEnter={() => setCurrentZoneID(zone.id)}
                          onClick={() => setCurrentZoneID(zone.id)}
                        />
                      )}
                    </Dropdown>
                    {loading && currentZoneID === zone.id && (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#000" loading size={15} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {props.List &&
        (isViewClick ? (
          <Redirect
            push
            to={{
              pathname: `/edit-zone/${currentZoneID}`,
              state: { name: "view", zone: { currentZone } },
            }}
          />
        ) : (
          isEditClick && (
            <Redirect
              push
              to={{
                pathname: `/edit-zone/${currentZoneID}`,
                state: { name: "edit", zone: { currentZone } },
              }}
            />
          )
        ))}
      {props.List?.length > 10 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={Math.ceil(props.List.length / 10)}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            forcePage={activePage}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProsps = (state) => {
  return { singleZone: state.zones.singleZone, zones: state.zones.zones };
};

const mapDispatchToProps = { set_zones };

export default connect(mapStateToProsps, mapDispatchToProps)(TableList);
