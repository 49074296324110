export const getResizedImage = (resized, featured) => {
  if (resized !== null) {
    if (resized?.[1]) {
      return resized[1];
    } else if (resized?.[1]) {
      return resized[0];
    } else {
      return featured;
    }
  } else {
    return featured;
  }
};
