import React, { Fragment } from "react";

import "./productcategory.scss";

const ProductCategory = (props) => {
  return (
    <Fragment>
      <div className="product-category-container">
        <div className="product-category-image-container">
          <img src={props.product.img} alt="category" />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCategory;
