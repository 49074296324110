export const type = {
  SET_ORDERS: "SET_ORDERS",
  SET_COUPONS: "SET_COUPONS",
  SET_COUPON_DETAIL: "SET_COUPON_DETAIL",
  SET_SERVICES: "SET_SERVICES",
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_VARIANTS: "SET_VARIANTS",
  SET_RETURNS: "SET_RETURNS",
  SET_SERVICE_DETAIL: "SET_SERVICE_DETAIL",
  SET_PRODUCT_DETAIL: "SET_PRODUCT_DETAIL",
  SET_VARIANT_DETAIL: "SET_PRODUCT_DETAIL",
  SET_USER: "SET_USER",
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_USERS: "SET_USERS",
  SET_BRANDS: "SET_BRANDS",
  SET_VENDOR: "SET_VENDOR",
  SET_STORES: "SET_STORES",
  SET_SINGLE_STORE: "SET_SINGLE_STORE",
  SET_SECTIONS: "SET_SECTIONS",
  SET_SINGLE_SECTION: "SET_SINGLE_SECTION",
  DELETE_PRODUCT_FROM_SECTION: "DELETE_PRODUCT_FROM_SECTION",
  SET_ZONES: "SET_ZONES",
  SET_PLATFORM_SETTINGS_ID: "SET_PLATFORM_SETTINGS_ID",
  SET_PLATFORM_SETTINGS: "SET_PLATFORM_SETTINGS",
  SET_BANNERS: "SET_BANNERS",
  SET_BANNER_DETAILS: "SET_BANNER_DETAILS",
  SET_DISPUTES: "SET_DISPUTES",
  REMOVE_PRODUCT: "REMOVE_PRODUCT",
  REMOVE_SERVICE: "REMOVE_SERVICE",
  REMOVE_CATEGORY: "REMOVE_CATEGORY",
  REMOVE_BRAND: "REMOVE_BRAND",
  REMOVE_VARIANT: "REMOVE_VARIANT",
  REMOVE_USER: "REMOVE_USER",
  SET_VARIANTS_ARR: "SET_VARIANTS_ARR",
  CHANGE_REDIRECT: "CHANGE_REDIRECT",
  SET_URLS: "SET_URLS",
};
