import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-paginate";
import { set_banner_details } from "../../../redux/actions/BannerAction";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const TableList = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [bannerID, setBannerID] = useState("");
  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  const getBannerDetails = (id) => {
    return props.List.filter((item) => {
      return item.id === id;
    })[0];
  };

  const showBanner = (id) => {
    setBannerID(id);
    const bannerDetails = getBannerDetails(id);
    props.set_banner_details({ bannerDetails: bannerDetails });
    setRedirect(true);
  };
  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="header-text">Banner Id</td>
            <td className="header-text">Country</td>
            <td className="header-text">Type</td>
            <td className="header-text">Redirect To</td>
            <td className="w-25 header-text">Image</td>
          </tr>
        </thead>
        <tbody>
          {props.List &&
            props.List.map((banner) => {
              return (
                <tr
                  key={banner.id}
                  onClick={() => showBanner(banner.id)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="header-text">{banner.id}</td>
                  <td className="header-text">{banner.country_name_en}</td>
                  <td className="header-text">{banner.banner_type}</td>
                  <td className="header-text">
                    <a href={banner.redirect_to}>{banner.redirects_to_link}</a>
                  </td>
                  <td className="w-25 header-text">
                    <a onClick={(e) => e.stopPropagation()} href={banner.image}>
                      <div
                        className="image-container mx-auto"
                        style={{ width: "400px", height: "100px" }}
                      >
                        <img
                          src={banner.image}
                          alt="banner"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {props.pagination?.total_pages && props.List.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            forcePage={parseInt(props?.pagination?.page) - 1}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `/view-banner/${bannerID}`,
            state: { name: "view" },
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerDetails: state.banner.bannerDetails,
  };
};

const mapDispatchTopProps = { set_banner_details };

export default connect(mapStateToProps, mapDispatchTopProps)(TableList);
