import { type } from "../types/types";

export const set_coupons = (coupons) => (dispatch) => {
  dispatch({
    type: type.SET_COUPONS,
    coupons: coupons,
  });
};

export const set_coupon_details = (couponDetails) => (dispatch) => {
  dispatch({
    type: type.SET_COUPON_DETAIL,
    couponDetails: couponDetails,
  });
};