import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Field, Form, Formik } from "formik";
import Header from "components/header/Header";
import * as Yup from "yup";
import { Redirect, useLocation } from "react-router-dom";

import "./sendemail.scss";
import { getClass } from "utils/utils";

const sendemailSchema = Yup.object().shape({
  subject: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => <textarea {...field} {...props} rows={10} />;

const SendEmail = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const inputvalues = {
    subject: "",
    message: "",
  };

  useEffect(() => {
    document.title = "Send Email";
  }, []);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmitClick = (values) => {
    setLoading(true);
    apiJson.emails
      .sendEmail({
        email: location.state.email,
        subject: values.subject,
        message: values.message,
      })
      .then((response) => {
        if (response.success) {
          message = "Email sent";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  return (
    <Fragment>
      <div className="right-content store-page-desktop">
        <div className="header-row">
          <Header heading="Send Email" subtext="" />
          <div className="vendor-page-right sendemail-page-desktop">
            <div className="right-content sendemail-page-content-container">
              <div className="form-container">
                <Formik
                  initialValues={inputvalues}
                  validationSchema={sendemailSchema}
                  onSubmit={handleSubmitClick}
                >
                  {({ errors, touched, values }) => (
                    <Form className="sendemail-form mx-auto">
                      <div className="row mx-0">
                        <div className="english-col col-9">
                          <div className="row mx-0">
                            <div className="label col-4">Subject</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.subject && errors.subject
                                )}`}
                                name="subject"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.subject && errors.subject && (
                                <div className="english-error">
                                  {errors.subject}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-9">
                          <div className="row mx-0 align-items-start">
                            <div className="label col-4">Message</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.message && errors.message
                                )}`}
                                name="message"
                                component={CustomInputComponent}
                                placeholder="Enter your message"
                              />
                              {touched.message && errors.message && (
                                <div className="english-error">
                                  {errors.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row button-row mx-0">
                        <div className="button-col col-9 d-flex justify-content-end">
                          <button type="submit" className="send-email-button">
                            {loading ? (
                              <div className="d-flex justify-content-center align-items-center w-100">
                                <ClipLoader color="#fff" loading size={24} />
                              </div>
                            ) : (
                              "Send Email"
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {redirect ? (
        <Redirect
          to={{
            pathname: location.state.name,
          }}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
