import React, { Fragment, useEffect, useState } from "react";

const ShippingRuleForm = (props) => {
  return (
    <div>
      <form
        onSubmit={props.saveBtnClicked}
        style={{
          padding: "15px 25px",
          background: "white"
        }}>
        <div className="row d-flex align-items-center">
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Dubai Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="dubai_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.dubai_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Sharjah Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="sharjah_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.sharjah_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Abu Dhabi Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="abu_dhabi_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.abu_dhabi_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Ajman Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="ajman_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.ajman_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Fujairah Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="fujairah_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.fujairah_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Ras Al Khaimah Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="ras_al_khaimah_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.ras_al_khaimah_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          <div className="col-6 row align-items-center mt-3">
            <div className="col-4">
              <label>Umm Al Quwain Charges:</label>
            </div>
            <div className="col-8">
              <input
                name="umm_al_quwain_charges"
                type="number"
                className="form-control"
                value={props?.shippingRule?.umm_al_quwain_charges || ""}
                onChange={props.onInputFieldChanged}
              />
            </div>
          </div>
          {
            props.activeTab == "Threshold" && (
              <div className="col-6 row align-items-center mt-3">
                <div className="col-4">
                  <label>Default Charges:</label>
                </div>
                <div className="col-8">
                  <input
                    name="default_charges"
                    type="number"
                    className="form-control"
                    value={props?.shippingRule?.default_charges || ""}
                    onChange={props.onInputFieldChanged}
                  />
                </div>
              </div>
            )
          }
        </div>
        <div className="row d-flex">
          {
            (props.activeTab == "Standard" || props.activeTab == "Threshold") && (
              <div className="col-6 row align-items-center mt-3">
                <div className="col-4">
                  <label>Order Threshold Amount:</label>
                </div>
                <div className="col-8">
                  <input
                    name="order_threshold_amount"
                    type="number"
                    className="form-control"
                    value={props?.shippingRule?.order_threshold_amount || ""}
                    onChange={props.onInputFieldChanged}
                  />
                </div>
              </div>
            )
          }
          {
            props.activeTab == "Bulk" && (
              <div className="col-6 row align-items-center mt-3">
                <div className="col-4">
                  <label>Order Threshold Weight(Tons):</label>
                </div>
                <div className="col-8">
                  <input
                    name="order_threshold_weight"
                    type="number"
                    className="form-control"
                    value={props?.shippingRule?.order_threshold_weight || ""}
                    onChange={props.onInputFieldChanged}
                  />
                </div>
              </div>
            )
          }
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <input
            className="mt-4"
            type="submit"
            value="Save"
            style={{
              background: "#742013",
              border: "1px solid #742013",
              color: "white",
              width: "200px"
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ShippingRuleForm;
