import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/header/Header";
import HeaderTab from "../tab/HeaderTab";
import Select from 'react-select'
import { ProductTabData } from "../../../Extra/TabsData";
import "./contentmetrix.scss";
import TableList from "./TableList";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

import { tabParam } from "utils/utils";
import { debounce } from "lodash";

const ContentMetrix = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [SKUSearchText, setSKUSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(ProductTabData.tablist[0].id);
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [variants, setVariants] = useState([]);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    document.title = "Content Metrix";
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getProducts = (page, params) => {
    setData(false);
    apiJson.products.getProductsMetrix(page, params).then((response) => {
      if (response.success) {
        let temp_result = response.data.variants;
        temp_result.map(variant => {
          variant.overall = ((variant.video + variant.upc_code + variant.images_score + variant.how_to_use + variant.description_score + variant.features_score + variant.benefits_score) / 7).toFixed(2);
        });
        console.log('temp_result',temp_result)
        setVariants(temp_result)
        setPagination(response.data.pagination)
        
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getProducts(
        1,
        tabParam(title) +
        (searchText?.length > 0 ? "&search_by_name=" + searchText : "") +
        (SKUSearchText?.length > 0 ? "&search_by_sku=" + SKUSearchText : "")
      );
    }
  };

  const pageChange = (page) => {
    getProducts(
      page,
      tabParam(tabTitle) +
      (searchText?.length > 0 ? "&search_by_name=" + searchText : "") +
      (SKUSearchText?.length > 0 ? "&search_by_sku=" + SKUSearchText : "")
    );
  };

  useEffect(() => {
    apiJson.brands.getBrands()
      .then(response => {
        if (response.success) {
          var api_brands = [];
          response.data.forEach(brand => {
            api_brands.push({
              value: brand.id,
              label: brand.name_en
            });
          });
          setBrands(api_brands);
        }
      })
    getProducts(1, "&with_status=active");
    // eslint-disable-next-line
  }, []);

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab, sku) => handleSearch(text, currTab, sku), 1000),
      []
    );

  const handleSearch = (text, tab, sku) => {
    getProducts(
      1,
      tabParam(tab) +
      "&search_by_name=" +
      text +
      (sku?.length > 0 ? "&search_by_sku=" + sku : "")
    );
  };

  const debouncedSKUCallback =
    // eslint-disable-next-line
    useCallback(
      debounce(
        (text, currTab, search) => handleSKUSearch(text, currTab, search),
        1000
      ),
      []
    );

  const handleSKUSearch = (text, tab, search) => {
    getProducts(
      1,
      tabParam(tab) +
      (search.length > 0 ? "&search_by_name=" + search : "") +
      "&search_by_sku=" +
      encodeURIComponent(text)
    );
  };

  return (
    <Fragment>
      <div className="right-content contentmetrix-page">
        <div className="header-row">
          <Header
            subtext="Manage analytics of products."
            heading="Content PQ Metrix"
            // search={true}
            // sku={true}
            filters={null}
            // handleSearch={(text) => {
            //   setSearchText(text);
            //   debouncedCallback(text, tabTitle, SKUSearchText);
            // }}
            // handleSKUSearch={(text) => {
            //   setSKUSearchText(text);
            //   debouncedSKUCallback(text, tabTitle, searchText);
            // }}
            // searchText={searchText}
            // searchSKUText={SKUSearchText}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              variants && (
                <div className="product-tab">
                  {/* <HeaderTab
                    onTabChoose={ChangeTabList}
                    tabTitle={tabTitle}
                    tab={headerList}
                  > */}
                    <TableList
                      List={variants}
                      onPageChange={pageChange}
                      pagination={pagination}
                    />
                  {/* </HeaderTab> */}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = { };

export default connect(mapStateToProsps, mapDispatchToProps)(ContentMetrix);
