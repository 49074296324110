import { Field, Form, Formik } from "formik";
import React, { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import Header from "components/header/Header";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_categories } from "redux/actions/CategoriesAction";
import Switch from "react-switch";
import Select from "react-select";
import { getUniqueParents } from "Extra/GetUnqiueParents";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { set_countries } from "redux/actions/CountryAction";
import { useParams } from "react-router-dom";
import { getClass, getRandomInt, deleteS3Image } from "utils/utils";
import "./editcategory.scss";

const variantSchema = Yup.object().shape({
  title_en: Yup.string().required("Required"),
  featured: Yup.string().required("Required"),
  country_id: Yup.number().required("Required"),
  image: Yup.string().required("Required"),
});

const EditCategory = (props) => {
  const param = useParams();
  const location = useLocation();
  const pageName = location.state.name;
  const [brands, setBrands] = useState([]);
  const [brandsOption, setBrandsOption] = useState([]);
  const [isdata, setisData] = useState(false);
  const [isEditClick, setisEditClick] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState({});
  const [redirectBack, setRedirectBack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [deletedImagesUrl, setDeletedImagesUrl] = useState([]);

  const [Category, setCategory] = useState(
    pageName === "create" ? {} : categoryDetail
  );
  const [UniqueParentCategoriesList, setUniqueParentCategoriesList] = useState(
    []
  );
  const [uploadingImages, setUploadingImages] = useState(false);

  const [values, setvalues] = useState({
    parent_id: "",
    parent_category_title_en: "",
    title_ar: "",
    title_en: "",
    featured: false,
    position: 0,
    country_id: "",
    country_name_en: "",
    image: "",
    brands: [],
    meta_title: "",
    meta_description: ""
  });
  const heading =
    location.state.name === "view" ? "View Category" : "Create/Edit a Category";

  const empty = {
    value: 0,
    label: "None",
  };
  var [clist, setClist] = useState([]);

  let countriesList = [];
  let pcatlist = [];
  const [parentCategories, setParentCategories] = useState([]);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Category";
  }, []);

  useEffect(() => {
    setDeletedImagesUrl([]);

    setisData(true);
    if (pageName === "view" || pageName === "edit") {
      apiJson.categories
        .getCategoryDetail(parseInt(param.id))
        .then((response) => {
          if (response.success) {
            setCategoryDetail(response.data);
            setCategory(response.data);
            setvalues({
              parent_id: response.data?.parent_id || "",
              parent_category_title_en:
                response.data?.parent_category_title_en || "",

              title_ar: response.data.title_ar,
              title_en: response.data.title_en,
              featured: response.data.featured,
              position: response.data.position,
              country_id: response.data.country_id,
              country_name_en: response.data.country_name_en,
              meta_title: response.data.meta_title,
              meta_description: response.data.meta_description,
              image: response.data.image,
              brands: response.data?.brands || [],
            });
            fetchBrands(response.data.country_id);
            setisData(false);
          } else if (!response.status) {
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            setisData(false);
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      fetchBrands();
      setvalues({
        parent_id: "",
        parent_category_title_en: "",
        title_ar: "",
        title_en: "",
        featured: false,
        position: 0,
        country_id: "",
        country_name_en: "",
        meta_title: "",
        meta_description: "",
        image: "",
        brands: [],
      });
      setisData(false);
    }
  }, []);

  const handleBrandChange = (value, setFieldValue) => {
    setFieldValue("brands", [
      ...value?.map((value) => {
        return {
          id: value.value,
          name_en: value.label,
        };
      }),
    ]);
  };

  const handleParentCategory = (value, setFieldValue) => {
    setFieldValue("parent_id", value.value);
    setFieldValue("parent_category_title_en", value.label);
  };
  const handleCountry = (e, setFieldValue) => {
    setFieldValue("country_id", e.value);
    setFieldValue("country_name_en", e.label);
    setFieldValue("parent_id", "");
    setFieldValue("parent_category_title_en", "");
    setFieldValue("brands", []);
    setBrandsOption(brands?.filter((brand) => brand.country_id === e.value));
    // eslint-disable-next-line
    UniqueParentCategoriesList.map((parentCat) => {
      if (parentCat.id === e.value) {
        // eslint-disable-next-line
        parentCat.list.map((pcat) => {
          pcatlist.push({
            value: pcat.id,
            label: pcat.title_en,
          });
        });
        setParentCategories(pcatlist);
      }
    });
  };

  const fetchBrands = (country) => {
    apiJson.brands.getBrands().then((response) => {
      if (response.success) {
        setBrands(response.data);
        if (country) {
          setBrandsOption(
            response.data.filter((brand) => brand.country_id === country)
          );
        }
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const handleFilesChange = (files, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        setFieldValue("image", response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  useEffect(() => {
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        props.set_countries({ countries: response.data });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
        }
      }
    });
  }, []);

  useEffect(() => {
    apiJson.categories.getCategoriesForCategories().then((response) => {
      if (response.success) {
        props.set_categories({ forCategories: response.data });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  useEffect(() => {
    props.countries.map((cou) => {
      countriesList.push({
        value: cou.id,
        label: cou.name_en,
      });
      return cou;
    });

    setClist(countriesList);

    setUniqueParentCategoriesList(
      getUniqueParents(props.forCategories, props.countries)
    );
    // eslint-disable-next-line
  }, [props.countries, props.forCategories]);

  const Update = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.categories
      .editCategory(parseInt(param.id), {
        category: {
          title_en: values.title_en,
          title_ar:
            values.title_ar?.length < 1 ? values.title_en : values.title_ar,
          featured: values.featured,
          parent_id: values.parent_id ? values.parent_id : null,
          image: values.image,
          position: parseInt(values.position),
          country_id: values.country_id,
          top_brands_attributes:
            values.brands?.length > 0
              ? values.brands?.map((brand) => {
                return {
                  brand_id: brand.id,
                };
              })
              : [],
          meta_title: values.meta_title,
          meta_description: values.meta_description
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Category Updated";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Create = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.categories
      .createCategory({
        category: {
          title_en: values.title_en,
          title_ar:
            values.title_ar.length < 1 ? values.title_en : values.title_ar,
          featured: values.featured,
          parent_id: values.parent_id ? values.parent_id : null,
          image: values.image,
          position: parseInt(values.position),
          country_id: values.country_id,
          top_brands_attributes:
            values.brands?.length > 0
              ? values.brands?.map((brand) => {
                return {
                  brand_id: brand.id,
                };
              })
              : [],
          meta_title: values.meta_title,
          meta_description: values.meta_description
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Category Created";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      setLoading(true);
      apiJson.categories.removeCategory(parseInt(param.id)).then((response) => {
        if (response.success) {
          message = "Category Deleted";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  };

  const handleSwitchChange = (value, setFieldValue) => {
    setFieldValue("featured", value);
  };
  const handleEditButton = () => {
    CategoryListAndValueAtEdit();
    location.state.name = "edit";
  };

  const CategoryListAndValueAtEdit = () => {
    // eslint-disable-next-line
    UniqueParentCategoriesList.map((parentCat) => {
      if (parentCat.id === Category.country_id) {
        // eslint-disable-next-line
        parentCat.list.map((pcat) => {
          pcatlist.push({
            value: pcat.id,
            label: pcat.title_en,
          });
        });

        pcatlist.unshift(empty);
        setParentCategories(pcatlist);
      }
    });
  };

  const handleSave = (values) => {
    if (location.state.name === "edit") {
      Update(values);
    } else if (location.state.name === "create") {
      Create(values);
    }
  };

  useEffect(() => {
    location.state.name !== "view"
      ? setisEditClick(false)
      : setisEditClick(true);
  }, [location.state.name]);

  return (
    <Fragment>
      <div className="right-content category-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage the Categories on your site."
          />
        </div>
        {isdata ? (
          <div className="d-flex justify-content-center align-item-center w-100">
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <div className="main-row category-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={values}
                validationSchema={variantSchema}
                enableReinitialize
                onSubmit={(values) => handleSave(values)}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="category-form mx-auto">
                    <fieldset disabled={isEditClick}>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Category Name</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.title_en && errors.title_en
                                )}`}
                                name="title_en"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.title_en && errors.title_en && (
                                <div className="english-error">
                                  {errors.title_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="arabic-col col-md-12 col-lg-6">
                          <div className="row justify-content-end mx-0">
                            <div className="col-8 input">
                              <Field
                                className="w-100"
                                name="title_ar"
                                type="text"
                                placeholder="اسم التصنيف"
                              />
                            </div>
                            <div className="label col-3">اسم التصنيف</div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Country</div>
                            <div className="col-8 input">
                              <Select
                                value={
                                  values.country_id
                                    ? {
                                      value: values.country_id,
                                      label: values.country_name_en,
                                    }
                                    : ""
                                }
                                onChange={(value) =>
                                  handleCountry(value, setFieldValue)
                                }
                                onBlur={() =>
                                  setFieldTouched("country_id", true)
                                }
                                options={clist}
                                name="country"
                                placeholder="Select Country"
                                className={`category-select w-100 ${getClass(
                                  errors.country_id && touched.country_id
                                )}`}
                                classNamePrefix="select"
                                isSearchable={false}
                                isDisabled={isEditClick}
                              />
                              {errors.country_id && touched.country_id && (
                                <div className="english-error">
                                  {errors.country_id}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Featured</div>
                            <div className="col-8 input">
                              <Switch
                                onChange={(value) =>
                                  handleSwitchChange(value, setFieldValue)
                                }
                                checked={values.featured}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={isEditClick}
                              />
                              {touched.featured && errors.featured && (
                                <div className="english-error">
                                  {errors.featured}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Position</div>
                            <div className="col-8 input">
                              <Field
                                className="w-100"
                                name="position"
                                value={values.position ? values.position : ""}
                                type="number"
                                placeholder="Position"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Parent Category</div>
                            <div className="col-8 input">
                              <Select
                                value={
                                  values.parent_id
                                    ? {
                                      value: values.parent_id,
                                      label: values.parent_category_title_en,
                                    }
                                    : ""
                                }
                                onChange={(value) =>
                                  handleParentCategory(value, setFieldValue)
                                }
                                options={parentCategories}
                                className="category-select w-100"
                                classNamePrefix="select"
                                name="parent_category"
                                isSearchable={true}
                                isDisabled={isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0 align-items-start">
                            <div className="label col-4">Top Brands</div>
                            <div className="col-8 input">
                              <Select
                                value={
                                  values.brands?.length === 0
                                    ? []
                                    : values.brands?.map((brand) => {
                                      return {
                                        value: brand.id,
                                        label: brand.name_en,
                                      };
                                    })
                                }
                                onChange={(value) =>
                                  handleBrandChange(value, setFieldValue)
                                }
                                options={brandsOption.map((brand) => {
                                  return {
                                    value: brand.id,
                                    label: brand.name_en,
                                  };
                                })}
                                className="category-select w-100"
                                name="parent_category"
                                classNamePrefix="control"
                                isSearchable={true}
                                isMulti
                                isDisabled={isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0 mb-0 align-items-start">
                            <div className="label col-4">Category Image</div>
                            <div className="col-8 input">
                              {uploadingImages ? (
                                <div className="d-flex justify-content-center align-item-center w-100">
                                  <ClipLoader color="#000" loading size={20} />
                                </div>
                              ) : values.image ? (
                                <ImagesGrid
                                  name={location.state.name}
                                  remove={(val, url) => {
                                    setFieldValue("image", "");
                                    setDeletedImagesUrl([
                                      ...deletedImagesUrl,
                                      url,
                                    ]);
                                  }}
                                  images={[values.image]}
                                />
                              ) : (
                                touched.image &&
                                errors.image && (
                                  <div
                                    className="english-error"
                                    style={{
                                      color: "red",
                                      width: "fit-content",
                                    }}
                                  >
                                    {errors.image}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {location.state.name !== "view" && (
                        <div className="row mx-0">
                          <div className="english-col col-md-12">
                            <div className="row mx-0">
                              <div className="col-8 input offset-4">
                                <DropzoneElement
                                  multiple={false}
                                  placeholder="Click to select a file"
                                  updateSelectedServiceFiles={(value) =>
                                    handleFilesChange(value, setFieldValue)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Meta Title</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.meta_title && errors.meta_title
                                )}`}
                                name="meta_title"
                                type="text"
                                placeholder="Enter meta title"
                              />
                              {touched.meta_title && errors.meta_title && (
                                <div className="english-error">
                                  {errors.meta_title}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-md-12 col-lg-6">
                          <div className="row mx-0">
                            <div className="label col-4">Meta Description</div>
                            <div className="col-8 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.meta_description && errors.meta_description
                                )}`}
                                name="meta_description"
                                type="text"
                                placeholder="Enter meta description"
                              />
                              {touched.meta_description && errors.meta_description && (
                                <div className="english-error">
                                  {errors.meta_description}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-8 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          {!isEditClick ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                key={getRandomInt(1, 1000)}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={21}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => handleEditButton()}
                              >
                                Edit
                              </button>
                              <button
                                className="blue-button"
                                type="button"
                                style={{ backgroundColor: "#cd0e0e" }}
                                key={getRandomInt(1, 1000)}
                                onClick={() => handleDelete()}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={21}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {redirectBack ? <Redirect push to="/categories" /> : ""}
                    {redirect ? <Redirect push to="/categories" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    categories: state.category.categories,
    parentCategories: state.category.parentCategories,
    forCategories: state.category.forCategories,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = { set_countries, set_categories };

export default connect(mapStateToProsps, mapDispatchToProps)(EditCategory);
