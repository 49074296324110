import React, { Fragment, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-paginate";
import "./table.scss";
import { BsThreeDots } from "react-icons/bs";
import {
  set_variant_details,
  remove_variant,
} from "../../../../redux/actions/VariantsAction";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { apiJson } from "../../../../api/Api";

const ListTable = (props) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);
  const handlePageChange = (pageNumber) => {
    setMin(pageNumber.selected * 10);
    setMax((pageNumber.selected + 1) * 10);
  };
  const { List } = props;
  const [editClicked, setEditClicked] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [createClicked, setCreateClicked] = useState(false);
  const [dropdown, setdropdown] = useState({
    value: false,
    id: -1,
  });

  const selectedVariant = (id) => {
    return List.filter((item) => {
      return item.id === id;
    });
  };

  const handleViewClick = (id) => {
    let list = selectedVariant(id);
    props.set_variant_details({ variantDetails: list.length && list[0] });
    setViewClicked(true);
  };

  const handleEditClick = (id) => {
    let list = selectedVariant(id);
    props.set_variant_details({ variantDetails: list.length && list[0] });
    setEditClicked(true);
  };

  const deleteVariant = (id) => {
    apiJson.variants.removeVariant(props.productDetails.id, id);
  };

  const handleDeleteClick = (id) => {
    props.remove_variant(id);
    deleteVariant(id);
  };

  return (
    <Fragment>
      <div className="product-list-table">
        <div className="product-variant-table">
          <div className="head upper no-gutters w-100 font-weight-bold">
            <div className="left-div">Variant List</div>
            <div className="button-div">
              <button
                className="right-button"
                onClick={() => setCreateClicked(true)}
              >
                Add New Variant
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <Table hover className="overflow-auto borderless">
              <thead style={{ borderBottom: "#F5F5F9" }}>
                <tr className="table-head">
                  <td className="w-10 header-text">#</td>
                  <td className="w-10 header-text">Name</td>
                  <td className="w-10 header-text">Price</td>
                  <td className="w-10 header-text">Discount Price</td>
                  <td className="w-10 header-text">Quantity</td>
                  <td className="w-10 header-text">Actions</td>
                </tr>
              </thead>
              <tbody>
                {props.List &&
                  props.List.slice(min, max).map((variant) => {
                    const id = variant.id;
                    return (
                      <tr key={variant.id}>
                        <td className="word-break-none name-text">
                          {variant.id}
                        </td>
                        <td className="type font-weight-bold name-text">
                          {variant.name_en}
                        </td>
                        <td className="type font-weight-bold price-quantity-text">
                          {variant.price} {props.currency}
                        </td>
                        <td className="price-quantity-text">
                          {variant.discounted_price} {props.currency}
                        </td>
                        <td className="price-quantity-text">
                          {variant.quantity}
                        </td>
                        <td className="">
                          <BsThreeDots
                            onMouseEnter={() => {
                              setdropdown({
                                value:
                                  id === dropdown.id ? !dropdown.value : true,
                                id: id,
                              });
                            }}
                            onClick={() => {
                              setdropdown({
                                value:
                                  id === dropdown.id ? !dropdown.value : true,
                                id: id,
                              });
                            }}
                          />
                          {dropdown.value && dropdown.id === id && (
                            <div className="action-wrapper">
                              <div className="menu">
                                <div
                                  className="view"
                                  onClick={() => handleViewClick(dropdown.id)}
                                >
                                  View
                                </div>
                                <div
                                  className="edit"
                                  onClick={() => handleEditClick(dropdown.id)}
                                >
                                  Edit
                                </div>
                                <div
                                  className="delete"
                                  onClick={() => handleDeleteClick(id)}
                                >
                                  Delete
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {props.List?.length > 10 && (
              <div
                className="d-flex justify-content-center mb-4 w-100"
                style={{ direction: "ltr" }}
              >
                <Pagination
                  direction="ltr"
                  previousLabel={<FaChevronLeft color="#404553" />}
                  nextLabel={<FaChevronRight color="#404553" />}
                  pageCount={Math.ceil(props.List.length / 10)}
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={2}
                  containerClassName="pagination"
                  pageClassName="ps-3 pe-3 pt-2 pb-2"
                  activeClassName={
                    "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
                  }
                  nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                  previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {viewClicked && (
        <Redirect
          push
          to={{
            pathname: "/variant",
            state: { name: "view" },
          }}
        />
      )}
      {editClicked && (
        <Redirect
          push
          to={{
            pathname: "/variant",
            state: { name: "edit" },
          }}
        />
      )}
      {createClicked && (
        <Redirect
          push
          to={{
            pathname: "/variant",
            state: { name: "create" },
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    variantDetails: state?.variant?.variantDetails,
    productDetails: state?.product?.productDetails,
  };
};

const mapDispatchTopProps = { set_variant_details, remove_variant };

export default connect(mapStateToProps, mapDispatchTopProps)(ListTable);
