import React, { Fragment, useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./editshippingzone.scss";
import { set_zones } from "redux/actions/ShippingZoneAction";
import { Redirect, useLocation } from "react-router-dom";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { getRandomItem } from "utils/utils";
import { getClass } from "utils/utils";

const variantSchema = Yup.object().shape({
  name_en: Yup.string().required("Required"),
  threshold: Yup.number().min(0, "Negative not allowed").required("Required"),
  above_threshold_price: Yup.number()
    .min(0, "Negative not allowed")
    .required("Required"),
  below_threshold_price: Yup.number()
    .min(0, "Negative not allowed")
    .required("Required"),
});

const otherSchema = Yup.object().shape({
  base_price: Yup.number().min(0, "Negative not allowed").required("Required"),
  weight_unit: Yup.number().min(0, "Negative not allowed").required("Required"),
  price_per_unit: Yup.number()
    .min(0, "Negative not allowed")
    .required("Required"),
});

const EditShippingZone = (props) => {
  const [loading, setLoading] = useState(false);
  const param = useParams();
  const [data, setData] = useState(false);
  const location = useLocation();
  const locationName = location.state.name;
  const [pageName, setPageName] = useState(locationName);
  const [currentZone, setCurrentZone] = useState(
    pageName === "create" ? {} : {}
  );
  const [isEditClick, setisEditClick] = useState(false);
  const [values, setvalues] = useState(
    pageName === "create"
      ? {
          name_en: "",
          threshold: "",
          above_threshold_price: "",
          below_threshold_price: "",
          base_price: "",
          weight_unit: "",
          price_per_unit: "",
        }
      : {
          id: "",
          name_en: "",
          threshold: "",
          above_threshold_price: "",
          below_threshold_price: "",
          base_price: "",
          weight_unit: "",
          price_per_unit: "",
        }
  );
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Zone";
  }, []);

  useEffect(() => {
    if (pageName === "view" || pageName === "edit") {
      setData(true);
      apiJson.zone.getZoneDetail(parseInt(param.id)).then((response) => {
        if (response.success) {
          props.set_zones({ singleZone: response.data });
          setCurrentZone(response.data);
          setData(false);
          setvalues({
            id: response.data.id,
            name_en: response.data.name,
            threshold: response.data.threshold,
            above_threshold_price: response.data.above_threshold_price,
            below_threshold_price: response.data.below_threshold_price,
            base_price: response.data.base_price,
            weight_unit: response.data.weight_unit,
            price_per_unit: response.data.weight_unit_price,
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          setData(false);
        } else {
          setData(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  }, []);

  const Update = (values) => {
    setLoading(true);
    apiJson.zone
      .editShippingZone(currentZone.id, {
        shipping_zone: {
          name: values.name_en,
          threshold: values.threshold?.toString(),
          above_threshold_price: values.above_threshold_price?.toString(),
          below_threshold_price: values.below_threshold_price?.toString(),
          base_price: values.base_price?.toString(),
          weight_unit: values.weight_unit?.toString(),
          weight_unit_price: values.price_per_unit?.toString(),
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Shipping zone updated";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Create = (values) => {
    setLoading(true);
    apiJson.zone
      .createShippingZone({
        shipping_zone: {
          name: values.name_en,
          threshold: values.threshold?.toString(),
          above_threshold_price: values.above_threshold_price?.toString(),
          below_threshold_price: values.below_threshold_price?.toString(),
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Shipping zone created";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const [redirectBack, setRedirectBack] = useState(false);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (pageName === "create" || pageName === "edit") {
      setisEditClick(false);
    } else if (pageName === "view") {
      setisEditClick(true);
    }
  }, [pageName]);

  const handleEdit = () => {
    setPageName("edit");
    setisEditClick(true);
  };

  const heading = isEditClick
    ? "View Shipping Zone"
    : "Create/Edit a Shipping Zone";

  const handleSave = (values) => {
    if (pageName === "edit") {
      Update(values);
    } else Create(values);
  };

  return (
    <Fragment>
      <div className="right-content zone-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage the Shipping Zones on your site."
          />
        </div>
        {data ? (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={35} />
          </div>
        ) : (
          <div className="main-row zone-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={values}
                enableReinitialize
                validationSchema={
                  currentZone.name !== "Other" ? variantSchema : otherSchema
                }
                onSubmit={(values) => handleSave(values)}
              >
                {({ errors, touched }) => (
                  <Form className="zone-form mx-auto">
                    <fieldset disabled={isEditClick}>
                      {currentZone.name !== "Other" && (
                        <>
                          <div className="row mx-0">
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4">Zone Name</div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.name_en && errors.name_en
                                    )}`}
                                    name="name_en"
                                    type="text"
                                    placeholder="Enter name"
                                  />
                                  {touched.name_en && errors.name_en && (
                                    <div className="english-error">
                                      {errors.name_en}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4">Threshold</div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.threshold && errors.threshold
                                    )}`}
                                    name="threshold"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    placeholder="0.00"
                                  />
                                  {touched.threshold && errors.threshold && (
                                    <div className="english-error">
                                      {errors.threshold}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0">
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4 text-wrap">
                                  Above Threshold Price
                                </div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.above_threshold_price &&
                                        errors.above_threshold_price
                                    )}`}
                                    name="above_threshold_price"
                                    type="number"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                  />
                                  {touched.above_threshold_price &&
                                    errors.above_threshold_price && (
                                      <div className="english-error">
                                        {errors.above_threshold_price}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4 text-wrap">
                                  Below Threshold Price
                                </div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.below_threshold_price &&
                                        errors.below_threshold_price
                                    )}`}
                                    name="below_threshold_price"
                                    type="number"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                  />
                                  {touched.below_threshold_price &&
                                    errors.below_threshold_price && (
                                      <div className="english-error">
                                        {errors.below_threshold_price}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {currentZone.name === "Other" && (
                        <>
                          <div className="row mx-0">
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4">Base Price</div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.base_price && errors.base_price
                                    )}`}
                                    name="base_price"
                                    type="number"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                  />
                                  {touched.base_price && errors.base_price && (
                                    <div className="english-error">
                                      {errors.base_price}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4">
                                  Weight Per Unit
                                </div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.weight_unit && errors.weight_unit
                                    )}`}
                                    name="weight_unit"
                                    type="number"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                  />
                                  {touched.weight_unit &&
                                    errors.weight_unit && (
                                      <div className="english-error">
                                        {errors.weight_unit}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0">
                            <div className="english-col col-6">
                              <div className="row mx-0">
                                <div className="label col-4">
                                  Price Per Unit
                                </div>
                                <div className="col-7 input">
                                  <Field
                                    className={`w-100 ${getClass(
                                      touched.price_per_unit &&
                                        errors.price_per_unit
                                    )}`}
                                    name="price_per_unit"
                                    type="number"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                  />
                                  {touched.price_per_unit &&
                                    errors.price_per_unit && (
                                      <div className="english-error">
                                        {errors.price_per_unit}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-8 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          {!isEditClick ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                disabled={loading}
                                key={getRandomItem(1, 1000)}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-items-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={20}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => handleEdit()}
                              >
                                Edit
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {redirectBack ? <Redirect push to="/zones" /> : ""}
                    {redirect ? <Redirect push to="/zones" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { singleZone: state.zones.singleZone, zones: state.zones.zones };
};

const mapDispatchToProps = { set_zones };

export default connect(mapStateToProsps, mapDispatchToProps)(EditShippingZone);
