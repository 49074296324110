import { useState } from "react";
import Header from "components/header/Header";
import "./createsubadmin.scss";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

function CreateSubadmin(props) {
    const [countries, setCountries] = useState([]);
    const [first_name, setFirstName] = useState("");
    const [first_name_err, setFirstNameError] = useState("");
    const [last_name, setLastName] = useState("");
    const [last_name_err, setLastNameError] = useState("");
    const [email, setEmail] = useState("");
    const [email_err, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [password_err, setPasswordError] = useState("");
    const [selected_countries, setSelectedCountries] = useState([]);
    const history = useHistory();
    let message = "";

    useEffect(() => {
        apiJson
            .header.getCountries()
            .then(response => {
                if (response.success) {
                    let temp_countries = [];
                    response.data.map(country => {
                        temp_countries.push({
                            label: country.name_en,
                            value: country.id
                        });
                    });
                    setCountries(temp_countries);
                }
            });
    }, []);
    
    useEffect(() => {
      if (!isEmpty(props.user) && !props.user.roles.includes("admin")) {
        message = "User has no role of admin or specific permissions";
        showError();
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    }, [props.user]);

    const showError = () => {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showSuccess = () => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const onInputFieldChanged = (e) => {
        if (e.target.name == "first_name") {
            setFirstName(e.target.value);
            setFirstNameError("");
        }
        else if (e.target.name == "last_name") {
            setLastName(e.target.value);
            setLastNameError("");
        }
        else if (e.target.name == "email") {
            setEmail(e.target.value);
            setEmailError("");
        }
        else if (e.target.name == "password") {
            setPassword(e.target.value);
            setPasswordError("");
        }
    }

    const onCountrySelect = (e) => {
        setSelectedCountries(e);
    }

    const handleValidation = () => {
        var isValid = true;

        if (!first_name) {
            setFirstNameError("*First name is required.");
            isValid = false;
        }
        else {
            setFirstNameError("");
        }

        if (!last_name) {
            setLastNameError("*Last name is required.");
            isValid = false;
        }
        else {
            setLastNameError("");
        }

        if (!email) {
            setEmailError("*Email is required.");
            isValid = false;
        }
        else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError("*Password is required.");
            isValid = false;
        }
        else {
            if (password.length < 6) {
                setPasswordError("*Password must be greater than six characters.");
                isValid = false;
            }
            else {
                setPasswordError("");
            }
        }

        return isValid;
    }

    const onSaveBtnClicked = (e) => {
        e.preventDefault();

        if (handleValidation()) {
            apiJson
                .subadmins.addSubadmin({
                    first_name_en: first_name,
                    last_name_en: last_name,
                    email: email,
                    password: password
                })
                .then(response => {
                    if (response.success) {
                        if (response.data) {
                            let body = {}

                            if (selected_countries.length) {
                                let temp_selected_countries = selected_countries.map(country => country.value);
                                body = {
                                    countries: temp_selected_countries
                                }
                            }

                            apiJson
                                .subadmins.assignSubdomainRoleToUser(response.data.data.id, body)
                                .then(response => {
                                    if (response.success) {
                                        message = "Subadmin created successfully.";
                                        showSuccess();
                                        history.push("/subadmins")
                                    }
                                    else {
                                        console.log("assign error", response.message);
                                    }
                                })
                        }
                    }
                    else {
                        message = "User already exists.";
                        showError();
                    }
                })
        }
    }

    return (
        <div className="create-subadmin">
                {!props.user ? (
        <div
          style={{ marginTop: "10px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ClipLoader color="#000" loading size={30} />
        </div>
      ) : (
        <>
            <Header
                heading="Create Subadmin"
                subtext="Create, edit, and manage the sub admins on your site."
            />
            <form className="form" onSubmit={onSaveBtnClicked}>
                <div className="row mx-0 my-2">
                    <div className="col-md-3">
                        <label>First Name</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            name="first_name"
                            type="text"
                            value={first_name}
                            className="form-control"
                            onChange={onInputFieldChanged}
                        />
                        <p className="m-0 text-danger">{first_name_err}</p>
                    </div>
                </div>
                <div className="row mx-0 my-3">
                    <div className="col-md-3">
                        <label>Last Name</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            name="last_name"
                            type="text"
                            value={last_name}
                            className="form-control"
                            onChange={onInputFieldChanged}
                        />
                        <p className="m-0 text-danger">{last_name_err}</p>
                    </div>
                </div>
                <div className="row mx-0 my-3">
                    <div className="col-md-3">
                        <label>Email</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            name="email"
                            type="email"
                            value={email}
                            className="form-control"
                            onChange={onInputFieldChanged}
                        />
                        <p className="m-0 text-danger">{email_err}</p>
                    </div>
                </div>
                <div className="row mx-0 my-3">
                    <div className="col-md-3">
                        <label>Password</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            name="password"
                            type="text"
                            value={password}
                            className="form-control"
                            onChange={onInputFieldChanged}
                        />
                        <p className="m-0 text-danger">{password_err}</p>
                    </div>
                </div>
                <div className="row mx-0 my-3">
                    <div className="col-md-3">
                        <label>Assign Countries</label>
                    </div>
                    <div className="col-md-9">
                        <ReactSelect
                            options={countries}
                            isMulti={true}
                            value={selected_countries}
                            onChange={onCountrySelect}
                        />
                        <p className="m-0 text-danger">{password_err}</p>
                    </div>
                </div>
                <div className="row m-auto">
                    <input
                        type="submit"
                        value="Save"
                        className="save-btn"
                    />
                </div>
            </form>
            </>)}
        </div>
    )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(CreateSubadmin);