import { type } from "../../types/types";

export default function reducer(
  state = { coupons: [], pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_COUPONS:
      return { ...state, ...action.coupons };
    case type.SET_COUPON_DETAIL:
      return { ...state, ...action.couponDetails };
    default:
      return state;
  }
}
