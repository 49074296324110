import { type } from "../../types/types";

export default function reducer(state = { countries: [] }, action) {
  switch (action.type) {
    case type.SET_COUNTRIES:
      return { ...state, ...action.countries };
    default:
      return state;
  }
}
