import React, { Fragment } from "react";
import { Row } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import "./productcard.scss";
import Logo from "../../../../assets/images/logo.png";
import { ReactComponent as WishList } from "../../../../assets/icons/wishlist.svg";
import { FaCheckCircle } from "react-icons/fa";
import ProductBadge from "./ProductBadge";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ffd700",
  },
  iconHover: {
    color: "#ffd700",
  },
  iconEmpty: {
    color: "#404553",
  },
})(Rating);

const ProductCard = (props) => {
  const rating = 3;

  return (
    <Fragment>
      <div className="product-card-container-desktop">
        <Row className="product-badge-row d-flex justify-content-between mx-auto">
          <div className="badge-container">
            <ProductBadge text={props.product.badge} />
          </div>
          <div className="like-button-container d-flex align-items-center justify-content-center">
            <WishList />
          </div>
        </Row>
        <Row className="product-image-row mx-auto">
          <img className="img mx-auto" src={props.product.img} alt="product" />
        </Row>
        <Row className="product-description-row mx-auto">
          <div className="product-promo-container mx-auto">
            <div className="product-promo">{props.product.promo}</div>
          </div>
          <div className="product-description-container">
            <div className="product-description">
              {props.product.description}
            </div>
          </div>
          <div className="product-price-container d-flex flex-wrap align-items-center">
            <div className="price-after-discount">
              <span className="currency fw-700">AED </span>
              <span className="value">
                {props.product.price_after_discount}
              </span>
            </div>
            <div className="price-before-discount text-uppercase">
              <span className="currency">AED </span>
              <span className="value">
                {props.product.price_before_discount}
              </span>
            </div>
          </div>
        </Row>
        <Row className="product-rating-row mx-auto">
          <div className="product-rating-container d-flex align-items-center">
            <StyledRating
              name="customized-empty"
              defaultValue={2}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              size="small"
              readOnly
              value={rating}
            />
            <div className="product-rating-count ml-2">{`(${props.product.count})`}</div>
          </div>
        </Row>
        <Row className="seller-info-row mx-auto">
          <div className="seller-info-container d-flex align-items-center">
            <div className="seller-logo">
              <img src={Logo} alt="logo" width="85px" height="30px" />
            </div>
            <div className="seller-verified d-flex justify-content-center align-items-center">
              <FaCheckCircle />
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};

export default ProductCard;
