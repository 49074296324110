import { type } from "../types/types";

export const set_products = (products) => (dispatch) => {
  dispatch({
    type: type.SET_PRODUCTS,
    products: products,
  });
};

export const set_variants = (variants) => (dispatch) => {
  dispatch({
    type: type.SET_VARIANTS_ARR,
    variants,
  });
};

export const set_product_details = (productDetails) => (dispatch) => {
  dispatch({
    type: type.SET_PRODUCT_DETAIL,
    productDetails: productDetails,
  });
};

export const set_deleted_images = (urls) => (dispatch) => {
  dispatch({
    type: type.SET_URLS,
    urls,
  });
};

export const remove_product = (productId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_PRODUCT,
    productId: productId,
  });
};
