import ProductMain from "Extra/ProductMain";
import React, { useEffect } from "react";

export default function ProductPage() {
  useEffect(() => {
    document.title = "Product";
  }, []);

  return (
    <div>
      <ProductMain />
    </div>
  );
}
