import React, { Fragment, useState, useEffect } from "react";

import "./sections.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Link } from "react-router-dom";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { type } from "components/Dropzone/type";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";

const EditSectionImage = (props) => {
  const [link, setLink] = useState("");
  const [uploadingImages, setUploadingImages] = useState(false);
  const [image, setImage] = useState("");
  const [deletedImagesUrl, setDeletedImagesUrl] = useState([]);

  let message = "";

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Edit Section Image";

    apiJson.home_offers_sections.getImage(props.match.params.id)
    .then(response => {
      if (response.success) {
        setLink(response.data.link);
        setImage(response.data.image);
      }
      else {
        message = "Error!";
      }
    })
  }, []);

  const handleFilesChange = (files) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        setImage(response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleValidation = () => {
    var isValid = true;

    if (!link) {
      isValid = false;
      alert("Link is required.");
    }

    if (!image) {
      isValid = false;
      alert("Image is required.");
    }

    return isValid;
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      apiJson.home_offers_sections.editSectionImage({
        link: link,
        image: image
      }, props.match.params.id, 1)
        .then(response => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
          }
          else {
            message = response.data.message;
            showError();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Sections"
            subtext="Create, edit, and manage Sections on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-lg-12">
                  <label>Link:</label>
                  <input type="text" value={link} onChange={(e) => setLink(e.target.value)} className="form-control" />
                </div>
                <div className="col-lg-12">
                  <label>Image:</label>
                  {uploadingImages ? (
                    <div className="d-flex justify-content-center align-item-center w-100">
                      <ClipLoader color="#000" loading size={20} />
                    </div>
                  ) : image ? (
                    <ImagesGrid
                      remove={(val, url) => {
                        setImage("");
                        setDeletedImagesUrl([
                          ...deletedImagesUrl,
                          url,
                        ]);
                      }}
                      images={[image]}
                    />
                  ) : (
                    <div
                      className="english-error"
                      style={{
                        width: "fit-content",
                        color: "red",
                        padding: "0",
                      }}
                    >

                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-cenetr w-100">
                    <DropzoneElement
                      multiple={false}
                      placeholder="Click to select a file"
                      updateSelectedServiceFiles={(value) =>
                        handleFilesChange(value)
                      }
                      type={type.IMAGE}
                    />
                  </div>
                </div>
                <br />
                <div className="col-lg-12">
                  <input type="submit" value="SAVE" className="btn btn-primary" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditSectionImage;
