import React, { Fragment } from "react";
import { InputBase } from "@material-ui/core";
import { BiSearch } from "react-icons/bi";

import "./header.scss";

const Header = (props) => {
  return (
    <Fragment>
      <div className="admin-header">
        <div className="header d-flex justify-content-between align-items-end">
          <div className="heading-col">
            <div className="heading">{props.heading}</div>
            <div className="sub-text">{props.subtext}</div>
          </div>
          <div className="search-col">
            <div className="search-bars-div">
            {props.storeSearch && (
                <div className="search" style={{ marginRight: "5px" }}>
                  <div className="search-container">
                    <InputBase
                      placeholder="Search by name…"
                      onChange={(e) => props.handleStoreSearch(e.target.value)}
                      value={props.searchText}
                      classes={{
                        root: "input-root",
                        input: "input-input",
                      }}
                      inputProps={{ "aria-label": "search" }}
                    />
                    <div className="search-icon d-flex justify-content-center align-items-center">
                      <BiSearch />
                    </div>
                  </div>
                </div>
              )}
              {props.search && (
                <div className="search" style={{ marginRight: "5px" }}>
                  <div className="search-container">
                    <InputBase
                      placeholder="Search by name…"
                      onChange={(e) => props.handleSearch(e.target.value)}
                      value={props.searchText}
                      classes={{
                        root: "input-root",
                        input: "input-input",
                      }}
                      inputProps={{ "aria-label": "search" }}
                    />
                    <div className="search-icon d-flex justify-content-center align-items-center">
                      <BiSearch />
                    </div>
                  </div>
                </div>
              )}
              {props.sku && (
                <div className="search" style={{ marginLeft: "5px" }}>
                  <div className="search-container">
                    <InputBase
                      placeholder="Search by SKU..."
                      onChange={(e) => props.handleSKUSearch(e.target.value)}
                      value={props.searchSKUText}
                      classes={{
                        root: "input-root",
                        input: "input-input",
                      }}
                      inputProps={{ "aria-label": "searchSKU" }}
                    />
                    <div className="search-icon d-flex justify-content-center align-items-center">
                      <BiSearch />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
