import React, { Fragment, useState, useEffect, useCallback } from "react";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";
import { ProductTabData } from "../../../Extra/TabsData";
import "./productcatalog.scss";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_products } from "redux/actions/ProductsAction";

import { tabParam } from "utils/utils";
import { debounce } from "lodash";

const ViewCustomer = (props) => {
  let message = "";
  const [data, setData] = useState(false);

  const [customer, setCustomer] = useState([]);
  const [customer_cart_items, setCustomerCartItems] = useState([]);
  const [customer_wishlist_items, setCustomerWishlistItems] = useState([]);
  const [customer_recent_orders, setCustomerRecentOrders] = useState([]);

  useEffect(() => {
    document.title = "Customer";

    apiJson.customers.getCustomer(props.match.params.id)
      .then(response => {
        if (response.success) {
          setCustomer(response.data);
        }
        else {
          console.log("error");
        }
      });

    apiJson.customers.getCustomerCart(props.match.params.id, 1)
      .then(response => {
        if (response.success) {
          setCustomerCartItems(response.data.items);
        }
        else {
          console.log("error");
        }
      });

    apiJson.customers.getCustomerWishListItems(props.match.params.id, 1)
      .then(response => {
        if (response.success) {
          setCustomerWishlistItems(response.data.items);
        }
        else {
          console.log("error");
        }
      });

    apiJson.customers.getCustomerRecentOrders(props.match.params.id)
      .then(response => {
        if (response.success) {
          setCustomerRecentOrders(response.data.orders);
        }
        else {
          console.log("error");
        }
      });
  }, []);

  const sendAbandonedCartMail = () => {
    apiJson.customers.sendAbandonedCartMail(props.match.params.id, 1)
    .then(response => {
      if (response.success) {
        message = "Email sent successfully.";
        showSuccess();
      }
      else {
        message = response.message;
        showError();
      }
    })
  }

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Fragment>
      <div className="right-content details-page">
        <div className="header-row">
          <Header
            subtext=""
            heading="Customer Details"
            search={false}
            sku={false}
            filters={null}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            <div className="product-tab">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <p><label><b>Name:</b>&nbsp;{customer.first_name_en}&nbsp;</label>{customer.last_name_en}</p>
                  <p><label><b>Email:</b>&nbsp;</label>{customer.email}</p>
                  <p><label><b>Phone:</b>&nbsp;</label>{customer.default_address ? customer.default_address.contact_number : customer.contact_number}</p>
                  <p><label><b>Address:</b>&nbsp;</label>{customer.default_address ? customer.default_address.address1 : ""}</p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <h5 className="text-center">Cart</h5>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6 className="text-center">Product Name</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6 className="text-center">Added Date</h6>
                      </div>
                    </div>
                    {
                      customer_cart_items.map(item => {
                        return (
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <a
                                href={
                                  process.env.REACT_APP_ALISUQ_UAE_FRONTEND_URL + item.orderable.category_title_en?.toLowerCase().replace(/ /g, "-")
                                  + "/" +
                                  item.orderable.product_slug
                                }
                                target="_blank"
                                className="text">
                                <p>{item.orderable.product_name_en} - {item.orderable.name_en}</p>
                              </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <p className="date text-center">{new Date(item.created_at).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      customer_cart_items.length > 0 ?
                      <button
                        className="text-center view-all-btn"
                        onClick={sendAbandonedCartMail}
                      >
                        Send Mail
                      </button>
                      :
                      <></>
                    }
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <h5 className="text-center">Wishlist</h5>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6 className="text-center">Product Name</h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6 className="text-center">Added Date</h6>
                      </div>
                    </div>
                    {
                      customer_wishlist_items.map(item => {
                        return (
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <a
                                href={
                                  process.env.REACT_APP_ALISUQ_UAE_FRONTEND_URL + item.line_item.category_title_en?.toLowerCase().replace(/ /g, "-")
                                  + "/" +
                                  item.line_item.product_slug
                                }
                                target="_blank"
                                className="text">
                                <p>{item.line_item.product_name_en} - {item.line_item.name_en}</p>
                              </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <p className="date text-center">{new Date(item.created_at).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="card">
                    <h5 className="text-center">Orders</h5>
                    <h5 className="text-center">Recent Orders</h5>
                    {
                      customer_recent_orders.map(order => {
                        return (
                          <div className="row">
                            <div className="col-lg-12">
                              <Link to={"/order-details/" + order.id} className="order-id">
                                <p className="text-center">{order.id}</p>
                              </Link>
                            </div>
                          </div>
                        )
                      })
                    }
                    <Link
                      to={"/customer-orders/" + customer.id}
                      className="text-center view-all-btn"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.product.pagination,
    products: state.product.products,
  };
};

const mapDispatchToProps = { set_products };

export default connect(mapStateToProsps, mapDispatchToProps)(ViewCustomer);
