export const ProductTabData = {
  id: "producttab",
  tablist: [{ id: "active" }, { id: "inactive" }],
};

export const DisputeTabData = {
  id: "disputetab",
  tablist: [{ id: "open" }, { id: "closed" }],
};

export const ReturnTabData = {
  id: "returntab",
  tablist: [
    { id: "requested" },
    { id: "rejected" },
    { id: "approved" },
    { id: "completed" },
  ],
};

export const ServiceTabData = {
  id: "servicetab",
  tablist: [{ id: "active" }, { id: "inactive" }],
};

export const OrdersTabData = {
  id: "orderstab",
  tablist: [
    { id: "all" },
    { id: "pending" },
    { id: "cancelled" },
    { id: "rejected" },
    { id: "processing" },
    { id: "shipped" },
    { id: "delivered" },
    { id: "partially_returned" },
    { id: "fully_returned" },
  ],
};

export const CouponTabData = {
  id: "coupontab",
  tablist: [
    { id: "all" },
    { id: "active" },
    { id: "expired" },
    { id: "pending" },
  ],
};

export const CategoriesTabData = {
  id: "categoriestab",
  tablist: [{ id: "all", count: 8 }],
};

export const BrandsTabData = {
  id: "brandstab",
  tablist: [{ id: "all", count: 8 }],
};

export const StoresTabData = {
  id: "storestab",
  tablist: [{ id: "all", count: 8 }],
};

export const ShippingZonesTabData = {
  id: "shippingzonestab",
  tablist: [{ id: "all", count: 8 }],
};

export const BannersTabData = {
  id: "bannerstab",
  tablist: [{ id: "all", count: 8 }],
};
