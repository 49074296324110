import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import TableList from "./TableList";
import Header from "components/header/Header";
import CouponModal from "components/modals/CouponModal";
import "./coupons.scss";
import { set_coupons } from "../../../redux/actions/CouponsAction";
import { apiJson } from "../../../api/Api";
import CouponHeaderTab from "./CouponHeaderTab";
import ClipLoader from "react-spinners/ClipLoader";

const Coupons = (props) => {
  let message = "";
  const [headerList, setHeaderList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [tabTitle, settabTitle] = useState("all");
  const [data, setData] = useState(false);
  const cancelClick = () => {
    setshowModal(false);
  };

  const tabParam = (tab) => {
    switch (tab) {
      case "all":
        return "";
      case "active":
        return "&active=true";
      case "expired":
        return "&expired=true";
      case "pending":
        return "&pending=true";
      default:
        return "";
    }
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getCoupons = (page, params) => {
    setData(true);
    apiJson.coupons.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "coupontab",
            tablist: [
              { id: "all", count: response.data.all },
              { id: "active", count: response.data.active },
              { id: "expired", count: response.data.expired },
              { id: "pending", count: response.data.pending },
            ],
          })
        : setHeaderList({
            id: "coupontab",
            tablist: [
              { id: "all", count: 0 },
              { id: "active", count: 0 },
              { id: "expired", count: 0 },
              { id: "pending", count: 0 },
            ],
          });
    });
    apiJson.coupons.getCoupons(page, params).then((response) => {
      if (response.success) {
        props.set_coupons({
          coupons: response.data.coupons,
          pagination: response.data.pagination,
        });
        setData(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Coupons";
    setData(true);
    getCoupons(1, "");
    // eslint-disable-next-line
  }, []);

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getCoupons(1, tabParam(title));
    }
  };
  const pageChange = (page) => {
    getCoupons(page, tabParam(tabTitle));
  };

  const createClick = () => {
    setshowModal(false);
    settabTitle("all");
    getCoupons(1, "");
  };
  return (
    <Fragment>
      <div className="right-content coupons-page-desktop">
        <div className="header-row">
          <Header
            heading="View Coupons"
            subtext="Create, edit, and manage Coupons on your site."
          />
        </div>
        <div className="main-row content-container-row">
          <div className="content mx-auto">
            <div className="create-button-row">
              <button
                type="button"
                className="create-coupon-button"
                onClick={() => setshowModal(true)}
              >
                Create New Coupon
              </button>
              {showModal && (
                <div className="modal-container">
                  <CouponModal
                    heading="Create Coupon"
                    cancelClick={cancelClick}
                    createClick={createClick}
                  />
                </div>
              )}
            </div>
            {data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              props.coupons && (
                <div className="coupon-tab">
                  <CouponHeaderTab
                    onTabChoose={ChangeTabList}
                    tabTitle={tabTitle}
                    tab={headerList}
                  >
                    <TableList
                      List={props.coupons}
                      onPageChange={pageChange}
                      pagination={props.pagination}
                    />
                  </CouponHeaderTab>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.coupon.pagination,
    coupons: state.coupon.coupons,
  };
};

const mapDispatchToProps = { set_coupons };

export default connect(mapStateToProsps, mapDispatchToProps)(Coupons);
