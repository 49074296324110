import React, { Fragment, useState, useEffect } from "react";

import "./sections.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Redirect } from "react-router-dom";

const Sections = (props) => {
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tabParam = (tab) => {
    switch (tab) {
      case "all":
        return "";
      case "uae":
        return "&with_country=UAE";
      case "ksa":
        return "&with_country=KSA";
      case "egypt":
        return "&with_country=Egypt";
      default:
        return "";
    }
  };

  const updateTablist = () =>
    apiJson.sections.getTabsCount().then((response) => {
      response.success &&
        setHeaderList({
          id: "storestab",
          tablist: [
            { id: "all", count: response.data.all },
            { id: "ksa", count: response.data.KSA },
            { id: "uae", count: response.data.UAE },
            { id: "egypt", count: response.data.Egypt },
          ],
        });
    });

  const getSections = (page, params) => {
    setData(false);
    apiJson.sections.getPaginatedSections(page, params).then((response) => {
      if (response.success) {
        props.set_sections({
          sections: response.data.sections,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Sections";
    getSections(1, "");
    apiJson.sections.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "storestab",
            tablist: [
              { id: "all", count: response.data.all },
              { id: "ksa", count: response.data.KSA },
              { id: "uae", count: response.data.UAE },
              { id: "egypt", count: response.data.Egypt },
            ],
          })
        : setHeaderList({
            id: "storestab",
            tablist: [
              { id: "all", count: 0 },
              { id: "ksa", count: 0 },
              { id: "uae", count: 0 },
              { id: "egypt", count: 0 },
            ],
          });
    });
    // eslint-disable-next-line
  }, []);

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      getSections(1, tabParam(tab));
    }
  };

  const pageChange = (page) => {
    getSections(page, tabParam(activeTab));
  };

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Sections"
            subtext="Create, edit, and manage Sections on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          {!data ? (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          ) : (
            <div className="content mx-auto">
              <div className="button-div">
                <button
                  type="button"
                  className="create-button"
                  onClick={() => setRedirect(true)}
                >
                  Create New Section
                </button>
              </div>
              <div className="sections-tab">
                <HeaderTab
                  onTabChoose={changeTab}
                  tabTitle={activeTab}
                  tab={headerList}
                >
                  <TableList
                    List={props.sections}
                    onPageChange={pageChange}
                    pagination={props.pagination}
                    updateTablist={updateTablist}
                  />
                </HeaderTab>
              </div>
            </div>
          )}
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/section", state: { name: "create" } }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.sections.pagination,
    sections: state.sections.sections,
  };
};

const mapDispatchToProps = { set_sections };

export default connect(mapStateToProsps, mapDispatchToProps)(Sections);
