import { type } from "../../types/types";

export default function reducer(
  state = { services: [], serviceDetails: {}, pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_SERVICES:
      return { ...state, ...action.services };
    case type.SET_SERVICE_DETAIL:
      return { ...state, ...action.serviceDetails };
    case type.REMOVE_SERVICE:
      return {
        ...state,
        services: state.services.filter(
          (service) => service.id !== action.serviceId
        ),
      };
    default:
      return { ...state };
  }
}
