import { type } from "../../types/types";

export default function reducer(state = { vendor: {} }, action) {
  switch (action.type) {
    case type.SET_VENDOR:
      return { ...state, ...action.vendor };
    default:
      return state;
  }
}
