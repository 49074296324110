import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";

import "./variant.scss";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { useEffect } from "react";

const variantSchema = Yup.object().shape({
  name_ar: Yup.string().required("Required"),
  description_ar: Yup.string().required("Required"),
  name_en: Yup.string().required("Required"),
  description_en: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  quantity: Yup.string().required("Required"),
});

const Variant = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const pageState = location?.state?.name || "create";
  const [isEditClick, setisEditClick] = useState(pageState !== "view");
  const createMode = pageState === "create";
  const [uploadedImages, setUploadedImages] = useState([]);

  const [openImagesGrid, setOpenImagesGrid] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);

  const formData = createMode ? {} : props.variantDetails;
  const values = {
    name_ar: formData?.name_ar || "",
    description_ar: formData?.description_ar || "",
    name_en: formData?.name_en || "",
    description_en: formData?.description_en || "",
    price: formData?.price || "",
    discounted_price: formData?.discounted_price || "",
    quantity: formData?.quantity || "",
    images: formData?.images || [],
    variation: formData?.variation || "",
    sku: formData?.sku || "",
    upc_code: formData?.upc_code || "",
    weight: formData?.weight || "",
  };

  const heading = isEditClick ? "Create/Edit a variant" : "View Variant";

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleFilesChange = (files) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        uploadedImages && Array.isArray(uploadedImages)
          ? setUploadedImages([
              ...uploadedImages,
              ...response.map((image) => image.location),
            ])
          : setUploadedImages([...response.map((image) => image.location)]);
        setOpenImagesGrid(true);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  const updateVariant = (values) => {
    if (uploadedImages.length > 0) {
      setLoading(true);
      apiJson.variants
        .updateVariant(props.productDetails.id, props.variantDetails.id, {
          variant: {
            name_en: values.name_en,
            description_en: values.description_en,
            name_ar: values.name_ar,
            description_ar: values.description_ar,
            price: values.price,
            discounted_price:
              values.discounted_price === ""
                ? values.price
                : values.discounted_price,
            quantity: values.quantity,
            images: uploadedImages,
            variation: values?.variation || "",
            sku: values?.sku || "",
            upc_code: values?.upc_code || "",
            weight: values?.weight || "",
          },
        })
        .then((response) => {
          if (response.success) {
            setLoading(false);
            setRedirect(true);
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Please upload atleast one image";
      showError();
    }
  };

  const createVariant = (values) => {
    if (values.discounted_price === "") {
      values.discounted_price = values.price;
    }

    if (uploadedImages.length > 0) {
      setLoading(true);
      apiJson.variants
        .createVariant(props.productDetails.id, {
          variant: {
            name_en: values.name_en,
            description_en: values.description_en,
            name_ar: values.name_ar,
            description_ar: values.description_ar,
            price: values.price,
            discounted_price: values.discounted_price,
            quantity: values.quantity,
            images: uploadedImages,

            variation: values?.variation || "",
            sku: values?.sku || "",
            upc_code: values?.upc_code || "",
            weight: values?.weight || "",
          },
        })
        .then((response) => {
          if (response.success) {
            setLoading(false);
            setRedirect(true);
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Please upload atleast one image";
      showError();
    }
  };

  useEffect(() => {
    formData?.images && setUploadedImages(formData.images);
    setOpenImagesGrid(true);
  }, [formData?.images]);

  return (
    <Fragment>
      <div className="right-content variant-page-desktop">
        <div className="header-row">
          <Header heading={heading} subtext="" />
        </div>
        <div className="main-row variant-page-content-container">
          <div className="form-container">
            <Formik
              initialValues={values}
              validationSchema={variantSchema}
              onSubmit={(values) => {
                if (pageState === "create") {
                  createVariant(values);
                } else {
                  updateVariant(values);
                }
              }}
            >
              {({ errors, touched, isValid, values, handleChange }) => (
                <Form className="variant-form mx-auto">
                  <fieldset disabled={!isEditClick}>
                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Name</div>
                          <div className="col-8 input">
                            <Field
                              className="w-100"
                              name="name_en"
                              type="text"
                              placeholder="Enter name"
                              onChange={handleChange}
                            />
                            {touched.name_en && errors.name_en && (
                              <div className="english-error">
                                {errors.name_en}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="arabic-col col-6">
                        <div className="row mx-0">
                          <div className="col-8 input">
                            <Field
                              className="w-100"
                              name="name_ar"
                              type="text"
                              onChange={handleChange}
                              placeholder="أدخل الاسم"
                            />
                            {touched.name_ar && errors.name_ar && (
                              <div className="arabic-error">
                                {errors.name_ar}
                              </div>
                            )}
                          </div>
                          <div className="label col-4">اسم</div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0 align-items-start">
                          <div className="label col-4">Description</div>
                          <div className="col-8 input">
                            <Field
                              className="w-100"
                              name="description_en"
                              type="text"
                              onChange={handleChange}
                              placeholder="Name Description"
                              as="textarea"
                              rows={5}
                            />
                            {touched.description_en &&
                              errors.description_en && (
                                <div className="english-error">
                                  {errors.description_en}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="arabic-col col-6">
                        <div className="row mx-0 align-items-start">
                          <div className="col-8 input">
                            <Field
                              className="w-100 "
                              name="description_ar"
                              type="text"
                              onChange={handleChange}
                              placeholder="أدخل الوصف"
                              as="textarea"
                              rows={5}
                            />
                            {touched.description_ar &&
                              errors.description_ar && (
                                <div className="arabic-error">
                                  {errors.description_ar}
                                </div>
                              )}
                          </div>
                          <div className="label col-4">وصف</div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Price</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="price"
                              type="number"
                              onChange={handleChange}
                              placeholder="0.00"
                            />
                            {touched.price && errors.price && (
                              <div className="english-error">
                                {errors.price}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="english-col discount-col col-6">
                        <div className="row mx-0">
                          <div className="label discount-label col-md-5 col-lg-4">
                            Discount Price
                          </div>
                          <div className="col-md-3 col-lg-4 input">
                            <Field
                              className="w-100"
                              name="discounted_price"
                              type="number"
                              onChange={handleChange}
                              placeholder="0.00"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Quantity</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="quantity"
                              type="number"
                              onChange={handleChange}
                              placeholder="0.00"
                            />
                            {touched.quantity && errors.quantity && (
                              <div className="english-error">
                                {errors.quantity}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Variation</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="variation"
                              type="text"
                              onChange={handleChange}
                              placeholder="variation"
                            />
                            {touched.quantity && errors.quantity && (
                              <div className="english-error">
                                {errors.quantity}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Sku</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="sku"
                              type="text"
                              onChange={handleChange}
                              placeholder="sku"
                            />
                            {touched.quantity && errors.quantity && (
                              <div className="english-error">
                                {errors.quantity}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">UPC Code</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="upc_code"
                              type="text"
                              onChange={handleChange}
                              placeholder="UPC Code"
                            />
                            {touched.upc_code && errors.upc_code && (
                              <div className="english-error">
                                {errors.upc_code}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-4">Weight</div>
                          <div className="col-4 input">
                            <Field
                              className="w-100"
                              name="weight"
                              type="number"
                              onChange={handleChange}
                              placeholder="0.00"
                            />
                            {touched.quantity && errors.quantity && (
                              <div className="english-error">
                                {errors.quantity}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid-row mx-0 d-flex align-items-start">
                      <div className="image-label col-2">Variant Images</div>
                      {openImagesGrid && (
                        <ImagesGrid
                          name={isEditClick ? "" : "view"}
                          remove={(val) => setUploadedImages(val)}
                          images={uploadedImages}
                        />
                      )}
                    </div>
                    {uploadingImages && (
                      <div className="d-flex justify-content-center align-item-center w-100">
                        <ClipLoader color="#000" loading size={20} />
                      </div>
                    )}
                  </fieldset>
                  <div className="row button-row mx-0">
                    <div className="button-col col-8 mx-auto d-flex flex-column">
                      {isEditClick && (
                        <DropzoneElement
                          updateSelectedServiceFiles={handleFilesChange}
                        />
                      )}
                      <div className="button-group">
                        {isEditClick ? (
                          <Fragment>
                            <button className="gray-button" type="button">
                              Cancel
                            </button>

                            <button
                              className="blue-button"
                              type="submit"
                              key={533313123}
                            >
                              {loading ? (
                                <ClipLoader color="#000" loading size={35} />
                              ) : (
                                "Save Variant"
                              )}
                            </button>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <button
                              className="blue-button"
                              type="button"
                              key={53331}
                              onClick={() => setisEditClick(true)}
                            >
                              Edit
                            </button>
                            <button className="gray-button" type="button">
                              Delete
                            </button>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {redirect ? <Redirect push to="/product-catalog" /> : ""}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    variantDetails: state?.variant?.variantDetails,
    productDetails: state?.product?.productDetails,
  };
};

export default connect(mapStateToProps)(Variant);
