import React, { Fragment, useEffect, useState } from "react";

import "./disputesdetails.scss";
import Message from "./components/Message";
import Header from "../../../components/header/Header";
import { set_disputes } from "redux/actions/DisputesAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import { useParams } from "react-router-dom";

const DisputesDetails = (props) => {
  const [sendingMessage, setSendingMessage] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState(false);
  const param = useParams();
  let message = "";
  const [disputeDetails, setDisputeDetails] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setsetlectedOption] = useState("");
  const filterOption = [
    { value: "open", label: "Open" },
    { value: "closed", label: "Closed" },
  ];

  useEffect(() => {
    document.title = "Dispute";
  }, []);

  const handleChange = (value) => {
    setInputValue(value);
  };
  const emptyInput = () => {
    setInputValue("");
  };
  const hanldeSubmit = (inputValue) => {
    Create(parseInt(param.id), inputValue);
  };

  const handleSelect = (e) => {
    setsetlectedOption(e);
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleUpdate = () => {
    setUpdating(true);
    if (selectedOption.value.toLowerCase() !== "open") {
      apiJson.disputes
        .UpdateDispute(parseInt(param.id), {
          role: "admin",
          dispute: {
            resolved: true,
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_disputes({ disputeDetail: response.data });
            message = "Status Changed";
            showSuccess();
            setUpdating(false);
          } else if (!response.status) {
            setUpdating(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setUpdating(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Change Status To Update";
      showError();
      setUpdating(false);
    }
  };

  const Create = (id, replyDesc) => {
    setSendingMessage(true);
    apiJson.disputes
      .createDisputeReply(id, {
        role: "admin",
        dispute_reply: {
          description: replyDesc,
        },
      })
      .then((response) => {
        if (response.success) {
          props.set_disputes({
            disputeDetail: {
              ...props.disputeDetail,
              replies: [...props.disputeDetail.replies, response.data],
            },
          });
          emptyInput();
          setSendingMessage(false);
        } else if (!response.status) {
          setSendingMessage(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setSendingMessage(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  useEffect(() => {
    setData(true);
    apiJson.disputes.getDisputeDetails(parseInt(param.id)).then((response) => {
      if (response.success) {
        props.set_disputes({ disputeDetail: response.data });
        setDisputeDetails(response.data);
        setsetlectedOption({
          value: response.data.status,
          label: response.data.status,
        });
        setData(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        setData(false);
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          setData(false);
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="right-content disputesdetails-page-desktop">
        <div className="header-row">
          <Header
            heading="Disputes Details"
            subtext="View, and manage the Disputes on your site."
          />
        </div>
        {data ? (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={35} />
          </div>
        ) : (
          <>
            {props.disputeDetail ? (
              <div className="main-row disputesdetails-page-content">
                <div className="content-wrapper">
                  <div className="detail-row mx-auto d-flex flex-md-column flex-lg-row">
                    <div className="dispute-detail d-flex flex-column">
                      <div className="detail d-flex">
                        <div className="label">Dispute #</div>
                        <div className="value">{disputeDetails.id}</div>
                      </div>
                      <div className="detail d-flex">
                        <div className="label">Store:</div>
                        <div className="value">
                          {disputeDetails.store_name_en}
                        </div>
                      </div>
                    </div>
                    <div className="customer-detail-cont">
                      <div className="customer-detail  d-flex flex-column">
                        <div className="detail d-flex">
                          <div className="label">Order #</div>
                          <div className="value">{disputeDetails.order_id}</div>
                        </div>
                        <div className="detail d-flex">
                          <div className="label">Customer email:</div>
                          <div className="value">
                            {disputeDetails.customer_email}
                          </div>
                        </div>
                        <div className="detail d-flex">
                          <div className="label">Status:</div>
                          <div className="value">
                            <div
                              className={
                                props.disputeDetail.status === "open"
                                  ? "green"
                                  : "border"
                              }
                            >
                              {props.disputeDetail.status}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end align-items-center">
                        {props.disputeDetail.status === "open" && (
                          <div className="update-status-col d-flex flex-lg-row flex-xl-row align-items-lg-end align-items-xl-center">
                            <div className="select" style={{ width: "200px" }}>
                              <Select
                                value={selectedOption}
                                onChange={handleSelect}
                                options={filterOption}
                                className="category-select"
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder="Select Status"
                              />
                            </div>
                            <button
                              className="update-button ms-lg-5"
                              type="button"
                              onClick={handleUpdate}
                              disabled={updating}
                            >
                              {updating ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <ClipLoader color="#fff" loading size={22} />
                                </div>
                              ) : (
                                "Update Status"
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="chat-row mx-auto">
                    <div className="header">Replies: </div>
                    {props.disputeDetail ? (
                      <div className="messages">
                        {props.disputeDetail?.replies?.map((message) => (
                          <Message
                            message={message}
                            dispute={disputeDetails}
                            key={message.id}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <ClipLoader color="#000" loading size={35} />
                      </div>
                    )}
                    {props.disputeDetail &&
                      props.disputeDetail.status &&
                      props.disputeDetail.status.toLowerCase() === "open" && (
                        <form
                          action="
                  "
                          onSubmit={(e) => {
                            e.preventDefault();
                            hanldeSubmit(inputValue);
                          }}
                        >
                          <div className="editor-row d-flex w-100">
                            <input
                              type="text"
                              name="message"
                              className="editor"
                              value={inputValue}
                              onChange={(e) => handleChange(e.target.value)}
                              placeholder="Type Message Here"
                            />
                            <button className="send-button" type="submit">
                              {sendingMessage ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <ClipLoader color="#fff" loading size={20} />
                                </div>
                              ) : (
                                "Send"
                              )}
                            </button>
                          </div>
                        </form>
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { disputeDetail: state.dispute.disputeDetail };
};

const mapDispatchToProps = { set_disputes };

export default connect(mapStateToProsps, mapDispatchToProps)(DisputesDetails);
