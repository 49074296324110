import React, { Fragment, useEffect } from "react";
import CreateBannerForm from "../BannerForm/BannerForm";
import Header from "../../../../components/header/Header";

const CreateBanner = () => {
  useEffect(() => {
    document.title = "Banner";
  }, []);
  return (
    <Fragment>
      <div className="right-content create-edit-product-desktop">
        <div className="header-row">
          <Header
            heading="Create Banner"
            subtext="Create, edit, and manage the Banners on your site."
          />
        </div>
        <div className="main-row bottom-container">
          <div className="form">
            <CreateBannerForm readOnly={false} state="create" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateBanner;
