import Header from "components/header/Header";
import { useEffect, useState } from "react";
import { apiJson } from "../../../api/Api";
import "./subadmins.scss";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

function SubAdminPermissions(props) {
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [editUser, setEditUser] = useState({});

  const { id } = useParams();
  const history = useHistory();

  const fetchData = () => {
    apiJson.subadmins.getPermissions().then((response) => {
      if (response.success) {
        setPermissions(response.data);
      }
    });
  };

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (!isEmpty(props.user) && !props.user.roles.includes("admin")) {
      message = "User has no role of admin or specific permissions";
      showError();
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  }, [props.user]);

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fetchSubadmin = () => {
    apiJson.subadmins.getSubadmin(id).then((response) => {
      if (response.success) {
        setUserPermissions(response.data.permissions);
        setEditUser(response.data);
      }
    });
  };

  useEffect(() => {
    fetchSubadmin();

    fetchData();
  }, []);

  const disabledAttribues = {
    products: {
      show: !userPermissions?.products?.index,
      update: !userPermissions?.products?.index,
      destroy: !userPermissions?.products?.index,
      create_products: !userPermissions?.products?.index,
      update_products: !userPermissions?.products?.index,
      tabs: !userPermissions?.products?.index,
      related_products: !userPermissions?.products?.create,
      related_tools: !userPermissions?.products?.create,
    },
    push_notification: {
      create: !userPermissions?.push_notification?.index,
      send: !userPermissions?.push_notification?.index,
    },
  };

  const handleCheckboxChange = (category, action, checked) => {
    switch (category) {
      case "products": {
        const products = { ...userPermissions?.products };
        delete disabledAttribues.products.related_products;
        delete disabledAttribues.products.related_tools;

        setUserPermissions((prevState) => ({
          ...prevState,
          [category]: {
            ...products,
            [action]: checked,
            ...(action === "index" && !checked && disabledAttribues.products),
            ...(action === "index" && checked && { tabs: true }),
            ...(action === "create" &&
              !checked && {
                related_products: disabledAttribues.products.related_products,
                related_tools: disabledAttribues.products.related_tools,
              }),
          },
        }));

        break;
      }

      case "push_notification": {
        const push_notification = { ...userPermissions?.push_notification };

        setUserPermissions((prevState) => ({
          ...prevState,
          [category]: {
            ...push_notification,
            [action]: checked,
            ...(action === "index" &&
              !checked &&
              disabledAttribues.push_notification),
          },
        }));

        break;
      }

      default: {
        setUserPermissions((prevState) => ({
          ...prevState,
          [category]: {
            ...prevState?.[category],
            [action]: checked,
          },
        }));
      }
    }
  };

  const onSavePermissions = () => {
    apiJson.subadmins.updateSubadmins(id, userPermissions).then((response) => {
      if (response.success) {
        message = "Sub admin permissions is updated successfully.";
        showSuccess();
        history.push("/subadmins");
      }
    });
  };

  const Checkboxes = (category) => {
    const actions = permissions[category];

    return Object.entries(actions).map(([action, label]) => (
      <label key={action} style={{ display: "flex", gap: "10px" }}>
        <input
          type="checkbox"
          disabled={disabledAttribues?.[category]?.[action]}
          checked={!!userPermissions?.[category]?.[action]}
          onChange={(e) =>
            handleCheckboxChange(category, action, e.target.checked)
          }
        />
        {label}
      </label>
    ));
  };

  return (
    <div className="subadmins">
      {!props.user || !editUser.id ? (
        <div
          style={{ marginTop: "10px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ClipLoader color="#000" loading size={30} />
        </div>
      ) : (
        <>
          <Header
            heading="Permissions"
            subtext={`Permissions for Sub Admin: ${editUser.first_name_en} ${editUser.last_name_en}`}
          />
          <div
            style={{ background: "#fff", padding: "20px", marginTop: "30px" }}
          >
            <div style={{ display: "grid", rowGap: "20px" }}>
              {Object.keys(permissions).map((category, index) => (
                <>
                  <div key={index}>
                    <h4>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </h4>

                    <div
                      key={category}
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        display: "flex",
                        gap: "10px",
                        columnGap: "30px",
                      }}
                    >
                      {Checkboxes(category)}
                    </div>
                  </div>
                  <hr style={{ margin: "5px 0px", color: "grey" }} />
                </>
              ))}
            </div>

            <Button
              style={{ marginTop: "20px", marginLeft: "auto", display: "flex" }}
              onClick={onSavePermissions}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SubAdminPermissions);
