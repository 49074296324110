import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";
import { BsThreeDots } from "react-icons/bs";
import { ProductTabData } from "../../../Extra/TabsData";
import "./details.scss";
import TableList from "./TableList";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_products } from "redux/actions/ProductsAction";
import Menu, { Item as MenuItem } from "rc-menu";
import { tabParam } from "utils/utils";
import { debounce } from "lodash";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ViewCustomerOrders = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [SKUSearchText, setSKUSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(ProductTabData.tablist[0].id);

  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [records_per_page, setRecordsPerPage] = useState(10);
  const setVars = (id) => {
    setCurrentOrderId(id);
    setRedirect(true);
  };
  const [redirect, setRedirect] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [orderID, setOrderID] = useState("");
  const [receipt, setReceipt] = useState(false);

  const menuItems = [
    <MenuItem
      key="1"
      onClick={() => setVars(orderID)}
      c
      style={{ cursor: "pointer" }}
    >
      View Details
    </MenuItem>,
    <MenuItem
      key="2"
      onClick={() => !receipt && generaReceipt(orderID)}
      style={{ cursor: "pointer" }}
    >
      Generate Receipt
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={() => !receipt && generatePackingSlip(orderID)}
      style={{ cursor: "pointer" }}
    >
      Generate Packing Slip
    </MenuItem>,
  ];

  const menu = <Menu className="menu">{menuItems}</Menu>;

  useEffect(() => {
    document.title = "Customer Orders";
    let page = 1;

    apiJson.customers.getCustomerOrders(props.match.params.id, page, records_per_page)
      .then(response => {
        if (response.success) {
          setOrders(response.data.orders);
          setPagination(response.data.pagination);
          setData(true);
        }
        else {
          console.log("error");
        }
      });
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab, sku) => handleSearch(text, currTab, sku), 1000),
      []
    );

  const handleSearch = (text, tab, sku) => {
    getCustomerOrders(
      props.match.params.id,
      tabParam(tab) +
      "&search_by_name=" +
      text +
      (sku?.length > 0 ? "&search_by_sku=" + sku : "")
    );
  };

  const handlePageChange = (pageNumber) => {
    getCustomerOrders(pageNumber.selected + 1);
  }

  const getCustomerOrders = (page) => {
    setData(false);
    apiJson.customers.getCustomerOrders(props.match.params.id, page, records_per_page).then((response) => {
      if (response.success) {
        setOrders(response.data.orders);
        setPagination(response.data.pagination);
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const generaReceipt = (id) => {
    setReceipt(true);
    apiJson.orders.generateReceipt(id).then((response) => {
      if (response.success) {
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const popup = window.open(fileURL);
        if (!popup || popup.outerHeight === 0) {
          alert(
            "Popup Blocker is enabled! Please add this site to your exception list."
          );
        } else {
          window.open("", "_self");
          window.close();
        }
        setReceipt(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };
  const generatePackingSlip = (id) => {
    setReceipt(true);
    apiJson.orders.generatePackingSlip(id).then((response) => {
      if (response.success) {
        const file = response.data;
        const fileURL = URL.createObjectURL(file);
        const popup = window.open(fileURL);
        if (!popup || popup.outerHeight === 0) {
          alert(
            "Popup Blocker is enabled! Please add this site to your exception list."
          );
        } else {
          window.open("", "_self");
          window.close();
        }
        setReceipt(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="right-content details-page">
        <div className="header-row">
          <Header
            subtext="Create, edit, and manage the customer orders on your site."
            heading="View Customer Orders"
            search={false}
            sku={false}
            filters={null}
          // handleSearch={(text) => {
          //   setSearchText(text);
          //   debouncedCallback(text, tabTitle, SKUSearchText);
          // }}
          // handleSKUSearch={(text) => {
          //   setSKUSearchText(text);
          //   debouncedSKUCallback(text, tabTitle, searchText);
          // }}
          // searchText={searchText}
          // searchSKUText={SKUSearchText}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              orders && (
                <div className="product-tab">
                  <table className="table table-bordered">
                    <tr>
                      <th>Order #</th>
                      <th>Customer Name</th>
                      <th>Contact</th>
                      <th>Status</th>
                      <th>Total Price</th>
                      <th>Discount</th>
                      <th>Created at</th>
                      <th>Actions</th>
                    </tr>
                    {
                      orders.map((order) => {
                        let id = order.id;
                        return (
                          <tr>
                            <td>{order.id}</td>
                            <td>{order.address.first_name}&nbsp;{order.address.last_name}</td>
                            <td>{order.address.contact_number}</td>
                            <td>{order.status}</td>
                            <td>AED {order.grand_total}</td>
                            <td>AED {order.discount}</td>
                            <td>{new Date(order.created_at).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td
                              className="header-text action-data"
                              onClick={(e) => e.stopPropagation()}>
                                <span>...</span>
                              <Dropdown
                                className="dropdown-for-order-actions"
                                overlay={menu}
                                overlayClassName="overlay-class"
                              >
                                <BsThreeDots
                                  onMouseEnter={() => !receipt && setOrderID(id)}
                                  onClick={() => !receipt && setOrderID(id)}
                                  className="dots"
                                />
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                  {orders.length > 0 && (
                    <div
                      className="d-flex justify-content-center mb-4 w-100"
                      style={{ direction: "ltr" }}
                    >
                      <Pagination
                        direction="ltr"
                        previousLabel={<FaChevronLeft color="#404553" />}
                        nextLabel={<FaChevronRight color="#404553" />}
                        pageCount={pagination?.total_pages}
                        forcePage={parseInt(pagination?.page) - 1}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={2}
                        containerClassName="pagination"
                        pageClassName="ps-3 pe-3 pt-2 pb-2"
                        activeClassName={
                          "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
                        }
                        nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                        previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                      />
                    </div>
                  )}
                  {redirect && (
                    <Redirect
                      push
                      to={{
                        pathname: `/order-details/${currentOrderId}`,
                        state: { OrderId: { currentOrderId } },
                      }}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.product.pagination,
    products: state.product.products,
  };
};

const mapDispatchToProps = { set_products };

export default connect(mapStateToProsps, mapDispatchToProps)(ViewCustomerOrders);
