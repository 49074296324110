import React, { Fragment } from "react";
import { MdSend } from "react-icons/md";

import "./emailcard.scss";

const EmailCard = (props) => {
  const Email = props.email;
  return (
    <Fragment>
      <div className="email-card">
        <div className="email-header-row d-flex justify-content-between align-items-center">
          <div className="heading-col d-flex flex-column">
            <div className="heading">{Email.title}</div>
            <div className="text">{`Send Emails to all customers for ${Email.title}`}</div>
          </div>
          <button className="email-send-button d-flex justify-content-center align-items-center">
            <div className="icon">
              <MdSend />
            </div>
            <div className="text">Send Emails</div>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailCard;
