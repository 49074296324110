import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

const TableList = (props) => {
  const [categoryID, setCategoryID] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const setVars = (id) => {
    setCategoryID(id);
    setRedirect(true);
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="w-10 header-text">Category Id</td>
            <td className="w-10 header-text">Category Image</td>
            <td className="w-10 header-text">Name (English)</td>
            <td className="w-10 header-text">Name (Arabic)</td>
            <td className="w-10 header-text">Parent Category</td>
            <td className="w-10 header-text">Featured</td>
            <td className="w-10 header-text">Position</td>
            <td className="w-10 header-text">Country</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((category) => {
              return (
                <tr
                  key={category.id}
                  onClick={() => setVars(category.id)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="w-10 header-text">{category.id}</td>
                  <td className="w-10 header-text">
                    <div
                      className="image-container mx-auto"
                      style={{
                        width: "80px",
                        height: "80px",
                        maxWidth: "80px",
                      }}
                    >
                      <img
                        src={category.image}
                        alt="category"
                        style={{
                          width: "auto",
                          maxWidth: "80px",
                          height: "80px",
                        }}
                      />
                    </div>
                  </td>
                  <td className="w-10 header-text">{category.title_en}</td>
                  <td className="w-10 header-text">{category.title_ar}</td>
                  <td className="w-10 header-text">
                    {category.parent_category_title_en
                      ? category.parent_category_title_en
                      : "-"}
                  </td>
                  <td className="w-10 header-text">
                    {category.featured ? "Yes" : "No"}
                  </td>
                  <td className="w-10 header-text">{category.position}</td>
                  <td className="w-10 header-text">
                    {category.country_name_en}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            forcePage={parseInt(props?.pagination?.page) - 1}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `/edit-category/${categoryID}`,
            state: { name: "view", categoryID: { categoryID } },
          }}
        />
      )}
    </div>
  );
};

export default TableList;
