import React, { Fragment, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { Redirect } from "react-router";
import { apiJson } from "../../../../api/Api";
import { remove_user } from "../../../../redux/actions/UsersAction";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import "./user.scss";

const User = (props) => {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleClick = (id) => {
    setRedirect(true);
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(id);
    }
  };
  const account = props.user;
  const menuItems = [
    <MenuItem
      key="1"
      onClick={() => handleClick(account.id)}
      style={{ cursor: "pointer" }}
    >
      Send Email
    </MenuItem>,
    <MenuItem
      key="1312"
      onClick={() => handleDelete(account.id)}
      style={{ cursor: "pointer" }}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items center">
          <ClipLoader color="#000" size={15} />
        </div>
      ) : (
        "Delete"
      )}
    </MenuItem>,
  ];

  const deleteUser = (id) => {
    setLoading(true);
    apiJson.profile.removeUser(id).then((response) => {
      if (response.success) {
        message = "User Deleted";
        showSuccess();
        props.remove_user(response.data.id);
        setLoading(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setLoading(false);
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const menu = <Menu className="menu">{menuItems}</Menu>;
  return (
    <Fragment>
      <div className="user-desktop">
        <div className="action-icon">
          <Dropdown
            className="dropdown-for-send"
            overlay={menu}
            overlayClassName="overlay-class"
          >
            <button style={{ background: "unset" }}>
              <BsThreeDots />
            </button>
          </Dropdown>
        </div>
        <div className="account-image-row mx-auto d-flex">
          <div className="wrapper d-flex align-items-center">
            <div className="image-container d-inline-flex justify-content-center align-items-center">
              {account.first_name_en && account.last_name_en ? (
                <div className="first-letter">
                  {`${account.first_name_en[0]} ${
                    account.last_name_en && account.last_name_en[0]
                  }`}
                </div>
              ) : (
                <img src={account.image} width="100%" height="100%" alt="" />
              )}
            </div>
            <div className="name-container">
              {`${account.first_name_en} ${account.last_name_en}`}
            </div>
          </div>
        </div>
        <div className="detail-row w-100 d-flex flex-column">
          <div className="detail d-flex justify-content-between align-items-center">
            <div className="label">First Name:</div>
            <div className="value">{account.first_name_en}</div>
          </div>
          <div className="detail d-flex justify-content-between align-items-center">
            <div className="label">Last Name:</div>
            <div className="value">{account.last_name_en}</div>
          </div>
          <div className="detail d-flex justify-content-between align-items-center">
            <div className="label">Email:</div>
            <div className="value" style={{ textTransform: "lowercase" }}>
              {account.email}
            </div>
          </div>
          {account.store_name_en && (
            <div className="detail d-flex justify-content-between align-items-center">
              <div className="label">Store Name:</div>
              <div className="value">{account.store_name_en}</div>
            </div>
          )}
        </div>
      </div>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: "/admin/send-email",
            state: { name: "/profile", email: account.email },
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { users: state.user.users };
};

const mapDispatchToProps = { remove_user };

export default connect(mapStateToProsps, mapDispatchToProps)(User);
