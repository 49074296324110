import { type } from "../../types/types";

export default function reducer(
  state = { sections: [], paginations: {} },
  action
) {
  switch (action.type) {
    case type.SET_SECTIONS:
      return { ...state, ...action.sections_list };
    default:
      return state;
  }
}
