import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import { apiJson } from "../../api/Api";
import { set_stores } from "../../redux/actions/StoresAction";
import "./couponmodal.scss";
import { getClass } from "utils/utils";
import { set_coupons } from "redux/actions/CouponsAction";
import { debounce } from "lodash";

const CouponModal = (props) => {
  let message = "";
  const editPage = props.editPage;
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  
  const couponTypes = [
    { value: "store_coupon", label: "Store" },
    { value: "platform_coupon", label: "Platform" },
    { value: "brand_coupon", label: "Brands" },
    { value: "product_coupon", label: "Products" },
  ];
  const show_on_product_values = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];

  const discountTypes = [
    { value: "percentage", label: "Percentage" },
    { value: "flat_amount", label: "Flat Amount" },
  ];
  const isEdit = props.heading === "Edit Coupon" ? true : false;

  const formData = props.editPage ? props.coupanDetails || {} : {};
  const [discountType, setDiscountType] = useState(
    formData?.discount_type
      ? { value: formData?.discount_type, label: formData?.discount_type }
      : discountTypes[0].value
  );
  const [initialValues, setInitialValues] = useState({
    discount_type: formData?.discount_type || discountTypes[0].id,
    coupon_type: formData?.coupon_type || "",
    code: formData?.code || "",
    discount: formData?.discount * 100 || "",
    max_discount: formData?.max_discount || "",
    flat_discount: formData?.flat_discount || "",
    min_order_amount: formData?.min_order_amount || "",
    max_usage_count: formData?.max_usage_count || "",
    store_id: formData?.store_id || "",
    product_coupons_attributes: formData?.products || [],
    brand_coupons_attributes: formData?.brands || [],
    store_name_en: formData?.store_name_en || "",
    start_date: formData
      ? moment(new Date(formData.start_date)).format("YYYY-MM-DDTHH:mm")
      : "",
    expiry: formData
      ? moment(new Date(formData.expiry)).format("YYYY-MM-DDTHH:mm")
      : "",
    show_on_product: formData?.show_on_product || "0"
  });

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let One_hour = 60 * 60 * 1000;
  let minusOneHour = new Date() - One_hour;

  const createCouponAttributeSchema = (fieldId, requiredMessage) => {
    return Yup.array().of(
      Yup.object().shape({
        [fieldId]: Yup.number().required(requiredMessage),
        name_en: Yup.string(),
      })
    );
  };

  const coupanSchema = Yup.object().shape({
    discount_type: Yup.string().required("Required"),
    coupon_type: Yup.string().required("Required"),
    product_coupons_attributes: Yup.array().when("coupon_type", {
      is: "product_coupon",
      then: createCouponAttributeSchema("product_id", "Product ID is required"),
      otherwise: Yup.array().notRequired(),
    }),

    brand_coupons_attributes: Yup.array().when("coupon_type", {
      is: "brand_coupon",
      then: createCouponAttributeSchema("brand_id", "Brand ID is required"),
      otherwise: Yup.array().notRequired(),
    }),
    code: Yup.string().required("Required"),
    discount: Yup.number("Should be number")
      .min(1, "Should be greater than 1")
      .max(100, "Should be less than 100")
      .required("Required"),
    max_discount: Yup.number("Should be number").positive(
      "Should be positive number"
    ),
    max_usage_count: Yup.number().min(1, "Must be greater than 1"),
    start_date: props.editPage
      ? Yup.date("Required")
        .nullable("Required")
        .typeError("Invalid Date")
        .required("Required")
      : Yup.date("Required")
        .nullable("Required")
        .typeError("Invalid Date")
        .required("Required")
        .test(
          "start_date",
          "Past date not allowed",
          (value) => value > new Date(minusOneHour)
        ),
    expiry: Yup.date("Required")
      .nullable("Required")
      .typeError("Invalid Date")

      .required("Required")
      .test("expiry", "Past date not allowed", (value) => value > new Date())
      .min(Yup.ref("start_date"), "End date can't be before start date"),
  });

  const amountCoupanSchema = Yup.object().shape({
    discount_type: Yup.string().required("Required"),
    coupon_type: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    start_date: props.editPage
      ? Yup.date("Required")
        .nullable("Required")
        .typeError("Invalid Date")
        .required("Required")
      : Yup.date("Required")
        .nullable("Required")
        .typeError("Invalid Date")
        .required("Required")
        .test(
          "start_date",
          "Past date not allowed",
          (value) => value > new Date(minusOneHour)
        ),
    expiry: Yup.date("Required")
      .nullable("Required")
      .typeError("Invalid Date")

      .required("Required")
      .test("expiry", "Past date not allowed", (value) => value > new Date())
      .min(Yup.ref("start_date"), "End date can't be before start date"),
    flat_discount: Yup.number("Must be number").required("Required"),
    max_usage_count: Yup.number().min(1, "Must be greater than 1"),
    min_order_amount: Yup.number("Must be number").positive("Must be positive"),
  });

  useEffect(() => {
    setInitialValues({
      discount_type: formData?.discount_type || discountTypes[0].value,
      coupon_type: formData?.coupon_type || "",
      code: formData?.code || "",
      discount: formData?.discount * 100 || "",
      max_discount: formData?.max_discount || "",
      flat_discount: formData?.flat_discount || "",
      min_order_amount: formData?.min_order_amount || "",
      max_usage_count: formData?.max_usage_count || "",
      store_id: formData?.store_id || "",
      store_name_en: formData?.store_name_en || "",
      product_coupons_attributes: formData?.products || [],
      brand_coupons_attributes: formData?.brands || [],
      start_date: formData
        ? moment(new Date(formData.start_date)).format("YYYY-MM-DDTHH:mm")
        : "",
      expiry: formData
        ? moment(new Date(formData.expiry)).format("YYYY-MM-DDTHH:mm")
        : "",
      show_on_product: formData?.show_on_product || "0"
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    apiJson.stores.getStores().then((response) => {
      if (response.success) {
        props.set_stores({ stores_list: response.data });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });

    onProductsSearch("");
    onBrandsSearch("");
  }, []);

  const debouncedProductsCallback = useCallback(
    debounce((text) => onProductsSearch(text), 1000),
    []
  );

  const debouncedBrandsCallback = useCallback(
    debounce((text) => onBrandsSearch(text), 1000),
    []
  );

  const onProductsSearch = (text) => {
    apiJson.header.search(1, text).then((response) => {
      if (response.success) {
        setProducts(response.data.products);
      }
    });
  };

  const onBrandsSearch = (text) => {
    apiJson.header.search(1, text).then((response) => {
      if (response.success) {
        setBrands(response.data.brands);
      }
    });
  };

  const createCoupon = (values) => {
    values.expiry = new Date(values.expiry);
    values.start_date = new Date(values.start_date);
    setLoading(true);
    apiJson.coupons
      .createCoupan(
        values?.discount_type?.toLowerCase() === "flat_amount"
          ? {
            coupon: {
              coupon_type: values.coupon_type,
              code: values.code,
              flat_discount: values.flat_discount?.toString(),
              min_order_amount:
                values.min_order_amount?.length === 0
                  ? null
                  : parseInt(values.min_order_amount),
              start_date: values.start_date,
              expiry: values.expiry,
              discount_type: values.discount_type,
              product_coupons_attributes:
              values.coupon_type === "product_coupon"
                ? values.product_coupons_attributes
                : null,
              brand_coupons_attributes:
                values.coupon_type === "brand_coupon"
                  ? values.brand_coupons_attributes
                  : null,
              store_id:
                values.coupon_type?.toLowerCase() === "store_coupon"
                  ? values.store_id
                  : null,
              max_usage_count:
                values.max_usage_count?.length === 0
                  ? null
                  : parseInt(values.max_usage_count),
              show_on_product: values.show_on_product
            },
          }
          : {
            coupon: {
              coupon_type: values.coupon_type,
              code: values.code,
              discount: (values.discount / 100).toString(),
              max_discount:
                values.max_discount?.length === 0
                  ? null
                  : parseInt(values.max_discount),
              start_date: values.start_date,
              expiry: values.expiry,
              discount_type: values.discount_type,
              product_coupons_attributes:
              values.coupon_type === "product_coupon"
                ? values.product_coupons_attributes
                : null,
              brand_coupons_attributes:
                values.coupon_type === "brand_coupon"
                  ? values.brand_coupons_attributes
                  : null,
              store_id:
                values.coupon_type?.toLowerCase() === "store_coupon"
                  ? values.store_id
                  : null,
              max_usage_count:
                values.max_usage_count?.length === 0
                  ? null
                  : parseInt(values.max_usage_count),
              show_on_product: values.show_on_product
            },
          }
      )
      .then((response) => {
        if (response.success) {
          message = "Coupon Created Successfully";
          setLoading(false);
          props.createClick();
          setRedirect(true);
          showSuccess();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const editCoupon = (values) => {
    setLoading(true);
    values.expiry = new Date(values.expiry);
    values.start_date = new Date(values.start_date);
    apiJson.coupons
      .updateCoupon(
        formData.id,
        discountType?.value?.toLowerCase() === "flat_amount"
          ? {
            coupon: {
              start_date: values.start_date,
              expiry: values.expiry,
              max_usage_count:
                values.max_usage_count?.length === 0
                  ? null
                  : parseInt(values.max_usage_count),
              min_order_amount:
                values.min_order_amount?.length === 0
                  ? null
                  : parseInt(values.min_order_amount),
              flat_discount: values.flat_discount?.toString(),
              show_on_product: values.show_on_product,
              store_id: values.store_id
            },
          }
          : {
            coupon: {
              start_date: values.start_date,
              expiry: values.expiry,
              max_usage_count:
                values.max_usage_count?.length === 0
                  ? null
                  : parseInt(values.max_usage_count),
              min_order_amount:
                values.min_order_amount?.length === 0
                  ? null
                  : parseInt(values.min_order_amount),
              flat_discount: values.flat_discount?.toString(),
              show_on_product: values.show_on_product,
              store_id: values.store_id
            },
          }
      )
      .then((response) => {
        if (response.success) {
          // let previous_coupons = [];

          // if (values.show_on_product == "1") {
          //   previous_coupons = props.coupons?.map(coupon => coupon.show_on_product = "0");
          //   previous_coupons = props.coupons?.map(coupon => coupon.id === response.data.id ? response.data : coupon);
          // }
          // else {

          // }

          // props.set_coupons({
          //   // coupons: props.coupons.map((coupon) =>
          //   //   coupon.id === response.data.id ? response.data : coupon
          //   // ),
          //   coupons: previous_coupons
          // });
          message = "Coupon Updated Successfully";
          // setLoading(false);
          props.createClick();
          // setRedirect(true);
          showSuccess();
          window.location.reload(false);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const renderFieldByCouponType = (
    values,
    setFieldValue,
    setFieldTouched,
    touched
  ) => {
    switch (values.coupon_type) {
      case "store_coupon":
        return (
          <div className="input-row d-flex align-items-start w-100">
            <div className="label">Store:</div>
            <div className="value">
              {isEdit ? (
                <span>{values.store_name_en}</span>
              ) : (
                <>
                  <Select
                    name="store_id"
                    value={
                      values.store_id
                        ? {
                            value: values.store_id,
                            label: values.store_name_en,
                          }
                        : ""
                    }
                    onChange={(value) => {
                      setFieldValue("store_id", value.value);
                      setFieldValue("store_name_en", value.label);
                    }}
                    onBlur={() => setFieldTouched("store_id", true)}
                    placeholder="Select Store"
                    className={`category-select w-100 ${getClass(
                      values.coupon_type?.toLowerCase() === "store_coupon" &&
                        touched.store_id &&
                        values.store_id === ""
                    )}`}
                    classNamePrefix="select"
                    options={props.stores?.map((store) => ({
                      value: store.id,
                      label: store.name_en,
                    }))}
                  />
                  {values.coupon_type?.toLowerCase() === "store_coupon" &&
                    touched.store_id &&
                    values.store_id === "" && (
                      <div style={{ color: "red", fontWeight: "normal" }}>
                        Required
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        );

      case "brand_coupon":
        return (
          <div className="input-row d-flex align-items-start w-100">
            <div className="label">Brands:</div>
            <div className="value">
              {isEdit ? (
                <span>
                  {values.brand_coupons_attributes
                    ?.map((brand) => brand.name_en)
                    .join(", ")}
                </span>
              ) : (
                <>
                  <Select
                    name="brand_coupons_attributes"
                    value={values.brand_coupons_attributes?.map((brand) => ({
                      value: brand.brand_id,
                      label: brand.name_en,
                    }))}
                    onInputChange={debouncedBrandsCallback}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "brand_coupons_attributes",
                        selectedOptions.map((option) => ({
                          brand_id: option.value,
                          name_en: option.label,
                        }))
                      );
                    }}
                    isMulti
                    onBlur={() =>
                      setFieldTouched("brand_coupons_attributes", true)
                    }
                    placeholder="Select Brands"
                    className={`category-select w-100 ${getClass(
                      touched.brand_coupons_attributes &&
                        !values.brand_coupons_attributes.length
                    )}`}
                    classNamePrefix="select"
                    options={brands.map((brand) => ({
                      value: brand.id,
                      label: brand.name_en,
                    }))}
                  />
                  {touched.brand_coupons_attributes &&
                    !values.brand_coupons_attributes.length && (
                      <div style={{ color: "red", fontWeight: "normal" }}>
                        Brand is required
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        );

      case "product_coupon":
        return (
          <div className="input-row d-flex align-items-start w-100">
            <div className="label">Products:</div>
            <div className="value">
              {isEdit ? (
                <span>
                  {values.product_coupons_attributes
                    ?.map((product) => product.name_en)
                    .join(", ")}
                </span>
              ) : (
                <>
                  <Select
                    name="product_coupons_attributes"
                    value={values.product_coupons_attributes?.map(
                      (product) => ({
                        value: product.product_id,
                        label: product.name_en,
                      })
                    )}
                    onInputChange={debouncedProductsCallback}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "product_coupons_attributes",
                        selectedOptions.map((option) => ({
                          product_id: option.value,
                          name_en: option.label,
                        }))
                      );
                    }}
                    isMulti
                    onBlur={() =>
                      setFieldTouched("product_coupons_attributes", true)
                    }
                    placeholder="Select Products"
                    className={`category-select w-100 ${getClass(
                      touched.product_coupons_attributes &&
                        !values.product_coupons_attributes.length
                    )}`}
                    classNamePrefix="select"
                    options={products.map((product) => ({
                      value: product.id,
                      label: product.name_en,
                    }))}
                  />
                  {touched.product_coupons_attributes &&
                    !values.product_coupons_attributes.length && (
                      <div style={{ color: "red", fontWeight: "normal" }}>
                        Product is required
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="coupon-modal">
        <div className="coupon-header w-100">{props.heading}</div>
        <Formik
          initialValues={initialValues}
          validationSchema={
            discountType?.value === "flat_amount"
              ? amountCoupanSchema
              : coupanSchema
          }
          enableReinitialize
          onSubmit={(values) => {
            if (editPage === true) {
              editCoupon({ ...values });
            } else {
              if (
                values.coupon_type?.toLowerCase() === "store_coupon" &&
                values.store_id === ""
              ) {
                message = "Select Store";
                showError();
              } else {
                createCoupon({ ...values });
              }
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className="boday-row w-100">
                <div className="input-group-row w-100 d-flex flex-column">
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Discount Type:</div>
                    <div className="value">
                      <Select
                        name="discount_type"
                        value={
                          values.discount_type
                            ? values.discount_type === "percentage"
                              ? discountTypes[0]
                              : discountTypes[1]
                            : ""
                        }
                        onBlur={() => setFieldTouched("discount_type", true)}
                        onChange={(val) => {
                          setFieldValue("discount_type", val.value);
                          setDiscountType(val);
                        }}
                        options={discountTypes}
                        className={`category-select w-100 ${getClass(
                          touched.discount_type && errors.discount_type
                        )}`}
                        classNamePrefix="select"
                        placeholder="Select Discount Type"
                        isDisabled={isEdit}
                      />
                      {touched.discount_type && errors.discount_type && (
                        <div
                          className=""
                          style={{ color: "red", fontWeight: "normal" }}
                        >
                          {errors.discount_type}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Coupon Type:</div>
                    <div className="value">
                      <Select
                        name="coupon_type"
                        value={couponTypes.find(
                          (item) => values.coupon_type === item.value
                        )}
                        onChange={(value) =>
                          setFieldValue("coupon_type", value.value)
                        }
                        options={couponTypes}
                        onBlur={() => setFieldTouched("coupon_type", true)}
                        placeholder="Select Coupon Type"
                        className={`category-select w-100 ${getClass(
                          touched.coupon_type && errors.coupon_type
                        )}`}
                        classNamePrefix="select"
                        isDisabled={isEdit}
                      />
                      {touched.coupon_type && errors.coupon_type && (
                        <div style={{ color: "red", fontWeight: "normal" }}>
                          {errors.coupon_type}
                        </div>
                      )}
                    </div>
                  </div>
                  {renderFieldByCouponType(
                    values,
                    setFieldValue,
                    setFieldTouched,
                    touched
                  )}

                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Start Date:</div>
                    <div className="value">
                      <Field
                        name="start_date"
                        type="datetime-local"
                        className={` ${getClass(
                          touched.start_date && errors.start_date
                        )}`}
                      />
                      {!isEdit && (
                        <ErrorMessage name="start_date">
                          {(msg) => (
                            <div style={{ color: "red", fontWeight: "normal" }}>
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Expire At:</div>
                    <div className="value">
                      <Field
                        name="expiry"
                        type="datetime-local"
                        className={` ${getClass(
                          touched.expiry && errors.expiry
                        )}`}
                      />
                      <ErrorMessage name="expiry">
                        {(msg) => (
                          <div style={{ color: "red", fontWeight: "normal" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Coupon Code:</div>
                    <div className="value">
                      <Field
                        type="text"
                        name="code"
                        disabled={isEdit}
                        className={` ${getClass(touched.code && errors.code)}`}
                      />
                      <ErrorMessage name="code">
                        {(msg) => (
                          <div style={{ color: "red", fontWeight: "normal" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  {values.discount_type === "percentage" && (
                    <>
                      <div className="input-row d-flex align-items-start w-100">
                        <div className="label">Discount %:</div>
                        <div className="value">
                          {isEdit ? (
                            <span>{values.discount}</span>
                          ) : (
                            <>
                              <Field
                                type="number"
                                name="discount"
                                disabled={isEdit}
                                className={` ${getClass(
                                  touched.discount && errors.discount
                                )}`}
                              />
                              <ErrorMessage name="discount">
                                {(msg) => (
                                  <div
                                    style={{
                                      color: "red",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="input-row d-flex align-items-start w-100">
                        <div className="label">Max. discount allowed:</div>
                        <div className="value">
                          {isEdit ? (
                            <span>{values.max_discount}</span>
                          ) : (
                            <>
                              <Field
                                type="number"
                                name="max_discount"
                                disabled={isEdit}
                              />
                              <ErrorMessage name="max_discount">
                                {(msg) => (
                                  <div
                                    style={{
                                      color: "red",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {values.discount_type === "flat_amount" && (
                    <>
                      <div className="input-row d-flex align-items-start w-100">
                        <div className="label">Discount amount:</div>
                        <div className="value">
                          {/* {isEdit ? (
                            <span>{values.flat_discount}</span>
                          ) : (
                            <> */}
                          <Field
                            type="number"
                            name="flat_discount"
                            // disabled={isEdit}
                            className={getClass(
                              touched.flat_discount && errors.flat_discount
                            )}
                          />
                          <ErrorMessage name="flat_discount">
                            {(msg) => (
                              <div
                                style={{
                                  color: "red",
                                  fontWeight: "normal",
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                          {/* </>
                          )} */}
                        </div>
                      </div>
                      <div className="input-row d-flex align-items-start w-100">
                        <div className="label">Min. order amount</div>
                        <div className="value">
                          {/* {isEdit ? (
                            <span>{values.min_order_amount}</span>
                          ) : (
                            <> */}
                          <Field
                            type="number"
                            name="min_order_amount"
                          // disabled={isEdit}
                          />
                          <ErrorMessage name="min_order_amount">
                            {(msg) => (
                              <div
                                style={{
                                  color: "red",
                                  fontWeight: "normal",
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                          {/* </>
                          )} */}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Max. Useage Count</div>
                    <div className="value">
                      <Field
                        type="number"
                        name="max_usage_count"
                      // disabled={isEdit}
                      />
                      <ErrorMessage name="max_usage_count">
                        {(msg) => (
                          <div style={{ color: "red", fontWeight: "normal" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="input-row d-flex align-items-start w-100">
                    <div className="label">Show on product:</div>
                    <div className="value">
                      <Select
                        name="show_on_product"
                        value={
                          values.show_on_product
                            ? values.show_on_product === "1"
                              ? show_on_product_values[0]
                              : show_on_product_values[1]
                            : ""
                        }
                        onChange={(value) =>
                          setFieldValue("show_on_product", value.value)
                        }
                        options={show_on_product_values}
                        onBlur={() => setFieldTouched("show_on_product", true)}
                        placeholder="Select if you want to show coupon on product"
                        className={`category-select w-100 ${getClass(
                          touched.show_on_product && errors.show_on_product
                        )}`}
                        classNamePrefix="select"
                      />
                      {touched.show_on_product && errors.show_on_product && (
                        <div style={{ color: "red", fontWeight: "normal" }}>
                          {errors.show_on_product}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={props.cancelClick}
                  >
                    cancel
                  </button>
                  <button className="create-button" type="submit">
                    {loading ? (
                      <ClipLoader color="#fff" loading size={15} />
                    ) : editPage ? (
                      "Update"
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {redirect ? <Redirect push to="/coupons" /> : ""}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    stores: state.stores.stores_list,
    coupanDetails: state.coupon.coupanDetails,
    coupons: state.coupon.coupons,
  };
};

const mapDispatchTopProps = { set_stores, set_coupons };

export default connect(mapStateToProps, mapDispatchTopProps)(CouponModal);
