import React from "react";
import { Route, Redirect } from "react-router-dom";

import { set_user } from "redux/actions/AuthAction";
import { connect } from "react-redux";

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const authenticated = localStorage.getItem("admin_access_token");
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const mapDispatchToProps = {
  set_user,
};
export default connect(null, mapDispatchToProps)(ProtectedRoutes);
