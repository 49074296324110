import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import Switch from "react-switch";
import Select from "react-select";
import "./service.scss";
import Header from "../../../components/header/Header";
import { set_categories } from "../../../redux/actions/CategoriesAction";
import { set_stores } from "../../../redux/actions/StoresAction";
import { set_service_details } from "../../../redux/actions/ServicesAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useHistory } from "react-router-dom";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RSelect from "react-select";
import { set_countries } from "redux/actions/CountryAction";
import {
  getClass,
  getRandomInt,
  IsQuillEmpty,
  deleteS3Image,
} from "utils/utils";
import Resizer from "react-image-file-resizer";

const Service = (props) => {
  const [gettingCat, setGetttingCat] = useState(true);
  const [countryOptions, setcountryOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const param = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const pageState = location?.state?.name || "create";
  const [data, setData] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadingSingleImage, setUploadingSingleImage] = useState(false);
  const [isEditClick, setisEditClick] = useState(
    pageState === "view" ? false : true
  );
  let message = "";

  var combine = [];
  var relatedservice = [];
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedService, setSelectedService] = useState();
  const [selectedTool, setSelectedTool] = useState();

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedTools, setRelatedTools] = useState([]);
  const [relatedServices, setRelatedServices] = useState([]);
  var extraService = [];
  var extraProduct = [];
  var extraTools = [];
  const [countryID, setCountryID] = useState();
  const [sendReq, setSendReq] = useState(false);
  const [deletedImagesUrl, setDeletedImagesUrl] = useState([]);
  const [values, setvalues] = useState(
    pageState === "create"
      ? {
          name_en: "",
          description_en: "",
          name_ar: "",
          description_ar: "",
          price: "",
          discounted_price: "",
          unit_time: "",
          category_id: [],
          store_id: "",
          service_type: "hourly",
          status: "inactive",
          benefits_en: "",
          benefits_ar: "",
          features_en: "",
          features_ar: "",
          country_id: "",
          store_country_name_en: "",
          howto_en: "",
          howto_ar: "",
          images: [],
        }
      : {
          name_en: props?.serviceDetails?.name_en,
          description_en: props?.serviceDetails?.description_en,
          name_ar: props?.serviceDetails?.name_ar,
          description_ar: props?.serviceDetails?.description_ar,
          price: props?.serviceDetails?.price,
          discounted_price: props?.serviceDetails?.discounted_price,
          unit_time: props?.serviceDetails?.unit_time,
          category_id: props?.serviceDetails?.categories?.map((category) => {
            return {
              value: category.id,
              label: category.title_en,
            };
          }),
          store_id: props?.serviceDetails?.store_id,
          service_type: props?.serviceDetails?.service_type || "hourly",
          country_id: props?.serviceDetails?.country_id,
          store_country_name_en: props?.serviceDetails?.store_country_name_en,
          status: props?.serviceDetails?.status,
          images: props?.serviceDetails?.images,
          benefits_en: props?.serviceDetails?.benefits_en,
          benefits_ar: props?.serviceDetails?.benefits_ar,
          features_en: props?.serviceDetails?.features_en,
          features_ar: props?.serviceDetails?.features_ar,
          howto_en: props?.serviceDetails?.how_to_use_en,
          howto_ar: props?.serviceDetails?.how_to_use_ar,
        }
  );

  const resizeFileForDesktop = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const resizeFileForMob = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSucess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  let categoryList = [];
  props.categories &&
    props.categories.map((category) => {
      categoryList.push({
        value: category.id,
        label: category.title_en,
      });

      return category;
    });

  const fetchCountries = async () => {
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        props.set_countries({ countries: response.data });
        setcountryOptions(response.data);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Service";
  }, []);

  const asyncCall = async () => await fetchCountries();
  // eslint-disable-next-line
  useEffect(() => asyncCall(), []);

  const handleFilesChange = (files, images, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingImages(true);
    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);

        setFieldValue("images", [
          ...images,
          ...response.map((image) => image.location),
        ]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };
  const mergeArrays = () => {
    selectedProduct?.map((item) => {
      combine.push({ related_product_id: parseInt(item.value) });
      return item;
    });
    selectedTool?.map((item) => {
      combine.push({ related_product_id: parseInt(item.value) });
      return item;
    });
    return combine;
  };

  const convertToServiceList = () => {
    selectedService?.map((item) => {
      relatedservice.push({ related_service_id: parseInt(item.value) });
      return item;
    });
    return relatedservice;
  };

  const handleSingleFilesChange = async (files, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingSingleImage(true);
    const resizeImageDesktop = await resizeFileForDesktop(files[0]);
    const resizeImageMob = await resizeFileForMob(files[0]);

    files.push(resizeImageMob);
    files.push(resizeImageDesktop);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingSingleImage(false);
        setFieldValue("featured_image", response[0].location);
        setFieldValue(`featured_image_resized`, [
          response[1].location,
          response[2].location,
        ]);
      })
      .catch((err) => {
        setUploadingSingleImage(false);
        message = "File upload error";
        showError();
      });
  };

  const deleteService = (id) => {
    setLoading(true);
    apiJson.services.removeService(id).then((response) => {
      if (response.success) {
        setLoading(false);
        history.push("/service-catalog");
        message = "Service Deleted!";
        showSucess();
      } else if (!response.status) {
        setLoading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const Update = (values) => {
    deleteS3Image(deletedImagesUrl);
    var service_related_products = mergeArrays();
    var service_related_services = convertToServiceList();
    setLoading(true);
    apiJson.services
      .updateService(location.state.id, {
        service: {
          name_en: values.name_en,
          description_en: values.description_en,
          name_ar:
            values.name_ar?.length > 1 && values.name_ar !== "<p><br></p>"
              ? values.name_ar
              : values.name_en,
          description_ar:
            values.description_ar?.length > 1 &&
            values.description_ar !== "<p><br></p>"
              ? values.description_ar
              : values.description_en,
          price: values.price,
          discounted_price:
            values.discounted_price && parseFloat(values.discounted_price) > 0
              ? values.discounted_price
              : values.price,
          status: values.status,
          benefits_en: values.benefits_en,
          benefits_ar:
            values.benefits_ar?.length > 1 &&
            values.benefits_ar !== "<p><br></p>"
              ? values.benefits_ar
              : values.benefits_en,
          features_en: values.features_en,
          features_ar:
            values.features_ar?.length > 1 &&
            values.features_ar !== "<p><br></p>"
              ? values.features_ar
              : values.features_en,
          how_to_use_en: values.howto_en,
          how_to_use_ar:
            values.howto_ar?.length > 1 && values.howto_ar !== "<p><br></p>"
              ? values.howto_ar
              : values.howto_en,
          service_categories_attributes: values.category_id.map((cat) => {
            return { category_id: cat.value };
          }),
          store_id: values.store_id,
          service_type: values.service_type,
          featured_image: values.featured_image,
          featured_image_resized: values.featured_image_resized,
          images: values.images,
          service_related_products_attributes: service_related_products,
          service_related_services_attributes: service_related_services,
        },
      })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setisEditClick(false);
          history.push({
            pathname: `/service/${param.id}/view`,
            state: { name: "view", id: param.id },
          });
          message = "Service Updated";
          showSucess();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Create = (values) => {
    deleteS3Image(deletedImagesUrl);
    var service_related_products = mergeArrays();
    var service_related_services = convertToServiceList();
    setLoading(true);
    apiJson.services
      .createService(
        {
          service: {
            name_en: values.name_en,
            name_ar:
              values.name_ar?.length > 1 && values.name_ar !== "<p><br></p>"
                ? values.name_ar
                : values.name_en,
            benefits_en: values.benefits_en,
            benefits_ar:
              values.benefits_ar?.length > 1 &&
              values.benefits_ar !== "<p><br></p>"
                ? values.benefits_ar
                : values.benefits_en,
            features_en: values.features_en,
            features_ar:
              values.features_ar?.length > 1 &&
              values.features_ar !== "<p><br></p>"
                ? values.features_ar
                : values.features_en,
            how_to_use_en: values.howto_en,
            how_to_use_ar:
              values.howto_ar?.length > 1 && values.howto_ar !== "<p><br></p>"
                ? values.howto_ar
                : values.howto_en,
            description_en: values.description_en,
            description_ar:
              values.description_ar?.length > 1 &&
              values.description_ar !== "<p><br></p>"
                ? values.description_ar
                : values.description_en,
            price: values.price,
            discounted_price:
              values.discounted_price && parseFloat(values.discounted_price) > 0
                ? values.discounted_price
                : values.price,
            status: values.status,
            service_categories_attributes: values.category_id.map((cat) => {
              return { category_id: cat.value };
            }),
            store_id: values.store_id,
            service_type: values.service_type,
            featured_image: values.featured_image,
            featured_image_resized: values.featured_image_resized,
            images: values.images,
            country_id: values.country_id,
            service_related_products_attributes: service_related_products,
            service_related_services_attributes: service_related_services,
          },
        },
        values.country_id
      )
      .then((response) => {
        if (response.success) {
          setLoading(false);
          message = "Service Created Successfully";
          showSucess();
          history.push("/service-catalog");
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const serviceSchema = Yup.object().shape({
    name_en: Yup.string().required("Required"),
    description_en: Yup.string().required("Required").nullable("Required"),
    price: Yup.number().min(0, "Negative not allowed").required("Required"),
    discounted_price: Yup.number().min(0, "Negative Not allowed"),
    unit_time: Yup.string(),
    benefits_en: Yup.string().required("Required").nullable("Required"),
    features_en: Yup.string().required("Required").nullable("Required"),
    howto_en: Yup.string().required("Required").nullable("Required"),
    country_id: Yup.number().required("Required"),
    store_id: Yup.number().required("Required"),
    service_type: Yup.string().required("Required"),
    category_id: Yup.array().min(1, "Required").nullable("Required"),
    featured_image: Yup.string().required("Required"),
    images: Yup.array()
      .min(1, "At least 1 image required")
      .nullable("Required"),
  });

  const handleCountryChange = (value, setFieldValue) => {
    setFieldValue("country_id", value.value);
    setFieldValue("store_country_name_en", value.label);
    setStoreOptions(
      props?.stores?.filter((store) => store.country_id === value.value)
    );
    setSendReq(true);
  };
  const handleCountryID = (storeID) => {
    let extra = 0;
    props.stores.map((store) =>
      store.id === storeID ? (extra = store.country_id) : -1
    );
    if (extra) {
      setSendReq(true);
    }
    setCountryID(extra);
  };

  const storeHandleChange = (value, setFieldValue) => {
    setFieldValue("store_id", value.value);
    setFieldValue("store_name_en", value.label);

    let selectedStore = props.stores.filter(
      (store) => parseInt(store.id) === parseInt(value.value)
    );
    let categories = props.categories.filter(
      (category) =>
        category.country_name_en === selectedStore[0].country_name_en
    );
    setCategories(
      categories.map((category) => {
        return { value: category.id, label: category.title_en };
      })
    );
    props.categories.filter(
      (category) =>
        category.country_name_en === selectedStore[0].country_name_en
    )?.length > 0
      ? setGetttingCat(false)
      : setGetttingCat(true);

    handleCountryID(selectedStore[0].id);
  };
  const heading = isEditClick ? "Create/Edit Service" : "View service";

  useEffect(() => {
    if (sendReq) {
      apiJson.services.getRelatedServices(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraService.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedServices(extraService);
      });
      apiJson.products.getRelatedProducts(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraProduct.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedProducts(extraProduct);
      });
      apiJson.products.getRelatedTools(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraTools.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedTools(extraTools);
      });
    }
    // eslint-disable-next-line
  }, [countryID]);

  useEffect(() => {
    setData(false);
    param?.state === "view" ? setisEditClick(false) : setisEditClick(true);
    location.state &&
      apiJson.services.getServiceDetail(location.state.id).then((response) => {
        if (response.success) {
          props.set_service_details({ serviceDetails: response.data });
          setvalues({
            name_en: response.data.name_en,
            description_en: response.data.description_en,
            name_ar: response.data.name_ar,
            description_ar: response.data.description_ar,
            price: response.data.price,
            discounted_price: response.data.discounted_price,
            unit_time: response.data.unit_time,
            category_id: response.data.categories?.map((category) => {
              return {
                value: category.id,
                label: category.title_en,
              };
            }),
            category_title_en: response.data.category_title_en,
            store_id: response.data.store_id,
            service_type: response.data.service_type || "hourly",
            country_id: response.data.country_id,
            store_country_name_en: response.data.store_country_name_en,
            store_name_en: response.data.store_name_en,
            status: response.data.status,
            images: response.data.images,
            benefits_en: response.data.benefits_en,
            benefits_ar: response.data.benefits_ar,
            features_en: response.data.features_en,
            features_ar: response.data.features_ar,
            howto_en: response.data.how_to_use_en,
            howto_ar: response.data.how_to_use_ar,
            featured_image: response.data.featured_image,
            featured_image_resized: response.data.featured_image_resized,
          });

          response.data.related_services?.map((item) => {
            extraService.push({ value: item.id, label: item.name_en });
            return item;
          });
          setSelectedService(extraService);
          response.data.related_products?.map((item) => {
            extraProduct.push({ value: item.id, label: item.name_en });
            return item;
          });
          setSelectedProduct(extraProduct);
          response.data.related_tools?.map((item) => {
            extraTools.push({ value: item.id, label: item.name_en });
            return item;
          });
          setSelectedTool(extraTools);

          apiJson.stores.getStores().then((res) => {
            if (res.success) {
              props.set_stores({ stores_list: res.data });
              setStoreOptions(
                res.data.filter(
                  (store) => store.country_id === response.data.country_id
                )
              );

              let extra = 0;
              res.data.map((store) =>
                store.id === response.data.store_id
                  ? (extra = store.country_id)
                  : -1
              );
              if (extra) {
                setSendReq(true);
              }
              setCountryID(extra);
            } else if (!res.status) {
              // eslint-disable-next-line
              message = res.message
                ? res.message !== ""
                  ? res.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            } else {
              if (res.status >= 400 && res.status < 500) {
                message = res.message
                  ? res.message
                  : res.errors
                  ? res.errors
                  : "something went wrong";
                showError();
              } else {
                message = res.message
                  ? res.message !== ""
                    ? res.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });

          apiJson.categories.getCategories().then((res) => {
            if (res.success) {
              props.set_categories({ categories: res.data });

              if (pageState !== "create") {
                setGetttingCat(false);

                setCategories(
                  res.data
                    .map((category) =>
                      category.country_name_en ===
                      response.data.store_country_name_en
                        ? { value: category.id, label: category.title_en }
                        : undefined
                    )
                    .filter((category) => category !== undefined)
                );
              }
              setData(true);
            } else if (!res.status) {
              message = res.message
                ? res.message !== ""
                  ? res.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            } else {
              if (res.status >= 400 && res.status < 500) {
                message = res.message
                  ? res.message
                  : res.errors
                  ? res.errors
                  : "something went wrong";
                showError();
              } else {
                message = res.message
                  ? res.message !== ""
                    ? res.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (!response.status) {
          setData(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setData(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });

    !location.state &&
      apiJson.stores.getStores().then((res) => {
        if (res.success) {
          props.set_stores({ stores_list: res.data });
          setSendReq(true);
        } else if (!res.status) {
          message = res.message
            ? res.message !== ""
              ? res.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (res.status >= 400 && res.status < 500) {
            message = res.message
              ? res.message
              : res.errors
              ? res.errors
              : "something went wrong";
            showError();
          } else {
            message = res.message
              ? res.message !== ""
                ? res.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });

    !location.state &&
      apiJson.categories.getCategories().then((res) => {
        if (res.success) {
          props.set_categories({ categories: res.data });
        } else if (!res.status) {
          message = res.message
            ? res.message !== ""
              ? res.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (res.status >= 400 && res.status < 500) {
            message = res.message
              ? res.message
              : res.errors
              ? res.errors
              : "something went wrong";
            showError();
          } else {
            message = res.message
              ? res.message !== ""
                ? res.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    setDeletedImagesUrl([]);
    !location.state && setSelectedProduct([]);
    !location.state && setSelectedService([]);
    !location.state && setSelectedTool([]);
    !location.state &&
      setvalues({
        country_id: "",
        store_country_name_en: "",
        name_en: "",
        description_en: "",
        name_ar: "",
        description_ar: "",
        price: "",
        discounted_price: "",
        unit_time: "",
        category_id: [],
        store_id: "",
        service_type: "hourly",
        store_name_en: "",
        category_title_en: "",
        status: "inactive",
        benefits_en: "",
        benefits_ar: "",
        features_en: "",
        features_ar: "",
        howto_en: "",
        howto_ar: "",
        featured_image: "",
        featured_image_resized: [],
        images: [],
      });

    !location.state && setData(true);
  }, [location.state]);

  const onDeleteCall = () => {
    if (window.confirm("This Action will delete the service!")) {
      deleteService(location.state.id);
    }
  };

  const serviceTypes = [
    { value: "hourly", label: "Hourly" },
    { value: "one_time", label: "One Time" },
  ];

  return (
    <Fragment>
      <div className="right-content service-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage services on your site."
          />
        </div>
        {param.state === "view" && (
          <div className="button-div">
            <button
              type="button"
              className="create-button"
              onClick={() =>
                history.push({ pathname: "/service", state: undefined })
              }
            >
              Create New Service
            </button>
          </div>
        )}
        {!data ? (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <div className="main-row service-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={values}
                validationSchema={serviceSchema}
                enableReinitialize
                onSubmit={(values) => {
                  if (param.state === "edit") {
                    Update(values);
                  } else {
                    Create(values);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                }) => (
                  <Form className="service-form mx-auto">
                    <fieldset disabled={!isEditClick}>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Country</div>
                            <div className="input col-8">
                              <Select
                                value={
                                  values.country_id
                                    ? {
                                        value: values.country_id,
                                        label: values.store_country_name_en,
                                      }
                                    : ""
                                }
                                onChange={(value) =>
                                  handleCountryChange(value, setFieldValue)
                                }
                                options={countryOptions?.map((country) => {
                                  return {
                                    value: country.id,
                                    label: country.name_en,
                                  };
                                })}
                                onBlur={() =>
                                  setFieldTouched("country_id", true)
                                }
                                className={`category-select w-100 ${getClass(
                                  touched.country_id && errors.country_id
                                )}`}
                                classNamePrefix="select"
                                isSearchable={false}
                                isDisabled={!isEditClick}
                                placeholder="Select Country"
                              />
                              {touched.country_id && errors.country_id && (
                                <div className="english-error">
                                  {errors.country_id}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Store</div>
                            <div className="input col-8">
                              <Select
                                value={
                                  values.store_id
                                    ? {
                                        value: values.store_id,
                                        label: values.store_name_en,
                                      }
                                    : ""
                                }
                                onChange={(value) =>
                                  storeHandleChange(value, setFieldValue)
                                }
                                onBlur={() => setFieldTouched("store_id", true)}
                                options={storeOptions?.map((store) => {
                                  return {
                                    value: store.id,
                                    label: store.name_en,
                                  };
                                })}
                                className={`category-select w-100 ${getClass(
                                  touched.store_id && errors.store_id
                                )}`}
                                classNamePrefix="select"
                                isSearchable={false}
                                isDisabled={!isEditClick}
                                placeholder="Select Store"
                              />
                              {touched.store_id && errors.store_id && (
                                <div className="english-error">
                                  {errors.store_id}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Name</div>
                            <div className="input col-8">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.name_en && errors.name_en
                                )}`}
                                name="name_en"
                                type="text"
                                onChange={handleChange}
                                placeholder="Enter name"
                              />
                              {touched.name_en && errors.name_en && (
                                <div className="english-error">
                                  {errors.name_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="input col-8 offset-2">
                              <Field
                                className="w-100 arabic-direction"
                                name="name_ar"
                                type="text"
                                onChange={handleChange}
                                placeholder="أدخل الاسم"
                              />
                            </div>
                            <div className="label col-2 text-end">اسم</div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Description</div>
                            <div className="input col-8">
                              <Field
                                className="w-100"
                                name="description_en"
                                type="text"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.description_en &&
                                        errors.description_en
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value ? field.value : ""}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "description_en",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched("description_en", true)
                                    }
                                  />
                                )}
                              </Field>

                              {touched.description_en &&
                                errors.description_en && (
                                  <div className="english-error">
                                    {errors.description_en}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="input col-8 offset-2">
                              <Field
                                className="w-100"
                                type="text"
                                name="description_ar"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value ? field.value : ""}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "description_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label  col-2 text-end">وصف</div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Benefits</div>
                            <div className="input col-8">
                              <Field
                                className="w-100"
                                name="benefits_en"
                                type="text"
                                onChange={handleChange}
                                value={values.benefits_en}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.benefits_en && errors.benefits_en
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "benefits_en",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              {touched.benefits_en && errors.benefits_en && (
                                <div className="english-error">
                                  {errors.benefits_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="input col-8 offset-2">
                              <Field
                                className="w-100"
                                name="benefits_ar"
                                type="text"
                                onChange={handleChange}
                                value={values.benefits_ar}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "benefits_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label  col-2 text-end">فوائد</div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Features</div>
                            <div className="input col-8">
                              <Field
                                className="w-100"
                                name="features_en"
                                type="text"
                                onChange={handleChange}
                                value={values.features_en}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.features_en && errors.features_en
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "features_en",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              {touched.features_en && errors.features_en && (
                                <div className="english-error">
                                  {errors.features_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="input col-8 offset-2">
                              <Field
                                className="w-100"
                                name="features_ar"
                                type="text"
                                onChange={handleChange}
                                value={values.features_ar}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "features_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label  col-2 text-end">سمات</div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">How to use</div>
                            <div className="input col-8">
                              <Field
                                className="w-100"
                                name="howto_en"
                                type="text"
                                onChange={handleChange}
                                value={values.howto_en}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className={getClass(
                                      touched.howto_en && errors.howto_en
                                    )}
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "howto_en",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              {touched.howto_en && errors.howto_en && (
                                <div className="english-error">
                                  {errors.howto_en}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="input col-8 offset-2">
                              <Field
                                className="w-100"
                                name="howto_ar"
                                type="text"
                                onChange={handleChange}
                                value={values.howto_ar}
                                placeholder="Enter shipping Policy"
                                component="textarea"
                              >
                                {({ field }) => (
                                  <ReactQuill
                                    className="arabic-direction"
                                    readOnly={!isEditClick}
                                    value={field.value}
                                    onBlur={() =>
                                      setFieldTouched(field.name, true)
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        "howto_ar",
                                        IsQuillEmpty(value) ? "" : value
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="label  col-2 text-end">
                              كيف تستعمل
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Status</div>
                            <div className="input col-8">
                              <Switch
                                onChange={() => {
                                  setFieldValue(
                                    "status",
                                    values.status === "active"
                                      ? "inactive"
                                      : "active"
                                  );
                                  setvalues({
                                    ...values,
                                    status:
                                      values.status === "active"
                                        ? "inactive"
                                        : "active",
                                  });
                                }}
                                checked={values.status === "active"}
                                onHandleColor="#37B900"
                                offHandleColor="#dcdcdc"
                                onColor="#e9e9e9"
                                offColor="#e9e9e9"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                                className="switch"
                                width={48}
                                height={24}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <label className="label col-2">
                              Related Products
                            </label>
                            <div className="col-8 input">
                              <RSelect
                                isMulti
                                isDisabled={!isEditClick}
                                value={selectedProduct}
                                onChange={setSelectedProduct}
                                options={relatedProducts}
                                className="category-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row gx-0">
                            <label className="label col-2">
                              Related Services
                            </label>
                            <div className="col-8 input">
                              <RSelect
                                isMulti
                                isDisabled={!isEditClick}
                                value={selectedService}
                                onChange={setSelectedService}
                                options={relatedServices}
                                className="category-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <label className="label col-2">Related Tools</label>
                            <div className="col-8 input">
                              <RSelect
                                isMulti
                                isDisabled={!isEditClick}
                                value={selectedTool}
                                onChange={setSelectedTool}
                                options={relatedTools}
                                className="category-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Category</div>
                            <div className="input col-8">
                              <RSelect
                                isMulti
                                value={values.category_id}
                                onChange={(value) => {
                                  setFieldValue("category_id", value);
                                }}
                                options={categories}
                                className={`"category-select w-100" ${getClass(
                                  touched.category_id && errors.category_id
                                )}`}
                                classNamePrefix="select"
                                isSearchable={false}
                                isDisabled={!isEditClick || gettingCat}
                                placeholder="Select Category"
                                onBlur={() =>
                                  setFieldTouched("category_id", true)
                                }
                              />
                              {touched.category_id && errors.category_id && (
                                <div className="english-error">
                                  {errors.category_id}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Price</div>
                            <div className="input col-8">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.price && errors.price
                                )}`}
                                name="price"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={handleChange}
                                placeholder="0.00"
                                min="0"
                                step="0.01"
                              />
                              {touched.price && errors.price && (
                                <div className="english-error">
                                  {errors.price}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col ">
                          <div className="row mx-0">
                            <div className="label col-2">Discounted Price</div>
                            <div className="input col-8">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.discounted_price &&
                                    errors.discounted_price
                                )}`}
                                name="discounted_price"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={handleChange}
                                placeholder="0.00"
                                min="0"
                                step="0.01"
                              />
                              {touched.discounted_price &&
                                errors.discounted_price && (
                                  <div className="english-error">
                                    {errors.discounted_price}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Service Type</div>
                            <div className="input col-8">
                              <Select
                                value={
                                  values.service_type
                                    ? serviceTypes.find(service => service.value === values.service_type)
                                    : "hourly"
                                }
                                onChange={(value) =>
                                  setFieldValue("service_type", value.value)
                                }
                                onBlur={() => setFieldTouched("service_type", true)}
                                options={serviceTypes}
                                className={`category-select w-100 ${getClass(
                                  touched.service_type && errors.service_type
                                )}`}
                                classNamePrefix="select"
                                isSearchable={false}
                                isDisabled={!isEditClick}
                                placeholder="Select Service Type"
                              />
                              {touched.service_type && errors.service_type && (
                                <div className="english-error">
                                  {errors.service_type}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Featured Image</div>
                            <div className="input col-8">
                              {uploadingSingleImage ? (
                                <div className="d-flex justify-content-center align-item-center w-100">
                                  <ClipLoader color="#000" loading size={20} />
                                </div>
                              ) : values.featured_image !== "" ? (
                                <ImagesGrid
                                  name={isEditClick ? "" : "view"}
                                  remove={(val, url) => {
                                    let resizedUrls = [];
                                    setFieldValue("featured_image", "");
                                    values.featured_image_resized &&
                                      values.featured_image_resized?.length >
                                        0 &&
                                      values.featured_image_resized.map(
                                        (url2) => {
                                          if (url.includes("https")) {
                                            resizedUrls.push(url2);
                                          }
                                          return url2;
                                        }
                                      );
                                    setDeletedImagesUrl([
                                      ...deletedImagesUrl,
                                      url,
                                      ...resizedUrls,
                                    ]);
                                  }}
                                  images={[values.featured_image]}
                                />
                              ) : (
                                touched.featured_image &&
                                errors.featured_image && (
                                  <div
                                    style={{ color: "red", fontSize: "0.9rem" }}
                                  >
                                    {errors.featured_image}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {isEditClick && (
                        <div className="row mx-0">
                          <div className="english-col">
                            <div className="row mx-0">
                              <div className="col-8 input offset-2">
                                <DropzoneElement
                                  placeholder="Click to select a file"
                                  updateSelectedServiceFiles={(files) =>
                                    handleSingleFilesChange(
                                      files,
                                      setFieldValue
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row mx-0">
                        <div className="english-col">
                          <div className="row mx-0">
                            <div className="label col-2">Service Images</div>
                            <div className="col-8 input">
                              {uploadingImages ? (
                                <div className="d-flex justify-content-center align-item-center w-100">
                                  <ClipLoader color="#000" loading size={20} />
                                </div>
                              ) : values.images?.length > 0 ? (
                                <ImagesGrid
                                  name={isEditClick ? "" : "view"}
                                  remove={(val, url) => {
                                    setDeletedImagesUrl([
                                      ...deletedImagesUrl,
                                      url,
                                    ]);
                                    setFieldValue("images", val);
                                  }}
                                  images={values.images}
                                />
                              ) : (
                                touched.images &&
                                errors.images && (
                                  <div
                                    style={{ color: "red", fontSize: "0.9rem" }}
                                  >
                                    {errors.images}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {isEditClick && (
                        <div className="row mx-0">
                          <div className="english-col">
                            <div className="row mx-0">
                              <div className="col-8 input offset-2">
                                <DropzoneElement
                                  placeholder="Click to select files"
                                  updateSelectedServiceFiles={(files) =>
                                    handleFilesChange(
                                      files,
                                      values.images,
                                      setFieldValue
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-8 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          {isEditClick ? (
                            <Fragment>
                              <button className="blue-button" type="submit">
                                {loading ? (
                                  <div className="d-flex justify-content-center align-items center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={20}
                                    />
                                  </div>
                                ) : param.state === "edit" ? (
                                  "Update Service"
                                ) : (
                                  "Save Service"
                                )}
                              </button>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => {
                                  history.push("/service-catalog");
                                }}
                              >
                                Cancel
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                key={getRandomInt(1, 100)}
                                onClick={() => {
                                  setisEditClick(true);
                                  history.push({
                                    pathname: `/service/${param.id}/edit`,
                                    state: { name: "edit", id: param.id },
                                  });
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => onDeleteCall()}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-items center w-100">
                                    <ClipLoader
                                      color="#000"
                                      loading
                                      size={20}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceDetails: state.service.serviceDetails,
    categories: state.category.categories,
    stores: state.stores.stores_list,
  };
};

const mapDispatchTopProps = {
  set_categories,
  set_service_details,
  set_stores,
  set_countries,
};

export default connect(mapStateToProps, mapDispatchTopProps)(Service);
