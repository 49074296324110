import React, { Fragment, useEffect, useState, useCallback } from "react";
import "./stores.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { set_stores } from "redux/actions/StoresAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "lodash";

const Stores = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Stores";
  }, []);

  useEffect(() => {
    apiJson.stores.searchStores("").then((response) => {
      if (response.success) {
        props.set_stores({ stores: response.data });
        setData(true);
        setHeaderList({
          id: "storestab",
          tablist: [{ id: "all", count: response.data.length }],
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text) => handleStoreSearch(text), 1000),
      []
    );

  const handleStoreSearch = (text, tab) => {
    setData(false);
    apiJson.stores.searchStores(text).then((response) => {
      if (response.success) {
        props.set_stores({ stores: response.data });
        setData(true);
        setHeaderList({
          id: "storestab",
          tablist: [{ id: "all", count: response.data.length }],
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }

  return (
    <Fragment>
      <div className="right-content stores-page-container w-100">
        <div className="header-row">
          <Header
            heading="Stores"
            subtext="Create, edit, and manage Stores on your site."
            storeSearch={true}
            handleStoreSearch={(text) => {
              setSearchText(text);
              debouncedCallback(text);
            }}
            searchText={searchText}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              props.stores && (
                <div className="stores-tab">
                  <HeaderTab tab={headerList}>
                    <TableList List={props.stores} />
                  </HeaderTab>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { stores: state.stores.stores };
};

const mapDispatchToProps = { set_stores };

export default connect(mapStateToProsps, mapDispatchToProps)(Stores);
