import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import { set_returns } from "redux/actions/ReturnsAction";
import "./viewreturn.scss";
import Header from "../../../components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { FaWallet } from "react-icons/fa";
import { RiBankCardLine } from "react-icons/ri";

import moment from "moment";
import RefundModal from "./RefundModal";
import { useParams } from "react-router-dom";
import { getResizedImage } from "Extra/GetResizedImage";

const ViewReturn = (props) => {
  const [updating, setUpdating] = useState(false);
  const param = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const filterOption = [
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
  ];
  const handleChange = (value) => {
    setSelectedOption(value);
  };
  const [returnDetails, setReturnDetails] = useState({});

  let message = "";
  const [data, setData] = useState(false);

  useEffect(() => {
    document.title = "Return";
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleUpdate = () => {
    if (selectedOption?.value) {
      setUpdating(true);
      apiJson.returns
        .updateReturns(returnDetails.id, {
          refund: {
            status: selectedOption.value.toLowerCase(),
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_returns({ returnDetail: response.data });
            message = "Status Updated";
            setUpdating(false);
            setReturnDetails(response.data);
            showSuccess();
          } else if (!response.status) {
            setUpdating(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setUpdating(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Select to update";
      showError();
    }
  };
  useEffect(() => {
    apiJson.returns.getReturnDetails(parseInt(param.id)).then((response) => {
      if (response.success) {
        props.set_returns({ returnDetail: response.data });
        setReturnDetails(response.data);
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="right-content view-return-page-desktop">
        <div className="header-row">
          <Header
            heading="View Returns"
            subtext="View, edit, and manage the Returns on your site."
          />
        </div>
        {data && returnDetails ? (
          <div className="main-row view-return-page-content-container">
            <div className="content-wrapper">
              <div className="return-header mx-0 d-flex">
                <div className="return-head-text">
                  Return ID: {returnDetails.id}
                </div>
                <div className="return-fade-text">
                  Placed on:{" "}
                  {moment(new Date(returnDetails.created_at)).format("LL")}
                </div>
              </div>
              <div className="return-detail-box">
                <div className="first-box d-flex">
                  <div className="return-details">
                    <h5 className="return-head-text">Pickup Address</h5>
                    <div className="return-fade-text name">
                      <p>{returnDetails.address.first_name}</p>
                      <p> {returnDetails.address.address1}</p>
                      <p>{returnDetails.address.contact_number}</p>
                    </div>
                  </div>
                  {returnDetails?.status !== "rejected" && (
                    <div className="return-method">
                      <div className="return-head-text">Refund Method</div>
                      <div className="payment-method d-flex align-items-center">
                        <div className="icon">
                          {returnDetails?.refund_method ? (
                            returnDetails?.refund_method === "bank" ? (
                              <RiBankCardLine />
                            ) : (
                              <FaWallet />
                            )
                          ) : (
                            <FaWallet />
                          )}
                        </div>
                        {(returnDetails?.status === "requested" ||
                          returnDetails?.status === "approved") && (
                            <div className="text">
                              {returnDetails?.refund_method === "bank"
                                ? "Only the amount paid with credit/debit card can be refunded to bank, remaining amount will be refunded to wallet."
                                : "Will be refunded to wallet"}
                            </div>
                          )}
                        {returnDetails?.status === "completed" && (
                          <div className="text">
                            Refunded to{" "}
                            {returnDetails?.refund_method
                              ? returnDetails?.refund_method
                              : "wallet"}
                          </div>
                        )}
                      </div>
                      {returnDetails?.status === "approved" && (
                        <div className="refund-button-cont">
                          <button
                            className="refund-button"
                            type="button"
                            onClick={() => setShowModal(!showModal)}
                          >
                            Refund to{" "}
                            {returnDetails?.refund_method
                              ? returnDetails?.refund_method
                              : "wallet"}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="second-box d-flex align-items-center flex-md-column flex-lg-row">
                  <div className="detail-col d-flex align-items-center">
                    <div className="image-col">
                      <img
                        src={getResizedImage(
                          returnDetails.line_item?.featured_image_resized,
                          returnDetails.line_item?.featured_image
                        )}
                        alt=""
                        style={{
                          width: "auto",
                          maxWidth: "100px",
                          height: "100px",
                        }}
                      />
                    </div>
                    <div className="description-col d-flex flex-column">
                      <div className="return-fade-text mb-1">
                        {returnDetails.line_item.store_name_en}
                      </div>
                      {returnDetails.line_item.service_name_en && (
                        <div className="return-text mb-3">
                          {returnDetails.line_item.service_name_en
                            ? returnDetails.line_item.service_name_en
                            : ""}
                        </div>
                      )}
                      {returnDetails.line_item.product_name_en && (
                        <div className="return-text mb-3">
                          {returnDetails.line_item.product_name_en
                            ? returnDetails.line_item.product_name_en
                            : ""}
                          {returnDetails.line_item.variant_name_en
                            ? " " + returnDetails.line_item.variant_name_en
                            : ""}
                        </div>
                      )}
                      {returnDetails.line_item?.sku && (
                        <div className="return-text">
                          <span className="" style={{ fontWeight: "600" }}>
                            Sku:
                          </span>
                          <span className="font-weight">
                            {returnDetails.line_item?.sku}
                          </span>
                        </div>
                      )}
                      <div className="return-text">
                        <span className="" style={{ fontWeight: "600" }}>
                          Quantity:
                        </span>
                        <span className="font-weight">
                          {returnDetails.quantity}
                        </span>
                      </div>
                      <div className="return-text">
                        <span className="capital">
                          {returnDetails.line_item.currency}
                        </span>
                        <span className="font-weight">
                          {returnDetails.quantity * returnDetails.line_item.variant_price}
                        </span>
                        {/* {parseFloat(returnDetails.line_item.sale_price) <
                          parseFloat(returnDetails.line_item.price) && (
                            <span className="ms-2 text-uppercase">
                              <small>
                                <del>
                                  {" "}
                                  {returnDetails.line_item.currency +
                                    " " +
                                    returnDetails.line_item.price}
                                </del>
                              </small>
                            </span>
                          )} */}
                      </div>
                      <div className="return-text">
                        Sold by
                        <span className="font-weight">
                          {returnDetails.line_item.store_name_en}
                        </span>
                      </div>
                      <div className="return-text">
                        <span className="return-fade-text">Reason</span>
                        {returnDetails.reason && returnDetails.reason}
                      </div>
                    </div>
                  </div>
                  <div className="status-col d-flex flex-column justify-content-end align-items-end">
                    <div className="d-flex flex-row justify-content-end align-items-center w-100">
                      <span>You</span>
                      <div className="status d-flex justify-content-center align-items-center">
                        <span className="text capitalize">
                          {returnDetails.status && returnDetails.status}
                        </span>
                      </div>
                    </div>
                    {returnDetails.status === "requested" && (
                      <div className="update-status-col d-flex flex-lg-column flex-xl-row align-items-lg-end align-items-xl-center">
                        <div className="select" style={{ width: "150px" }}>
                          <Select
                            value={selectedOption?.value ? selectedOption : ""}
                            onChange={(value) => handleChange(value)}
                            options={filterOption}
                            className="category-select"
                            classNamePrefix="select"
                            isSearchable={false}
                            placeholder="Select Status"
                          />
                        </div>
                        <button
                          className="update-button"
                          type="button"
                          onClick={handleUpdate}
                          disabled={updating}
                        >
                          {updating ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#fff" loading size={20} />
                            </div>
                          ) : (
                            "Update Status"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={35} />
          </div>
        )}

        {showModal && (
          <RefundModal
            id={returnDetails.id}
            currency={
              returnDetails?.refund_method === "bank"
                ? returnDetails.currency
                : "aed"
            }
            itemCurrency={returnDetails.line_item.currency}
            price={returnDetails.line_item.price}
            variant_price={returnDetails.line_item.variant_price}
            quantity={returnDetails.quantity}
            onClose={(val) => setShowModal(val)}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { returnDetail: state.return.returnDetail };
};

const mapDispatchToProps = { set_returns };

export default connect(mapStateToProsps, mapDispatchToProps)(ViewReturn);
