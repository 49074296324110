import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./shipment.scss";
import ShipmentItem from "./ShipmentItem";
import { GoPackage } from "react-icons/go";

const classname = ["status", "orange-status", "red-status"];

const pending = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);
const processing = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const shipped = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="default-bar"></Col>
  </Row>
);

const delivered = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
    <Col xs={3} md={3} className="bar"></Col>
  </Row>
);

const cancelled = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const rejected = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const partially_returned = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
    <Col xs={3} md={3} className="orange-bar"></Col>
  </Row>
);

const fully_returned = (
  <Row className="progress g-0 mt-1">
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
    <Col xs={3} md={3} className="red-bar"></Col>
  </Row>
);

const Shipment = ({ orderId, shipment, currency, index }) => {
  const [barStatus, setBarStatus] = useState(1);
  useEffect(() => {
    switch (shipment?.status.toLowerCase()) {
      case "cancelled":
        setBarStatus(2);
        break;
      case "rejected":
        setBarStatus(2);
        break;
      case "customer_cancellation":
        setBarStatus(2);
        break;
      case "partially_returned":
        setBarStatus(1);
        break;
      case "fully_returned":
        setBarStatus(2);
        break;
      default:
        setBarStatus(0);
    }
  }, [shipment?.status]);

  return (
    <div className="shipment-desktop custom-card-2 mt-3">
      <div className="wrapper">
        <div className="shipment-summary vertical-separator px-2 pt-3 pb-2">
          <Row className="g-0">
            <Col md={12} className="pb-2">
              <span className="shipment-title text-uppercase faded-black-2-text fw-600 mr-3">
                <GoPackage className="mr-2" /> Shipment {index + 1}
              </span>
              <span className="shipment-number faded-black-2-text">
                {orderId} - {shipment.id}
              </span>
            </Col>
          </Row>
          
          {shipment?.status && (
            <>
              {shipment?.status === "pending" && pending}
              {shipment?.status === "cancelled" && cancelled}
              {shipment?.status === "rejected" && rejected}
              {shipment?.status === "customer_cancellation" && rejected}
              {shipment?.status === "processing" && processing}
              {shipment?.status === "shipped" && shipped}
              {shipment?.status === "delivered" && delivered}
              {shipment?.status === "partially_returned" && partially_returned}
              {shipment?.status === "fully_returned" && fully_returned}
            </>
          )}
          <div className={classname[barStatus]}>
            <div className="row no-gutters">
              <div className="col-md-6 fw-700 text-uppercase mt-1">
                {shipment.status && shipment.status.includes("_")
                  ? shipment.status.replaceAll("_", " ")
                  : shipment.status}
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-items">
          {shipment.line_items.map((shipmentItem) => (
            <ShipmentItem
              shipmentItem={shipmentItem}
              currency={currency}
              key={shipmentItem.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shipment;
