import React, { Fragment } from "react";

import "./productcategorylist.scss";
import GroceriesCategory from "../../../../assets/images/homepage/groceries-category.png";
import LaptopsCategory from "../../../../assets/images/homepage/laptops-category.png";
import KitchenCategory from "../../../../assets/images/homepage/home-category.png";
import WomenCategory from "../../../../assets/images/homepage/women-category.png";
import MenCategory from "../../../../assets/images/homepage/men-category.png";
import BeautyCategory from "../../../../assets/images/homepage/beauty-category.png";
import FragrancesCategory from "../../../../assets/images/homepage/fragrances-category.png";
import ToysCategory from "../../../../assets/images/homepage/toys-category.png";
import MobilesCategory from "../../../../assets/images/homepage/mobiles-category.png";
import ElectronicsCategory from "../../../../assets/images/homepage/electronics-category.png";
import ApplicancesCategory from "../../../../assets/images/homepage/appliances-category.png";
import ProductCategory from "./ProductCategory";

const productcategory = [
  {
    id: 1,
    img: GroceriesCategory,
    type: "Groceries",
  },
  {
    id: 2,
    img: LaptopsCategory,
    type: "Laptops",
  },
  {
    id: 3,
    img: KitchenCategory,
    type: "Home & Kitchen",
  },
  {
    id: 4,
    img: WomenCategory,
    type: "Women's Fashion",
  },
  {
    id: 5,
    img: MenCategory,
    type: "Men's Fashion",
  },
  {
    id: 6,
    img: BeautyCategory,
    type: "Beauty",
  },
  {
    id: 7,
    img: FragrancesCategory,
    type: "Fragrances",
  },
  {
    id: 8,
    img: ToysCategory,
    type: "Toys",
  },
  {
    id: 9,
    img: MobilesCategory,
    type: "Mobiles",
  },
  {
    id: 10,
    img: ElectronicsCategory,
    type: "Electronics",
  },
  {
    id: 11,
    img: ApplicancesCategory,
    type: "Home Applicances",
  },
  {
    id: 12,
    img: MenCategory,
    type: "Men's Fashion",
  },
];

const ProductCategoryList = (props) => {
  return (
    <Fragment>
      <div className="product-category-list-container d-flex flex-wrap justify-content-between align-items-center mx-auto">
        {productcategory.map((category) => {
          return <ProductCategory key={category.id} product={category} />;
        })}
      </div>
    </Fragment>
  );
};

export default ProductCategoryList;
