import Header from "components/header/Header";
import "../subadmins/subadmins.scss";
import { useCallback, useEffect, useState } from "react";
import { apiJson } from "../../../api/Api";
import { format } from "date-fns";
import SearchPopover from "../order/SearchPopover";
import { debounce } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

function TrakingLogs() {
  const [trakingLogs, setTrakingLogs] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [recentValue, setRecentValue] = useState({});
  const [searching, setSearching] = useState({});
  const [searchSubadmin, setSearchSubadmin] = useState([]);

  const getTrakingLogs = (searchParams) => {
    apiJson.trakingLogs.getTrakingLogs(searchParams).then((response) => {
      if (response.success) {
        setTrakingLogs(response.data);
        setSearchParams({
          item_id: recentValue.productName ? searchParams.item_id : "",
          whodunnit: recentValue.subAdmin ? searchParams.whodunnit : "",
        });
      }
    });
  };

  useEffect(() => {
    getTrakingLogs();
  }, []);

  const onSeacrhChange = (text, type) => {
    setSearchedProducts([]);
    setSearchSubadmin([]);

    if (!text) {
      getTrakingLogs({
        ...searchParams,
        ...(type === "product" ? { item_id: "" } : { whodunnit: "" }),
      });
    }

    if (type === "product") {
      text && setSearching({ product: true });
      setRecentValue((prev) => ({ ...prev, productName: text }));
      debouncedCallback(1, text + "&change_log=true", type);
    } else {
      text && setSearching({ subAdmin: true });
      setRecentValue((prev) => ({ ...prev, subAdmin: text }));
      debouncedCallback(1, text + "&whodunnit=true", type);
    }
  };

  const debouncedCallback = useCallback(
    debounce(
      (text, selected_country, type) =>
        performSearch(text, selected_country, type),
      1000
    ),
    []
  );

  const performSearch = function (text, country, type) {
    apiJson.trakingLogs.search(country, text).then(function (response) {
      if (response.success) {
        setSearching({});
        if (type === "product") {
          setSearchedProducts(response.data.products);
          setSearchSubadmin([]);
        } else {
          setSearchedProducts([]);
          setSearchSubadmin(response.data.users);
        }
      }
    });
  };

  const handleProductClicked = (id) => {
    setSearchedProducts([]);
    setSearchParams((prev) => ({ ...prev, item_id: id }));
    getTrakingLogs({ ...searchParams, item_id: id });
  };

  const handleSubAdminClicked = (id) => {
    setSearchSubadmin([]);
    getTrakingLogs({ ...searchParams, whodunnit: id });
    setSearchParams((prev) => ({ ...prev, whodunnit: id }));
  };

  const Loader = () => {
    return (
      <div
        className="popover d-flex"
        style={{
          maxWidth: "100%",
          width: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          zIndex: 1000,
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <ClipLoader color="#000" loading size={25} />
      </div>
    );
  };

  const tdStyle = {
    textAlign: "center",
    verticalAlign: "middle",
    borderBottom: "1px solid #ddd",
    borderLeft: "none",
    borderRight: "none",
  };
  return (
    <div className="subadmins">
      <Header heading="Traking Logs" subtext="Traking Logs" />

      <div className="section">
        <div className="d-flex gap-4 mb-4 w-100%">
          <div style={{ width: "100%", position: "relative" }}>
            <input
              type="search"
              onChange={(e) => onSeacrhChange(e.target.value, "sub_admin")}
              className="form-control"
              placeholder="Search by user name"
              value={recentValue.subAdmin}
            />

            <div style={{ position: "relative" }}>
              {recentValue.subAdmin && searchSubadmin?.length > 0 && (
                <SearchPopover
                  recentValue={recentValue.subAdmin}
                  searching={searching.subAdmin}
                  users={searchSubadmin}
                  onVariantClicked={handleSubAdminClicked}
                  changeLog={true}
                />
              )}
              {searching.subAdmin && Loader()}
            </div>
          </div>

          <div style={{ width: "100%", position: "relative" }}>
            <input
              type="search"
              onChange={(e) => onSeacrhChange(e.target.value, "product")}
              className="form-control"
              placeholder="Search by product name"
              value={recentValue.productName}
            />

            <div style={{ position: "relative" }}>
              {recentValue.productName && searchedProducts?.length > 0 && (
                <SearchPopover
                  recentValue={recentValue.productName}
                  searching={searching.product}
                  products={searchedProducts}
                  onVariantClicked={handleProductClicked}
                  changeLog={true}
                />
              )}
              {searching.product && Loader()}
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={tdStyle}>User name</th>
              <th style={tdStyle}>Module</th>
              <th style={tdStyle}>Action</th>
              <th style={tdStyle}>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {trakingLogs?.map((trakingLog, index) => {
              return (
                <tr key={index}>
                  <td style={tdStyle}>
                    {trakingLog.whodunnit.first_name_en +
                      " " +
                      trakingLog.whodunnit.last_name_en}
                  </td>
                  <td style={tdStyle}>{trakingLog.item_type}</td>
                  <td style={tdStyle}>{trakingLog.event}</td>
                  <td style={tdStyle}>
                    {format(Date.parse(trakingLog.created_at), "dd MMM yyy p")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TrakingLogs;
