import React, { Fragment, useEffect, useState } from "react";

import "./dashboard.scss";

import Header from "components/header/Header";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { set_countries } from "redux/actions/CountryAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { set_categories } from "redux/actions/CategoriesAction";
const DashBoard = (props) => {
  const [data, setData] = useState(false);

  let message = "";
  const [values, setValues] = useState({});

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  useEffect(() => {
    apiJson.dashboard.getDashboardData().then((response) => {
      if (response.success) {
        setValues(response.data);
        setData(true);
      } else if (!response.status) {
        setData(true);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
    return () => {
      setValues({});
      setData(new Date());
    };
  }, []);

  useEffect(() => {
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        props.set_countries({ countries: response.data });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="right-content dashboard-page-desktop">
        <div className="header-row">
          <Header heading="Dashboard" subtext="" />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            {data ? (
              <div className="dashboard-row d-flex flex-wrap">
                <div className="dashboard">
                  <div className="dashboard-card">
                    <div className="dasboard-header-row d-flex justify-content-center align-items-center">
                      <div className="dashboard-heading">Total Revenue</div>
                    </div>
                    <div className="dashboard-body-row d-flex justify-content-center align-items-center">
                      <div className="value mt-2">
                        {values && values.total_revenue?.currency && (
                          <span className="me-2 capitalize">
                            {values.total_revenue?.currency}
                          </span>
                        )}
                        {values && values.total_revenue?.amount}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard">
                  <div className="dashboard-card">
                    <div className="dasboard-header-row d-flex justify-content-center align-items-center">
                      <div className="dashboard-heading">New Orders</div>
                    </div>
                    <div className="dashboard-body-row d-flex justify-content-center align-items-center">
                      <div className="value mt-2">
                        {values && values.new_orders}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard">
                  <div className="dashboard-card">
                    <div className="dasboard-header-row d-flex justify-content-center align-items-center">
                      <div className="dashboard-heading">Total Orders</div>
                    </div>
                    <div className="dashboard-body-row d-flex justify-content-center align-items-center">
                      <div className="value mt-2">
                        {values && values.all_orders}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <ClipLoader color="#000" loading size={35} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    countries: state.countries.countries,
    topLevel: state.category.topLevel,
  };
};

const mapDispatchToProps = { set_countries, set_categories };

export default connect(mapStateToProsps, mapDispatchToProps)(DashBoard);
