import { type } from "../types/types";

export const set_categories = (categories, parent) => (dispatch) => {
  dispatch({
    type: type.SET_CATEGORIES,
    categories: categories,
  });
};

export const remove_category = (categoryId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_CATEGORY,
    categoryId: categoryId,
  });
};
