import React, { Fragment, useState, useRef, useEffect } from "react";
import { FaFileExport } from "react-icons/fa";
import { apiJson } from "../../../../api/Api";
import { toast } from "react-toastify";
import "../../../../../node_modules/react-date-range/dist/styles.css";
import "../../../../../node_modules/react-date-range/dist/theme/default.css";

import Modal from "./Modal";
import { useOutsideAlerter } from "../../../../utils/CustomHooks";
import "./reportcard.scss";
import { MdDateRange } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import { addDays, format, subDays } from "date-fns";

const ReportCard = (props) => {
  const [loading, setLoading] = useState(false);
  let message = "";
  const [showModal, setShowModal] = useState(false);
  const [currentReportID, setCurrentReportID] = useState("");
  const wrapperRef = useRef(null);
  const [show, setshow] = useState(false);
  const [timeLimit, setTimeLimit] = useState(new Date());
  useOutsideAlerter(wrapperRef, setshow);
  const [state, setState] = useState();

  const setStoreNameOrID = (id) => {
    setShowModal(false);
    handleGenerate(currentReportID, id);
  };

  const chooseFunction = (reportID) => {
    reportID === 6 || reportID === 7
      ? callhandlegenerate(reportID)
      : setModalAndReportId(reportID);
  };
  const callhandlegenerate = (ID) => {
    setCurrentReportID(ID);
    handleGenerate(ID);
  };

  const setModalAndReportId = (id) => {
    setCurrentReportID(id);
    setShowModal(!showModal);
  };
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const Report = props.report;
  const handleGenerate = (id, storeID) => {
    switch (id) {
      case 1:
        setLoading(true);
        if (storeID !== "allStores") {
          apiJson.Reports.getTotalSales(
            format(state.startDate, "yyy-MM-dd"),
            format(state.endDate, "yyy-MM-dd"),
            storeID
          ).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (storeID === "allStores") {
          apiJson.Reports.getTotalSalesForAllStores(
            format(state.startDate, "yyy-MM-dd"),
            format(state.endDate, "yyy-MM-dd")
          ).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        }
        break;
      case 2:
        setLoading(true);
        apiJson.Reports.getAllUsers(
          storeID === "allStores" ? "" : "?store_id=" + storeID
        ).then((response) => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
            setLoading(false);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
            setLoading(false);
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
        break;
      case 3:
        setLoading(true);
        if (storeID !== "allStores") {
          apiJson.Reports.getAllProducts(storeID).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (storeID === "allStores") {
          apiJson.Reports.getAllProductsForAllStores().then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        }
        break;
      case 4:
        setLoading(true);
        if (storeID !== "allStores") {
          apiJson.Reports.getAllServices(storeID).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (storeID === "allStores") {
          apiJson.Reports.getAllServicesForAllStores().then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        }
        break;
      case 5:
        setLoading(true);
        if (storeID !== "allStores") {
          apiJson.Reports.getInventory(storeID).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (storeID === "allStores") {
          apiJson.Reports.getInventoryForAllStores().then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        }
        break;
      case 6:
        setLoading(true);
        apiJson.Reports.getAllStores().then((response) => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
            setLoading(false);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
            setLoading(false);
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
        break;
      case 7:
        setLoading(true);
        apiJson.Reports.getProfit(
          format(state.startDate, "yyy-MM-dd"),
          format(state.endDate, "yyy-MM-dd"),
          null
        ).then((response) => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
            setLoading(false);
          } else if (!response.status) {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
            setLoading(false);
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
        break;
      case 8:
        setLoading(true);
        if (storeID !== "allStores") {
          apiJson.Reports.getVendorPayment(
            format(state.startDate, "yyy-MM-dd"),
            format(state.endDate, "yyy-MM-dd"),
            storeID
          ).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        } else if (storeID === "allStores") {
          apiJson.Reports.getVendorPaymentForAllStores(
            format(state.startDate, "yyy-MM-dd"),
            format(state.endDate, "yyy-MM-dd")
          ).then((response) => {
            if (response.success) {
              message = response.data.message;
              showSuccess();
              setLoading(false);
            } else if (!response.status) {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
              setLoading(false);
            } else {
              setLoading(false);
              if (response.status >= 400 && response.status < 500) {
                message = response.message
                  ? response.message
                  : response.errors
                  ? response.errors
                  : "something went wrong";
                showError();
              } else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : "something went wrong"
                  : "something went wrong";
                showError();
              }
            }
          });
        }
        break;
      default:
        alert("No report of this type");
    }
  };

  useEffect(() => {
    Report.report_id === 7 || Report.report_id === 8
      ? apiJson.settings.getPlatformSettingID().then((response) => {
          if (response.success) {
            apiJson.settings
              .getPlatformSetting(response.data.platform_id)
              .then((report) => {
                if (report.success) {
                  setState({
                    startDate: subDays(
                      new Date(),
                      report.data.refund_time_limit + 7
                    ),
                    endDate: subDays(new Date(), report.data.refund_time_limit),
                    key: "selection",
                  });
                  setTimeLimit(
                    subDays(new Date(), report.data.refund_time_limit)
                  );
                } else if (!report.status) {
                  setState({
                    startDate: subDays(new Date(), 7),
                    endDate: new Date(),
                    key: "selection",
                  });
                  // eslint-disable-next-line
                  message = report.message
                    ? report.message !== ""
                      ? report.message
                      : "something went wrong"
                    : "something went wrong";
                  showError();
                  setLoading(false);
                } else {
                  setState({
                    startDate: subDays(new Date(), 7),
                    endDate: new Date(),
                    key: "selection",
                  });
                  setLoading(false);
                  if (report.status >= 400 && report.status < 500) {
                    message = report.message
                      ? report.message
                      : report.errors
                      ? report.errors
                      : "something went wrong";
                    showError();
                  } else {
                    message = report.message
                      ? report.message !== ""
                        ? report.message
                        : "something went wrong"
                      : "something went wrong";
                    showError();
                  }
                }
              });
          } else if (!response.status) {
            setState({
              startDate: subDays(new Date(), 7),
              endDate: new Date(),
              key: "selection",
            });
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setState({
              startDate: subDays(new Date(), 7),
              endDate: new Date(),
              key: "selection",
            });
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        })
      : setState({
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection",
        });
    return () => {
      setState({});
      setTimeLimit(new Date());
    };
  }, []);
  return (
    <Fragment>
      <div className="report-card">
        <div className="report-header-row d-flex flex-column align-items-center">
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <div className="heading">{Report.report_title}</div>
            <button
              className="generate-button d-flex justify-content-center align-items-center"
              onClick={() => {
                chooseFunction(Report.report_id);
              }}
              disabled={loading}
            >
              <Fragment>
                <div className="icon">
                  <FaFileExport />
                </div>
                <div className="text">Generate</div>
              </Fragment>
            </button>
          </div>
          {
            <div
              className={`${
                Report.report_id === 1 ||
                Report.report_id === 7 ||
                Report.report_id === 8
                  ? " "
                  : "invisible "
              }report-header d-flex align-items-center justify-content-between`}
            >
              <div className="heading">Date Range</div>
              <div
                className="date-rangepicker d-flex align-items-center"
                ref={wrapperRef}
                onClick={() => setshow(true)}
              >
                {state?.endDate && (
                  <>
                    <div className="start-date">
                      {format(state.startDate, "dd MMM yyy")}
                    </div>
                    <div className="date-seperator">-</div>
                    <div className="end-date">
                      {format(state.endDate, "dd MMM yyy")}
                    </div>
                    <div className="date-icon d-flex justify-content-center">
                      <MdDateRange />
                    </div>
                  </>
                )}
                {show &&
                  (Report.report_id === 7 || Report.report_id === 8 ? (
                    <DateRangePicker
                      onChange={(item) => setState(item.selection)}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={[state]}
                      maxDate={timeLimit}
                      direction="horizontal"
                      className="date-picker"
                    />
                  ) : (
                    <DateRangePicker
                      onChange={(item) => setState(item.selection)}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={[state]}
                      direction="horizontal"
                      className="date-picker"
                    />
                  ))}
              </div>
            </div>
          }
        </div>
        {showModal && (
          <div className="modal-container">
            <Modal
              onClose={() => setShowModal(false)}
              title={Report.report_title}
              onSubmit={setStoreNameOrID}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ReportCard;
