import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import {
  set_product_details,
  remove_product,
} from "redux/actions/ProductsAction";
import { connect } from "react-redux";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { apiJson } from "../../../api/Api";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import { getResizedImage } from "Extra/GetResizedImage";
const TableList = (props) => {
  const [redirectToProduct, setRedirectToProduct] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [currentProduct, setCurrentProduct] = useState([]);
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const onAddToProduct = (singleProduct) => {
    setRedirectToProduct(true);
    setCurrentProduct(singleProduct);
  };

  const onViewClick = (currentProduct) => {
    setViewClicked(true);
  };

  const onEditClick = (currentProduct) => {
    setEditClicked(true);
  };

  const onRemoveClick = (currentProduct) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      deleteProduct(currentProduct.id);
    }
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };

  const menuItems = [
    <MenuItem
      key="2"
      onClick={() => onViewClick(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      View
    </MenuItem>,
    <MenuItem
      key="4"
      onClick={() => onEditClick(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key="1"
      onClick={() => onAddToProduct(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Add to section
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={() => onRemoveClick(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Delete
    </MenuItem>,
  ];
  const menu = <Menu className="menu">{menuItems}</Menu>;

  const deleteProduct = (id) => {
    apiJson.products
      .removeProduct(id)
      .then((response) => response.success && props.updateTablist());
    props.remove_product(currentProduct.id);
  };

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="">Product Image</td>
            <td className="">Name</td>
            <td className="">Status</td>
            <td className="">Created At</td>
            <td className="">Total Variants</td>
            <td className="">Store Name</td>
            <td className="">Actions</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((product) => {
              return (
                <tr key={product.id}>
                  <td className="product-image">
                    <div>
                      {product?.featured_image ? (
                        <img
                          src={getResizedImage(
                            product?.featured_image_resized,
                            product?.featured_image
                          )}
                          style={{
                            width: "auto",
                            maxWidth: "80px",
                            height: "80px",
                          }}
                          alt=""
                        />
                      ) : (
                        <span>No Image</span>
                      )}
                    </div>
                  </td>
                  <td className="name-text">{product.name_en}</td>
                  <td>
                    <div className="status-text">{product.status}</div>
                  </td>
                  <td>
                    <div className="name-text">
                      {moment(new Date(product.created_at)).format("LL")}
                    </div>
                  </td>
                  <td className="name-text">{product.variants_count}</td>
                  <td className="name-text">{product.store_name_en}</td>
                  <td className="">
                    <div className="">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        <BsThreeDots
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            setCurrentProduct(product);
                          }}
                          size={20}
                        />
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {redirectToProduct && (
          <Redirect
            push
            to={{
              pathname: `/add-product/${currentProduct.id}`,
            }}
          />
        )}
        {viewClicked && (
          <Redirect
            push
            to={{
              pathname: `/product/${currentProduct.id}/view`,
              state: { id: currentProduct.id },
            }}
          />
        )}
        {editClicked && (
          <Redirect
            push
            to={{
              pathname: `/product/${currentProduct.id}/edit`,
              state: { id: currentProduct.id },
            }}
          />
        )}
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            forcePage={parseInt(props?.pagination?.page) - 1}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productDetails: state.product.productDetails,
  };
};

const mapDispatchToProps = { set_product_details, remove_product };

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
