import React, { Fragment, useEffect } from "react";

import "../../../../node_modules/react-date-range/dist/styles.css";
import "../../../../node_modules/react-date-range/dist/theme/default.css";

import "./report.scss";
import ReportCard from "./components/ReportCard";
import { ReportsData } from "../../../Extra/Reports";
import Header from "../../../components/header/Header";

const Report = () => {
  useEffect(() => {
    document.title = "Reports";
  }, []);

  const Data = ReportsData;

  return (
    <Fragment>
      <div className="right-content report-page-desktop">
        <div className="header-row">
          <Header heading="Reports" subtext="Generate Reports on your site." />
        </div>
        <div className="main-row page-container">
          <div className="content-wrapper">
            <div className="top-reports-row d-flex flex-wrap">
              {Data.map((report) => {
                return (
                  <div className="report" key={report.report_id}>
                    <ReportCard report={report} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Report;
