import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/header/Header";
import HeaderTab from "../tab/HeaderTab";
import Select from 'react-select'
import { ProductTabData } from "../../../Extra/TabsData";
import ProductBulkImportModal from "./ProductBulkImportModal";
import ProductBulkImportLiveModal from "./ProductBulkImportLiveModal";
import "./productcatalog.scss";
import TableList from "./TableList";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_products } from "redux/actions/ProductsAction";

import { tabParam } from "utils/utils";
import { debounce } from "lodash";

const ProductCatalog = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [SKUSearchText, setSKUSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(ProductTabData.tablist[0].id);
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showBulkModalLive, setShowBulkModalLive] = useState(false);
  const { user = {} } = props

  useEffect(() => {
    document.title = "Products";
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getProducts = (page, params) => {
    setData(false);
    apiJson.products.getProducts(page, params).then((response) => {
      if (response.success) {
        props.set_products({
          products: response.data.products,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getProducts(
        1,
        tabParam(title) +
        (searchText?.length > 0 ? "&search_by_name=" + searchText : "") +
        (SKUSearchText?.length > 0 ? "&search_by_sku=" + SKUSearchText : "")
      );
    }
  };

  const pageChange = (page) => {
    getProducts(
      page,
      tabParam(tabTitle) +
      (searchText?.length > 0 ? "&search_by_name=" + searchText : "") +
      (SKUSearchText?.length > 0 ? "&search_by_sku=" + SKUSearchText : "")
    );
  };

  const updateTablist = () =>
    apiJson.products.getTabsCount().then((response) => {
      response.success &&
        setHeaderList({
          id: "productlist",
          tablist: [
            { id: "active", count: response.data.active },
            { id: "inactive", count: response.data.inactive },
            {
              id: "uncategorized",
              count: response.data.uncategorized,
            },
          ],
        });
    });

  useEffect(() => {
    apiJson.brands.getBrands()
      .then(response => {
        if (response.success) {
          var api_brands = [];
          response.data.forEach(brand => {
            api_brands.push({
              value: brand.id,
              label: brand.name_en
            });
          });
          setBrands(api_brands);
        }
      })
    getProducts(1, "&with_status=active");
    apiJson.products.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
          id: "productlist",
          tablist: [
            { id: "active", count: response.data.active },
            { id: "inactive", count: response.data.inactive },
            {
              id: "uncategorized",
              count: response.data.uncategorized,
            },
          ],
        })
        : setHeaderList({
          id: "productlist",
          tablist: [
            { id: "active", count: 0 },
            { id: "inactive", count: 0 },
            { id: "uncategorized", count: 0 },
          ],
        });
    });
    // eslint-disable-next-line
  }, []);

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab, sku) => handleSearch(text, currTab, sku), 1000),
      []
    );

  const handleSearch = (text, tab, sku) => {
    getProducts(
      1,
      tabParam(tab) +
      "&search_by_name=" +
      text +
      (sku?.length > 0 ? "&search_by_sku=" + sku : "")
    );
  };

  const debouncedSKUCallback =
    // eslint-disable-next-line
    useCallback(
      debounce(
        (text, currTab, search) => handleSKUSearch(text, currTab, search),
        1000
      ),
      []
    );

  const handleSKUSearch = (text, tab, search) => {
    getProducts(
      1,
      tabParam(tab) +
      (search.length > 0 ? "&search_by_name=" + search : "") +
      "&search_by_sku=" +
      encodeURIComponent(text)
    );
  };

  const onActionChanged = (action) => {
    if (brand) {
      if (action == "delete") {
        setData(true);
        apiJson.products.deleteProductsByBrand(brand)
          .then(response => {
            if (response.success) {
              message = response.data.message;
              getProducts(1, "&with_status=active");
              showSuccess();
            }
            setData(false);
          })
      }
    }
    else {
      alert("Select brand.");
    }
  }

  const bulkImport = () => {
    setShowBulkModal(true);
  };

  const createClick = () => {
    setShowBulkModal(false);
  };

  const cancelClick = () => {
    setShowBulkModal(false);
  };

  const cancelBulkImportLiveClick = () => {
    setShowBulkModalLive(false);
  };

  const bulkImportLive = () => {
    setShowBulkModalLive(true);
  };

  return (
    <Fragment>
      {showBulkModal && (
        <div className="product-import-modal-container">
          <ProductBulkImportModal
            heading="Bulk Import Product"
            cancleClick={cancelClick}
            createClick={createClick}
            dataType={"products"}
          />
        </div>
      )}
      {
        showBulkModalLive && (
          <div className="product-import-modal-container">
            <ProductBulkImportLiveModal
              heading="Update Products"
              cancleClick={cancelBulkImportLiveClick}
              createClick={createClick}
              dataType={"products"}
            />
          </div>
        )
      }
      <div className="right-content productcatalog-page">
        <div className="header-row">
          <Header
            subtext="Create, edit, and manage the products on your site."
            heading="View Products"
            search={true}
            sku={true}
            filters={null}
            handleSearch={(text) => {
              setSearchText(text);
              debouncedCallback(text, tabTitle, SKUSearchText);
            }}
            handleSKUSearch={(text) => {
              setSKUSearchText(text);
              debouncedSKUCallback(text, tabTitle, searchText);
            }}
            searchText={searchText}
            searchSKUText={SKUSearchText}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            {(user.permissions?.products?.create_products || user.permissions?.products?.update_products) &&
              <button type="button" onClick={bulkImport} className="btn btn-primary import-btn">Import Products</button>
            }
            {/* {" "}
            <button type="button" onClick={bulkImportLive} className="btn btn-primary update-btn">Foreground Import</button> */}
            <br />
            <div className="card top-bar">
              <div className="card-body">
                <div className="top-row">
                  <label>Apply to:&nbsp;</label>
                  <Select options={brands} className="brand-dropdown" onChange={(e) => setBrand(e.value)} />
                  <select name="action" className="form-control text-center action-dropdown" onChange={(e) => onActionChanged(e.target.value)}>
                    <option value="">--- Action ---</option>
                    <option value="delete">Delete</option>
                  </select>
                </div>
              </div>
            </div>
            <br />
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              props.products && (
                <div className="product-tab">
                  <HeaderTab
                    onTabChoose={ChangeTabList}
                    tabTitle={tabTitle}
                    tab={headerList}
                  >
                    <TableList
                      List={props.products}
                      onPageChange={pageChange}
                      pagination={props.pagination}
                      updateTablist={updateTablist}
                    />
                  </HeaderTab>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.product.pagination,
    products: state.product.products,
    user: state.auth.user,
  };
};

const mapDispatchToProps = { set_products };

export default connect(mapStateToProsps, mapDispatchToProps)(ProductCatalog);
