import { type } from "../types/types";

export const set_users = (users) => (dispatch) => {
  dispatch({
    type: type.SET_USERS,
    users: users,
  });
};

export const remove_user = (userId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_USER,
    userId: userId,
  });
};
