import { type } from "../../types/types";

export default function reducer(
  state = {
    categories: [],
    parentCategories: [],
    topLevel: [],
    forBrand: [],
    forCategories: [],
    pagination: {},
  },
  action
) {
  switch (action.type) {
    case type.SET_CATEGORIES:
      return { ...state, ...action.categories };
    case type.REMOVE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== action.categoryId
        ),
      };
    default:
      return state;
  }
}
