import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TableList = (props) => {
  const [currentBrand, setCurrentBrand] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [currentBrandID, setCurrentBrandID] = useState("");

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  const setVars = (brand) => {
    setCurrentBrand(brand);
    setCurrentBrandID(brand.id);
    setRedirect(true);
  };
  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="w-10 header-text">Brand Id</td>
            <td className="w-10 header-text">Name (English)</td>
            <td className="w-10 header-text">Name (Arabic)</td>
            <td className="w-10 header-text">Country</td>
          </tr>
        </thead>
        <tbody>
          {props.List &&
            props.List.map((brand) => {
              return (
                <tr
                  key={brand.id}
                  onClick={() => setVars(brand)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="w-10 header-text">{brand.id}</td>
                  <td className="w-10 header-text">{brand.name_en}</td>
                  <td className="w-10 header-text">{brand.name_ar}</td>
                  <td className="w-10 header-text">{brand.country_name_en}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {props.pagination?.total_pages && props.List.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            forcePage={parseInt(props?.pagination?.page) - 1}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `/edit-brand/${currentBrandID}`,
            state: { name: "view", brand: { currentBrand } },
          }}
        />
      )}
    </div>
  );
};

export default TableList;
