import { type } from "../types/types";

export const set_brands = (brands) => (dispatch) => {
  dispatch({
    type: type.SET_BRANDS,
    brands: brands,
  });
};

export const remove_brand = (brandId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_BRAND,
    brandId: brandId,
  });
};
