import HighGloss_600x from "../assets/images/homepage/products/high-gloss-600x.png";
import KurdKutter from "../assets/images/homepage/products/krud-kutter.jpeg";
import KrudKutterAutomotive from "../assets/images/Products_Images/krud-kutter-automotive.jpeg";
import NeverWet from "../assets/images/Products_Images/never-wet.jpeg";
import RubberizedBlack from "../assets/images/Products_Images/rubberized-black.jpeg";
import ToughTaskRemover from "../assets/images/Products_Images/tough-task-remover.jpeg";
import DryErase from "../assets/images/Products_Images/dry-erase.jpeg";
import DapWeldwood from "../assets/images/Products_Images/dap-weldwood.jpeg";
import FastNFinal from "../assets/images/Products_Images/fast-n-final.jpeg";
import ConcreteSaver from "../assets/images/Products_Images/concrete-saver-6700.png";
import PaintPen from "../assets/images/Products_Images/paint-pen.jpeg";
import WoodFloorAdhesive from "../assets/images/Products_Images/wood-floor-adhesive.jpeg";
import { FileDeletePromise } from "./aws_s3/aws_s3";

const getRandomItem = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const PRODUCT_IMAGES = [
  HighGloss_600x,
  KurdKutter,
  KrudKutterAutomotive,
  NeverWet,
  RubberizedBlack,
  ToughTaskRemover,
  DryErase,
  DapWeldwood,
  FastNFinal,
  ConcreteSaver,
  PaintPen,
  WoodFloorAdhesive,
];

export const getRandomProductImage = () => {
  return getRandomItem(PRODUCT_IMAGES);
};
export const IsQuillEmpty = (val) => {
  const data = val.split(">");
  return data.length <= 4 && (data[1] === "<br" || data[1] === "<br/");
};

export { getRandomItem };

export const getRandomInt = (min, max) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

export const getClass = (inValid) => {
  return inValid ? "highlight" : "";
};

export const deleteS3Image = (urls) =>
  // eslint-disable-next-line
  urls.map((url) => {
    let imageName = url.split("amazonaws.com/");
    FileDeletePromise(
      imageName[imageName.length - 1].includes("?")
        ? imageName[imageName.length - 1].split("?")[0]
        : imageName[imageName.length - 1]
    );
  });

export const tabParam = (tab) =>
  tab === "active" || tab === "inactive"
    ? "&with_status=" + tab
    : "&uncategorized=true";
