import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import {
  set_product_details,
  remove_product,
} from "redux/actions/ProductsAction";
import { connect } from "react-redux";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { apiJson } from "../../../api/Api";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { getResizedImage } from "Extra/GetResizedImage";
import "./tablelist.scss";
import { Link } from "react-router-dom";

const TableList = (props) => {
  const [redirectToProduct, setRedirectToProduct] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [currentProduct, setCurrentProduct] = useState([]);
  const [values, setValues] = useState("");
  const { user } = props;

  const history = useHistory();

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const onAddToProduct = (singleProduct) => {
    setRedirectToProduct(true);
    setCurrentProduct(singleProduct);
  };

  const onAddToTab = (singleProduct) => {
    setCurrentProduct(singleProduct);
    history.push("/home/product/" + singleProduct.id + "/add_to_tab");
  };

  const onViewClick = (currentProduct) => {
    setViewClicked(true);
  };

  const onEditClick = (currentProduct) => {
    setEditClicked(true);
  };

  const onRemoveClick = (currentProduct) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      deleteProduct(currentProduct.id);
    }
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };

  const menuItems = [
    user.permissions?.products.show && (
      <MenuItem
        key="2"
        onClick={() => onViewClick(currentProduct)}
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        View
      </MenuItem>
    ),
    user.permissions?.products.update && (
      <MenuItem
        key="4"
        onClick={() => onEditClick(currentProduct)}
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        Edit
      </MenuItem>
    ),
    <MenuItem
      key="1"
      onClick={() => onAddToProduct(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Add to section
    </MenuItem>,
    <MenuItem
      key="5"
      onClick={() => onAddToTab(currentProduct)}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Add to home tab
    </MenuItem>,
    user.permissions?.products.destroy && (
      <MenuItem
        key="3"
        onClick={() => onRemoveClick(currentProduct)}
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        Delete
      </MenuItem>
    ),
  ].filter(Boolean);

  const menu = <Menu className="menu">{menuItems}</Menu>;

  const deleteProduct = (id) => {
    apiJson.products
      .removeProduct(id)
      .then((response) => response.success && props.updateTablist());
    props.remove_product(currentProduct.id);
  };

  return (
    <div className="content-pq-table table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="" style={{ paddingLeft: "20px" }}>Product</td>
            <td className="">Overall</td>
            <td className="">Images</td>
            <td className="">Video</td>
            <td className="">Overview</td>
            <td className="">Features</td>
            <td className="">Benefits</td>
            <td className="">How to use</td>
            <td className="">UPC Code</td>
            <td className="">Actions</td>
          </tr>
        </thead>
        <tbody className="table-body">
          {values !== "" ? (
            values.map((product) => {
              return (
                <tr key={product.id}>
                  <td className="product-image" style={{ width: "400px" }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 10px"
                    }}>
                      {
                        <img
                          src={getResizedImage(
                            product?.variant_image,
                            product?.variant_image
                          )}
                          style={{
                            width: "50px",
                            maxWidth: "50px",
                            height: "50px",
                            borderRadius: "10px",
                            border: "1px solid #f1f1f5",
                            marginRight: "10px"
                          }}
                          alt=""
                        />
                      }
                      <div>
                        <p
                          style={{
                            whiteSpace: "nowrap",       // Prevents wrapping
                            overflow: "hidden",         // Hides overflow
                            textOverflow: "ellipsis",   // Adds ellipsis
                            maxWidth: "300px",          // Set a max width to constrain the text
                          }}
                        >{`${product.product_name} ${product.variant_name}`}</p>
                        <p>SKU: {product.sku}</p>
                        <p>Brand: {product.brand}</p>
                      </div>
                    </div>
                  </td>
                  <td className="name-text overall-col">
                    <div className="progress-bar">
                      <div
                      className="active"
                      style={{
                        width: `${product.overall}%`,
                        backgroundColor: product.overall > 70 ? "green" : product.overall > 50 ? "#48a4e3" : product.overall == 50 ? "orange" : "red"
                      }}></div>
                    </div>
                    <span>{product.overall}%</span>
                  </td>
                  <td className="name-text">
                    {product.images_score}%
                  </td>
                  <td className="name-text">
                    {
                      product.video == 100 ?
                        "Yes"
                        :
                        "No"
                    }
                  </td>
                  <td className="name-text">
                    {product.description_score}%
                  </td>
                  <td className="name-text">
                    {product.features_score}%
                  </td>
                  <td className="name-text">
                    {
                      product.benefits_score == 100 ?
                        "Yes"
                        :
                        "No"
                    }
                  </td>
                  <td className="name-text">
                    {
                      product.how_to_use == 100 ?
                        "Yes"
                        :
                        "No"
                    }
                  </td>
                  <td className="name-text">
                    {
                      product.upc_code == 100 ?
                        "Yes"
                        :
                        "No"
                    }
                  </td>
                  <td className="">
                    <Link
                      to={`/product/${product.id}/edit`}
                    >
                      <div className="edit-icon-div">
                        <MdModeEdit
                          size={18}
                          color="#6f7780"
                        />
                      </div>
                    </Link>
                    {/* <div className="">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        <BsThreeDots
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => {
                            setCurrentProduct(product);
                          }}
                          size={20}
                        />
                      </Dropdown>
                    </div> */}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {redirectToProduct && (
          <Redirect
            push
            to={{
              pathname: `/add-product/${currentProduct.id}`,
            }}
          />
        )}
        {viewClicked && (
          <Redirect
            push
            to={{
              pathname: `/product/${currentProduct.id}/view`,
              state: { id: currentProduct.id },
            }}
          />
        )}
        {editClicked && (
          <Redirect
            push
            to={{
              pathname: `/product/${currentProduct.id}/edit`,
              state: { id: currentProduct.id },
            }}
          />
        )}
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            forcePage={parseInt(props?.pagination?.page) - 1}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productDetails: state.product.productDetails,
    user: state.auth.user,
  };
};

const mapDispatchToProps = { set_product_details, remove_product };

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
