import SendEmail from "pages/admin/sendemail/SendEmail";
import Service from "pages/admin/service/Service";
import Variant from "pages/admin/variant/Variant";
import ViewReturn from "pages/admin/returns/ViewReturn";
import DisputesDetails from "pages/admin/disputesdetails/DisputesDetails";
import OrderDetails from "pages/admin/orderdetals/OrderDetails";
import Emails from "pages/admin/emails/Emails";
import Report from "pages/admin/report/Report";
import Profile from "pages/admin/profile/Profile";
import DashBoard from "pages/admin/dashboard/DashBoard";
import ProductCatalog from "pages/admin/productcatalog/ProductCatalog";
import ContentMetrix from "pages/admin/contentmetrix/ContentMetrix";
import ViewCustomers from "pages/admin/customers/ViewCustomers";
import ViewCustomer from "pages/admin/customers/ViewCustomer";
import Disputes from "pages/admin/disputes/Disputes";
import Returns from "pages/admin/returns/Returns";
import ServiceCatalog from "pages/admin/servicecatalog/ServiceCatalog";
import Orders from "pages/admin/order/Orders";
import Coupons from "pages/admin/coupons/Coupons";
import Shop from "pages/admin/shop/Shop";
import Categories from "pages/admin/categories/Categories";
import Brands from "pages/admin/brands/Brands";
import Media from "pages/admin/media/Media";
import Stores from "pages/admin/stores/Stores";
import ShippingZones from "pages/admin/shippingzones/ShippingZones";
import ShippingRules from "pages/admin/shippingrules/ShippingRules";
import Banners from "pages/admin/banners/Banners";
import CreateBanner from "pages/admin/banners/CreateBanner/CreateBanner";
import ViewBanner from "pages/admin/banners/ViewBanner/ViewBanner";
import ModalTesting from "pages/admin/ModalTesting";
import EditStore from "pages/admin/editstore/EditStore";
import EditShippingZone from "pages/admin/editshippingzone/EditShippingZone";
import EditBrand from "pages/admin/editbrand/EditBrand";
import EditMedia from "pages/admin/media/EditMedia";
import EditCategory from "pages/admin/editcategory/EditCategory";
import PlatformSetting from "pages/admin/platformsetting/PlatformSetting";
import Sections from "pages/admin/sections/Sections";
import EditSection from "pages/admin/editsections/EditSections";
import SectionItems from "pages/admin/sectionItems/SectionItems";
import AddProductTo from "pages/admin/sections/AddProductTo";
import AddServiceTo from "pages/admin/sections/AddServiceTo";
import ProductPage from "pages/admin/productCard/ProductPage";
import HomeOffersSections from "pages/admin/home_offers/Sections";
import CreateHomeOffersSections from "pages/admin/home_offers/CreateSection";
import EditHomeOffersSections from "pages/admin/home_offers/EditSection";
import HomeOffersSectionImages from "pages/admin/home_offers/SectionImages";
import OffersBanners from "pages/admin/offers_banners/OffersBanners";
import UpdateBanner from "pages/admin/banners/UpdateBanner/UpdateBanner";
import CreateOffer from "pages/admin/offers/CreateOffer";
import CreateOrder from "pages/admin/order/CreateOrder";
import ViewAllProducts from "pages/admin/inventory/products/ViewAllProducts";
import ViewCustomerOrders from "pages/admin/customers/ViewCustomerOrders";
import EditCustomer from "pages/admin/customers/EditCustomer";
import CreateSectionImage from "pages/admin/home_offers/CreateSectionImage";
import EditSectionImage from "pages/admin/home_offers/EditSectionImage";
import CreateOfferImage from "pages/admin/offers_banners/CreateOfferImage";
import EditOfferImage from "pages/admin/offers_banners/EditOfferImage";
import Tabs from "pages/admin/home_tabs/Tabs";
import CreateTab from "pages/admin/home_tabs/CreateTab";
import EditTab from "pages/admin/home_tabs/EditTab";
import TabProducts from "pages/admin/home_tabs/TabProducts";
import AddProductToTab from "pages/admin/home_tabs/AddProductToTab";
import Subadmins from "pages/admin/subadmins/Subadmins";
import CreateSubadmin from "pages/admin/subadmins/CreateSubadmin";
import SubAdminPermissions from "pages/admin/subadmins/SubAdminPermissions";
import { useMemo } from "react";
import TrakingLogs from "pages/admin/trakingLogs/TrakingLogs";
import NewOffer from "pages/admin/newoffers/NewOffer";
import NewOffersBanners from "pages/admin/new_offers_banners/NewOffersBanners";
import PushNotificationMessages from "pages/admin/pushnotificationmessages/PushNotificationMessages";
import CreatePushNotification from "pages/admin/pushnotificationmessages/CreatePushNotification";

export function useRoutes(user) {
  const routes = useMemo(
    () => [
      { path: "/", component: DashBoard, hidden: false },
      { path: "/dashboard", component: DashBoard, hidden: false },
      { path: "/service/:id?/:state?", component: Service, hidden: false },
      { path: "/variant", component: Variant, hidden: false },
      { path: "/return-detail/:id", component: ViewReturn, hidden: false },
      {
        path: "/dispute-detail/:id",
        component: DisputesDetails,
        hidden: false,
      },
      { path: "/order-details/:id", component: OrderDetails, hidden: false },
      { path: "/emails", component: Emails, hidden: !user.permissions?.emails?.index },
      { path: "/reports", component: Report, hidden: !user.permissions?.reports?.index },
      {
        path: "/product-catalog",
        component: ProductCatalog,
        hidden: !user.permissions?.products?.index,
      },
      {
        path: "/content-pq-metrix",
        component: ContentMetrix,
        hidden: !user.permissions?.products?.index,
      },
      { path: "/service-catalog", component: ServiceCatalog, hidden: !user.permissions?.services?.index },
      { path: "/disputes", component: Disputes, hidden: !user.permissions?.disputes?.index },
      { path: "/make-offer", component: NewOffer, hidden: !user.permissions?.["Offers Sections"]?.index },
      { path: "/offers/banners", component: OffersBanners, hidden: !user.permissions?.["Offers Banners"]?.index },
      { path: "/new-offer", component: CreateOffer, hidden: !user.permissions?.["Offers Sections"]?.index },
      { path: "/new-offers/banners", component: NewOffersBanners, hidden: !user.permissions?.["Offers Banners"]?.index },
      {
        path: "/offers/banner/create",
        component: CreateOfferImage,
        hidden: false,
      },
      {
        path: "/offers/banner/edit/:id",
        component: EditOfferImage,
        hidden: false,
      },
      { path: "/profile", component: Profile, hidden: !user.permissions?.users?.users_list },
      { path: "/customers", component: ViewCustomers, hidden: !user.permissions?.customers?.index },
      { path: "/push-notification", component: PushNotificationMessages, hidden: !user.permissions?.push_notification?.index },
      { path: "/push-notification/create", component: CreatePushNotification, hidden: !user.permissions?.push_notification?.create },
      { path: "/customer/:id", component: ViewCustomer, hidden: false },
      { path: "/customer/edit/:id", component: EditCustomer, hidden: false },
      {
        path: "/customer-orders/:id",
        component: ViewCustomerOrders,
        hidden: false,
      },
      { path: "/returns", component: Returns, hidden: !user.permissions?.refunds?.index },
      { path: "/orders", component: Orders, hidden: !user.permissions?.orders?.index },
      { path: "/new-order", component: CreateOrder, hidden: false },
      { path: "/coupons", component: Coupons, hidden: !user.permissions?.coupons?.index },
      { path: "/store", component: Shop, hidden: false },
      {
        path: "/inventory/products",
        component: ViewAllProducts,
        hidden: !user.permissions?.["Bulk Export"]?.index,
      },
      { path: "/categories", component: Categories, hidden: !user.permissions?.categories?.index },
      { path: "/brands", component: Brands, hidden: !user.permissions?.brands?.index },
      { path: "/media", component: Media, hidden: !user.permissions?.brands?.index },
      { path: "/stores", component: Stores, hidden: !user.permissions?.stores?.index },
      { path: "/zones", component: ShippingZones, hidden: !user.permissions?.["Shipping Zones"]?.index },
      { path: "/shipping-rules", component: ShippingRules, hidden:  !user.permissions?.["Shipment Rules"]?.show },
      { path: "/banners", component: Banners, hidden: !user.permissions?.banners?.index },
      { path: "/home/offers", component: HomeOffersSections, hidden: !user.permissions?.["Home Offers Sections"]?.index },
      {
        path: "/home/create-offers",
        component: CreateHomeOffersSections,
        hidden: false,
      },
      {
        path: "/home/offers/edit/:id",
        component: EditHomeOffersSections,
        hidden: false,
      },
      {
        path: "/home/offers/:id",
        component: HomeOffersSectionImages,
        hidden: false,
      },
      {
        path: "/home/offers_image/create/:id",
        component: CreateSectionImage,
        hidden: false,
      },
      {
        path: "/home/offers_image/edit/:id",
        component: EditSectionImage,
        hidden: false,
      },
      { path: "/home-tabs/:id", component: Tabs, hidden: !user.permissions?.["Home Products Tabs"]?.index },
      { path: "/home/create-tab", component: CreateTab, hidden: false },
      { path: "/home/tab/edit/:id", component: EditTab, hidden: false },
      { path: "/home/tab/:id", component: TabProducts, hidden: false },
      {
        path: "/traking-logs",
        component: TrakingLogs,
        hidden: !user.permissions?.["Traking Logs"]?.index,
      },
      {
        path: "/home/product/:id/add_to_tab",
        component: AddProductToTab,
        hidden: false,
      },
      { path: "/create-banner", component: CreateBanner, hidden: false },
      { path: "/view-banner/:id", component: ViewBanner, hidden: false },
      { path: "/update-banner/:id", component: UpdateBanner, hidden: false },
      { path: "/testing", component: ModalTesting, hidden: false },
      { path: "/edit-store/:id", component: EditStore, hidden: false },
      { path: "/admin/send-email", component: SendEmail, hidden: false },
      { path: "/edit-zone/:id", component: EditShippingZone, hidden: false },
      { path: "/create-zone", component: EditShippingZone, hidden: false },
      { path: "/edit-brand/:id", component: EditBrand, hidden: false },
      { path: "/edit-media/:id", component: EditMedia, hidden: false },
      { path: "/create-brand", component: EditBrand, hidden: false },
      { path: "/create-media", component: EditMedia, hidden: false },
      { path: "/edit-category/:id", component: EditCategory, hidden: false },
      { path: "/create-category", component: EditCategory, hidden: false },
      { path: "/platform-setting", component: PlatformSetting, hidden: !user.permissions?.settings?.index },
      { path: "/sections", component: Sections, hidden: !user.permissions?.sections?.index },
      { path: "/section/:id?/:state?", component: EditSection, hidden: false },
      { path: "/section-items/:id", component: SectionItems, hidden: false },
      { path: "/add-product/:id?", component: AddProductTo, hidden: false },
      { path: "/add-service/:id", component: AddServiceTo, hidden: false },
      { path: "/product/:id?/:state?", component: ProductPage, hidden: false },
      { path: "/subadmins/create", component: CreateSubadmin, hidden: !user?.roles?.includes('admin') },
      { path: "/subadmins", component: Subadmins, hidden: !user?.roles?.includes('admin') },
      {
        path: "/subadmins/permissions/:id",
        component: SubAdminPermissions,
        hidden: false,
      },
    ],
    [user]
  );

  return { routes };
}
