import React, { Fragment, useState, useEffect } from "react";

import "./sections.scss";
import Header from "components/header/Header";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { type } from "components/Dropzone/type";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";

const EditOfferImage = (props) => {
  const [banner_type, setBannerType] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [uploadingWebsiteImages, setUploadingWebsiteImages] = useState(false);
  const [websiteImage, setWebsiteImage] = useState("");
  const [deletedWebsiteImagesUrl, setDeletedWebsiteImagesUrl] = useState([]);

  const [uploadingMobileImages, setUploadingMobileImages] = useState(false);
  const [mobileImage, setMobileImage] = useState("");
  const [deletedMobileImagesUrl, setDeletedMobileImagesUrl] = useState([]);

  let message = "";

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Edit Offer Image";

    apiJson.offer.getOffersBanner(props.match.params.id)
      .then(response => {
        if (response.success) {
          setBannerType(response.data.banner_type);
          setTitle(response.data.title);
          setLink(response.data.link);
          setWebsiteImage(response.data.website_image);
          setMobileImage(response.data.mobile_image);
        }
        else {

        }
      });
  }, []);

  const handleFilesChange = (files) => {
    let fileUploadPromises = [];
    setUploadingWebsiteImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingWebsiteImages(false);
        setWebsiteImage(response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingWebsiteImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleMobileFilesChange = (files) => {
    let fileUploadPromises = [];
    setUploadingMobileImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingMobileImages(false);
        setMobileImage(response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingMobileImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleValidation = () => {
    var isValid = true;

    if (!banner_type) {
      isValid = false;
      alert("Banner type is required.");
    }

    if (!link) {
      isValid = false;
      alert("Link is required.");
    }

    if (!websiteImage) {
      isValid = false;
      alert("Website image is required.");
    }

    if (!mobileImage) {
      isValid = false;
      alert("Mobile image is required.");
    }

    return isValid;
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      apiJson.offer.editOffersBanners({
        banner: {
          banner_type: banner_type,
          title: title,
          link: link,
          website_image: websiteImage,
          mobile_image: mobileImage
        }
      }, props.match.params.id)
        .then(response => {
          if (response.success) {
            message = response.data.message;
            showSuccess();
          }
          else {
            message = response.message;
            showError();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Edit Offers Banner"
            subtext="Create, edit, and manage banners on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <form onSubmit={(e) => onFormSubmit(e)}>
              <div className="row">
                <div className="col-lg-12">
                  <label>Banner Type:</label>
                  <select name="banner_type" value={banner_type} onChange={(e) => setBannerType(e.target.value)} className="form-control">
                    <option value="">--- Select Banner Type --</option>
                    <option value="top_banner">Top Banner</option>
                    <option value="boxes_banner">Boxes Banner</option>
                  </select>
                </div>
                <br /><br /><br />
                <div className="col-lg-12">
                  <label>Title(Optional):</label>
                  <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" />
                </div>
                <br /><br /><br />
                <div className="col-lg-12">
                  <label>Link:</label>
                  <input type="text" value={link} onChange={(e) => setLink(e.target.value)} className="form-control" />
                </div>
                <br /><br /><br />
                <div className="col-lg-12">
                  <label>Website Image:</label>
                  {uploadingWebsiteImages ? (
                    <div className="d-flex justify-content-center align-item-center w-100">
                      <ClipLoader color="#000" loading size={20} />
                    </div>
                  ) : websiteImage ? (
                    <ImagesGrid
                      remove={(val, url) => {
                        setWebsiteImage("");
                        setDeletedWebsiteImagesUrl([
                          ...deletedWebsiteImagesUrl,
                          url,
                        ]);
                      }}
                      images={[websiteImage]}
                    />
                  ) : (
                    <div
                      className="english-error"
                      style={{
                        width: "fit-content",
                        color: "red",
                        padding: "0",
                      }}
                    >

                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-cenetr w-100">
                    <DropzoneElement
                      multiple={false}
                      placeholder="Click to select a file"
                      updateSelectedServiceFiles={(value) =>
                        handleFilesChange(value)
                      }
                      type={type.IMAGE}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Mobile Image:</label>
                  {uploadingMobileImages ? (
                    <div className="d-flex justify-content-center align-item-center w-100">
                      <ClipLoader color="#000" loading size={20} />
                    </div>
                  ) : mobileImage ? (
                    <ImagesGrid
                      remove={(val, url) => {
                        setMobileImage("");
                        setDeletedMobileImagesUrl([
                          ...deletedMobileImagesUrl,
                          url,
                        ]);
                      }}
                      images={[mobileImage]}
                    />
                  ) : (
                    <div
                      className="english-error"
                      style={{
                        width: "fit-content",
                        color: "red",
                        padding: "0",
                      }}
                    >

                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-cenetr w-100">
                    <DropzoneElement
                      multiple={false}
                      placeholder="Click to select a file"
                      updateSelectedServiceFiles={(value) =>
                        handleMobileFilesChange(value)
                      }
                      type={type.IMAGE}
                    />
                  </div>
                </div>
                <br />
                <div className="col-lg-12">
                  <input type="submit" value="SAVE" className="btn btn-primary" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditOfferImage;
