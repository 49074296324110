import React, { Fragment, useState } from "react";
import { FaShoppingBag, FaUserFriends } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { FaSignOutAlt } from "react-icons/fa";
import { MdSettings, MdMessage } from "react-icons/md";
import { TiArrowBack } from "react-icons/ti";
import { VscPackage } from "react-icons/vsc";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdOutlinePermMedia } from "react-icons/md";
import { GoTools } from "react-icons/go";
import { FiBox } from "react-icons/fi";
import { GrFlagFill } from "react-icons/gr";
import { Link, useHistory } from "react-router-dom";
import { set_user } from "../../redux/actions/AuthAction";
import RedeemIcon from "@material-ui/icons/Redeem";
import ALISUQLOGONew from "assets/images/ALISUQLOGONew.png";
import "./sidebar.scss";
import { connect } from "react-redux";
import { apiJson } from "api/Api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const Sidebar = (props) => {
  const [signingOut, setSigningOut] = useState(false);
  const { user } = props;
  const history = useHistory();
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const signout = () => {
    setSigningOut(true);
    apiJson.auth.signout().then((response) => {
      if (response.success) {
        document.cookie =
          "authHeaders=undefined; expires= Thu, 21 Aug 2014 20:00:00 UTC";
        localStorage.removeItem("admin_access_token");
        localStorage.removeItem("admin_client");
        localStorage.removeItem("admin_uid");
        props.set_user({ isSignedIn: false, user: {} });
        history.replace("/login");
      } else {
        setSigningOut(false);
        if (
          parseInt(response.error?.response?.status) === 404 ||
          parseInt(response.error?.response?.status) === 401
        ) {
          document.cookie =
            "authHeaders=undefined; expires= Thu, 21 Aug 2014 20:00:00 UTC";
          localStorage.removeItem("admin_access_token");
          localStorage.removeItem("admin_client");
          localStorage.removeItem("admin_uid");
          props.set_user({ isSignedIn: false, user: {} });
          history.replace("/login");
        } else {
          message =
            props.language === "ar" ? "هناك خطأ ما" : "something went wrong";
          showError();
        }
      }
    });
  };

  const links = [
    {
      to: "/content-pq-metrix",
      icon: <MdOutlinePermMedia />,
      text: "Content PQ Metrix",
      className: "returns-row",
      // hidden: user.permissions?.sections?.index,
      hidden: true,
      new_feature: true
    },
    {
      to: "/media",
      icon: <MdOutlinePermMedia />,
      text: "Media",
      className: "returns-row",
      // hidden: user.permissions?.sections?.index,
      hidden: true,
      new_feature: true
    },
    {
      to: "/sections",
      icon: <FaShoppingBag />,
      text: "Home Sections",
      className: "returns-row",
      hidden: user.permissions?.sections?.index,
    },
    {
      to: "/home-tabs/1",
      icon: <FaShoppingBag />,
      text: "Home Tabs",
      className: "returns-row",
      hidden: user.permissions?.["Home Products Tabs"]?.index,
    },
    {
      to: "/home/offers",
      icon: <FaShoppingBag />,
      text: "Home Offers",
      className: "returns-row",
      hidden: user.permissions?.["Home Offers Sections"]?.index,
    },
    {
      to: "/stores",
      icon: <FaShoppingBag />,
      text: "Stores",
      className: "store-row",
      hidden: user.permissions?.stores?.index,
    },
    {
      to: "/inventory/products",
      icon: <VscPackage />,
      text: "Inventory",
      className: "orders-row",
      hidden: user.permissions?.["Bulk Export"]?.index,
    },
    {
      to: "/orders",
      icon: <VscPackage />,
      text: "Orders",
      className: "orders-row",
      hidden: user.permissions?.orders?.index,
    },
    {
      to: "/coupons",
      icon: <RedeemIcon />,
      text: "Coupons",
      className: "returns-row",
      hidden: user.permissions?.coupons?.index,
    },
    {
      to: "/returns",
      icon: <TiArrowBack />,
      text: "Returns",
      className: "returns-row",
      hidden: user.permissions?.refunds?.index,
    },
    {
      to: "/disputes",
      icon: <RiErrorWarningLine />,
      text: "Disputes",
      className: "disputes-row",
      hidden: user.permissions?.disputes?.index,
    },
    // {
    //   to: "/traking-logs",
    //   icon: <RiErrorWarningLine />,
    //   text: "Traking Logs",
    //   className: "disputes-row",
    //   hidden: user.permissions?.["Traking Logs"]?.index,
    // },
    {
      to: "/make-offer",
      icon: <RiErrorWarningLine />,
      text: "Create Offer 1",
      className: "disputes-row",
      hidden: user.permissions?.["Offers Sections"]?.index,
    },
    {
      to: "/new-offer",
      icon: <RiErrorWarningLine />,
      text: "Create Offer 2",
      className: "disputes-row",
      hidden: user.permissions?.["Offers Sections"]?.index,
    },
    {
      to: "/profile",
      icon: <FaUserFriends />,
      text: "Users",
      className: "users-row",
      hidden: user.permissions?.users?.users_list,
    },
    // {
    //   to: "/subadmins",
    //   icon: <FaUserFriends />,
    //   text: "Sub Admins",
    //   className: "users-row",
    //   hidden: user?.roles?.includes('admin'),
    // },
    {
      to: "/customers",
      icon: <FaUserFriends />,
      text: "Customers",
      className: "users-row",
      hidden: user.permissions?.customers?.index,
    },
    {
      to: "/push-notification",
      icon: <MdMessage />,
      text: "Push Notification Messages",
      className: "push-notification-row",
      hidden: user.permissions?.push_notification?.index,
    },
    {
      to: "/categories",
      icon: <FaShoppingBag />,
      text: "Categories",
      className: "category-row",
      hidden: user.permissions?.categories?.index,
    },
    {
      to: "/brands",
      icon: <FaShoppingBag />,
      text: "Brands",
      className: "category-row",
      hidden: user.permissions?.brands?.index,
    },
    {
      to: "/banners",
      icon: <GrFlagFill />,
      text: "Banners",
      className: "banner-row",
      hidden: user.permissions?.banners?.index,
    },
    {
      to: "/shipping-rules",
      icon: <FaShoppingBag />,
      text: "Shipping Rules",
      className: "zone-row",
      hidden: user.permissions?.["Shipment Rules"]?.show,
    },
    {
      to: "/reports",
      icon: <FiFileText />,
      text: "Reports",
      className: "zone-row",
      hidden: user.permissions?.reports?.index,
    },
    {
      to: "/platform-setting",
      icon: <MdSettings />,
      text: "Settings",
      className: "settings-row",
      hidden: user.permissions?.settings?.index,
    },
  ];

  return (
    <Fragment>
      <div className="admin-sidebar">
        <div className="sidebar-wrapper d-flex flex-column">
          <Link to="/dashboard">
            <div className="sidebar-row logo-row d-flex align-items-center">
              <div className="image-container">
                <img src={ALISUQLOGONew} alt="logo" />
              </div>
            </div>
          </Link>
          {(user?.permissions?.products?.index ||
            user?.permissions?.products?.create) && (
              <div className="accordion" id="dropdownaccordion">
                <div className="accordion-item">
                  <div className="accordion-header" id="products">
                    <div
                      className="accordion-button sidebar-row pointer"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseProduct"
                      aria-expanded="false"
                      aria-controls="collapseProduct"
                    >
                      <div className="icon-with-text d-flex align-items-center">
                        <div className="text-icon">
                          <FiBox />
                        </div>
                        <div className="text">Products</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseProduct"
                    className="accordion-collapse collapse show"
                    aria-labelledby="products"
                    data-bs-parent="#dropdownaccordion"
                  >
                    <div className="accordion-body d-flex flex-column">
                      {user?.permissions?.products?.index && (
                        <Link to="/product-catalog" className="all-items">
                          All Products
                        </Link>
                      )}
                      {user?.permissions?.products?.create && (
                        <Link to="/product/create" className="create-item">
                          Create New Product
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {user?.permissions?.services?.index && (
            <div className="accordion" id="dropdownaccordion">
              <div className="accordion-item">
                <div className="accordion-header" id="services">
                  <div
                    className="accordion-button sidebar-row pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseService"
                    aria-expanded="true"
                    aria-controls="collapseService"
                  >
                    <div className="icon-with-text d-flex align-items-center">
                      <div className="text-icon">
                        <GoTools />
                      </div>
                      <div className="text">Services</div>
                    </div>
                  </div>
                </div>
                <div
                  id="collapseService"
                  className="accordion-collapse collapse"
                  aria-labelledby="services"
                  data-bs-parent="#dropdownaccordion"
                >
                  <div className="accordion-body d-flex flex-column">
                    {user?.permissions?.services?.index && (
                      <Link to="/service-catalog" className="all-items">
                        All Services
                      </Link>
                    )}
                    <Link to="/service" className="create-item">
                      Create New Service
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {links.map(
            ({ to, icon, text, className, hidden, new_feature }, index) =>
              hidden && (
                <Link
                  key={index}
                  to={to}
                  className={`sidebar-row ${className}`}
                >
                  <div className="icon-with-text d-flex align-items-center">
                    <div className="text-icon">{icon}</div>
                    <div className="text">{text}</div>
                    {
                      new_feature && (
                        <div style={{
                          marginLeft: "auto",
                          background: "#e00",
                          fontSize: "12px",
                          padding: "0px 10px",
                          borderRadius: "3px",
                          color: "white"
                        }}>NEW</div>
                      )
                    }
                  </div>
                </Link>
              )
          )}
          {(user?.roles?.includes('admin') ||
            user.permissions?.["Traking Logs"]?.index) && (
              <div className="accordion" id="dropdownaccordion">
                <div className="accordion-item">
                  <div className="accordion-header" id="products">
                    <div
                      className="accordion-button sidebar-row pointer"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseProduct"
                      aria-expanded="false"
                      aria-controls="collapseProduct"
                    >
                      <div className="icon-with-text d-flex align-items-center">
                        <div className="text-icon">
                          <FiBox />
                        </div>
                        <div className="text">Roles & Permisions</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseProduct"
                    className="accordion-collapse collapse show"
                    aria-labelledby="products"
                    data-bs-parent="#dropdownaccordion"
                  >
                    <div className="accordion-body d-flex flex-column">
                      {user?.roles?.includes('admin') && (
                        <Link to="/subadmins" className="all-items">
                          Sub admins
                        </Link>
                      )}
                      {user.permissions?.["Traking Logs"]?.index && (
                        <Link to="/traking-logs" className="create-item">
                          Logs
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div
            className="sidebar-row settings-row"
            style={{ cursor: "pointer" }}
            onClick={!signingOut ? signout : undefined}
          >
            <div className="icon-with-text d-flex align-items-center">
              <div className="text-icon">
                <FaSignOutAlt />
              </div>
              <div className="text w-100 d-flex flex-row align items-center ">
                <div>Sign Out</div>
                {signingOut && (
                  <div style={{ marginLeft: "10px" }}>
                    {" "}
                    <ClipLoader color="#fff" size="13px" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapDispatchToProps = { set_user };

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
