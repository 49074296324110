import React, { Fragment, useState } from "react";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import DropzoneElement from "../../../components/Dropzone/BulkImportDropzoneElement";
import { type } from "../../../components/Dropzone/type";
import { FileUploadPromise } from "../../../utils/aws_s3/aws_s3";
import ClipLoader from "react-spinners/ClipLoader";
import "./productbulkimportmodal.scss";
import { connect } from "react-redux";
import Select from "react-select";
import { isEmpty } from "lodash"

const ProductBulkImportModal = (props) => {
  const [fileName, setFileName] = useState("Select CSV");
  const [uploadKey, setUploadKey] = useState(null);
  const [values, setValues] = useState({
    image: "",
    service_type: ""
  });
  const [loading, setLoading] = useState(false);
  const productCsvSample = process.env.REACT_APP_PRODUCTS_BULK_IMPORT_SAMPLE;
  const serviceCsvSample = process.env.REACT_APP_SERVICES_BULK_IMPORT_SAMPLE;
  const { user } = props
  const [error, setError] = useState({})
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleFilesChange = (e) => {
    FileUploadPromise(e[0])
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setFileName(e[0].name);
          setUploadKey(response?.key);
          setValues({
            ...values,
            image: response?.location,
          });
          setError((prev) => ({ ...prev, image: "" }));
        }
      })
      .catch((error) => {
        message = error
          ? error !== ""
            ? error
            : "something went wrong"
          : "something went wrong";
        showError();
      });
  };

  const checkFields = () => {
    if (!values.image) {
      setError((prev) => ({ ...prev, image: "Please import CSV file" }));
    }
    if (!values.service_type && props.dataType === "services") {
      setError((prev) => ({
        ...prev,
        service_type: "Please select service type",
      }));
    } 
    if(values.image && values.service_type && props.dataType === "services") {
      setError({})
      return true;
    }

    if(values.image && props.dataType === "products") {
      setError({})
      return true
    }

    return false;
  };

  const importProducts = () => {
    if (!checkFields()) return;
    setLoading(true);
    apiJson.imports
      .bulkImport({
        bulk_import: {
          file_name: fileName,
          file_url: values.image,
          key: uploadKey,
          data_type: props.dataType,
          import_type: "create",
          store_id: 1,
          ...(props.dataType === "services" && {service_type: values.service_type})
        },
      })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          props.createClick();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          props.createClick();
        } else {
          setLoading(false);
          props.createClick();
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const updateImportProducts = () => {
    if (!checkFields()) return;
    setLoading(true);

    apiJson.imports
      .bulkImport({
        bulk_import: {
          file_name: fileName,
          file_url: values.image,
          key: uploadKey,
          data_type: props.dataType,
          import_type: "update",
          store_id: 1,
          ...(props.dataType === "services" && {service_type: values.service_type})
        },
      })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          props.createClick();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          props.createClick();
        } else {
          setLoading(false);
          props.createClick();
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const serviceTypes = [
    { value: "hourly", label: "Hourly" },
    { value: "one_time", label: "One Time" },
  ];

  return (
    <Fragment>
      <div className="product-bulk-modal">
        <div className="product-bulk-header w-100">{props.heading}</div>
        <div className="boday-row w-100">
          <div className="input-group-row w-100 d-flex flex-column">
            <div className="input-row d-flex align-items-center w-100">
              <div className="label">Upload File</div>
              <div className="value">
                <DropzoneElement
                  multiple={false}
                  updateSelectedServiceFiles={handleFilesChange}
                  value={fileName}
                  type={type.CSV}
                />
                <div className="field_error" style={{ marginLeft: '45px' }}>{error.image}</div>
              </div>
            </div>

            {props.dataType === "services" && (
              <div className="input-row d-flex align-items-center w-100">
                <div className="label">Service Type</div>
                <div className="value" style={{ width: "50%", margin: "auto" }}>
                  <Select
                    name="service_type"
                    onChange={(val) => {
                      setError((prev) => ({
                        ...prev,
                        service_type: "",
                      }));
                      setValues((prev) => ({
                        ...prev,
                        service_type: val.value,
                      }));
                    }}
                    options={serviceTypes}
                    classNamePrefix="select"
                    placeholder="Select Service Type"
                  />
                  <div className="field_error">{error.service_type}</div>
                </div>
              </div>
            )}

            <div className="input-row d-flex align-items-start w-100 download-sample-area">
              <a
                href={
                  props.dataType === "products"
                    ? productCsvSample
                    : serviceCsvSample
                }
              >
                Download Sample CSV
              </a>
            </div>
          </div>
          <div className="buttons-row w-100 d-flex align-items-center">
            <button
              className="cancel-button w-100"
              type="button"
              onClick={props.cancleClick}
            >
              Cancel
            </button>
            {(props.dataType !== "services" && user.permissions.products.create_products || props.dataType === "services" )  && (
              <button
                className="create-button w-100"
                type="button"
                onClick={importProducts}
                disabled={loading}
              >
                {loading ? (
                  <ClipLoader color="#000" loading size={20} />
                ) : (
                  "Import"
                )}
              </button>
            )}
            {props.dataType == "products" &&
              user.permissions.products.update_products && (
                <button
                  className="update-button w-100"
                  type="button"
                  onClick={updateImportProducts}
                  disabled={loading}
                >
                  {loading ? (
                    <ClipLoader color="#000" loading size={20} />
                  ) : (
                    "Update Products"
                  )}
                </button>
              )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return { storeId: state?.vendorStore?.vendorStore?.id, user: state.auth.user };
};

export default connect(mapStateToProps)(ProductBulkImportModal);
