import React, { Fragment, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from "moment";
import "./orderheader.scss";
import { set_orders } from "redux/actions/OrdersAction";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiJson } from "api/Api";
import ClipLoader from "react-spinners/ClipLoader";

const filters = [
  [
    { value: "pending", label: "Pending" },
    { value: "processing", label: "Processing" },
    { value: "rejected", label: "Rejected" },
    { value: "customer_cancellation", label: "Customer Cancellation" },
  ],
  [
    { value: "rejected", label: "Rejected" },
    { value: "end", label: "end" },
  ],
  [
    { value: "processing", label: "Processing" },
    { value: "rejected", label: "Rejected" },
    { value: "customer_cancellation", label: "Customer Cancellation" },
    { value: "shipped", label: "Shipped" },
  ],
  [
    { value: "shipped", label: "Shipped" },
    { value: "delivered", label: "Delivered" },
  ],
  [
    { value: "processing", label: "Processing" },
    { value: "ready_for_collection", label: "Ready For Collection" },
    { value: "rejected", label: "Rejected" },
    { value: "customer_cancellation", label: "Customer Cancellation" },
  ],
  [
    { value: "ready_for_collection", label: "Ready For Collection" },
    { value: "collected", label: "Collected" },
  ],
];
const OrderHeader = (props) => {
  const { order, fetchOrder } = props;
  const [adminRejectionPopupIsVisible, setAdminRejectionPopupIsVisible] = useState(false);
  const [adminRejectionReason, setAdminRejectionReason] = useState("");
  const [customerCancellationPopupIsVisible, setCustomerCancellationPopupIsVisible] = useState(false);
  const [customerCancellationReason, setCustomerCancellationReason] = useState(false);
  const [filterOption, setFilterOption] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [noFilter, setNoFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completingTransaction, setCompletingTransaction] = useState(false);
  const [returningOrder, setReturningOrder] = useState(false);
  const [returnPaymentModalIsVisible, setReturnPaymentModalIsVisible] = useState(false);
  const [transaction, setTransaction] = useState(order?.dues_paid);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    if (order.status !== filterValue.value) {
      let body = {
        order: {
          status: filterValue.value,
          previous_status: order.status
        },
      };

      if (filterValue.value == "rejected" || filterValue.value == "customer_cancellation") {
        if (filterValue.value == "rejected") {
          body.order.rejection_reason = adminRejectionReason;
        }
        else if (filterValue.value == "customer_cancellation") {
          body.order.customer_cancellation_reason = customerCancellationReason;
        }
      }
      apiJson.orders
        .updateOrder(order.id, body)
        .then((response) => {
          if (response.success) {
            props.set_orders({ orderDetails: response.data });
            setLoading(false);
            message = "Status update Successfully";
            showSuccess();
          } else if (!response.status) {
            setLoading(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setLoading(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Change status then update";
      showError();
      setLoading(false);
    }
  };

  useEffect(() => {
    setNoFilter(false);

    if (order.status === "pending") {
      setFilterOption(filters[0]);
    } else if (order.status === "rejected" || order.status === "customer_cancellation") {
      setFilterOption(filters[1]);
      setNoFilter(true);
    } else if (order.status === "processing") {
      if (order.is_delivery) {
        setFilterOption(filters[2]);
      }
      else {
        setFilterOption(filters[4]);
      }
    } else if (order.status === "shipped") {
      setFilterOption(filters[3]);
      setNoFilter(false);
    } else if (order.status === "ready_for_collection") {
      setFilterOption(filters[5]);
      setNoFilter(false);
    } else if (
      order.status === "delivered" ||
      order.status === "collected" ||
      order.status === "partially_returned" ||
      order.status === "fully_returned"
    ) {
      setNoFilter(true);
    }

    setFilterValue({
      value: order.status,
      label: order.status,
    });
  }, [order]);

  const handleSelect = (e) => {
    setFilterValue(e);
  };

  const handleUpdateClicked = () => {
    if (filterValue?.value == "rejected") {
      setAdminRejectionPopupIsVisible(true);
    }
    else if (filterValue?.value == "customer_cancellation") {
      setCustomerCancellationPopupIsVisible(true);
    }
    else {
      handleUpdate();
    }
  };

  const closeAdminRejectionPopup = () => {
    setAdminRejectionPopupIsVisible(false);
  }

  const closeCustomerCancellationPopup = () => {
    setCustomerCancellationPopupIsVisible(false);
  }

  const onCustomerCancellationReasonChanged = (e) => {
    setCustomerCancellationReason(e.target.value);
  }

  const onAdminRejectionReasonChanged = (e) => {
    setAdminRejectionReason(e.target.value);
  }

  const onUpdateClicked = () => {
    setAdminRejectionPopupIsVisible(false);
    setCustomerCancellationPopupIsVisible(false);
    handleUpdate();
  }

  const completeTransaction = () => {
    setCompletingTransaction(true);
    apiJson.orders.completeTransaction(order.id).then((response) => {
      if (response.success) {
        setCompletingTransaction(false);
        setTransaction(true);
      } else if (!response.status) {
        setCompletingTransaction(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setCompletingTransaction(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const returnOrder = () => {
    setReturningOrder(true);
    apiJson.orders
      .updateOrder(order.id, {
        order: {
          status: "fully_returned",
          previous_status: order.status
        },
      })
      .then((response) => {
        if (response.success) {
          setReturningOrder(false);
          fetchOrder();
          if (order.payment_methods.payment_card) {
            setReturnPaymentModalIsVisible(true);
          }
          message = "Status updated Successfully";
          showSuccess();
        } else if (!response.status) {
          setReturningOrder(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setReturningOrder(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const handleCloseMsgPopup = () => {
    setReturnPaymentModalIsVisible(false);
  }

  return (
    <div className="order-header-desktop">
      <div className="wrapper">
        {order && (
          <>
            <Row className="header g-0 pb-3">
              <div className="order-number">{order.id}</div>
              <div className="gray-text placed-on">
                Placed on {moment(new Date(order.created_at)).format("LL")}
              </div>
              <div className="gray-text placed-on" style={{ marginLeft: "auto" }}>
                <p style={{ margin: "0px" }}>
                  <span className="order-number">Status:&nbsp;</span>
                  {order.status?.replaceAll("_", " ")?.toUpperCase()}
                </p>
                {
                  (order?.status == "rejected" || order?.status == "customer_cancellation") && (
                    <p style={{ margin: "0px" }}>
                      <span className="order-number">Reason:&nbsp;</span>
                      {order?.status == "customer_cancellation" ? order?.customer_cancellation_reason?.replaceAll("_", " ") : order?.rejection_reason?.replaceAll("_", " ")}
                    </p>
                  )
                }
              </div>
            </Row>

            <Row className="detail-col g-0 pt-2">
              <Col md={12} className="address-col py-1">
                <div className="title">ORDER TYPE</div>
                <div className="name my-2">
                  {
                    order.is_delivery == 0 ?
                      "Pickup"
                      :
                      "Home Delivery"
                  }
                </div>
              </Col>
              <Col md={6} className="address-col py-1">
                {
                  order?.is_delivery == 0 ?
                    <>
                      <div className="title">PICKUP ADDRESS</div>
                      <div className="name py-2">{order.pickup_address?.store_address}</div>
                    </>
                    :
                    <>
                      <div className="title">SHIPPING ADDRESS</div>
                      <div className="name py-2">{order.address?.first_name}</div>
                      <div className="address px-1">{order.address?.address1}</div>
                      <div className="address px-1">{order.address?.address2}</div>
                    </>
                }
              </Col>

              <Col md={6} className="phone-col pl-2">
                <div className="title">CUSTOMER NAME</div>
                <div className="mobile-number">
                  {
                    order.is_delivery ?
                      order?.address?.first_name + " " + order?.address?.last_name
                      :
                      order.pickup_customer_name
                  }
                </div>
                <div className="title">MOBILE NUMBER</div>
                {
                  order?.is_delivery ?
                    <div className="phone-verification-col d-flex align-items-center">
                      <div className="mobile-number">
                        {order.address?.contact_number}
                      </div>
                      <div className="verified-mark d-flex align-items-center">
                        {order.address?.contact_number_verified === true ? (
                          <Fragment>
                            <span className="green-checkbox mr-1">
                              <AiFillCheckCircle />
                            </span>
                            <span className="gray-text">Verified</span>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span className="green-checkbox mr-1">
                              <BiError />
                            </span>
                            <span className="gray-text mr-0 me-0 text-nowrap mt-1">
                              Not Verified
                            </span>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    :
                    <div className="mobile-number">
                      {order.pickup_customer_contact}
                    </div>
                }

                {transaction && order.payment_methods.cash_on_delivery && (
                  <>
                    <div className=" mt-2 fs-size title">
                      Cash On Delivery Transcation
                    </div>
                    <div className="phone-verification-col d-flex align-items-center">
                      <div className="mobile-number">Completed</div>
                    </div>
                  </>
                )}
                <div className="status-col d-flex flex-column justify-content-end align-items-end">
                  <div className="status d-flex justify-content-center align-items-center">
                    <span className="text capitalize">
                      { }
                      {order.status && order.status.includes("_")
                        ? order.status.replaceAll("_", " ")
                        : order.status}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={12} className="mt-3 d-flex justify-content-end">
                {!noFilter && (
                  <div className="update-status-col d-flex align-items-center">
                    <div className="select" style={{ width: "150px" }}>
                      <Select
                        value={filterValue}
                        onChange={handleSelect}
                        options={filterOption}
                        className="category-select w-100"
                        classNamePrefix="select"
                        isSearchable={false}
                        placeholder="Select Status"
                      />
                    </div>
                    <button
                      className="update-button"
                      type="button"
                      onClick={handleUpdateClicked}
                    >
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <ClipLoader color="#fff" loading size={24} />
                        </div>
                      ) : (
                        "Update Status"
                      )}
                    </button>
                  </div>
                )}
                {order.status === "delivered" &&
                  (
                    <div className="update-status-col d-flex align-items-center">
                      <button
                        className="returned-button fs-size"
                        type="button"
                        onClick={returnOrder}
                      >
                        {returningOrder ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ClipLoader color="#fff" loading size={25} />
                          </div>
                        ) : (
                          "Change Status To Returned"
                        )}
                      </button>
                      {
                        order.payment_methods.cash_on_delivery && !transaction ?
                          <button
                            className="update-button fs-size"
                            type="button"
                            onClick={completeTransaction}
                          >
                            {completingTransaction ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <ClipLoader color="#fff" loading size={25} />
                              </div>
                            ) : (
                              "Complete COD transaction"
                            )}
                          </button>
                          :
                          <></>
                      }
                    </div>
                  )}
              </Col>
            </Row>
          </>
        )}
        <Modal
          show={adminRejectionPopupIsVisible}
          onHide={closeAdminRejectionPopup}
          className="order-cancellation-modal"
        >
          <Modal.Header>
            <Modal.Title className="title">Order Rejection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Select Reason:</label><br />
              <div className="options">
                <label>
                  <input
                    type="radio"
                    value={"out_of_stock"}
                    checked={adminRejectionReason == "out_of_stock" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;Out Of Stock
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"general_adjustment"}
                    checked={adminRejectionReason == "general_adjustment" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;General Adjustment
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"shipment_address_undeliverable"}
                    checked={adminRejectionReason == "shipment_address_undeliverable" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;Shipment Address Undeliverable
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"customer_exchange"}
                    checked={adminRejectionReason == "customer_exchange" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;Customer Exchange
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"pricing_error"}
                    checked={adminRejectionReason == "pricing_error" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;Pricing Error
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"customer_changed_mind"}
                    checked={adminRejectionReason == "customer_changed_mind" ? true : false}
                    onChange={onAdminRejectionReasonChanged}
                  />&nbsp;Customer Changed Mind
                </label><br />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={onUpdateClicked}
              className="btn"
            >
              UPDATE
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={customerCancellationPopupIsVisible}
          onHide={closeCustomerCancellationPopup}
          className="order-cancellation-modal"
        >
          <Modal.Header>
            <Modal.Title className="title">Customer Cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Select Reason:</label><br />
              <div className="options">
                <label>
                  <input
                    type="radio"
                    value={"changed_mind"}
                    checked={customerCancellationReason == "changed_mind" ? true : false}
                    onChange={onCustomerCancellationReasonChanged}
                  />&nbsp;Changed Mind
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"replaced_order"}
                    checked={customerCancellationReason == "replaced_order" ? true : false}
                    onChange={onCustomerCancellationReasonChanged}
                  />&nbsp;Replaced Order
                </label><br />
                <label>
                  <input
                    type="radio"
                    value={"accidently_placed"}
                    checked={customerCancellationReason == "accidently_placed" ? true : false}
                    onChange={onCustomerCancellationReasonChanged}
                  />&nbsp;Accidently Placed
                </label><br />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={onUpdateClicked}
              className="btn"
            >
              UPDATE
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={returnPaymentModalIsVisible}
          onHide={handleCloseMsgPopup}
        >
          <Modal.Header>
            <Modal.Title style={{ color: "red" }}>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>This order is paid by card so you have to return the amount from Stripe.</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleCloseMsgPopup}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProsps = (state) => {
  return { orderDetails: state.order.orderDetails };
};

const mapDispatchToProps = { set_orders };

export default connect(mapStateToProsps, mapDispatchToProps)(OrderHeader);
