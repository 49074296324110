import { Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import AdminLayoutRenderer from "components/layout/AdminLayoutRenderer";
import { ToastContainer } from "react-toastify";
import LoginPage from "pages/loginpage/LoginPage";

import { domain } from "api/Constants";
import { connect } from "react-redux";
import { set_user } from "redux/actions/AuthAction";
import $ from "jquery";
import history from "./history";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import "./app.scss";
import axios from "axios";
import { set_redirect_home } from "redux/actions/NotFoundAction";
import NotFound from "components/NotFound/NotFound";
import { useRoutes } from "components/layout/useRoutes";

require("dotenv").config();
function App(props) {
  const { routes } = useRoutes(props.user);

  $.auth.configure({
    apiUrl: domain,

    signOutPath: "/auth/sign_out",

    tokenValidationPath: "/auth/validate_token",
    authProviderPaths: {
      facebook: "/auth/facebook",
      google: "/auth/google_oauth2",
    },
  });

  axios.interceptors.response.use(
    (res) => {
      if (res.status === 404) {
        props?.set_redirect_home(true);
      }
      return res;
    },
    (err) => {
      if (parseInt(err?.response?.status) === 404) {
        props?.set_redirect_home(true);
      }
      return Promise.reject(err);
    }
  );

  const showError = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const currentRoute = window.location.pathname;
  
  useEffect(() => {
    if (!isEmpty(props.user) && routes.find((route) => route.path === currentRoute)?.hidden) {
      showError("User has no role of admin or specific permissions")

      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  }, [currentRoute, props.user]);

  return (
    <Router history={history}>
      <div className="App">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/not-found" component={NotFound} />
          <Route path="/" component={AdminLayoutRenderer} />
        </Switch>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
      </div>

      {props.redirect && <Redirect to="/not-found" />}
    </Router>
  );
}

const mapStateToProps = (state) => {
  return { redirect: state.notFound.redirect, user: state.auth.user };
};

const mapDispatchToProps = {
  set_user,
  set_redirect_home,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
