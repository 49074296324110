import { type } from "../../types/types";

export default function reducer(
  state = { banners: [], bannerDetails: {}, pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_BANNERS:
      return { ...state, ...action.banners };
    case type.SET_BANNER_DETAILS:
      return { ...state, ...action.bannerDetails };
    default:
      return { ...state };
  }
}
