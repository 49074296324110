import { Fragment, useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { type } from "components/Dropzone/type";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import * as Yup from "yup";
import Switch from "react-switch";
import "./editstore.scss";
import Header from "components/header/Header";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_stores } from "redux/actions/StoresAction";
import { set_single_store } from "redux/actions/SingleStoreAction";
import Resizer from "react-image-file-resizer";
import { Link } from '@material-ui/core';

const editStoreSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  contact: Yup.string().required("Required"),
  commission: Yup.number().min(0, "Negative not allowed").required("Required"),
  can_reject_order: Yup.string().required("Required"),
  verified: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  can_manage_product: Yup.string().required("Required"),
  name_ar: Yup.string(),
  description_en: Yup.string(),
  description_ar: Yup.string(),
  bank_account_iban: Yup.string(),
  bank_account_no: Yup.string(),
  bank_account_title: Yup.string(),
  bank_name: Yup.string(),
  currency: Yup.string(),
  business_name: Yup.string(),
  business_type: Yup.string(),
  business_website: Yup.string(),
  businsess_license_file: Yup.string(),
  license_no: Yup.string(),
  no_of_sku: Yup.number(),
});

const EditStore = (props) => {
  const params = useParams();
  const location = useLocation();
  const [pageState, setPageState] = useState(location.state.name);
  const [isEditClick, setisEditClick] = useState(false);
  const [store, setStore] = useState(undefined);
  const [initialVal, setInitialVal] = useState({});
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  let message = "";
  const cls = isEditClick ? "d-none" : "";
  const heading = isEditClick ? "Create/Edit a Store" : "View Store";
  const [redirectBack, setRedirectBack] = useState(false);
  const [uploadingSingleImage, setUploadingSingleImage] = useState(false);

  const handleEditClick = (values) => {
    setisEditClick(true);
    setPageState("edit");
    setInitialVal(values);
  };

  const handleSaveClick = (values) => {
    setisEditClick(true);
    setPageState("edit");
    values.can_reject_order = initialVal.can_reject_order;
    values.status = initialVal.status;
    values.can_manage_product = initialVal.can_manage_product;
    Update(values);
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    document.title = "Store";
    apiJson.stores.getSingleStore(params.id).then((response) => {
      if (response.success) {
        props.set_single_store({ store: response.data });
        setInitialVal({
          name: response.data.name_en,
          country: response.data.country_name_en,
          email: response.data.contact_email,
          contact: response.data.vendor_contact,
          commission: response.data.commission * 100,
          can_reject_order: response.data.can_reject_order,
          verified: response.data.verified,
          status: response.data.active,
          can_manage_product: response.data.can_manage_product,
          name_ar: response.data.name_ar,
          description_en: response.data.description_en,
          description_ar: response.data.description_ar,
          bank_account_iban: response.data.bank_account_iban,
          bank_account_no: response.data.bank_account_no,
          bank_account_title: response.data.bank_account_title,
          bank_name: response.data.bank_name,
          currency: response.data.currency,
          business_name: response.data.business_name,
          business_type: response.data.business_type,
          business_website: response.data.business_website,
          logo_image: response.data.logo_image,
          license_no: response.data.license_no,
          no_of_sku: response.data.no_of_sku,
        });
        setStore(response.data);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSingleFileChange = async (files) => {
    let fileUploadPromises = [];
    setUploadingSingleImage(true);
    const resizeImageDesktop = await resizeFileForDesktop(files[0]);
    const resizeImageMob = await resizeFileForMob(files[0]);

    files.push(resizeImageMob);
    files.push(resizeImageDesktop);
    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });
    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingSingleImage(false);
        var values = Object.assign({}, initialVal);
        values.logo_image = response[0].location;
        setInitialVal(values);
      })
      .catch((err) => {
        setUploadingSingleImage(false);
      });
  };

  const resizeFileForDesktop = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const resizeFileForMob = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const Update = (values) => {
    setLoading(true);
    apiJson.stores
      .updateSingleStore(params.id, {
        store: {
          ...store,
          contact_email: values.email,
          vendor_contact: values.contact?.toString(),
          commission: String(values.commission / 100),
          can_reject_order: values.can_reject_order,
          status: values.active,
          can_manage_product: values.can_manage_product,
          active: values.status,
          logo_image: values.logo_image
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Store updated";
          setLoading(false);
          setRedirect(true);
          props.set_single_store(response.data);
          setStore(response.data);
          showSuccess();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
      }
    });
  };

  return (
    <Fragment>
      <div className="right-content store-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="View, edit, and manage the Stores on your site."
          />
        </div>

        {store ? (
          <div className="main-row store-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={initialVal}
                validationSchema={editStoreSchema}
                enableReinitialize
              >
                {({ errors, touched, isValid, values }) => (
                  <Form className="store-form mx-auto">
                    <fieldset disabled={!isEditClick}>
                      <div className={`row mx-0 ${cls}`}>
                        <div className="col-12 p-0">
                          <div className="heading">Country Details</div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">Country</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="country"
                                type="text"
                                placeholder="Enter Country"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4">Currency</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="currency"
                                type="text"
                                placeholder="Currency"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="col-12 p-0">
                          <div className="heading">Store Details</div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Store Name En
                            </div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="name"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.name && errors.name && (
                                <div className="english-error">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Store Name Ar</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="name_ar"
                                type="text"
                                placeholder="Store name ar"
                              />
                              {touched.country && errors.country && (
                                <div className="english-error">
                                  {errors.country}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Description En
                            </div>
                            <div className="col-7 input">
                              <textarea
                                className="w-100"
                                name="description_en"
                                id=""
                                rows="5"
                                defaultValue={values.description_en}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Description Ar</div>
                            <div className="col-7 input">
                              <textarea
                                className="w-100"
                                name="description_ar"
                                id=""
                                rows="5"
                                defaultValue={values.description_ar}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">Email</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="email"
                                type="email"
                                placeholder="store@random.com"
                              />
                              {touched.email && errors.email && (
                                <div className="english-error">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-6">
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4">Contact</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="contact"
                                type="number"
                                placeholder="Enter contact #..."
                              />
                              {touched.contact && errors.contact && (
                                <div className="english-error">
                                  {errors.contact}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">Commission</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="commission"
                                type="number"
                                placeholder="Commission"
                                step="0.01"
                                min="0"
                                value={values.commission}
                              />
                              {touched.commission && errors.commission && (
                                <div className="english-error">
                                  {errors.commission}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4">Store Logo</div>
                            <div className="col-7 input">
                              <div
                                className="image-container d-flex align-items-center justify-content-center mx-auto"
                                style={{ width: "150px", height: "100px" }}
                              >
                                <img
                                  src={values.logo_image}
                                  alt="store_logo"
                                  width="auto"
                                  height="auto"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 ${cls}`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">No of Sku</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="no_of_sku"
                                type="text"
                                placeholder="no_of_sku"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row mx-0 ${cls}`}>
                        <div className="col-12 p-0">
                          <div className="heading">Bank Details</div>
                        </div>
                        <div className={`english-col col-6 `}>
                          <div className="row mx-0">
                            <div className="label col-4 col-lg-4 gx-0">
                              Bank Account Title
                            </div>
                            <div className="col-7  input">
                              <Field
                                className="w-100"
                                name="bank_account_title"
                                type="text"
                                placeholder="Enter bank_account_title"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6 `}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Bank Name</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="bank_name"
                                type="text"
                                placeholder="bank_name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Bank Account IBAN
                            </div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="bank_account_iban"
                                type="text"
                                placeholder="Enter bank_account_iban"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Bank Account #</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="bank_account_no"
                                type="text"
                                placeholder="bank_account_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row mx-0 ${cls}`}>
                        <div className="col-12 p-0">
                          <div className="heading">Business Details</div>
                        </div>
                        <div className={`english-col col-6 `}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Business Name
                            </div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="business_name"
                                type="text"
                                placeholder="Enter business_name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Business Type</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="business_type"
                                type="text"
                                placeholder="business_type"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`row mx-0 ${cls}`}>
                        <div className={`english-col col-6`}>
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Business Website
                            </div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="business_website"
                                type="text"
                                placeholder="Enter business_website"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`english-col col-6`}>
                          <div className="row justify-content-end mx-0">
                            <div className="label col-4 ">Liscense no.</div>
                            <div className="col-7 input">
                              <Field
                                className="w-100"
                                name="license_no"
                                type="text"
                                placeholder="license_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-auto">
                        <div className="col-12 p-0">
                          <div className="heading">Settings</div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">
                              Can Reject Order
                            </div>
                            <div className="col-md-8 input">
                              <Switch
                                onChange={() =>
                                  setInitialVal({
                                    ...initialVal,
                                    can_reject_order:
                                      !initialVal.can_reject_order,
                                  })
                                }
                                checked={initialVal.can_reject_order}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">Status</div>
                            <div className="col-7 input">
                              <Switch
                                onChange={() =>
                                  setInitialVal({
                                    ...initialVal,
                                    status: !initialVal.status,
                                  })
                                }
                                checked={initialVal.status}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4 gx-0">Manage Products?</div>
                            <div className="col-7 input">
                              <Switch
                                onChange={() =>
                                  setInitialVal({
                                    ...initialVal,
                                    can_manage_product: !initialVal.can_manage_product,
                                  })
                                }
                                checked={initialVal.can_manage_product}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={!isEditClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {pageState === "view" && (
                      <div className="row mb-0">
                        <div className="col-7 mx-auto liscense-file-tag">
                          <div
                            href={store.businsess_license_file}
                            target="_blank"
                          >
                            <span type="span">view Liscense File</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link href={store.agreement_policy_url} style={{textDecorationLine:'none'}}target="_blank" rel="noopener noreferrer">Download agreement policy</Link>
                          </div>
                        </div>
                      </div>
                    )}
                    {
                      isEditClick ?
                        <div className="row">
                          <div className="col-lg-6">
                            <label><b>Store Logo</b></label>
                            <div class="image-container d-flex align-items-center justify-content-center mx-auto" style={{ width: "150px", height: "100px" }}>
                              <img src={initialVal.logo_image} style={{ maxWidth: "100%", maxHeight: "100%;" }} />
                            </div>
                            <br />
                            <label><b>If you want to change logo then select image:</b></label>
                            <DropzoneElement
                              multiple={false}
                              placeholder="Click to select logo"
                              updateSelectedServiceFiles={(files) =>
                                handleSingleFileChange(files)
                              }
                              type={type.IMAGE}
                            />
                            {uploadingSingleImage && (
                              <div className="d-flex justify-content-center align-item-center w-100">
                                <ClipLoader color="#000" loading size={20} />
                              </div>
                            )}
                          </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="row button-row mx-0">
                      <div className="button-col col-7 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          {isEditClick ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                onClick={() => handleSaveClick(values)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-items-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={20}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => handleEditClick(values)}
                              >
                                Edit
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {redirectBack ? <Redirect push to="/stores" /> : ""}
                    {redirect ? <Redirect push to="/stores" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#000" loading size={35} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { stores: state.stores.stores, store: state.single_store.store };
};

const mapDispatchToProps = { set_stores, set_single_store };

export default connect(mapStateToProsps, mapDispatchToProps)(EditStore);
