import React, { Fragment, useState } from "react";
import Select from "react-select";

import "./editstatusmodal.scss";

const EditStatusModal = (props) => {
  const StatusData = props.Data;
  const [status, setstatus] = useState(StatusData[0]);

  const statusChange = (status) => {
    setstatus(status);
  };

  return (
    <Fragment>
      <div className="edit-status-modal">
        <div className="modal-header-row w-100">{props.heading}</div>
        <div className="boday-row w-100">
          <div className="input-group-row w-100 d-flex flex-column">
            <div className="input-row d-flex align-items-center w-100">
              <div className="label">{props.label} Id:</div>
              <div className="value">{props.id}</div>
            </div>
            <div className="input-row d-flex align-items-center w-100">
              <div className="label">{props.label} Status:</div>
              <div className="value">
                <div className="select">
                  <Select
                    value={status}
                    onChange={statusChange}
                    options={StatusData}
                    className="category-select"
                    classNamePrefix="select"
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-row w-100 d-fex justify-content-start align-items-center">
            <button
              className="cancel-button"
              type="button"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              className="update-button"
              type="button"
              onClick={props.onUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditStatusModal;
