export const ReportsData = [
  {
    report_id: 1,
    report_title: "Total Sales",
  },
  {
    report_id: 2,
    report_title: "All Users",
  },
  {
    report_id: 3,
    report_title: "All Products",
  },
  {
    report_id: 4,
    report_title: "All Services",
  },
  {
    report_id: 5,
    report_title: "Inventory",
  },
  {
    report_id: 6,
    report_title: "All Stores",
  },
  {
    report_id: 7,
    report_title: "Profit",
  },
  {
    report_id: 8,
    report_title: "Vendor Payment",
  },
];
