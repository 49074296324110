import { type } from "../types/types";

export const set_platform_settings_id = (platformSettingId) => (dispatch) => {
  dispatch({
    type: type.SET_PLATFORM_SETTINGS_ID,
    platformSettingId: platformSettingId,
  });
};

export const set_platform_settings = (platformSetting) => (dispatch) => {
  dispatch({
    type: type.SET_PLATFORM_SETTINGS,
    platformSetting: platformSetting,
  });
};
