import React, { Fragment, useState, useEffect } from "react";

import "./banners.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_banners } from "../../../redux/actions/BannerAction";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const Banners = (props) => {
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [createRedirect, setCreateRedirect] = useState(false);
  const [tabTitle, settabTitle] = useState("all");

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tabParam = (tab) => {
    switch (tab) {
      case "all":
        return "";
      case "uae":
        return "&with_country=UAE";
      case "ksa":
        return "&with_country=KSA";
      case "egypt":
        return "&with_country=Egypt";
      default:
        return "";
    }
  };

  const getBanners = (page, params) => {
    setData(true);
    apiJson.banner.getBanners(page, params).then((response) => {
      if (response.success) {
        props.set_banners({
          banners: response.data.banners,
          pagination: response.data.pagination,
        });
        setData(false);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(false);
      } else {
        setData(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Banners";
    getBanners(1, "");
    apiJson.banner.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "bannerstab",
            tablist: [
              { id: "all", count: response.data.all },
              { id: "ksa", count: response.data.KSA },
              { id: "uae", count: response.data.UAE },
              { id: "egypt", count: response.data.Egypt },
            ],
          })
        : setHeaderList({
            id: "bannerstab",
            tablist: [
              { id: "all", count: 0 },
              { id: "ksa", count: 0 },
              { id: "uae", count: 0 },
              { id: "egypt", count: 0 },
            ],
          });
    });
    // eslint-disable-next-line
  }, []);

  const changeTab = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getBanners(1, tabParam(title));
    }
  };

  const pageChange = (page) => {
    getBanners(page, tabParam(tabTitle));
  };

  return (
    <Fragment>
      <div className="right-content banners-page-container w-100">
        <div className="header-row">
          <Header
            heading="Banners"
            subtext="Create, edit, and manage Banners on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <div className="button-div">
              <button
                type="button"
                className="create-button"
                onClick={() => setCreateRedirect(true)}
              >
                Create New Banner
              </button>
            </div>
            {data ? (
              <div className="d-flex justify-content-center align-item-center w-100">
                <ClipLoader color="#000" loading size={30} />
              </div>
            ) : (
              props.banners && (
                <div className="banners-tab">
                  <HeaderTab
                    onTabChoose={changeTab}
                    tabTitle={tabTitle}
                    tab={headerList}
                  >
                    <TableList
                      List={props.banners}
                      onPageChange={pageChange}
                      pagination={props.pagination}
                    />
                  </HeaderTab>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {createRedirect && (
        <Redirect
          push
          to={{
            pathname: "/create-banner",
            state: { name: "create" },
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.banner.pagination,
    banners: state.banner.banners,
  };
};

const mapDispatchToProps = { set_banners };

export default connect(mapStateToProsps, mapDispatchToProps)(Banners);
