import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Redirect, Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TableList = (props) => {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);
  const [currentSection, setCurrentSection] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [sectionID, setSectionID] = useState("");
  const [countryID, setCountryID] = useState("");
  const [values, setValues] = useState("");

  const history = useHistory();

  useEffect(() => {
    setMin(0);
    setMax(10);
    setActivePage(0);
    setValues(props.List);
  }, [props.List]);

  const menuItems = [
    // <MenuItem
    //   key="1"
    //   onClick={() => setRedirectToEdit(true)}
    //   style={{ cursor: "pointer" }}
    // >
    //   {loading ? (
    //     <div className="d-flex justify-content-center align-items-center">
    //       <ClipLoader color="#000" loading size={15} />
    //     </div>
    //   ) : (
    //     "Edit"
    //   )}
    // </MenuItem>,
    <MenuItem
      key="1"
      style={{ cursor: "pointer" }}
    >
        <Link to={"/home/offers/" + sectionID}>View</Link>
    </MenuItem>,
    <MenuItem
      key="2"
      onClick={() => onDeleteCalled()}
      style={{ cursor: "pointer" }}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <ClipLoader color="#000" loading size={15} />
        </div>
      ) : (
        "Delete"
      )}
    </MenuItem>,
  ];

  const menu = <Menu className="menu">{menuItems}</Menu>;

  const setVars = (section) => {
    setSectionID(section.id);
    setCurrentSection(section);
    setCountryID(section.country_id);
    // setRedirect(true);
    history.push("/home/offers/edit/" + section.id);
  };

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const onDeleteCalled = () => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      onDeleteSection(sectionID, 1);
    } else console.log("canceled!");
  };
  const onDeleteSection = (sectionId, countryID) => {
    setLoading(true);
    apiJson.home_offers_sections.deleteSection(sectionId, countryID).then((response) => {
      if (response.success) {
        setValues(values.filter((sec) => sec.id !== sectionId));
        message = "Section Deleted";
        showSuccess();
        setLoading(false);
      } else if (!response.status) {
        setLoading(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setLoading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setMin(pageNumber.selected * 10);
    setMax((pageNumber.selected + 1) * 10);
    setActivePage(pageNumber.selected);
  };
  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="w-10 header-text">Section Id</td>
            <td className="w-10 header-text">Title </td>
            <td className="w-10 header-text">Country Name </td>
            <td className="w-10 header-text">Actions </td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.slice(min, max).map((section) => {
              return (
                <tr
                  key={section.id}
                  onClick={() => setVars(section)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="w-10 header-text">{section.id}</td>
                  <td className="w-10 header-text">{section.title_en}</td>
                  <td className="w-10 header-text">
                    UAE
                  </td>
                  <td
                    className="w-10 header-text d-flex justify-content-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="action-icon">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        <BsThreeDots
                          style={{ cursor: "pointer" }}
                          size={20}
                          onMouseEnter={() => {
                            setSectionID(section.id);
                            setCountryID(section.country_id);
                          }}
                          onClick={() => {
                            setSectionID(section.id);
                            setCountryID(section.country_id);
                          }}
                        />
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          )}
        </tbody>
      </Table>
      {values !== "" && values.length > 10 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={Math.ceil(values.length / 10)}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName={"ms-3 me-3 "}
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `/section-items/${sectionID}`,
            state: { countryID: { countryID } },
          }}
        />
      )}
      {redirectToEdit && (
        <Redirect
          push
          to={{
            pathname: `/section/${sectionID}/edit`,
            state: {
              name: "edit",
              id: { sectionID },
              country_id: countryID,
            },
          }}
        />
      )}
    </div>
  );
};

const mapStateToProsps = (state) => {
  return { sections: state.sections.sections };
};

const mapDispatchToProps = { set_sections };

export default connect(mapStateToProsps, mapDispatchToProps)(TableList);
