import React, { Fragment, useState, useEffect, useCallback } from "react";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import { ServiceTabData } from "Extra/TabsData";
import { set_services } from "redux/actions/ServicesAction";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./servicecatalog.scss";
import { tabParam } from "utils/utils";
import { debounce } from "lodash";
import ProductBulkImportModal from "../productcatalog/ProductBulkImportModal";

const ServiceCatalog = (props) => {
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(ServiceTabData.tablist[0].id);
  const [showBulkModal, setShowBulkModal] = useState(false);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getServices = (page, params) => {
    setData(false);
    apiJson.services.getServices(page, params).then((response) => {
      if (response.success) {
        props.set_services({
          services: response.data.services,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getServices(
        1,
        tabParam(title) +
        (searchText?.length > 0 ? "&search_by_name=" + searchText : "")
      );
    }
  };

  const pageChange = (page) => {
    getServices(
      page,
      tabParam(tabTitle) +
      (searchText?.length > 0 ? "&search_by_name=" + searchText : "")
    );
  };

  const updateTablist = () =>
    apiJson.services.getTabsCount().then((response) => {
      response.success &&
        setHeaderList({
          id: "serviceTabData",
          tablist: [
            { id: "active", count: response.data.active },
            { id: "inactive", count: response.data.inactive },
            {
              id: "uncategorized",
              count: response.data.uncategorized,
            },
          ],
        });
    });

  const createClick = () => {
    setShowBulkModal(false);
  };

  const cancelClick = () => {
    setShowBulkModal(false);
  };

  useEffect(() => {
    document.title = "Services";
    getServices(1, "&with_status=active");
    apiJson.services.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
          id: "serviceTabData",
          tablist: [
            { id: "active", count: response.data.active },
            { id: "inactive", count: response.data.inactive },
            {
              id: "uncategorized",
              count: response.data.uncategorized,
            },
          ],
        })
        : setHeaderList({
          id: "serviceTabData",
          tablist: [
            { id: "active", count: 0 },
            { id: "inactive", count: 0 },
            { id: "uncategorized", count: 0 },
          ],
        });
    });
    // eslint-disable-next-line
  }, []);

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab) => handleSearch(text, currTab), 1000),
      []
    );

  const handleSearch = (text, tab, sku) => {
    getServices(1, tabParam(tab) + "&search_by_name=" + text);
  };

  const bulkImport = () => {
    setShowBulkModal(true);
  }

  return (
    <Fragment>
      {showBulkModal && (
        <div className="product-import-modal-container">
          <ProductBulkImportModal
            heading="Bulk Import Services"
            cancleClick={cancelClick}
            createClick={createClick}
            dataType={"services"}
          />
        </div>
      )}
      <div className="right-content service-catalog-page-desktop">
        <div className="header-row">
          <Header
            heading="View Services"
            search={true}
            filters={null}
            handleSearch={(text) => {
              setSearchText(text);
              debouncedCallback(text, tabTitle);
            }}
            subtext="Create, edit, and manage Services on your site."
          />
        </div>
        {data ? (
          <div className="main-row content-container-row">
            <div className="content w-100">
              <div className="service-catalog-tab">
                <button type="button" onClick={bulkImport} className="btn btn-primary import-btn">Import Services</button>
                <br /><br />
                <HeaderTab
                  onTabChoose={ChangeTabList}
                  tabTitle={tabTitle}
                  tab={headerList}
                >
                  <TableList
                    List={props.services}
                    onPageChange={pageChange}
                    pagination={props.pagination}
                    updateTablist={updateTablist}
                  />
                </HeaderTab>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <ClipLoader color="#000" loading size={35} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.service.pagination,
    services: state.service.services,
  };
};

const mapDispatchToProps = { set_services };

export default connect(mapStateToProsps, mapDispatchToProps)(ServiceCatalog);
