import React from "react";
import "./ImagesGrid.scss";

const ImagesGrid = (props) => {
  return (
    <div className="image-grid">
      <div className="row image-row justify-content-start mx-0">
        {props.images &&
          props.images.map((image) => {
            return (
              <div
                key={image}
                className="image-col d-flex flex-column justify-content-center align-items-center"
              >
                <div className="image">
                  <img src={image} width="auto" height="auto" alt="" />
                </div>
                {props.name !== "view" && (
                  <button
                    onClick={() => {
                      props.remove(
                        props.images.filter((url) => url !== image),
                        image
                      );
                    }}
                    className="remove-button mx-auto"
                  >
                    Remove
                  </button>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ImagesGrid;
