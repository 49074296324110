export const CustomersEmails = [
  {
    id: 1,
    title: "Summer Sales",
    items: 19,
  },
  {
    id: 2,
    title: "Winter Sales",
    items: 19,
  },
  {
    id: 3,
    title: "New Arrivals",
    items: 19,
  },
];
