import React, { useState, useEffect, Fragment } from "react";
import "../assets/styles/product/productCard.scss";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { apiJson } from "api/Api";
import { Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { set_categories } from "../redux/actions/CategoriesAction";
import { set_countries } from "redux/actions/CountryAction";
import { set_brands } from "../redux/actions/BrandsAction";
import { set_stores } from "../redux/actions/StoresAction";
import Select from "react-select";
import VariantCard from "./VariantCard";
import ClipLoader from "react-spinners/ClipLoader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { set_variants } from "redux/actions/ProductsAction";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import RSelect from "react-select";
import { getClass, IsQuillEmpty } from "utils/utils";
import { WithContext as ReactTags } from 'react-tag-input';

const suggestions = [];

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const productValidation = Yup.object().shape({
  name_en: Yup.string().required("Required"),
  add_assembly: Yup.boolean(),
  assembly_attributes: Yup.object().when('add_assembly', {
    is: true,
    then: Yup.object().shape({
      price: Yup.number()
        .required("Required")
        .min(0, "Price cannot be negative")
    }),
    otherwise: Yup.object().shape({
      price: Yup.number()
        .notRequired()
        .min(0, "Price cannot be negative") 
    })
  }),
  description_en: Yup.string().required("Required").nullable("Required"),
  // benefits_en: Yup.string().required("Required").nullable("Required"),
  // features_en: Yup.string().required("Required").nullable("Required"),
  // how_to_use_en: Yup.string().required("Required").nullable("Required"),
  country_id: Yup.number().required("Required"),
  shipment_rule_id: Yup.number().required("Required"),
  store_id: Yup.number().required("Required"),
  category_id: Yup.array().min(1, "Required").nullable("Required"),
  brand_id: Yup.number().required("Required"),
  lead_days: Yup.number()
    .transform((originalValue) => {
      return isNaN(originalValue) ? undefined : Number(originalValue);
    })
    .typeError("Lead days must be a valid number")
    .min(1, "Lead days should be greater than or equal to 1")
    .notRequired(),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        name_en: Yup.string().required("Required"),
        description_en: Yup.string().required("Required").nullable("Required"),
        // benefits_en: Yup.string().required("Required").nullable("Required"),
        // features_en: Yup.string().required("Required").nullable("Required"),
        // how_to_use_en: Yup.string().required("Required").nullable("Required"),
        // price: Yup.number()
        //   .min(1, "Should be greater than 1")
        //   .required("Required"),
        // discounted_price: Yup.number().min(0, "Negative not allowed"),
        variation: Yup.string().required("Required").nullable("Required"),
        // quantity: Yup.number().min(0, "Negative not allowed"),
        sku: Yup.string().required("Required").nullable("Required"),
        weight: Yup.number()
          .min(0, "Negative not allowed")
          .required("Required"),
        featured_image: Yup.string().required("Required").nullable("Required"),
        images: Yup.array()
          // .min(1, "At least 1 image required")
          .nullable("Required"),
      })
    )
    .min(1, "At least 1 variant required"),
});

function ProductCard(props) {

  const { state, id } = useParams();
  const [countryOptions, setcountryOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productValues, setProductValues] = useState({});
  const [back, setBack] = useState(false);
  const [goToEdit, setGoToEdit] = useState(false);
  const [goToView, setGoToView] = useState(false);
  const [selectedVar, setSelectedVar] = useState(-1);
  const [submitForm, setSubmitForm] = useState(false);
  const { user } = props

  const [isTool, setIsTool] = useState(
    id !== "create"
      ? props.product?.tool === null
        ? false
        : props.product?.tool
      : false
  );

  const [isInquiry, setIsInquiry] = useState(
    id !== "create"
      ? props.product?.is_inquiry === null
        ? false
        : props.product?.is_inquiry
      : false
  );

  const isAddAssembly = () => {
    if (id === "create") {
      return false;
    }
    return props.product?.add_assembly ?? false;
  };

  const [pickupAllowed, setPickupAllowed] = useState(
    id !== "create"
      ? props.product?.pickup_allowed === null
        ? false
        : props.product?.pickup_allowed
      : false
  );

  const [isWholesale, setIsWholesale] = useState(
    id !== "create"
      ? props.product?.product_type === "wholesale"
        ? true
        : false
      : false
  );
  const [productUnitOptions, setProductUnitOptions] = useState([
    "piece",
    "carton"
  ]);
  const [brandsOption, setBrandsOption] = useState([]);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedTools, setRelatedTools] = useState([]);
  const [relatedServices, setRelatedServices] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    id !== "create"
      ? props.product?.related_products?.map((item) => {
        return { value: item.id, label: item.name_en };
      })
      : []
  );
  const [selectedService, setSelectedService] = useState(
    id !== "create"
      ? props.product?.related_services?.map((item) => {
        return { value: item.id, label: item.name_en };
      })
      : []
  );
  const [selectedTool, setSelectedTool] = useState(
    id !== "create"
      ? props.product?.related_tools?.map((item) => {
        return { value: item.id, label: item.name_en };
      })
      : []
  );
  const [shippingRules, setShippingRules] = useState([]);
  const [countryID, setCountryID] = useState();
  const [sendReq, setSendReq] = useState(false);

  let combine = [];
  var extraService = [];
  var extraProduct = [];
  var extraTools = [];

  const [tags, setTags] = React.useState([]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getShipmentRules = (id) => {
    apiJson.shippingRules.getShippingRules().then((response) => {
      if (response.success) {
        setShippingRules(response.data?.map(option => {
          return {
            label: option.name,
            value: option.id
          }
        }));
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }

  const fetchBrands = (country) => {
    apiJson.brands.getBrands().then((response) => {
      if (response.success) {
        props.set_brands({ brands: response.data });
        if (country) {
          setBrandsOption(
            response.data?.filter((brand) => brand.country_id === country)
          );
        }
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const fetchCountries = async () => {
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        props.set_countries({ countries: response.data });
        setcountryOptions(response.data);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const fetchStores = async () => {
    apiJson.stores.getStores().then((response) => {
      if (response.success) {
        props.set_stores({
          stores_list: response.data,
        });
        props?.product?.country_id &&
          setStoreOptions(
            response?.data?.filter(
              (store) => store.country_id === props?.product?.country_id
            )
          );
        if (state === "view" || state === "edit") {
          let extra = 0;
          response.data.map((store) =>
            store.id === props.product?.store_id
              ? (extra = store.country_id)
              : -1
          );
          setCountryID(extra);
          if (extra) {
            setSendReq(true);
          }
        }
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const fetchCategories = async () => {
    apiJson.categories.getCategories().then((response) => {
      if (response.success) {
        props.set_categories({ categories: response.data });
        props?.product?.country_id &&
          setCategoryOptions(
            response.data?.filter(
              (category) => category.country_id === props?.product?.country_id
            )
          );
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    if (sendReq) {
      apiJson.services.getRelatedServices(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraService.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedServices(extraService);
      });
      apiJson.products.getRelatedProducts(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraProduct.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedProducts(extraProduct);
      });
      apiJson.products.getRelatedTools(countryID).then((res) => {
        if (res.success) {
          res.data.map((item) => {
            extraTools.push({ value: item.id, label: item.name_en });
            return item;
          });
        }
        setRelatedTools(extraTools);
      });
    }
    // eslint-disable-next-line
  }, [countryID, sendReq]);

  const AsyncCall = async () => {
    setBack(false);
    setGoToEdit(false);
    setGoToView(false);
    await fetchCountries();
    await getShipmentRules();
    await fetchStores();
    await fetchCategories();
    setSelectedVar(-1);
    if (state) {
      setProductValues({
        ...props?.product,
        assembly_attributes: {
          id: props?.product?.assembly?.id,
          price: props?.product?.assembly?.price || undefined,
        },
        add_assembly: isAddAssembly(),
        category_id: props?.product?.categories?.map((category) => {
          return {
            value: category.id,
            label: category.title_en,
          };
        }),
      });
      fetchBrands(props?.product?.country_id);
    } else {
      fetchBrands();
      setSelectedProduct([]);
      setSelectedService([]);
      setSelectedTool([]);
      setProductValues({
        benefits_ar: "",
        benefits_en: "",
        brand_id: "",
        brand_name_ar: "",
        brand_name_en: "",
        category_id: [],
        category_title_ar: "",
        category_title_en: "",
        country_id: "",
        shipment_rule_id: "",
        assembly_attributes: { price: undefined },
        add_assembly: false,
        currency: "",
        description_ar: "",
        description_en: "",
        features_ar: "",
        features_en: "",
        how_to_use_ar: "",
        how_to_use_en: "",
        id: "",
        name_ar: "",
        name_en: "",
        country_of_origin: "",
        video: "",
        status: "inactive",
        store_country_name_en: "",
        store_id: "",
        store_logo: "",
        store_name_ar: "",
        store_name_en: "",
        variants: [],
      });
    }
  };
  // eslint-disable-next-line
  useEffect(() => AsyncCall(), [id, state]);

  const handleSave = (values) => {
    let keywords = [];
    tags.map(tag => {
      keywords = keywords.concat({
        name: tag.text
      })
    });
    props.onSave({
      ...values,
      category_id: undefined,
      product_categories_attributes: values.category_id.map((cat) => {
        return { category_id: cat.value };
      }),
      product_keywords_attributes: keywords
    });
  };

  const handleUpdate = (values) => {
    let keywords = [];
    tags.map(tag => {
      keywords = keywords.concat({
        name: tag.text
      })
    });
    props.onUpdate({
      ...values,
      category_id: undefined,
      product_categories_attributes: values.category_id.map((cat) => {
        return { category_id: cat.value };
      }),
      product_keywords_attributes: keywords
    });
  };

  const handleCountryChange = (value, setFieldValue) => {
    setFieldValue("country_id", value.value);
    setFieldValue("store_country_name_en", value.label);
    setFieldValue("store_id", "");
    setFieldValue("store_name_en", "");
    setFieldValue("brand_id", "");
    setFieldValue("brand_name_en", "");
    setBrandsOption(
      props.brands?.filter((brand) => brand.country_id === value.value)
    );
    setStoreOptions(
      props?.stores?.filter((store) => store.country_id === value.value)
    );
    setCountryID(value.value);
    setSendReq(true);
  };

  const handleShimentRuleChange = (value, setFieldValue) => {
    setFieldValue("shipment_rule_id", value.value);
    setFieldValue("shipment_rule_name", value.label);
  };

  const handleStoreChange = (value, id, setFieldValue) => {
    setFieldValue("store_id", value.value);
    setFieldValue("store_name_en", value.label);
    setFieldValue("category_id", []);
    setFieldValue("category_title_en", "");
    setCategoryOptions(
      props.categories?.filter((category) => category.country_id === id)
    );
  };

  const handleBrandChange = (value, setFieldValue) => {
    setFieldValue("brand_id", value.value);
    setFieldValue("brand_name_en", value.label);
  };

  const handleUnitChange = (value, setFieldValue) => {
    setFieldValue("unit", value.value);
  };

  const addVariant = (values, setFieldValue) => {
    const variant = {
      id: Math.random() + "var",
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      discounted_price: undefined,
      quantity: undefined,
      variation: "",
      price: undefined,
      sku: undefined,
      upc_code: undefined,
      weight: undefined,
      featured_image: "",
      images: [],
      benefits_en: "",
      benefits_ar: "",
      features_en: "",
      features_ar: "",
      how_to_use_en: "",
      how_to_use_ar: "",
      featured_image_resized: [],
    };
    let variants = values.variants;
    variants.push(variant);
    setProductValues({
      ...values,
      variants: [...variants],
    });
  };
  const mergeArrays = () => {
    selectedProduct?.map((item) => {
      combine.push({ related_product_id: parseInt(item.value) });
      return item;
    });
    selectedTool?.map((item) => {
      combine.push({ related_product_id: parseInt(item.value) });
      return item;
    });
    return combine;
  };
  var relatedservice = [];

  const convertToServiceList = () => {
    selectedService?.map((item) => {
      relatedservice.push({ related_service_id: parseInt(item.value) });
      return item;
    });
    return relatedservice;
  };

  useEffect(() => {
    if (document.getElementsByClassName("en-error").length > 0 && submitForm) {
      setSubmitForm(false);
      document.getElementsByClassName("en-error")[0].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, [document.getElementsByClassName("en-error"), submitForm]);

  useEffect(() => {
    if (props.product) {
      if (props.product.keywords != undefined) {
        if (props.product.keywords.length > 0) {
          let keywords = [];
          props.product.keywords.map(tag => {
            keywords = keywords.concat({
              id: tag.name,
              text: tag.name
            });
          });
          setTags(keywords);
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if(addAssebly){
  //     setFieldError('assembly_price', false)
  //     setFieldValue("assembly_price", "")
  //   } else {
  //     setFieldError('assembly_price', "Required")
  //   }
  // }, [addAssebly])

  return (
    <div className=" product-card-desktop-main">
      <Formik
        initialValues={{
          ...productValues,
          variants: productValues?.variants,
        }}
        validationSchema={productValidation}
        enableReinitialize
        onSubmit={(values) => {
          var product_related_products = [];
          var product_related_services = [];
          if (!isTool) {
            product_related_products = mergeArrays();
            product_related_services = convertToServiceList();
          }
          if (state === "edit") {
            delete values["related_products"];
            delete values["related_services"];
            delete values["related_tools"];
            
            handleUpdate({
              ...values,
              tool: isTool,
              is_inquiry: isInquiry,
              pickup_allowed: pickupAllowed,
              product_type: isWholesale ? "wholesale" : "retail",
              product_related_products_attributes: product_related_products,
              product_related_services_attributes: product_related_services,
              tags: tags,
              country_of_origin: values.country_of_origin == null ? "" : values.country_of_origin
            });
          } else if (!state) {
            handleSave({
              ...values,
              tool: isTool,
              is_inquiry: isInquiry,
              ...(!values.add_assembly && { assembly_attributes: null }),
              pickup_allowed: pickupAllowed,
              product_type: isWholesale ? "wholesale" : "retail",
              product_related_products_attributes: product_related_products,
              product_related_services_attributes: product_related_services,
              tags: tags,
              country_of_origin: values.country_of_origin == null ? "" : values.country_of_origin
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleBlur,
          handleChange,
          submitForm,
        }) =>
          typeof values.name_en === "string" ? (
            <Form>
              <fieldset disabled={state === "view"}>
                <div className=" product-card-desktop row gx-0">
                  <div className="english-col row gx-0">
                    <label className="label col-2">Name</label>
                    <div className="input col-8">
                      <Field
                        className={`w-100 ${getClass(
                          touched.name_en && errors.name_en
                        )}`}
                        name="name_en"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter name"
                      />
                      {touched.name_en && errors.name_en && (
                        <div className="en-error">{errors.name_en}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <div className="col-8 input offset-2">
                      <Field
                        className="w-100 arabic-direction"
                        name="name_ar"
                        type="text"
                        onChange={handleChange}
                        placeholder="أدخل الاسم"
                      />
                    </div>
                    <label className="label col-2 text-end">اسم</label>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Description</label>
                    <div className="col-8 input">
                      <Field
                        className="w-100"
                        name="description_en"
                        type="text"
                      >
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.description_en && errors.description_en
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "description_en",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className={getClass(
                          //     touched.description_en && errors.description_en
                          //   )}
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "description_en",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          //   onBlur={() =>
                          //     setFieldTouched("description_en", true)
                          //   }
                          // />
                        )}
                      </Field>
                      {touched.description_en && errors.description_en && (
                        <div className="en-error">{errors.description_en}</div>
                      )}
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <div className="col-8 input offset-2">
                      <Field
                        className="w-100"
                        name="description_ar"
                        type="text"
                      >
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.description_ar && errors.description_ar
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "description_ar",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className="arabic-direction"
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "description_ar",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          // />
                        )}
                      </Field>
                    </div>
                    <label className="label col-2 text-end">وصف</label>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Benefits</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="benefits_en" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.benefits_en && errors.benefits_en
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "benefits_en",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className={getClass(
                          //     touched.benefits_en && errors.benefits_en
                          //   )}
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "benefits_en",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          //   onBlur={() => setFieldTouched("benefits_en", true)}
                          // />
                        )}
                      </Field>
                      {touched.benefits_en && errors.benefits_en && (
                        <div className="en-error">{errors.benefits_en}</div>
                      )}
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <div className="col-8 input offset-2">
                      <Field className="w-100" name="benefits_ar" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.benefits_ar && errors.benefits_ar
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "benefits_ar",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className="arabic-direction"
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "benefits_ar",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          // />
                        )}
                      </Field>
                    </div>
                    <label className="label col-2 text-end">فوائد</label>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Features</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="features_en" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.features_en && errors.features_en
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "features_en",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className={getClass(
                          //     touched.features_en && errors.features_en
                          //   )}
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "features_en",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          //   onBlur={() => setFieldTouched("features_en", true)}
                          // />
                        )}
                      </Field>
                      {touched.features_en && errors.features_en && (
                        <div className="en-error">{errors.features_en}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <div className="col-8 input offset-2">
                      <Field className="w-100" name="features_ar" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.features_ar && errors.features_ar
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "features_ar",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className="arabic-direction"
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "features_ar",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          // />
                        )}
                      </Field>
                    </div>
                    <label className="label col-2 text-end">سمات</label>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">How to use</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="how_to_use_en" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.how_to_use_en && errors.how_to_use_en
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "how_to_use_en",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className={getClass(
                          //     touched.how_to_use_en && errors.how_to_use_en
                          //   )}
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "how_to_use_en",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          //   onBlur={() =>
                          //     setFieldTouched("how_to_use_en", true)
                          //   }
                          // />
                        )}
                      </Field>
                      {touched.how_to_use_en && errors.how_to_use_en && (
                        <div className="en-error">{errors.how_to_use_en}</div>
                      )}
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <div className="col-8 input offset-2">
                      <Field className="w-100" name="how_to_use_ar" type="text">
                        {({ field }) => (
                          <CKEditor
                            className={getClass(
                              touched.how_to_use_ar && errors.how_to_use_ar
                            )}
                            editor={ClassicEditor}
                            data={field.value ? field.value : ""}
                            disabled={state === "view"}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue(
                                "how_to_use_ar",
                                data
                              )
                              // console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                          // <ReactQuill
                          //   className="arabic-direction"
                          //   readOnly={state === "view"}
                          //   value={field.value ? field.value : ""}
                          //   onChange={(value) =>
                          //     setFieldValue(
                          //       "how_to_use_ar",
                          //       IsQuillEmpty(value) ? "" : value
                          //     )
                          //   }
                          // />
                        )}
                      </Field>
                    </div>
                    <label className="label col-2 text-end">كيف تستعمل</label>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Status</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => {
                              setFieldValue(
                                "status",
                                field.value === "active" ? "inactive" : "active"
                              );
                            }}
                            checked={field.value === "active"}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">It is a Tool</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => setIsTool(!isTool)}
                            checked={isTool}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Is this an inquiry product?</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => setIsInquiry(!isInquiry)}
                            checked={isInquiry}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Allow this product for pickup order?</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => setPickupAllowed(!pickupAllowed)}
                            checked={pickupAllowed}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Add assembly price</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => setFieldValue("add_assembly" , !values.add_assembly)}
                            checked={values.add_assembly}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  {values.add_assembly && (
                    <div className="english-col row gx-0">
                      <label className="label col-2">Assembly Price</label>
                      <div className="input col-8">
                        <Field
                          className={`w-100 ${getClass(
                            touched.assembly_attributes?.price && errors.assembly_attributes?.price
                          )}`}
                          name="assembly_attributes.price"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          onChange={handleChange}
                          placeholder="Enter assembly price"
                        />
                        {touched.assembly_attributes?.price && errors.assembly_attributes?.price && (
                          <div className="en-error">{errors.assembly_attributes.price}</div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="english-col row gx-0">
                    <label className="label col-2">Shipment Rule</label>
                    <div className="col-8 input">
                      <Select
                        value={
                          values.shipment_rule_id
                            ? {
                              value: values.shipment_rule_id,
                              label: values.shipment_rule_name,
                            }
                            : ""
                        }
                        onChange={(value) =>
                          handleShimentRuleChange(value, setFieldValue)
                        }
                        options={shippingRules}
                        onBlur={() => setFieldTouched("shipment_rule_id", true)}
                        className={`category-select w-100 ${getClass(
                          touched.shipment_rule_id && errors.shipment_rule_id
                        )}`}
                        classNamePrefix="select"
                        isSearchable={false}
                        isDisabled={state === "view"}
                        placeholder="Select Shipment Rule"
                      />
                      {touched.shipment_rule_id && errors.shipment_rule_id && (
                        <div className="en-error">{errors.shipment_rule_id}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Country</label>
                    <div className="col-8 input">
                      <Select
                        value={
                          values.country_id
                            ? {
                              value: values.country_id,
                              label: values.store_country_name_en,
                            }
                            : ""
                        }
                        onChange={(value) =>
                          handleCountryChange(value, setFieldValue)
                        }
                        options={countryOptions?.map((country) => {
                          return { value: country.id, label: country.name_en };
                        })}
                        onBlur={() => setFieldTouched("country_id", true)}
                        className={`category-select w-100 ${getClass(
                          touched.country_id && errors.country_id
                        )}`}
                        classNamePrefix="select"
                        isSearchable={false}
                        isDisabled={state === "view"}
                        placeholder="Select Country"
                      />
                      {touched.country_id && errors.country_id && (
                        <div className="en-error">{errors.country_id}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Store</label>
                    <div className="col-8 input">
                      <Select
                        value={
                          values.store_id
                            ? {
                              value: values.store_id,
                              label: values.store_name_en,
                            }
                            : ""
                        }
                        onChange={(value) =>
                          handleStoreChange(
                            value,
                            values.country_id,
                            setFieldValue
                          )
                        }
                        onBlur={() => setFieldTouched("store_id", true)}
                        options={storeOptions?.map((store) => {
                          return {
                            value: store.id,
                            label: store.name_en,
                          };
                        })}
                        className={`category-select w-100 ${getClass(
                          touched.store_id && errors.store_id
                        )}`}
                        classNamePrefix="select"
                        isSearchable={true}
                        isDisabled={state === "view"}
                        placeholder="Select Store"
                      />
                      {touched.store_id && errors.store_id && (
                        <div className="en-error">{errors.store_id}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Category</label>
                    <div className="col-8 input">
                      <RSelect
                        isMulti
                        value={values.category_id}
                        onChange={(value) => {
                          setFieldValue("category_id", value);
                        }}
                        onBlur={() => setFieldTouched("category_id", true)}
                        options={categoryOptions?.map((category) => {
                          return {
                            value: category.id,
                            label: category.title_en,
                          };
                        })}
                        className={`category-select w-100 ${getClass(
                          touched.category_id && errors.category_id
                        )}`}
                        classNamePrefix="select"
                        isSearchable={true}
                        isDisabled={state === "view"}
                        placeholder="Select Category"
                      />
                      {touched.category_id && errors.category_id && (
                        <div className="en-error">{errors.category_id}</div>
                      )}
                    </div>
                  </div>

                  <div className="english-col row gx-0">
                    <label className="label col-2">Brand</label>
                    <div className="col-8 input">
                      <Select
                        value={
                          values.brand_id
                            ? {
                              value: values.brand_id,
                              label: values.brand_name_en,
                            }
                            : ""
                        }
                        onChange={(value) =>
                          handleBrandChange(value, setFieldValue)
                        }
                        onBlur={() => setFieldTouched("brand_id", true)}
                        options={brandsOption?.map((brand) => {
                          return {
                            value: brand.id,
                            label: brand.name_en,
                          };
                        })}
                        className={`category-select w-100 ${getClass(
                          touched.brand_id && errors.brand_id
                        )}`}
                        classNamePrefix="select"
                        isSearchable={true}
                        isDisabled={state === "view"}
                        placeholder="Select Brand"
                      />
                      {touched.brand_id && errors.brand_id && (
                        <div className="en-error">{errors.brand_id}</div>
                      )}
                    </div>
                  </div>
                  {!isTool && user.permissions?.products?.related_products && (
                    <div className="english-col row gx-0">
                      <label className="label col-2">Related Products</label>
                      <div className="col-8 input">
                        <RSelect
                          isMulti
                          isDisabled={state === "view"}
                          name="related_products"
                          value={selectedProduct}
                          onChange={setSelectedProduct}
                          options={relatedProducts}
                          isSearchable={true}
                          className="category-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  )}
                  {!isTool && (
                    <div className="english-col row gx-0">
                      <label className="label col-2">Related Services</label>
                      <div className="col-8 input">
                        <RSelect
                          isMulti
                          isDisabled={state === "view"}
                          name="related_services"
                          value={selectedService}
                          onChange={setSelectedService}
                          options={relatedServices}
                          isSearchable={true}
                          className="category-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  )}
                  {!isTool && user.permissions?.products?.related_tools && (
                    <div className="english-col row gx-0">
                      <label className="label col-2">Related Tools</label>
                      <div className="col-8 input">
                        <RSelect
                          isMulti
                          isDisabled={state === "view"}
                          name="related_tools"
                          value={selectedTool}
                          onChange={setSelectedTool}
                          options={relatedTools}
                          isSearchable={true}
                          className="category-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  )}
                  <div className="english-col row gx-0">
                    <label className="label col-2">Video Link</label>
                    <div className="input col-8">
                      <Field
                        className={`w-100 ${getClass(
                          touched.video && errors.video
                        )}`}
                        name="video"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter youtube video embedded link"
                      />
                      {touched.video && errors.video && (
                        <div className="en-error">{errors.video}</div>
                      )}
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Keywords</label>
                    <div className="input col-8">
                      <ReactTags
                        tags={tags}
                        suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        handleTagClick={handleTagClick}
                        inputFieldPosition="bottom"
                        autocomplete
                        autofocus={false}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Country of origin</label>
                    <div className="input col-8">
                      <Field
                        className={`w-100 ${getClass(
                          touched.country_of_origin && errors.country_of_origin
                        )}`}
                        name="country_of_origin"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter country of origin"
                      />
                      {touched.country_of_origin && errors.country_of_origin && (
                        <div className="en-error">{errors.country_of_origin}</div>
                      )}
                    </div>
                  </div>
                  <div className="english-col row gx-0">
                      <label className="label col-2">Lead Days</label>
                      <div className="input col-8">
                        <Field
                          className={`w-100 ${getClass(
                            touched.lead_days && errors.lead_days
                          )}`}
                          name="lead_days"
                          placeholder="Enter lead days"
                          type="number"
                        />
                        {touched.lead_days && errors.lead_days && (
                          <div className="en-error">{errors.lead_days}</div>
                        )}
                      </div>
                    </div>
                  <div className="english-col row gx-0">
                    <label className="label col-2">Wholesale product?</label>
                    <div className="col-8 input">
                      <Field className="w-100" name="status" type="text">
                        {({ field }) => (
                          <Switch
                            onChange={() => setIsWholesale(!isWholesale)}
                            checked={isWholesale}
                            onHandleColor="#37B900"
                            offHandleColor="#dcdcdc"
                            onColor="#e9e9e9"
                            offColor="#e9e9e9"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            disabled={state === "view"}
                            className="switch"
                            width={48}
                            height={24}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  {
                    isWholesale ?
                      <div className="english-col row gx-0">
                        <label className="label col-2">Measuring Unit</label>
                        <div className="col-8 input">
                          <Select
                            value={
                              values.unit
                                ? {
                                  value: values.unit,
                                  label: values.unit,
                                }
                                : ""
                            }
                            onChange={(value) =>
                              handleUnitChange(value, setFieldValue)
                            }
                            // onBlur={() => setFieldTouched("brand_id", true)}
                            options={productUnitOptions?.map((unit) => {
                              return {
                                value: unit,
                                label: unit,
                              };
                            })}
                            className={`category-select w-100 ${getClass(
                              touched.unit && errors.unit
                            )}`}
                            classNamePrefix="select"
                            isSearchable={true}
                            isDisabled={state === "view"}
                            placeholder="Select Unit"
                          />
                          {touched.unit && errors.unit && (
                            <div className="en-error">{errors.unit}</div>
                          )}
                        </div>
                      </div>
                      :
                      <></>
                  }
                </div>
                <FieldArray name="variants">
                  {(arrayHelpers) => {
                    return (
                      <>
                        {values?.variants?.length > 0 &&
                          values?.variants.map((variant, index) => (
                            <React.Fragment key={variant.id}>
                              <VariantCard
                                selected={selectedVar}
                                onUpdateVar={(id) => setSelectedVar(id)}
                                setfieldvalue={setFieldValue}
                                setfieldtouched={setFieldTouched}
                                variant={variant}
                                index={index}
                                variants={values.variants}
                                variants_touched={touched?.variants}
                                variants_errors={errors?.variants}
                                handleBlur={handleBlur}
                                productDetail={productValues}
                                onUpdate={(id) => {
                                  setProductValues({
                                    ...values,
                                    variants: [
                                      ...values.variants
                                        // eslint-disable-next-line
                                        .map((variant) => {
                                          if (typeof variant?.id === "string") {
                                            if (id !== variant.id) {
                                              return variant;
                                            }
                                          } else {
                                            if (id === variant?.id) {
                                              return {
                                                ...variant,
                                                _destroy: true,
                                              };
                                            } else {
                                              return variant;
                                            }
                                          }
                                        })
                                        .filter(
                                          (variant) => variant !== undefined
                                        ),
                                    ],
                                  });
                                }}
                                product_type={isWholesale ? "wholesale" : "retail"}
                              />
                            </React.Fragment>
                          ))}
                      </>
                    );
                  }}
                </FieldArray>
              </fieldset>
              <div className="row button-row mx-0">
                {state !== "view" && (
                  <div className="variant-button-col col-12 d-flex justify-content-end align-items-center">
                    {touched?.variants?.length === 0 &&
                      errors.variants === "At least 1 variant required" && (
                        <div
                          className="en-error"
                          style={{ marginInlineEnd: "20px" }}
                        >
                          {errors.variants}
                        </div>
                      )}
                    <button
                      type="button"
                      className="variant-button"
                      onClick={() => addVariant(values, setFieldValue)}
                    >
                      + Add Variant
                    </button>
                  </div>
                )}
                <div className="button-col col-8 mx-auto d-flex">
                  <div
                    className="button-group d-flex align-items-center"
                    style={{ marginTop: "20px" }}
                  >
                    {state ? (
                      state === "edit" ? (
                        <Fragment>
                          <button
                            className="blue-button"
                            type="button"
                            onClick={() => {
                              submitForm();
                              setSubmitForm(true);
                            }}
                          >
                            {props.loading ? (
                              <div className="d-flex justify-content-center align-items center w-100">
                                <ClipLoader color="#fff" loading size={20} />
                              </div>
                            ) : (
                              "Update"
                            )}
                          </button>
                          <button
                            className="gray-button"
                            type="button"
                            onClick={() => {
                              setGoToView(true);
                            }}
                          >
                            Cancel
                          </button>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <button
                            className="blue-button"
                            type="button"
                            onClick={() => {
                              setGoToEdit(true);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="gray-button"
                            type="button"
                            onClick={() => {
                              setBack(true);
                            }}
                          >
                            Cancel
                          </button>
                        </Fragment>
                      )
                    ) : (
                      <Fragment>
                        <button
                          className="blue-button"
                          type="button"
                          onClick={() => {
                            submitForm();
                            setSubmitForm(true);
                          }}
                        >
                          {props.loading ? (
                            <div className="d-flex justify-content-center align-items center w-100">
                              <ClipLoader color="#fff" loading size={20} />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          className="gray-button"
                          type="button"
                          onClick={() => {
                            setBack(true);
                          }}
                        >
                          Cancel
                        </button>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <div
              style={{ marginTop: "-5px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <ClipLoader color="#000" loading size={30} />
            </div>
          )
        }
      </Formik>
      {goToEdit && <Redirect push to={`/product/${id}/edit`} />}
      {goToView && <Redirect push to={`/product/${id}/view`} />}
      {back && <Redirect push to="/product-catalog" />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    brands: state.brand.brands,
    stores: state.stores.stores_list,
    productDetails: state.product.productDetails,
    variants: state.product.variants,
    countries: state.countries.countries,
    user: state.auth.user
  };
};

const mapDispatchTopProps = {
  set_categories,
  set_brands,
  set_stores,
  set_variants,
  set_countries,
};

export default connect(mapStateToProps, mapDispatchTopProps)(ProductCard);
