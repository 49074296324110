import { useState } from "react";
import Header from "components/header/Header";
import "./subadmins.scss";
import { apiJson } from "../../../api/Api";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Subadmins() {
    const [data, setData] = useState([]);
    const [records_per_page, setRecordsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchData(1);
    }, []);

    const fetchData = (page) => {
        apiJson
            .subadmins.getSubadmins(page, records_per_page)
            .then(response => {
                                if (response.success) {
                    setTotalPages(response.data.pagination.total_pages);
                    setData(response.data.sub_admins);
                }
            });
    }

    return (
        <div className="subadmins">
            <Header
                heading="Subadmins"
                subtext="Subadmins"
            />
            <Link
                to="/subadmins/create"
                className="create-btn"
            >Create New User</Link>
            <div className="section">
                <table className="table table-bordered">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {
                        data?.map(user => {
                            return (
                                <tr>
                                    <td>{user.id}</td>
                                    <td>{user.first_name_en + " " + user.last_name_en}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {
                                            user.status == "active" ?
                                                <div className="active">
                                                    {user.status}
                                                </div>
                                                :
                                                <div className="inactive">
                                                    {user.status}
                                                </div>
                                        }
                                    </td>
                                    <td><Link to={`/subadmins/permissions/${user.id}`}>Permissions</Link></td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default Subadmins;