import React, { Fragment, useEffect, useState } from "react";

import "./shippingzones.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { set_zones } from "redux/actions/ShippingZoneAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";

const ShippingZones = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Zones";
  }, []);

  useEffect(() => {
    apiJson.zone.getShippingZones().then((response) => {
      if (response.success) {
        props.set_zones({ zones: response.data });
        setData(true);
        setHeaderList({
          id: "storestab",
          tablist: [{ id: "all", count: response.data.length }],
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="right-content shipping-zones-page-container w-100">
        <div className="header-row">
          <Header
            heading="Shipping Zones"
            subtext="Create, edit, and manage Shipping Zones on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="content mx-auto">
            <div className="button-div">
              <button
                type="button"
                className="create-button"
                onClick={() => setRedirect(true)}
              >
                Create New Zone
              </button>
            </div>
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="shipping-zones-tab">
                <HeaderTab tab={headerList}>
                  <TableList List={props.zones} />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/create-zone", state: { name: "create" } }}
          />
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { zones: state.zones.zones };
};

const mapDispatchToProps = { set_zones };

export default connect(mapStateToProsps, mapDispatchToProps)(ShippingZones);
