import Header from "components/header/Header";
import EditStatusModal from "components/modals/EditStatusModal";
import React, { Fragment, useState } from "react";
import "./modaltesting.scss";

const StatusData = [
  { value: "requested", label: "requested" },
  { value: "rejected", label: "rejected" },
  { value: "approved", label: "approved" },
  { value: "completed", label: "completed" },
];

const ModalTesting = (props) => {
  const [isOrderClick, setisOrderClick] = useState(false);
  const [isReturnClick, setisReturnClick] = useState(false);

  return (
    <Fragment>
      <div className="right-content">
        <div className="header-row">
          <Header heading="Modal Testing" subtext="" />
        </div>
        <div className="main-row service-page-content-container">
          <div className="wrapper">
            <button
              className="order-status-modal"
              onClick={() => setisOrderClick(true)}
            >
              Edit Order Status
            </button>
            {isOrderClick && (
              <div className="modal-container">
                <EditStatusModal
                  heading="Edit Order Status"
                  Data={StatusData}
                  label="Order"
                  id="2456"
                />
              </div>
            )}

            <button
              className="return-status-modal"
              onClick={() => setisReturnClick(true)}
            >
              Edit Return
            </button>
            {isReturnClick && (
              <div className="modal-container">
                <EditStatusModal
                  heading="Edit Return Status"
                  Data={StatusData}
                  label="Return"
                  id="2456"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalTesting;
