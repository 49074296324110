import { type } from "../../types/types";

export default function reducer(state = { users: [], pagination: {} }, action) {
  switch (action.type) {
    case type.SET_USERS:
      return { ...state, ...action.users };
    case type.REMOVE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.userId),
      };
    default:
      return state;
  }
}
