import React, { Fragment, useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Redirect, useLocation } from "react-router-dom";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { set_countries } from "redux/actions/CountryAction";
import { set_categories } from "redux/actions/CategoriesAction";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import ImagesGrid from "components/Dropzone/ImagesGrid";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { FileUploadPromise } from "utils/aws_s3/aws_s3";
import { getRandomInt, deleteS3Image } from "utils/utils";
import "./editbrand.scss";
import { type } from "components/Dropzone/type";
import { getClass } from "utils/utils";

const variantSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  file: Yup.string().required("Required").nullable("Required"),
});

const EditMedia = (props) => {
  const param = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const locationName = location.state.name;
  const [pageName, setPageName] = useState(locationName);
  const [initValues, setInitValues] = useState({
    name: "",
    alt: "",
    description: "",
    file: "",
  });
  const [data, setData] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [isEditClick, setisEditClick] = useState(false);
  const [deletedImagesUrl, setDeletedImagesUrl] = useState([]);

  var [clist, setClist] = useState([]);

  useEffect(() => {
    document.title = "Create Media";
  }, []);

  let countriesList = [];
  let message = "";
  const [UniqueParentCategoriesList, setUniqueParentCategoriesList] = useState(
    []
  );
  let tcatlist = [];

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    setDeletedImagesUrl([]);
    if (pageName === "edit" || pageName === "view") {
      setData(true);
      apiJson.gallery.getGalleryDetails(parseInt(param.id)).then((response) => {
        if (response.success) {
          setInitValues(response.data);
          setData(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          setData(false);
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            setData(false);
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            setData(false);
            showError();
          }
        }
      });
    } else {
      setInitValues({
        name: "",
        alt: "",
        file: "",
        description: ""
      });
      setData(false);
    }
  }, []);

  const handleEdit = () => {
    setPageName("edit");
    location.state.name = "edit";
  };

  useEffect(() => {
    if (pageName === "create" || pageName === "edit") {
      setisEditClick(false);
    } else if (pageName === "view") {
      setisEditClick(true);
    }
  }, [pageName]);
  const heading = isEditClick ? "View Media" : "Create/Edit Media";

  const Update = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.gallery
      .updateGallery(parseInt(param.id), {
        gallery: {
          name: values.name,
          alt: values.alt,
          file: values.file,
          description: values.description
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Media Updated";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const Create = (values) => {
    deleteS3Image(deletedImagesUrl);

    setLoading(true);
    apiJson.gallery
      .createGallery({
        gallery: {
          name: values.name,
          alt: values.alt,
          file: values.file,
          description: values.description
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Media Created";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const handleFilesChange = (files, setFieldValue) => {
    let fileUploadPromises = [];
    setUploadingImages(true);

    files.forEach((file) => {
      fileUploadPromises.push(FileUploadPromise(file));
    });

    Promise.all(fileUploadPromises)
      .then((response) => {
        setUploadingImages(false);
        setFieldValue("file", response.map((image) => image.location)[0]);
      })
      .catch((err) => {
        setUploadingImages(false);
        message = "File upload error";
        showError();
      });
  };

  const handleSave = (values) => {
    if (pageName === "edit") {
      Update(values);
    } else if (pageName === "create") {
      Create(values);
    }
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this media?")) {
      setLoading(true);
      apiJson.gallery.removeGallery(parseInt(param.id)).then((response) => {
        if (response.success) {
          message = "Media Deleted";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="right-content brand-page-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage the media on your site."
          />
        </div>
        {data ? (
          <div className="d-flex justify-content-center align-item-center w-100">
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <div className="main-row brand-page-content-container">
            <div className="form-container">
              <Formik
                initialValues={initValues}
                validationSchema={variantSchema}
                onSubmit={(values) => handleSave(values)}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="brand-form mx-auto">
                    <fieldset disabled={isEditClick}>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Name
                            </div>
                            <div className="col-md-8 col-lg-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.name && errors.name
                                )}`}
                                name="name"
                                type="text"
                                placeholder="Enter name"
                              />
                              {touched.name && errors.name && (
                                <div className="english-error">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="arabic-col col-6">
                          <div className="row">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Alt
                            </div>
                            <div className="col-md-8 col-lg-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.alt && errors.alt
                                )}`}
                                name="alt"
                                type="text"
                                placeholder="Enter alt"
                                style={{ textAlign: "left" }}
                              />
                              {touched.alt && errors.alt && (
                                <div className="english-error">
                                  {errors.alt}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Description
                            </div>
                            <div className="col-md-8 col-lg-8 col-xl-7 input">
                              <Field
                                className={`w-100 ${getClass(
                                  touched.name && errors.name
                                )}`}
                                name="description"
                                as="textarea"
                                rows="4"
                                placeholder="Enter description"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0 mb-0 align-items-start">
                            <div className="label col-md-4 col-lg-4 col-xl-3">
                              Image
                            </div>
                            {uploadingImages ? (
                              <div className="d-flex justify-content-center align-item-center w-100">
                                <ClipLoader color="#000" loading size={20} />
                              </div>
                            ) : values.file ? (
                              <ImagesGrid
                                name={location.state.name}
                                remove={(val, url) => {
                                  setFieldValue("file", "");
                                  setDeletedImagesUrl([
                                    ...deletedImagesUrl,
                                    url,
                                  ]);
                                }}
                                images={[values.file]}
                              />
                            ) : (
                              touched.file &&
                              errors.file && (
                                <div
                                  className="english-error"
                                  style={{
                                    width: "fit-content",
                                    color: "red",
                                    padding: "0",
                                  }}
                                >
                                  {errors.file}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      {location.state.name !== "view" && (
                        <div className="d-flex justify-content-center align-items-cenetr w-100">
                          <DropzoneElement
                            multiple={false}
                            placeholder="Click to select a file"
                            updateSelectedServiceFiles={(value) =>
                              handleFilesChange(value, setFieldValue)
                            }
                            type={type.IMAGE}
                          />
                        </div>
                      )}<div className="row mx-0">
                      <div className="english-col col-6">
                        <div className="row mx-0">
                          <div className="label col-md-4 col-lg-4 col-xl-3">
                            File URL
                          </div>
                          <div className="col-md-8 col-lg-8 col-xl-7 input">
                            {values.file}
                          </div>
                        </div>
                      </div>
                    </div>
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-12 mx-auto d-flex flex-column">
                        <div className="button-group d-flex justify-content-center align-items-center">
                          {!isEditClick || location.state.name === "create" ? (
                            <Fragment>
                              <button
                                className="gray-button"
                                type="button"
                                onClick={() => setRedirectBack(true)}
                              >
                                Cancel
                              </button>
                              <button
                                className="blue-button"
                                type="submit"
                                disabled={loading}
                                key={getRandomInt(1, 1000)}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={21}
                                    />
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <button
                                className="blue-button"
                                type="button"
                                onClick={() => handleEdit()}
                              >
                                Edit
                              </button>
                              <button
                                className="blue-button"
                                type="button"
                                style={{ backgroundColor: "#cd0e0e" }}
                                key={getRandomInt(1, 1000)}
                                onClick={() => handleDelete()}
                                disabled={loading}
                              >
                                {loading ? (
                                  <div className="d-flex justify-content-center align-item-center w-100">
                                    <ClipLoader
                                      color="#fff"
                                      loading
                                      size={22}
                                    />
                                  </div>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {redirectBack ? <Redirect push to="/media" /> : ""}
                    {redirect ? <Redirect push to="/media" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    categories: state.category.categories,
    parentCategories: state.category.parentCategories,
    forBrand: state.category.forBrand,
    topLevelCategories: state.category.topLevel,
    countries: state.countries.countries,
  };
};

const mapDispatchToProps = { set_categories, set_countries };

export default connect(mapStateToProsps, mapDispatchToProps)(EditMedia);
