import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "components/header/Header";
import "../subadmins/createsubadmin.scss";
import { apiJson } from "api/Api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
});

function CreatePushNotification() {
  const history = useHistory();

  const createNotification = (data) => {
    apiJson.pushnotificationmessages
      .createPushNotification(data)
      .then((response) => {
        if (response.success) {
          showSucess("Push Notification created successfully!");
          history.push("/push-notification");
        }
      });
  };

  const showSucess = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="create-subadmin">
      <Header
        heading="Create Push Notification Messages"
        subtext="Create Push Notification Messages"
      />
      <div className="section">
        <Formik
          initialValues={{ title: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            createNotification(values);
          }}
        >
          {() => (
            <Form className="form">
              <div className="row mx-0 my-2">
                <div className="col-md-3">
                  <label htmlFor="title">Title</label>
                </div>
                <div className="col-md-9">
                  <Field
                    name="title"
                    type="text"
                    className="form-control"
                    id="title"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="m-0 text-danger"
                  />
                </div>
              </div>

              <div className="row mx-0 my-2">
                <div className="col-md-3">
                  <label htmlFor="message">Message</label>
                </div>
                <div className="col-md-9">
                  <Field
                    name="message"
                    type="text"
                    className="form-control"
                    id="message"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="m-0 text-danger"
                  />
                </div>
              </div>

              <div className="row m-auto">
                <input type="submit" value="Save" className="save-btn" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreatePushNotification;
