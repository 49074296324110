import React, { Fragment, useEffect, useState } from "react";
import ListTable from "../../product/ProductTable/Table";
import "./view-products.scss";
import Header from "../../../../components/header/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_variants } from "../../../../redux/actions/VariantsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../../api/Api";
var FileSaver = require('file-saver');

const ViewAllProducts = (props) => {
  const readOnly = true;
  const [is_exporting_products, setIsExportingProducts] = useState(false);
  const [is_exporting_all_orders, setIsExportingAllOrders] = useState(false);
  const [exportFileLink, setExportFileLink] = useState("");

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {

  }, []);

  const exportActiveProducts = () => {
    setIsExportingProducts(true);
    apiJson.exports.exportActivateProducts()
      .then(async (response) => {
        if (response.success) {
          setExportFileLink("https://api.alisuq.com/variants.csv");
          // const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          // FileSaver.saveAs(blob, "products.csv");
          setIsExportingProducts(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      })
  };

  const exportActiveProductsWithPriceQty = () => {
    setIsExportingProducts(true);
    apiJson.exports.exportActiveProductsWithPriceQty()
      .then((response) => {
        if (response.success) {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          FileSaver.saveAs(blob, "products.csv");
          setIsExportingProducts(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      })
  };

  const exportAllOrders = () => {
    setIsExportingAllOrders(true);
    apiJson.exports.exportAllOrders()
      .then((response) => {
        if (response.success) {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          FileSaver.saveAs(blob, "orders.csv");
          setIsExportingAllOrders(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      })
  };

  return (
    <Fragment>
      <div className="right-content view-products-page-desktop">
        <div className="header-row">
          <Header
            heading="Products Inventory"
            subtext="Manage products here."
          />
        </div>
        <div className="main-row mid-bottom">
          <div className="bottom-container inventory-items">
            <button type="button" className="export-all-btn" onClick={exportActiveProducts}>Export All Active Products</button>
            <button style={{ marginLeft: "10px" }} type="button" className="export-all-btn" onClick={exportActiveProductsWithPriceQty}>Export All Active SKUS with price & quantity</button>
            {/* <button type="button" className="export-all-btn" onClick={exportAllOrders}>Export All Orders</button> */}
            {
              is_exporting_products ?
                <div className="text-center">
                  <p>Exporting Products. It may take sometime. Please wait...</p>
                </div>
                :
                <></>
            }
            {
              is_exporting_all_orders ?
                <div className="text-center">
                  <p>Exporting Orders. Please wait...</p>
                </div>
                :
                <></>
            }
          </div>
          <div>
            {
              exportFileLink ?
                <a onClick={() => setExportFileLink("")} href={exportFileLink}>Click here to download file</a>
                :
                <></>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    productDetails: state?.product?.productDetails,
    variants: state?.variant?.variants,
  };
};

const mapDispatchTopProps = { set_variants };

export default connect(mapStateToProps, mapDispatchTopProps)(ViewAllProducts);
