import React, { Fragment, useEffect } from "react";
import Login from "./componets/Login";

import "./loginpage.scss";

const LoginPage = () => {
  useEffect(() => {
    document.title = "AliSuq";
  }, []);
  return (
    <Fragment>
      <div className="login-page-desktop">
        <Login />
      </div>
    </Fragment>
  );
};

export default LoginPage;
