import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./ordersummary.scss";

const OrderSummary = ({
  currency,
  subTotoal,
  shipping,
  orderTotal,
  estimatedVat,
  couponCode,
  discount,
}) => {
  return (
    <div className="order-summary-desktop  custom-card-2 g-0 mt-3">
      <div className="vertical-separator p-3">
        <Row>
          <Col md={12} className="title fw-bold">
            ORDER SUMMARY
          </Col>
        </Row>
        <Row className="g-0 subtitle">
          <Col md={2} className="py-1">
            Subtotal
          </Col>
          <Col md={8}></Col>
          <Col md={2} className="sub-total text-end fw-bold">
            <span className="capitalize"> {currency}</span> {subTotoal}
          </Col>
        </Row>

        <Row className="g-0 subtitle">
          <Col md={2} className="pt-1">
            Shipping Fee
          </Col>
          <Col md={8}></Col>
          <Col md={2} className="shipping-fee text-end">
            <span className="capitalize"> {currency}</span> {shipping}
          </Col>
        </Row>

        {couponCode && (
          <Row className="g-0 subtitle">
            <Col md={2} className="pt-1">
              Coupon Code
            </Col>
            <Col md={8}></Col>
            <Col
              md={2}
              className="shipping-fee text-end"
              style={{ color: "#404553", textTransform: "unset" }}
            >
              {couponCode}
            </Col>
          </Row>
        )}

        {discount && (
          <Row className="g-0 subtitle">
            <Col md={2} className="pt-1">
              Discount
            </Col>
            <Col md={8}></Col>
            <Col
              md={2}
              className="shipping-fee text-end"
              style={{ color: "#404553" }}
            >
              <span className="capitalize"> {currency}</span> {discount}
            </Col>
          </Row>
        )}
      </div>

      <div className="vertical-separator px-3 pt-2">
        <Row className="title g-0">
          <Col md={6} className="order-total fw-bold">
            Order Total
            <span className="gray-text fw-400">(Inclusive of VAT)</span>
          </Col>
          <Col md={3}></Col>
          <Col md={3} className="order-total-price fw-bold text-end">
            <span className="capitalize"> {currency}</span> {orderTotal}
          </Col>
        </Row>
        <Row className="subtitle g-0 gray-text py-2">
          <Col md={3}>Estimated VAT</Col>
          <Col md={6}></Col>
          <Col md={3} className="text-end">
            <span className="capitalize"> {currency}</span> {estimatedVat}
          </Col>
        </Row>
      </div>

      <div className="test p-3"></div>
    </div>
  );
};

export default OrderSummary;
