import { type } from "../../types/types";

export default function reducer(
  state = { products: [], variants: [], deletedImagesUrl: [], pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_PRODUCTS:
      return { ...state, ...action.products };
    case type.SET_PRODUCT_DETAIL:
      return { ...state, ...action.productDetails };
    case type.REMOVE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.productId
        ),
      };
    case type.SET_URLS:
      return {
        ...state,
        ...action.urls,
      };
    case type.SET_VARIANTS_ARR:
      return {
        ...state,
        variants: action.variants,
      };
    default:
      return { ...state };
  }
}
