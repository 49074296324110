import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./orders.scss";
import TableList from "./TableList";
import { OrdersTabData } from "Extra/TabsData";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { set_orders } from "redux/actions/OrdersAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "lodash";
var FileSaver = require('file-saver');

const Orders = (props) => {
  const [headerList, setHeaderList] = useState([]);
  const [data, setData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(OrdersTabData.tablist[0].id);
  const [is_exporting_all_orders, setIsExportingAllOrders] = useState(false);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const tabParam = (tab) => (tab === "all" ? "" : "&with_status=" + tab);

  const getOrders = (page, params) => {
    setData(false);
    apiJson.orders.getOrders(page, params).then((response) => {
      if (response.success) {
        props.set_orders({
          orders: response.data.orders,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Orders";
  }, []);

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getOrders(
        1,
        tabParam(title) +
        (searchText?.length > 0 ? "&search=" + searchText : "")
      );
    }
  };

  const pageChange = (page) => {
    getOrders(
      page,
      tabParam(tabTitle) +
      (searchText?.length > 0 ? "&search=" + searchText : "")
    );
  };

  useEffect(() => {
    apiJson.country.getCountries();
    getOrders(1, "");
    apiJson.orders.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
          id: "orderstab",
          tablist: [
            { id: "all", count: response.data.all },
            { id: "pending", count: response.data.pending },
            { id: "cancelled", count: response.data.cancelled },
            { id: "rejected", count: response.data.rejected },
            { id: "processing", count: response.data.processing },
            { id: "shipped", count: response.data.shipped },
            { id: "delivered", count: response.data.delivered },
            {
              id: "partially_returned",
              count: response.data.partially_returned,
            },
            { id: "fully_returned", count: response.data.fully_returned },
          ],
        })
        : setHeaderList({
          id: "orderstab",
          tablist: [
            { id: "all", count: 0 },
            { id: "pending", count: 0 },
            { id: "cancelled", count: 0 },
            { id: "rejected", count: 0 },
            { id: "processing", count: 0 },
            { id: "shipped", count: 0 },
            { id: "delivered", count: 0 },
            { id: "partially_returned", count: 0 },
            { id: "fully_returned", count: 0 },
          ],
        });
    });
    // eslint-disable-next-line
  }, []);

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab) => handleSearch(text, currTab), 1000),
      []
    );

  const handleSearch = (text, tab) => {
    getOrders(1, tabParam(tab) + "&search=" + encodeURIComponent(text));
  };

  const exportAllOrders = () => {
    setIsExportingAllOrders(true);
    apiJson.exports.exportAllOrders()
      .then((response) => {
        if (response.success) {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          FileSaver.saveAs(blob, "orders.csv");
          setIsExportingAllOrders(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      })
  };

  return (
    <Fragment>
      <div className="right-content order-page-container w-100">
        <div className="header-row">
          <Header
            heading="Orders"
            subtext="Create, edit, and manage Orders on your site."
            search={true}
            filters={null}
            handleSearch={(text) => {
              setSearchText(text);
              debouncedCallback(text, tabTitle);
            }}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <Link to="/new-order" className="create-order-button">Create Order</Link>
          {" "}
          <button
            type="button"
            className="export-all-btn btn-primary ml-auto"
            onClick={exportAllOrders}
            style={{
              borderRadius: "5px"
            }}
          >Export All Orders</button>
          {" "}
          {
            is_exporting_all_orders ?
              <div className="text-center">
                <p>Exporting Orders. Please wait...</p>
              </div>
              :
              <></>
          }
          <div className="content mx-auto">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              props.orders && (
                <div className="orders-tab">
                  {/* <Link
                      className="create-order-button"
                      to="/new-order"
                    >
                      Create New Order
                    </Link> */}
                  <HeaderTab
                    onTabChoose={ChangeTabList}
                    tabTitle={tabTitle}
                    tab={headerList}
                  >
                    <TableList
                      List={props.orders}
                      onPageChange={pageChange}
                      pagination={props.pagination}
                    />
                  </HeaderTab>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.order.pagination,
    orders: state.order.orders,
  };
};

const mapDispatchToProps = { set_orders };

export default connect(mapStateToProsps, mapDispatchToProps)(Orders);
