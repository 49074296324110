import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const TableList = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [currentDisputeId, setCurrentDisputeId] = useState("");
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const setVars = (id) => {
    setCurrentDisputeId(id);
    setRedirect(true);
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="">Dispute id</td>
            <td className="">Order id</td>
            <td className="">Created At</td>
            <td className="">Customer</td>
            <td className="">Status</td>
            <td className="">Store</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((dispute) => {
              return (
                <tr
                  key={dispute.id}
                  onClick={() => setVars(dispute.id)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="">{dispute.id}</td>
                  <td className="">{dispute.order_id}</td>
                  <td className="">
                    {moment(new Date(dispute.created_at)).format("LL")}
                  </td>
                  <td className="">{dispute.customer_name_en}</td>
                  <td>
                    <div className="status-text">{dispute.status}</div>
                  </td>
                  <td className="">{dispute.store_name_en}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {redirect && (
          <Redirect
            push
            to={{
              pathname: `/dispute-detail/${currentDisputeId}`,
              state: { disputeId: { currentDisputeId } },
            }}
          />
        )}
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            forcePage={parseInt(props?.pagination?.page) - 1}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};

export default TableList;
