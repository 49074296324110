import React, { useState } from "react";
import { Table } from "react-bootstrap";
import "./tablelist.scss";
import { BsThreeDots } from "react-icons/bs";
import { set_single_section } from "redux/actions/SingleSectionAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { getResizedImage } from "Extra/GetResizedImage";

const TableList = (props) => {
  const [productDetail, setProductDetail] = useState({});
  const [itemType, setItemType] = useState("");
  const [serviceDetail, setServiceDetail] = useState({});

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSucess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const DeleteProduct = (sectionId, productID, countryID) => {
    apiJson.sections
      .removeProductFromSection(sectionId, {
        country_id: countryID,
        section: {
          product_id: productID,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Product Removed!";
          props.set_single_section({ section: response.data });
          props.onDelete();
          showSucess();
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const DeleteService = (sectionId, serviceID, countryID) => {
    apiJson.sections
      .removeServiceFromSection(sectionId, {
        country_id: countryID,
        section: {
          service_id: serviceID,
        },
      })
      .then((response) => {
        if (response.success) {
          message = "Service Removed!";
          props.set_single_section({ section: response.data });
          props.onDelete();
          showSucess();
        } else if (!response.status) {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const onDeleteProductbtn = (sectionID, productID, countryID) => {
    if (window.confirm("This item will be removed from section")) {
      DeleteProduct(sectionID, productID, countryID);
    }
  };
  const onDeleteServicebtn = (sectionID, serviceID, countryID) => {
    if (window.confirm("This item will be removed from section")) {
      DeleteService(sectionID, serviceID, countryID);
    }
  };
  const handleDeleteClick = () => {
    if (itemType !== "") {
      if (itemType.toLowerCase() === "product") {
        onDeleteProductbtn(
          productDetail.list_id,
          productDetail.product_id,
          productDetail.country_id
        );
      } else {
        onDeleteServicebtn(
          serviceDetail.list_id,
          serviceDetail.service_id,
          serviceDetail.country_id
        );
      }
    }
  };

  const menuItems = [
    <MenuItem
      key="2"
      onClick={handleDeleteClick}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Delete
    </MenuItem>,
  ];
  const menu = <Menu className="menu">{menuItems}</Menu>;

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="w-10 header-text">Product Image</td>
            <td className="w-10 header-text">Name</td>
            <td className="w-10 header-text">Type</td>
            <td className="w-10 header-text">Status</td>
            <td className="w-10 header-text">Action</td>
          </tr>
        </thead>
        <tbody>
          {props.List.products.map((Product) => {
            return (
              <tr key={Product.id}>
                <td className="w-10 header-text">
                  <div className="store-image-container">
                    {Product.variants?.length > 0 ? (
                      <img
                        src={getResizedImage(
                          Product.variants[0]?.featured_image_resized,
                          Product.variants[0].featured_image
                        )}
                        style={{
                          width: "auto",
                          maxWidth: "80px",
                          height: "80px",
                        }}
                        alt=""
                      />
                    ) : (
                      <span>No Image</span>
                    )}
                  </div>
                </td>
                <td className="w-10 header-text">{Product.name_en}</td>
                <td className="w-10 header-text">Product</td>
                <td className="w-10 header-text">{Product.status}</td>

                <td
                  className="w-10 header-text"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="action-icon">
                    <Dropdown
                      className="dropdown-for-send"
                      overlay={menu}
                      overlayClassName="overlay-class"
                    >
                      <BsThreeDots
                        style={{ cursor: "pointer" }}
                        size={20}
                        onMouseEnter={() => {
                          setProductDetail({
                            list_id: props.List.id,
                            product_id: Product.id,
                            country_id: props.List.country_id,
                          });
                          setItemType("product");
                        }}
                        onClick={() => {
                          setProductDetail({
                            list_id: props.List.id,
                            product_id: Product.id,
                            country_id: props.List.country_id,
                          });
                          setItemType("product");
                        }}
                      />
                    </Dropdown>
                  </div>
                </td>
              </tr>
            );
          })}

          {props.List.services &&
            props.List.services.map((Service) => {
              return (
                <tr key={Service.id}>
                  <td className="w-10 header-text">
                    <div className="store-image-container">
                      <img
                        src={getResizedImage(
                          Service.featured_image_resized,
                          Service.featured_image
                        )}
                        style={{
                          width: "auto",
                          maxWidth: "80px",
                          height: "80px",
                        }}
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="w-10 header-text">{Service.name_en}</td>
                  <td className="w-10 header-text">Service</td>

                  <td className="w-10 header-text">{Service.status}</td>

                  <td
                    className="w-10 header-text"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="action-icon">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        <BsThreeDots
                          style={{ cursor: "pointer" }}
                          size={20}
                          onMouseEnter={() => {
                            setServiceDetail({
                              list_id: props.List.id,
                              service_id: Service.id,
                              country_id: props.List.country_id,
                            });
                            setItemType("service");
                          }}
                          onClick={() => {
                            setServiceDetail({
                              list_id: props.List.id,
                              service_id: Service.id,
                              country_id: props.List.country_id,
                            });
                            setItemType("service");
                          }}
                        />
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
const mapStateToProsps = (state) => {
  return {
    section: state.single_section.section,
    products: state.single_section.products,
    services: state.single_section.services,
  };
};

const mapDispatchToProps = { set_single_section };

export default connect(mapStateToProsps, mapDispatchToProps)(TableList);
