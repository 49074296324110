import { type } from "../../types/types";

export default function reducer(
  state = { disputes: [], disputeDetail: {}, pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_DISPUTES:
      return { ...state, ...action.disputes_list };
    default:
      return state;
  }
}
