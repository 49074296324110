import React, { Fragment, useEffect, useRef } from "react";

import "./message.scss";
import { connect } from "react-redux";
import moment from "moment";

const Message = (props) => {
  const message = props.message;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const classNames =
    message.owner === "customer" || message.owner === "vendor"
      ? "direction-left"
      : "direction-right";

  useEffect(() => {
    scrollToBottom();
  }, [props.disputeDetail?.replies]);

  return (
    <Fragment>
      <div className="meassage-desktop w-100">
        <div
          className={`${classNames} wrapper w-100 d-flex align-items-center`}
        >
          <div className="image-container d-flex align-items-center justify-content-center">
            <div className="first-letter">
              {message.owner === "customer"
                ? "C"
                : message.owner === "vendor"
                ? "V"
                : "A"}
            </div>
          </div>
          <div className="text-container">
            <span className="text">{message.description}</span>
            <span className="date">
              {moment(new Date(message.created_at)).format("lll")}
            </span>
          </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    disputeDetail: state.dispute.disputeDetail,
  };
};

export default connect(mapStateToProps, null)(Message);
