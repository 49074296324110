import React, { Fragment, useState, useEffect } from "react";
import "./disputes.scss";
import TableList from "./TableList";
import { DisputeTabData } from "../../../Extra/TabsData";
import Header from "components/header/Header";
import HeaderTab from "../tab/HeaderTab";
import { set_disputes } from "redux/actions/DisputesAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

const Disputes = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [tabTitle, settabTitle] = useState(DisputeTabData.tablist[0].id);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const tabParam = (tab) => "&with_status=" + tab;

  const getDisputes = (page, params) => {
    setData(false);
    apiJson.disputes.getDisputes(page, params).then((response) => {
      if (response.success) {
        props.set_disputes({
          disputes: response.data.disputes,
          pagination: response.data.pagination,
        });
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Disputes";
    getDisputes(1, tabParam("open"));
    apiJson.disputes.getTabsCount().then((response) => {
      response.success
        ? setHeaderList({
            id: "disputetab",
            tablist: [
              { id: "open", count: response.data.open },
              { id: "closed", count: response.data.closed },
            ],
          })
        : setHeaderList({
            id: "disputetab",
            tablist: [
              { id: "open", count: 0 },
              { id: "closed", count: 0 },
            ],
          });
    });
    // eslint-disable-next-line
  }, []);

  const ChangeTabList = (title) => {
    if (title !== tabTitle) {
      settabTitle(title);
      getDisputes(1, tabParam(title));
    }
  };

  const pageChange = (page) => {
    getDisputes(page, tabParam(tabTitle));
  };

  return (
    <Fragment>
      <div className="right-content disputes-page-desktop">
        <div className="header-row">
          <Header
            heading="View Disputes"
            subtext="Create, edit, and manage Disputes on your site."
          />
        </div>
        <div className="main-row disputes-content-container">
          <div className="content-wrapper">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              <div className="idpsutes-catalogue-tab">
                <HeaderTab
                  onTabChoose={ChangeTabList}
                  tabTitle={tabTitle}
                  tab={headerList}
                >
                  <TableList
                    List={props.disputes}
                    onPageChange={pageChange}
                    pagination={props.pagination}
                  />
                </HeaderTab>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.dispute.pagination,
    disputes: state.dispute.disputes,
  };
};

const mapDispatchToProps = { set_disputes };

export default connect(mapStateToProsps, mapDispatchToProps)(Disputes);
