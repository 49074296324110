import { type } from "../types/types";

export const set_services = (services) => (dispatch) => {
  dispatch({
    type: type.SET_SERVICES,
    services: services,
  });
};

export const set_service_details = (serviceDetails) => (dispatch) => {
  dispatch({
    type: type.SET_SERVICE_DETAIL,
    serviceDetails: serviceDetails,
  });
};

export const remove_service = (serviceId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_SERVICE,
    serviceId: serviceId,
  });
};
