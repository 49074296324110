import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "rc-dropdown/assets/index.css";
import { Redirect } from "react-router-dom";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import { ClipLoader } from "react-spinners";

const TableList = (props) => {
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [returnID, setReturnID] = useState("");
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };

  const onGotoDetails = (id) => {
    setReturnID(id);
    setRedirectToDetails(true);
  };
  return (
    <div className="table-responsive mx-auto">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="">Return id</td>
            <td className="">Order id</td>
            <td className="">Item Name</td>
            <td className="">Quantity</td>
            <td className="">Item Type</td>
            <td className="">Status</td>
            <td className="">Placed On</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((return_order) => (
              <tr
                key={return_order.id}
                onClick={() => onGotoDetails(return_order.id)}
                style={{ cursor: "pointer" }}
              >
                <td className="">{return_order.id}</td>
                <td className="">{return_order.order_id}</td>
                {return_order.item_type === "Variant" ? (
                  <td className="">{return_order.product_name_en}</td>
                ) : (
                  <td className="">{return_order.line_item_name_en}</td>
                )}
                <td className="">{return_order.quantity}</td>
                <td className="">{return_order.item_type}</td>
                <td>
                  <div className="status-text">{return_order.status}</div>
                </td>
                <td className="">
                  {moment(new Date(return_order.created_at)).format("LL")}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {redirectToDetails && (
          <Redirect
            push
            to={{
              pathname: `/return-detail/${returnID}`,
              state: { returnID: { returnID } },
            }}
          />
        )}
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            forcePage={parseInt(props?.pagination?.page) - 1}
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};

export default TableList;
