import React, { Fragment, useState, useEffect, useCallback } from "react";
import Header from "../../../components/header/Header";
import { Link } from "react-router-dom";

import { ProductTabData } from "../../../Extra/TabsData";
import "./details.scss";
import TableList from "./TableList";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_products } from "redux/actions/ProductsAction";

import { tabParam } from "utils/utils";
import { debounce } from "lodash";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
var FileSaver = require('file-saver');

const ViewCustomers = (props) => {
  let message = "";
  const [data, setData] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [SKUSearchText, setSKUSearchText] = useState("");
  const [tabTitle, settabTitle] = useState(ProductTabData.tablist[0].id);

  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [records_per_page, setRecordsPerPage] = useState(50);
  const [selected_ids, setSelectedIds] = useState([]);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    document.title = "Customers";
    let page = 1;

    apiJson.customers.getCustomers(1, page, records_per_page, searchedText, date)
      .then(response => {
        if (response.success) {
          response.data.customers.map(customer => {
            var total_spend = 0;
            customer.orders.map(order => {
              total_spend = total_spend + parseFloat(order.grand_total);
            });
            customer.total_spend = total_spend;
            customer.isChecked = false;
          });
          setCustomers(response.data.customers);
          setPagination(response.data.pagination);
          setData(true);
        }
        else {
          console.log("error");
        }
      });
  }, []);

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, currTab, sku) => handleSearch(text, currTab, sku), 1000),
      []
    );

  const handleSearch = (text, tab, sku) => {
    getCustomers(
      1,
      tabParam(tab) +
      "&search_by_name=" +
      text +
      (sku?.length > 0 ? "&search_by_sku=" + sku : "")
    );
  };

  const onMoreClicked = (id) => {
    var customer = customers.find(customer => customer.id == id);
    customer.more_dropdown_is_visible = true;
    let prev_customers = Object.assign([], customers);
    customers.map(customer => {
      customer.more_dropdown_is_visible = false;
      if (customer.id == id) {
        customer.more_dropdown_is_visible = true;
      }
    });
    setCustomers(prev_customers);
  };

  const handlePageChange = (pageNumber) => {
    getCustomers(pageNumber.selected + 1);
  }

  const getCustomers = (page) => {
    setData(false);
    apiJson.customers.getCustomers(1, page, records_per_page, searchedText, date).then((response) => {
      if (response.success) {
        var count = 0;
        let users = response.data.customers;
        users.map(customer => {
          var total_spend = 0;
          customer.orders.map(order => {
            total_spend = total_spend + parseFloat(order.grand_total);
          });
          customer.total_spend = total_spend;
          customer.isChecked = false;
        });
        users.map(user => {
          selected_ids.map(id => {
            if (id == user.id) {
              user.isChecked = true;
            }
          });
        });
        setSelectAllIsChecked(false);
        setCustomers(users);
        setPagination(response.data.pagination);
        setData(true);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const onSelectAllCheckboxChanged = (e) => {
    if (e.target.checked) {
      var prev_customers = customers;
      let ids = selected_ids;
      prev_customers.forEach(customer => {
        ids = ids.concat((customer.id).toString());
        customer.isChecked = true;
      });
      setSelectedIds(ids);
      setSelectAllIsChecked(true);
    }
    else {
      var ids = selected_ids;
      var prev_customers = customers;
      prev_customers.forEach(customer => {
        ids = ids.filter(id => id !== (customer.id).toString());
        customer.isChecked = false;
      });
      setSelectedIds(ids);
      setSelectAllIsChecked(false);
    }
    setCustomers(prev_customers);
  }

  const onCheckboxClicked = (e) => {
    if (e.target.checked) {
      setSelectedIds(selected_ids.concat(e.target.value));

      var prev_customers = customers;
      prev_customers.forEach(customer => {
        if (customer.id == parseInt(e.target.value)) {
          customer.isChecked = true;
        }
      });
    }
    else {
      const items = selected_ids.filter(item => item !== e.target.value);
      setSelectedIds(items);

      var prev_customers = customers;
      prev_customers.forEach(customer => {
        if (customer.id == parseInt(e.target.value)) {
          customer.isChecked = false;
        }
      });
    }
    setCustomers(prev_customers);
  }

  const exportCustomers = () => {
    setExporting(true);
    apiJson.customers.exportCustomers({
      ids: selected_ids
    }).then((response) => {
      if (response.success) {
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(blob, "customers_orders.csv");
        message = "Customers exported successfully.";
        showSuccess();
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
      setExporting(false);
    });
  }

  const onSearchBtnClicked = () => {
    let page = 1;
    apiJson.customers.getCustomers(1, page, records_per_page, searchedText, date)
      .then(response => {
        if (response.success) {
          response.data.customers.map(customer => {
            var total_spend = 0;
            customer.orders.map(order => {
              total_spend = total_spend + parseFloat(order.grand_total);
            });
            customer.total_spend = total_spend;
            customer.isChecked = false;
          });
          setCustomers(response.data.customers);
          setPagination(response.data.pagination);
          setData(true);
        }
        else {
          console.log("error");
        }
      });
  }

  return (
    <Fragment>
      <div className="right-content details-page">
        <div className="header-row">
          <Header
            subtext="Create, edit, and manage the customers on your site."
            heading="View Customers"
            search={false}
            sku={false}
            filters={null}
          // handleSearch={(text) => {
          //   setSearchText(text);
          //   debouncedCallback(text, tabTitle, SKUSearchText);
          // }}
          // handleSKUSearch={(text) => {
          //   setSKUSearchText(text);
          //   debouncedSKUCallback(text, tabTitle, searchText);
          // }}
          // searchText={searchText}
          // searchSKUText={SKUSearchText}
          />
        </div>
        <div className="main-row content-container">
          <div className="content-wrapper">
            {!data ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#000" loading size={35} />
              </div>
            ) : (
              customers && (
                <div className="product-tab">
                  <div className="row">
                    <div className="col-md-6">
                      {
                        exporting ?
                          <div className="btn btn-primary">
                            Exporting <ClipLoader color="white" loading size={17} />
                          </div>
                          :
                          <div className="btn btn-primary"
                            onClick={exportCustomers}
                          >
                            Export
                          </div>
                      }
                    </div>
                    <div className="col-md-6 row">
                      <input type="text" placeholder="Search by name, email..." value={searchedText} onChange={(e) => setSearchedText(e.target.value)} className="form-control" style={{ maxWidth: "250px", marginLeft: "auto" }} />
                      <input type="date" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} className="form-control" style={{ maxWidth: "250px" }} />
                      <input type="button" className="btn btn-primary" value="Search" style={{ maxWidth: "70px" }} onClick={onSearchBtnClicked} />
                    </div>
                  </div>
                  <br />
                  <div>
                    <input type="checkbox" onChange={onSelectAllCheckboxChanged} checked={selectAllIsChecked} />
                    &nbsp;Select all
                  </div>
                  <table className="table table-bordered">
                    <tr>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Created at</th>
                      <th>Phone</th>
                      <th>Order Count</th>
                      <th>Total Spend</th>
                      <th>Wallet</th>
                      <th></th>
                    </tr>
                    {
                      customers.map((customer) => {
                        return (
                          <tr>
                            <td>
                              <input type="checkbox" value={customer.id} onChange={onCheckboxClicked} checked={customer.isChecked} />&nbsp;
                              {customer.id}
                            </td>
                            <td>{customer.first_name_en}&nbsp;{customer.last_name_en}</td>
                            <td>{customer.email}</td>
                            <td>{new Date(customer.created_at).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{customer.contact_number ? customer.contact_number : customer.default_address?.contact_number}</td>
                            <td>{customer.total_orders}</td>
                            <td>AED {customer.total_spend}</td>
                            <td>AED {customer.wallet_amount}</td>
                            <td onClick={() => onMoreClicked(customer.id)}>
                              <span style={{ cursor: "pointer" }}>...</span>
                              {
                                customer.more_dropdown_is_visible ?
                                  <div className="more-div">
                                    <Link to={"/customer/" + customer.id}><p>View</p></Link>
                                    <hr style={{ margin: "0px" }} />
                                    <Link to={"/customer/edit/" + customer.id}><p>Edit</p></Link>
                                  </div>
                                  :
                                  <></>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                  {customers.length > 0 && (
                    <div
                      className="d-flex justify-content-center mb-4 w-100"
                      style={{ direction: "ltr" }}
                    >
                      <Pagination
                        direction="ltr"
                        previousLabel={<FaChevronLeft color="#404553" />}
                        nextLabel={<FaChevronRight color="#404553" />}
                        pageCount={pagination?.total_pages}
                        forcePage={parseInt(pagination?.page) - 1}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={2}
                        containerClassName="pagination"
                        pageClassName="ps-3 pe-3 pt-2 pb-2"
                        activeClassName={
                          "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
                        }
                        nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                        previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
                      />
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.product.pagination,
    products: state.product.products,
  };
};

const mapDispatchToProps = { set_products };

export default connect(mapStateToProsps, mapDispatchToProps)(ViewCustomers);
