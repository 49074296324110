import React, { Fragment, useState, useEffect } from "react";
import { set_users } from "../../../redux/actions/UsersAction";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import "./profile.scss";
import UserList from "./components/UserList";
import Header from "../../../components/header/Header";

const Profile = (props) => {
  const [data, setData] = useState(false);
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Users";
  }, []);

  useEffect(() => {
    setData(true);
    apiJson.profile.getUsersList(1).then((response) => {
      if (response.success) {
        props.set_users({
          users: response.data.customers,
          pagination: response.data.pagination,
        });
        setData(false);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(false);
      } else {
        setData(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const heading = "Users List";
  return (
    <Fragment>
      <div className="right-content profile-page-desktop">
        <div className="header-row">
          <Header heading={heading} subtext="Manage Users on your site." />
        </div>
        <div className="main-row  profile-page-container">
          {data ? (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          ) : (
            <div className="content-wrapper">
              <div className="user-list">
                <UserList AccountDetail={props.users} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { users: state.user.users };
};

const mapDispatchToProps = { set_users };

export default connect(mapStateToProsps, mapDispatchToProps)(Profile);
