import React, { Fragment, useState, useEffect } from "react";

import "./tabs.scss";
import TableList from "./TableList";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { set_sections } from "redux/actions/SectionsAction";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import ProductsTableList from "./ProductsTableList";

const TabProducts = (props) => {
  const [data, setData] = useState(false);
  const [headerList, setHeaderList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [products, setProducts] = useState([]);

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Tab Products";
  }, []);

  useEffect(() => {
    apiJson.home_tabs.getTabProducts(props.match.params.id, 1).then((response) => {
      if (response.success) {
        setProducts(response.data.tab_products);
        setData(true);
        setHeaderList({
          id: "sectionstab",
          tablist: [{ id: "all", count: response.data.length }],
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
        setData(true);
      } else {
        setData(true);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="right-content brands-page-container w-100">
        <div className="header-row">
          <Header
            heading="Tab Products"
            subtext="Create, edit, and manage Tab products on your site."
          />
        </div>
        <div className="main-row content-container-row w-100">
          {!data ? (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          ) : (
            <div className="content mx-auto">
              <div className="sections-tab">
                <HeaderTab tab={headerList}>
                  <ProductsTableList List={products} tab={props.match.params.id} />
                </HeaderTab>
              </div>
            </div>
          )}
        </div>
        {redirect && (
          <Redirect
            push
            to={{ pathname: "/section", state: { name: "create" } }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default TabProducts;
