import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  set_services,
  set_service_details,
  remove_service,
} from "redux/actions/ServicesAction";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { getResizedImage } from "Extra/GetResizedImage";

const TableList = (props) => {
  const [currentService, setCurrentService] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [isAddToClick, setisAddToClick] = useState(false);
  const [isEditClick, setisEditClick] = useState(false);
  const [isViewClick, setisViewClick] = useState(false);
  const [values, setValues] = useState("");

  useEffect(() => {
    setValues(props.List);
  }, [props.List]);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSucess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleViewClick = () => {
    setisViewClick(true);
  };

  const handleEditClick = () => {
    setisEditClick(true);
  };

  const handleDeleteClick = () => {
    if (window.confirm("Are you sure you want to delete this section?")) {
      setIsDelete(true);
      deleteService(currentService.id);
    }
  };

  const deleteService = (id) => {
    apiJson.services.removeService(id).then((response) => {
      if (response.success) {
        props.updateTablist();
        props.set_services({
          services: props.services.filter((ser) => ser.id !== response.data.id),
        });
        message = "Service Deleted!";
        showSucess();
        setIsDelete(false);
      } else if (!response.status) {
        setIsDelete(false);
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        setIsDelete(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  const handleAddToSection = () => {
    setisAddToClick(true);
  };

  const handlePageChange = (pageNumber) => {
    props.onPageChange(pageNumber.selected + 1);
  };
  const menuItems = [
    <MenuItem
      key="2"
      onClick={handleViewClick}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      View
    </MenuItem>,
    <MenuItem
      key="4"
      onClick={handleEditClick}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key="1"
      onClick={handleAddToSection}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Add to section
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={handleDeleteClick}
      style={{ cursor: "pointer", textAlign: "center" }}
    >
      Delete
    </MenuItem>,
  ];
  const menu = <Menu className="menu">{menuItems}</Menu>;

  return (
    <div className="table-responsive service-catalog-table">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="">Service Image</td>
            <td className="">Name</td>
            <td className="">Service Type</td>
            <td className="">Status</td>
            <td className="">Created At</td>
            <td className="">Price Per Unit</td>
            <td className="">Store Name</td>
            <td className="">Actions</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.map((service) => {
              let id = service.id;
              return (
                <tr key={service.id}>
                  <td className="service-image">
                    <img
                      src={getResizedImage(
                        service.featured_image_resized,
                        service.featured_image
                      )}
                      style={{
                        width: "auto",
                        maxWidth: "80px",
                        height: "80px",
                      }}
                      alt=""
                    />
                  </td>
                  <td className="name-text">{service.name_en}</td>
                  <td className="name-text">{service.service_type === "one_time" ? "One Time" : "Hourly"}</td>
                  <td>
                    <div className="status-text">{service.status}</div>
                  </td>
                  <td>
                    <div className="name-text">
                      {moment(new Date(service.created_at)).format("LL")}
                    </div>
                  </td>
                  <td
                    className="name-text"
                    style={{ textTransform: "uppercase" }}
                  >
                    {service.discounted_price} {service.currency}
                  </td>
                  <td className="name-text">{service.store_name_en}</td>
                  <td className="" onClick={(e) => e.preventDefault()}>
                    <div className="">
                      <Dropdown
                        className="dropdown-for-send"
                        overlay={menu}
                        overlayClassName="overlay-class"
                      >
                        {isDelete ? (
                          <BsThreeDots
                            style={{ cursor: "pointer" }}
                            size={20}
                          />
                        ) : (
                          <BsThreeDots
                            style={{ cursor: "pointer" }}
                            onClick={() => setCurrentService(service)}
                            onMouseEnter={() => setCurrentService(service)}
                            size={20}
                          />
                        )}
                      </Dropdown>
                      {isDelete && id === currentService.id && (
                        <div className="d-flex justify-content-center align-items-center">
                          <ClipLoader color="#000" loading size={15} />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.pagination?.total_pages && values.length > 0 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            forcePage={parseInt(props?.pagination?.page) - 1}
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
      {values !== "" ? (
        isEditClick ? (
          <Redirect
            push
            to={{
              pathname: `/service/${currentService.id}/edit`,
              state: { name: "edit", id: currentService.id },
            }}
          />
        ) : isViewClick ? (
          <Redirect
            push
            to={{
              pathname: `/service/${currentService.id}/view`,
              state: { name: "view", id: currentService.id },
            }}
          />
        ) : (
          isAddToClick && (
            <Redirect
              push
              to={{
                pathname: `/add-service/${currentService.id}`,
              }}
            />
          )
        )
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.service.services,
  };
};

const mapDispatchToProps = {
  set_service_details,
  set_services,
  remove_service,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
