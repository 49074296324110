require("dotenv").config();
export const domain = process.env.REACT_APP_API_DOMAIN;
const api = "api/v2";
export const apiUrl = {
  header: {
    getBrands: `${domain}/${api}/customer/brands?country_id=`,
    getCountries: `${domain}/${api}/customer/countries`,
    allBrands: `${domain}/${api}/customer/brands?country_id=`,
    allCategories: `${domain}/${api}/customer/categories/details_with_brands?country_id=`,
    featuredCategories: `${domain}/${api}/customer/categories/featured?country_id=`,
    search: `${domain}/${api}/customer/search?country_id=`,
    searchProducts: `${domain}/${api}/customer/search/search_products?country_id=`,
    recentSearches: `${domain}/${api}/customer/recent_searches`,
    deleteRecent: `${domain}/${api}/customer/recent_searches/`,
    clearHistory: `${domain}/${api}/customer/recent_searches/clear_history`,
  },
  customers: {
    getCustomers: `${domain}/${api}/admin/customers?country_id=`,
    getCustomer: `${domain}/${api}/admin/customers/`,
    getCustomerCart: `${domain}/${api}/admin/customers/`,
    sendAbandonedCartMail: `${domain}/${api}/admin/customers/`,
    getCustomerWishListItems: `${domain}/${api}/admin/customers/`,
    getCustomerRecentOrders: `${domain}/${api}/admin/customers/`,
    getCustomerOrders: `${domain}/${api}/admin/orders/customer_orders?customer_id=`,
    exportCustomers: `${domain}/${api}/admin/bulk_export/customers_orders`,
  },
  trakingLogs: {
    getTrakingLogs: `${domain}/${api}/admin/change_logs`,
    search: `${domain}/${api}/customer/search?country_id=`,
  },
  auth: {
    resetPass: `${domain}/auth/password`,
    validateToken: `${domain}/auth/validate_token`,
    signout: `${domain}/auth/sign_out`,
  },
  offer: {
    createOffer: `${domain}/${api}/admin/offers`,
    createOffersSections: `${domain}/${api}/admin/offers_sections`,
    getOffers: `${domain}/${api}/admin/offers`,
    getVariantsBySku: `${domain}/${api}/admin/offers/variants_by_sku`,
    getOffersSections: `${domain}/${api}/admin/offers_sections`,
    getOffersBanners: `${domain}/${api}/admin/offers_banners`,
    deleteBanner: `${domain}/${api}/admin/offers_banners/`,
    createOffersBanners: `${domain}/${api}/admin/offers_banners`,
    editOffersBanners: `${domain}/${api}/admin/offers_banners/`,
  },
  newoffer: {
    createOffer: `${domain}/${api}/admin/new_offers`,
    createOffersSections: `${domain}/${api}/admin/new_offers_sections`,
    getOffers: `${domain}/${api}/admin/new_offers`,
    getVariantsBySku: `${domain}/${api}/admin/new_offers/variants_by_sku`,
    getOffersSections: `${domain}/${api}/admin/new_offers_sections`,
    getOffersBanners: `${domain}/${api}/admin/new_offers_banners`,
    deleteBanner: `${domain}/${api}/admin/new_offers_banners/`,
    createOffersBanners: `${domain}/${api}/admin/new_offers_banners`,
    editOffersBanners: `${domain}/${api}/admin/new_offers_banners/`,
  },
  coupons: {
    getCoupons: `${domain}/${api}/admin/coupons?page=`,
    getTabsCount: `${domain}/${api}/admin/coupons/tabs`,
    createCoupan: `${domain}/${api}/admin/coupons`,
    updateCoupon: `${domain}/${api}/admin/coupons/`,
  },
  pushnotificationmessages: {
    create: `${domain}/${api}/admin/push_notifications`
  },
  dispute: {
    getDisputes: `${domain}/${api}/common/disputes?role=admin&page=`,
    getTabsCount: `${domain}/${api}/common/disputes/tabs`,
    getDisputeDetails: `${domain}/${api}/common/disputes/`,
    createReply: `${domain}/${api}/common/disputes/`,
    updateDispute: `${domain}/${api}/common/disputes/`,
  },
  dashboard: {
    getDashboardData: `${domain}/${api}/admin/dashboard`,
  },
  cart: {
    addToCart: `${domain}/${api}/customer/line_items`,
    moveToWishList: `${domain}/${api}/customer/wishlist_items/toggle`,
    removeItem: `${domain}/${api}/customer/line_items`,
    updateProduct: `${domain}/${api}/customer/line_items`,
    addCoupon: `${domain}/${api}/customer/coupons/calculate_discount?coupon_code=`,
    removeCoupon: `${domain}/${api}/customer/coupons/remove?`,
    clearCart: `${domain}/${api}/customer/cart/clear`,
    clearWishlist: `${domain}/${api}/customer/wishlist_items/clear`,
    getCartItems: `${domain}/${api}/customer/cart`,
    getWishList: `${domain}/${api}/customer/wishlist_items`,
    updateCartReset: `${domain}/${api}/customer/cart/reset_cart_data_changed_flag?`,
    discountReset: `${domain}/${api}/customer/cart/reset_expired_coupon_removed_flag?`,
  },
  subadmins: {
    addSubadmin: `${domain}/auth`,
    assignSubdomainRoleToUser: `${domain}/${api}/admin/users/`,
    getSubAdmins: `${domain}/${api}/admin/sub_admins`,
    getPermissions: `${domain}/${api}/admin/sub_admins/permissions`
  },
  address: {
    getAddress: `${domain}/${api}/admin/addresses`,
    setDefault: `${domain}/${api}/admin/addresses/`,
    addAddress: `${domain}/${api}/admin/addresses`,
    varifyAddress: `${domain}/${api}/admin/orders`,
    deletAddress: `${domain}/${api}/admin/addresses/`,
    updateAddress: `${domain}/${api}/admin/addresses/`,
  },
  orders: {
    getOrders: `${domain}/${api}/admin/orders?page=`,
    getTabsCount: `${domain}/${api}/admin/orders/tabs`,
    getOrderDetails: `${domain}/${api}/admin/orders/`,
    completeTransaction: `${domain}/${api}/admin/orders/`,
    updateOrder: `${domain}/${api}/admin/orders/`,
    getOrderReceipt: `${domain}/${api}/admin/orders/`,
    getPackingSlip: `${domain}/${api}/admin/orders/`,
    createOrder: `${domain}/${api}/customer/orders`,
    placeOrder: `${domain}/${api}/customer/orders/`,
  },
  store: {
    getStores: `${domain}/${api}/admin/stores`,
    getSingleStore: `${domain}/${api}/admin/stores/`,
    updateStore: `${domain}/${api}/admin/stores/`,
  },
  country: {
    getCountries: `${domain}/${api}/admin/countries`,
  },
  home_offers_sections: {
    getSections: `${domain}/${api}/admin/home_offers_sections?country_id=`,
    createSection: `${domain}/${api}/admin/home_offers_sections`,
    deleteSection: `${domain}/${api}/admin/home_offers_sections/`,
    getSectionImages: `${domain}/${api}/admin/home_offers_sections/`
  },
  home_tabs: {
    getTabs: `${domain}/${api}/admin/home_products_tabs?country_id=`,
    createTab: `${domain}/${api}/admin/home_products_tabs?country_id=`,
    getTab: `${domain}/${api}/admin/home_products_tabs/`,
    deleteTab: `${domain}/${api}/admin/home_products_tabs/`,
    getTabProducts: `${domain}/${api}/admin/home_tab_products/`,
    deleteTabProduct: `${domain}/${api}/admin/home_tab_products/`
  },
  section: {
    getSections: `${domain}/${api}/admin/sections/all`,
    getPaginatedSections: `${domain}/${api}/admin/sections?page=`,
    getTabsCount: `${domain}/${api}/admin/sections/tabs`,
    createSection: `${domain}/${api}/admin/sections`,
    getSingleSection: `${domain}/${api}/admin/sections/`,
    removeSection: `${domain}/${api}/admin/sections/`,
    addProductToSection: `${domain}/${api}/admin/sections/`,
    addServiceToSection: `${domain}/${api}/admin/sections/`,
    removeServiceFromSection: `${domain}/${api}/admin/sections/`,
    removeProductFromSection: `${domain}/${api}/admin/sections/`,
    updateSection: `${domain}/${api}/admin/sections/`,
  },
  services: {
    getServices: `${domain}/${api}/admin/services?page=`,
    getTabsCount: `${domain}/${api}/admin/services/tabs`,
    getAllServices: `${domain}/${api}/customer/services`,
    getServiceDetail: `${domain}/${api}/admin/services/`,
    createService: `${domain}/${api}/admin/services`,
    updateService: `${domain}/${api}/admin/services/`,
    removeService: `${domain}/${api}/admin/services/`,
    getRelatedServices: `${domain}/${api}/admin/services/related_services?`,
  },
  products: {
    getProductsMetrix: `${domain}/${api}/admin/products/content_pq_metrix?page=`,
    getProducts: `${domain}/${api}/admin/products?page=`,
    getTabsCount: `${domain}/${api}/admin/products/tabs`,
    getAllProducts: `${domain}/${api}/customer/products`,
    getAllProductsVariants: `${domain}/${api}/customer/products/products_variants`,
    getProductDetail: `${domain}/${api}/admin/products/`,
    createProduct: `${domain}/${api}/admin/products`,
    updateProduct: `${domain}/${api}/admin/products/`,
    removeProduct: `${domain}/${api}/admin/products/`,
    getRelatedProducts: `${domain}/${api}/admin/products/related_products?`,
    getRelatedTools: `${domain}/${api}/admin/products/related_tools?`,
  },
  imports: {
    bulkImport: `${domain}/${api}/common/bulk_imports/import`,
  },
  variants: {
    getVariants: `${domain}/${api}/admin/products/`,
    removeVariant: `${domain}/${api}/admin/products/`,
  },
  returns: {
    getReturns: `${domain}/${api}/admin/refunds?page=`,
    getTabsCount: `${domain}/${api}/admin/refunds/tabs`,
    getReturnDetail: `${domain}/${api}/admin/refunds/`,
    updateReturn: `${domain}/${api}/admin/refunds/`,
    refund: `${domain}/${api}/admin/refunds/`,
  },
  categories: {
    getCategories: `${domain}/${api}/admin/categories/all`,
    getPaginatedCategories: `${domain}/${api}/admin/categories?page=`,
    getTabsCount: `${domain}/${api}/admin/categories/tabs`,
    getLeafCategories: `${domain}/${api}/admin/categories/available_leaf`,
    createCategory: `${domain}/${api}/admin/categories`,
    removeCategory: `${domain}/${api}/admin/categories`,
    editCategory: `${domain}/${api}/admin/categories/`,
    getCategoriesForBrand: `${domain}/${api}/admin/categories/for_brand`,
    getCategoriesforCategories: `${domain}/${api}/admin/categories/for_category`,
    getCategoryDetail: `${domain}/${api}/admin/categories/`,
  },
  profile: {
    getUsers: `${domain}/${api}/admin/users`,
    removeUser: `${domain}/${api}/admin/users`,
    getCustomers: `${domain}/${api}/admin/users/customer_list`,
    getUsersList: `${domain}/${api}/admin/users/users_list?page=`,
  },
  gallery: {
    getGallery: `${domain}/${api}/admin/gallery/all`,
    getPaginatedGallery: `${domain}/${api}/admin/gallery?page=`,
    createGallery: `${domain}/${api}/admin/gallery`,
    updateGallery: `${domain}/${api}/admin/gallery/`,
    removeGallery: `${domain}/${api}/admin/gallery/`,
    getGalleryDetails: `${domain}/${api}/admin/gallery/`
  },
  brands: {
    getBrands: `${domain}/${api}/admin/brands/all`,
    getPaginatedBrands: `${domain}/${api}/admin/brands?page=`,
    getTabsCount: `${domain}/${api}/admin/brands/tabs`,
    getBrandsByLeafCategory: `${domain}/${api}/admin/brands/by_leaf_category`,
    createBrand: `${domain}/${api}/admin/brands`,
    updateBrand: `${domain}/${api}/admin/brands/`,
    removeBrand: `${domain}/${api}/admin/brands/`,
    getBrandByCategory: `${domain}/${api}/admin/brands/by_category?category_id=`,
    getBrandDetails: `${domain}/${api}/admin/brands/`,
  },
  adminProfile: {
    getadminProfile: `${domain}/${api}/admin/store`,
  },
  createStore: {
    createadmin: `${domain}/auth`,
  },
  settings: {
    getPlatformSettingID: `${domain}/${api}/admin/platforms/platform_id`,
    getPlatformSetting: `${domain}/${api}/admin/platforms`,
    updatePlatformSetting: `${domain}/${api}/admin/platforms`,
  },
  banner: {
    getBanners: `${domain}/${api}/admin/banners?page=`,
    getTabsCount: `${domain}/${api}/admin/banners/tabs`,
    getBanner: `${domain}/${api}/admin/banners`,
    updateBanner: `${domain}/${api}/admin/banners`,
    createBanner: `${domain}/${api}/admin/banners`,
    deleteBanner: `${domain}/${api}/admin/banners`,
    getBannerDetails: `${domain}/${api}/admin/banners/`,
  },
  zone: {
    getShippingZones: `${domain}/${api}/admin/shipping_zones`,
    createShippingZone: `${domain}/${api}/admin/shipping_zones`,
    editShippingZone: `${domain}/${api}/admin/shipping_zones/`,
    getZoneDetail: `${domain}/${api}/admin/shipping_zones/`,
    deleteZone: `${domain}/${api}/admin/shipping_zones/`,
  },
  shippingRules: {
    getShippingRules: `${domain}/${api}/admin/shipment_rules`,
  },
  emails: {
    sendEmail: `${domain}/${api}/admin/emails/send_individual_email`,
  },
  reports: {
    getTotalSales: `${domain}/${api}/admin/reports/total_sales?sale[start_date]=`,
    getAllUsers: `${domain}/${api}/admin/reports/all_users`,
    getAllProducts: `${domain}/${api}/admin/reports/all_products`,
    getAllServices: `${domain}/${api}/admin/reports/all_services`,
    getInventory: `${domain}/${api}/admin/reports/inventory`,
    getAllStores: `${domain}/${api}/admin/reports/all_stores`,
    getProfit: `${domain}/${api}/admin/reports/profit?profit[start_date]=`,
    getVendorPayment: `${domain}/${api}/admin/reports/vendor_payment?vendor_payment[start_date]=`,
  },
  exports: {
    exportActivateProducts: `${domain}/${api}/admin/bulk_export`,
    exportAllOrders: `${domain}/${api}/admin/bulk_export/all_orders`
  }
};
