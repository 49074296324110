import { type } from "../../types/types";

export default function reducer(state = { zones: [], singleZone: {} }, action) {
  switch (action.type) {
    case type.SET_ZONES:
      return { ...state, ...action.zones_list };
    default:
      return state;
  }
}
