import Header from "components/header/Header";
import React, { useState } from "react";
import "../pages/admin/product/CreateProductPage/createproduct.scss";
import "../assets/styles/product/productMain.scss";
import ProductCard from "./ProductCard";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  set_variants,
  set_product_details,
  set_deleted_images,
} from "redux/actions/ProductsAction";
import { apiJson } from "api/Api";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { deleteS3Image } from "utils/utils";
import { isEmpty } from "lodash";

function ProductMain(props) {
  const { state, id } = useParams();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [product, setProduct] = useState(
    state !== "create" ? props.productDetails : {}
  );
  const [redirectToCreate, setRedirectToCreate] = useState(false);
  const heading = id === "create" ? "Create Product" : "View/Edit your product";
  let message = "";
  const { user } = props
  const path = window.location.pathname.split('/');
  const currentRoute = path[path.length - 1]

  useEffect(() => {
    if (!isEmpty(user) && !user.permissions.products.show && currentRoute === "view" && /^[+-]?\d+$/.test(id)) {
      message = "User has no role of admin or specific permissions"
      showError();
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }

    if (!isEmpty(user) && !user.permissions.products.update && currentRoute === "edit" && /^[+-]?\d+$/.test(id)) {
      message = "User has no role of admin or specific permissions"
      showError();
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }

    if (!isEmpty(user) && !user.permissions.products.create && id === "create") {
      message = "User has no role of admin or specific permissions"
      showError();
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  }, [user]);


  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSucess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const onSave = (val) => {
    deleteS3Image(props.deletedImagesUrl);
    setLoading(true);
    let variants = val.variants;
    delete val["variants"];

    apiJson.products
      .createProduct({
        product: {
          ...val,
          status: val.status,
          name_ar: val.name_ar?.length > 1 ? val.name_ar : val.name_en,
          description_ar:
            val.description_ar?.length > 1 &&
              val.description_ar !== "<p><br></p>"
              ? val.description_ar
              : val.description_en,
          benefits_ar:
            val.benefits_ar?.length > 1 && val.benefits_ar !== "<p><br></p>"
              ? val.benefits_ar
              : val.benefits_en,
          features_ar:
            val.features_ar?.length > 1 && val.features_ar !== "<p><br></p>"
              ? val.features_ar
              : val.features_en,
          how_to_use_ar:
            val.how_to_use_ar?.length > 1 && val.how_to_use_ar !== "<p><br></p>"
              ? val.how_to_use_ar
              : val.how_to_use_en,
          video: val.video ? val.video : "",
          variants_attributes: [
            ...variants?.map((variant) => {
              delete variant["id"];
              return {
                ...variant,
                name_ar:
                  variant.name_ar?.length > 1
                    ? variant.name_ar
                    : variant.name_en,
                description_ar:
                  variant.description_ar?.length > 1 &&
                    variant.description_ar !== "<p><br></p>"
                    ? variant.description_ar
                    : variant.description_en,
                benefits_ar:
                  variant.benefits_ar?.length > 1 &&
                    variant.benefits_ar !== "<p><br></p>"
                    ? variant.benefits_ar
                    : variant.benefits_en,
                features_ar:
                  variant.features_ar?.length > 1 &&
                    variant.features_ar !== "<p><br></p>"
                    ? variant.features_ar
                    : variant.features_en,
                how_to_use_ar:
                  variant.how_to_use_ar?.length > 1 &&
                    variant.how_to_use_ar !== "<p><br></p>"
                    ? variant.how_to_use_ar
                    : variant.how_to_use_en,
                discounted_price:
                  val.product_type == "wholesale" ?
                    0
                    :
                    variant.discounted_price &&
                      parseFloat(variant.discounted_price) > 0
                      ? variant.discounted_price
                      : variant.price,
                discount_percentage:
                  variant.discounted_price &&
                    parseFloat(variant.discounted_price) > 0
                    ?
                    ((variant.price - variant.discounted_price) / variant.price) * 100
                    :
                    0,
                price:
                  val.product_type == "wholesale" ?
                    0
                    :
                    variant.price,
                quantity:
                  val.product_type == "wholesale" || val.is_inquiry == true ?
                    0
                    :
                    variant.quantity,
                upc_code: variant?.upc_code
              };
            }),
          ],
        },
      })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setRedirect(true);
          message = "Product created successfully";
          showSucess();
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const onUpdate = (val) => {
    deleteS3Image(props.deletedImagesUrl);

    setLoading(true);
    let variants = val.variants;
    delete val["variants"];

    // console.log({
    //   product: {
    //     ...val,
    //     status: val.status,
    //     name_ar: val.name_ar?.length > 1 ? val.name_ar : val.name_en,
    //     description_ar:
    //       val.description_ar?.length > 1 &&
    //         val.description_ar !== "<p><br></p>"
    //         ? val.description_ar
    //         : val.description_en,
    //     benefits_ar:
    //       val.benefits_ar?.length > 1 && val.benefits_ar !== "<p><br></p>"
    //         ? val.benefits_ar
    //         : val.benefits_en,
    //     features_ar:
    //       val.features_ar?.length > 1 && val.features_ar !== "<p><br></p>"
    //         ? val.features_ar
    //         : val.features_en,
    //     how_to_use_ar:
    //       val.how_to_use_ar?.length > 1 && val.how_to_use_ar !== "<p><br></p>"
    //         ? val.how_to_use_ar
    //         : val.how_to_use_en,
    //     video: val.video ? val.video : ""
    //   },
    // });

    apiJson.products
      .updateProduct(val.id, {
        product: {
          ...val,
          status: val.status,
          name_ar: val.name_ar?.length > 1 ? val.name_ar : val.name_en,
          description_en: val.description_en?.length > 1 ? val.description_en : "",
          description_ar:
            val.description_ar?.length > 1
              ? val.description_ar
              : val.description_en,
          benefits_en: val.benefits_en?.length > 1
            ?
            val.benefits_en
            :
            "",
          benefits_ar:
            val.benefits_ar?.length > 1
              ? val.benefits_ar
              : val.benefits_en?.length > 1
                ?
                val.benefits_en
                :
                "",
          features_en: val.features_en?.length > 1
            ?
            val.features_en
            :
            "",
          features_ar:
            val.features_ar?.length > 1
              ? val.features_ar
              : val.features_en?.length > 1
                ?
                val.features_en
                :
                "",
          how_to_use_en: val.how_to_use_en?.length > 1
            ?
            val.how_to_use_en
            :
            "",
          how_to_use_ar:
            val.how_to_use_ar?.length > 1
              ? val.how_to_use_ar
              : val.how_to_use_en?.length > 1
                ?
                val.how_to_use_en
                :
                "",
          video: val.video ? val.video : "",
          variants_attributes: [
            ...variants?.map((variant) => {
              !variant._destroy &&
                typeof variant.id === "string" &&
                delete variant["id"];
              return {
                ...variant,
                name_ar:
                  variant.name_ar?.length > 1
                    ? variant.name_ar
                    : variant.name_en,
                description_ar:
                  variant.description_ar?.length > 1
                    ? variant.description_ar
                    : variant.description_en,
                benefits_ar:
                  variant.benefits_ar?.length > 1
                    ? variant.benefits_ar
                    : variant.benefits_en,
                features_ar:
                  variant.features_ar?.length > 1
                    ? variant.features_ar
                    : variant.features_en,
                how_to_use_ar:
                  variant.how_to_use_ar?.length > 1
                    ? variant.how_to_use_ar
                    : variant.how_to_use_en,
                discounted_price:
                  val.product_type == "wholesale" ?
                    0
                    :
                    variant.discounted_price &&
                      parseFloat(variant.discounted_price) > 0
                      ? variant.discounted_price
                      : variant.price,
                discount_percentage:
                  variant.discounted_price &&
                    parseFloat(variant.discounted_price) > 0
                    ?
                    ((variant.price - variant.discounted_price) / variant.price) * 100
                    :
                    0,
                price:
                  val.product_type == "wholesale" ?
                    0
                    :
                    variant.price,
                quantity:
                  val.product_type == "wholesale" || val.is_inquiry == true ?
                    0
                    :
                    variant.quantity,
                upc_code: variant?.upc_code
              };
            }),
          ],
        },
      })
      .then((response) => {
        if (response.success) {
          setLoading(false);
          message = "Product Updated Successfully";
          showSucess();
          history.push(`/product/${id}/view`);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  useEffect(() => {
    props.set_deleted_images({
      deletedImagesUrl: [],
    });
    setData(false);
    setRedirectToCreate(false);
    if (id !== "create") {
      apiJson.products.getProductDetail(id).then((response) => {
        if (response.success) {
          console.log(response.data);
          props.set_product_details({ productDetails: response.data });
          props.set_variants(response.data.variants);
          setProduct(response.data);
          setData(true);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          setData(true);
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    } else {
      props.set_variants([]);
      setProduct({});
      setData(true);
    }
  }, [state, id]);

  return (
    <div>
      <div className="product-main-desktop right-content create-edit-product-desktop">
        <div className="header-row">
          <Header
            heading={heading}
            subtext="Create, edit, and manage the Products on your site."
          />
        </div>
        {state === "view" && user.permissions?.products?.create && (
          <div className="button-div">
            <button
              type="button"
              className="create-button"
              onClick={() => {
                setData(false);
                setRedirectToCreate(true);
              }}
            >
              Create New Product
            </button>
          </div>
        )}
        {!data ? (
          <div
            style={{ marginTop: "10px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <div className="main-row bottom-container">
            <div className="form">
              <ProductCard
                loading={loading}
                product={product}
                onSave={(val) => {
                  setProduct(val);
                  onSave(val);
                }}
                onUpdate={(val) => {
                  setProduct(val);
                  onUpdate(val);
                }}
                onClose={() => setRedirect(true)}
              />
            </div>
          </div>
        )}
      </div>
      {redirect && <Redirect push to="/product-catalog" />}
      {redirectToCreate && <Redirect push to="/product/create" />}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    brands: state.brand.brands,
    stores: state.stores.stores_list,
    productDetails: state.product.productDetails,
    user: state.auth.user,
    variants: state.product.variants,
    deletedImagesUrl: state.product.deletedImagesUrl,
  };
};

const mapDispatchToProps = {
  set_variants,
  set_product_details,
  set_deleted_images,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductMain);
