import React, { Fragment, useState } from "react";
// import { Row } from "reactstrap";
import { FiArrowUpLeft } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import "./searchpopover.scss";
import { useEffect } from "react";

const SearchPopover = (props) => {
  const [redirectToItems, setRedirectToItems] = useState({ value: false });
  const [redirectToCat, setRedirectToCat] = useState({ value: false });

  return (
    <Fragment>
      <div className="search-popover">
        {props.searching ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "50px" }}
          >
            <ClipLoader color="#000" loading size={25} />
          </div>
        ) : (
          <div className="row search-popover-row">
            <div className="search-col d-flex">
              <div className="search-result-col pl-2">
                <ul>
                  {props?.products?.map((product) =>
                    product.variants?.map((variant) => {
                      return (
                        <div
                          onClick={() =>
                            props.onVariantClicked(
                              props.changeLog ? product.id : variant.id
                            )
                          }
                        >
                          <li key={product.id}>
                            <span
                              style={{
                                width: "35px",
                                height: "35px",
                                minWidth: "35px",
                                minHeight: "35px",
                              }}
                              className="d-inline-flex justify-content-center"
                            >
                              <img
                                src={variant.featured_image_resized[0]}
                                style={{
                                  maxWidth: "35px",
                                  maxHeight: "35px",
                                }}
                                height="auto"
                                width="auto"
                                alt=""
                              />
                            </span>
                            <span
                              style={{
                                paddingInlineStart: "4px",
                                textAlign: "initial",
                                width: "calc(100% - 35px)",
                                maxWidth: "calc(100% - 35px)",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                position: "absolute",
                                fontSize: "15px",
                                lineHeight: "15px",
                                textTransform: "capitalize",
                              }}
                              className="pl-3"
                            >
                              {product.name_en + " " + variant.name_en}
                            </span>
                            <span
                              style={{
                                paddingInlineStart: "4px",
                                textAlign: "initial",
                                width: "calc(100% - 35px)",
                                maxWidth: "calc(100% - 35px)",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontSize: "14px",
                              }}
                              className="pl-3"
                            >
                              SKU:&nbsp;{variant.sku}
                            </span>
                          </li>
                          <hr style={{ margin: "0px" }} />
                        </div>
                      );
                    })
                  )}
                  {props?.users?.map((user) => (
                    <div onClick={() => props.onVariantClicked(user.id)}>
                      <li key={user.id} style={{ height: '40px'}}>
                        <span
                          style={{
                            paddingInlineStart: "4px",
                            textAlign: "initial",
                            width: "calc(100% - 35px)",
                            maxWidth: "calc(100% - 35px)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            position: "absolute",
                            fontSize: "15px",
                            lineHeight: "15px",
                            textTransform: "capitalize",
                            height: '30px'
                          }}
                          className="pl-3"
                        >
                          {user.first_name_en + " " + user.last_name_en}
                        </span>
                      </li>
                      <hr style={{ margin: "0px" }} />
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {redirectToItems.value && (
          <Redirect
            push
            to={{
              pathname:
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/items/products",
              state: redirectToItems.state,
            }}
          />
        )}
        {redirectToCat.value && (
          <Redirect
            push
            to={{
              pathname:
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/all-categories",
              state: { title: redirectToCat.title },
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     products: state.header.searchProducts,
//     countryUrl: state.language.countryUrl,
//     services: state.header.searchServices,
//     categories: state.header.searchCat,
//     brands: state.header.searchBrands,
//     language: state.language.language,
//   };
// };

export default SearchPopover;
