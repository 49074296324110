import React, { useEffect, useState } from "react";
import Header from "components/header/Header";
import "../subadmins/subadmins.scss";
import { apiJson } from "api/Api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";

function PushNotificationMessages(props) {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const { user } = props;

  const getNotifications = (page) => {
    apiJson.pushnotificationmessages
      .getPushNotification(page)
      .then((response) => {
        if (response.success) {
          setData(response.data.push_notifications);
          setPagination(response.data.pagination);
        }
      });
  };

  const pageChange = (page) => {
    getNotifications(page.selected + 1);
  };

  const showSucess = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSendNotification = (id) => {
    apiJson.pushnotificationmessages
      .sendPushNotification(id)
      .then((response) => {
        if (response.success) {
          showSucess("Push Notification send successfully!");
        }
      });
  };

  useEffect(() => {
    getNotifications(1);
  }, []);

  return (
    <div className="subadmins">
      <Header
        heading="Push Notification Messages"
        subtext="Push Notification Messages"
      />
      {user.permissions?.push_notification?.create && (
        <Link to="/push-notification/create" className="create-btn">
          Create Push Notification
        </Link>
      )}
      <div className="section">
        <table className="table table-bordered">
          <tr>
            <th>Title</th>
            <th>Message</th>
            <th>Action</th>
          </tr>
          {data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.message}</td>
                <td>
                  {user.permissions?.push_notification?.send && (
                    <span
                      className="create-btn"
                      style={{
                        marginTop: "0px",
                        cursor: "pointer",
                        minWidth: "max-content",
                      }}
                      onClick={() => onSendNotification(item.id)}
                    >
                      Send
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
        {pagination?.total_pages && data.length > 0 && (
          <div
            className="d-flex justify-content-center mb-4 w-100"
            style={{ direction: "ltr" }}
          >
            <Pagination
              direction="ltr"
              previousLabel={<FaChevronLeft color="#404553" />}
              nextLabel={<FaChevronRight color="#404553" />}
              pageCount={pagination?.total_pages}
              onPageChange={pageChange}
              pageRangeDisplayed={2}
              containerClassName="pagination"
              forcePage={parseInt(pagination?.page) - 1}
              pageClassName={" ps-3 pe-3 pt-2 pb-2 "}
              activeClassName={
                "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
              }
              nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
              previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(PushNotificationMessages);
