import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./createorder.scss";
import TableList from "./TableList";
import { OrdersTabData } from "Extra/TabsData";
import HeaderTab from "../tab/HeaderTab";
import Header from "components/header/Header";
import SearchPopover from "./SearchPopover";
import { set_orders } from "redux/actions/OrdersAction";
import { connect, useSelector } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "lodash";

const Orders = (props) => {
  const [countries, setCountries] = useState([]);
  const [selected_country, setSelectedCountry] = useState("");
  const [products_loading, setProductsLoading] = useState(false);
  const [searched_products, setSearchedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsOption, setProductsOption] = useState([]);
  const [adding, setAdding] = useState(false);
  const [cart, setCart] = useState({});
  const [order, setOrder] = useState({});

  const [isSearchClick, setIsSearchClick] = useState(false);
  const [recentvalue, setrecentvalue] = useState("");
  const [recentSearch, setrecentSearch] = useState([]);
  const [searching, setSearching] = useState(false);
  const [loader, setLoader] = useState(false);

  const [first_name, setFirstName] = useState("");
  const [first_name_error, setFirstNameError] = useState("");
  const [last_name, setLastName] = useState("-");
  const [last_name_error, setLastNameError] = useState("");
  const [address_1, setAddress1] = useState("");
  const [address_1_error, setAddress1Error] = useState("");
  const [address_2, setAddress2] = useState("");
  const [address_2_error, setAddress2Error] = useState("");
  const [contact, setContact] = useState("");
  const [contact_error, setContactError] = useState("");
  const [shipping_country, setShippingCountry] = useState("");
  const [shipping_country_error, setShippingCountryError] = useState("");
  const [state, setState] = useState("Abu Dhabi");
  const [state_error, setStateError] = useState("");
  const [label, setLabel] = useState("");
  const [label_error, setLabelError] = useState("");
  const [address_saved, setAddressSaved] = useState(false);
  const [place_order_btn_text, setPlaceOrderBtnText] = useState("PLACE ORDER");

  let message = "";

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Orders";

    apiJson.country.getCountries().then(response => {
      if (response.success) {
        setCountries(response.data);
        response.data.forEach(country => {
          clearCart(country.id);
        });
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);


  const onSeacrhChange = (text) => {
    if (selected_country) {
      setIsSearchClick(true);
      setrecentvalue(text);
      if (text.length < 2) {
        return [];
      } else {
        setAdding(true);
        !searching && setSearching(true);
        debouncedCallback(text, selected_country);
      }
    }
    else {
      message = "Select country first.";

      showError();
    }
  };

  const handleSearchClcik = (e) => {
    setIsSearchClick(true);
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text, selected_country) => performSearch(text, selected_country), 1000),
      []
    );

  const performSearch = function (text, country) {
    apiJson.header.searchProducts(country, text).then(function (response) {
      setAdding(false);
      setSearching(false);
      if (response.success) {
        setSearchedProducts(response.data);
        // props.set_search({
        //   searchCat: response.data.categories,
        //   searchBrands: response.data.brands,
        //   searchProducts: response.data.products,
        //   searchServices: response.data.services,
        // });
      } else if (!response.status) {
        message = "Something went wrong";
        showError();
      } else {
        if (response?.status === 401) {
          props.set_user({ isSignedIn: false, user: {} });
          localStorage.removeItem("access_token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          document.cookie =
            "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = "Something went wrong";
            showError();
          } else {
            message = "Something went wrong";
            showError();
          }
        }
      }
    });
  };

  const clearRecentHistory = () =>
    apiJson.header.clearHistory().then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch([]);
      } else if (!response.status) {
        message = "Something went wrong.";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = "Something went wrong.";
          showError();
        } else {
          message = "Something went wrong.";
          showError();
        }
      }
    });

  const deleteRecent = (id) => {
    setLoader(true);
    apiJson.header.deleteRecent(id).then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch(recentSearch.filter((ele) => ele.id !== id));
      } else if (!response.status) {
        message = "Something went wrong.";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = "Something went wrong.";
          showError();
        } else {
          message = "Something went wrong.";
          showError();
        }
      }
    });
  };

  const clearCart = (id) => {
    setAdding(true);
    apiJson.cart
      .clearCartItems(id)
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setCart(response.data);
          setProductsOption([]);
        } else if (!response.status) {
          message = "Something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "Something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "Something went wrong"
              : "Something went wrong";
            showError();
          }
        }
      });
  }

  const onCountryChange = (e) => {
    setProducts([]);
    setProductsOption([]);
    setAddressSaved(false);
    if (e.target.name == "country" && e.target.value != "") {
      setSelectedCountry(e.target.value);
      // setProductsLoading(true);
      // apiJson.products.getProductsVariantsByCountry(e.target.value).then(response => {
      //   if (response.success) {
      //     let p = [];
      //     response.data.forEach(product => {
      //       product.variants.forEach(variant => {
      //         p = p.concat({
      //           value: variant.id,
      //           label: product.name_en + " - " + variant.name_en,
      //           sku: variant.sku,
      //           quantity: 1
      //         });
      //       })
      //     });
      //     // setProducts(p);
      //   } else if (!response.status) {
      //     // eslint-disable-next-line
      //     message = response.message
      //       ? response.message !== ""
      //         ? response.message
      //         : "something went wrong"
      //       : "something went wrong";
      //     showError();
      //   } else {
      //     if (response.status >= 400 && response.status < 500) {
      //       message = response.message
      //         ? response.message
      //         : response.errors
      //           ? response.errors
      //           : "something went wrong";
      //       showError();
      //     } else {
      //       message = response.message
      //         ? response.message !== ""
      //           ? response.message
      //           : "something went wrong"
      //         : "something went wrong";
      //       showError();
      //     }
      //   }
      //   setProductsLoading(false);
      // })
    }
  }

  const filterOption = (option, inputValue) => {
    const { label, value, sku } = option;
    const otherKey = products.filter(
      opt => opt.label === label && opt.value.includes(inputValue)
    );
    return value.includes(inputValue) || otherKey.length > 0;
  };

  const handleProductClicked = (id) => {
    setSearchedProducts([]);
    setrecentvalue("");
    setAddressSaved(false);
    // setProductsOption(value);
    addToCart(1, "Variant", id);
  };

  const onQuantityChange = (e, product) => {
    setAddressSaved(false);
    if (e.target.value > 0) {
      setAdding(true);
      apiJson.cart
        .updateProduct(product.id, {
          line_item:
          {
            quantity: parseInt(e.target.value)
          }
        }, selected_country)
        .then((response) => {
          setAdding(false);
          if (response.success) {
            setCart(response.data);
          } else if (!response.status) {
            message = "Something went wrong";
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "Something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "Something went wrong"
                : "Something went wrong";
              showError();
            }
          }
        });
    }
  }

  const addToCart = (quantity, type, id) => {
    setAddressSaved(false);
    setAdding(true);
    apiJson.cart
      .addToCart({
        line_item: {
          quantity: parseInt(quantity),
          orderable_type: type,
          orderable_id: id,
        },
      },
        selected_country
      )
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setCart(response.data);
        } else if (!response.status) {
          message = "Something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "Something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "Something went wrong"
              : "Something went wrong";
            showError();
          }
        }
      });
  };

  const removeItemFromCart = (product) => {
    setAddressSaved(false);
    setAdding(true);
    apiJson.cart
      .removeItem(product.id, selected_country)
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setCart(response.data);

          var prev_products_option = [...productsOption];
          var key = -1;
          prev_products_option.map((p, k) => {
            if (p.value == product.orderable.id) {
              key = k;
            }
          });
          if (key !== -1) {
            prev_products_option.splice(key, 1);
          }
          setProductsOption(prev_products_option);
        } else if (!response.status) {
          message = "Something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "Something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "Something went wrong"
              : "Something went wrong";
            showError();
          }
        }
      });
  }

  const clearItems = () => {
    setAddressSaved(false);
    setAdding(true);
    apiJson.cart
      .clearCartItems(selected_country)
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setCart(response.data);
          setProductsOption([]);
        } else if (!response.status) {
          message = "Something went wrong";
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : "Something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "Something went wrong"
              : "Something went wrong";
            showError();
          }
        }
      });
  }

  const onShippingAddressChanged = (e) => {
    if (e.target.name == "first_name") {
      setFirstName(e.target.value);
    }
    else if (e.target.name == "last_name") {
      setLastName(e.target.value);
    }
    else if (e.target.name == "address_1") {
      setAddress1(e.target.value);
    }
    else if (e.target.name == "address_2") {
      setAddress2(e.target.value);
    }
    else if (e.target.name == "contact") {
      setContact(e.target.value);
    }
    else if (e.target.name == "country") {
      setShippingCountry(e.target.value);
    }
    else if (e.target.name == "state") {
      setState(e.target.value);
    }
    else if (e.target.name == "label") {
      setLabel(e.target.value);
    }
  }

  const shippingAddressValidation = () => {
    var isValid = true;

    if (!first_name) {
      setFirstNameError("*First name is required.");
      isValid = false;
    }
    else {
      setFirstNameError("");
    }

    // if (!last_name) {
    //   setLastNameError("*Last name is required.");
    //   isValid = false;
    // }
    // else {
    //   setLastNameError("");
    // }

    if (!address_1) {
      setAddress1Error("*Address is required.");
      isValid = false;
    }
    else {
      setAddress1Error("");
    }

    if (!address_2) {
      setAddress2Error("*Apartment No. is required.");
      isValid = false;
    }
    else {
      setAddress2Error("");
    }

    if (!shipping_country) {
      setShippingCountryError("*Country is required.");
      isValid = false;
    }
    else {
      setShippingCountryError("");
    }

    if (!state) {
      setStateError("*State is required.");
      isValid = false;
    }
    else {
      setStateError("");
    }

    if (!contact) {
      setContactError("*Contact is required.");
      isValid = false;
    }
    else {
      setContactError("");
    }

    // if (!label) {
    //   setLabelError("*Label is required.");
    //   isValid = false;
    // }
    // else {
    //   setLabelError("");
    // }

    return isValid;
  }

  const onSaveAddressBtnClicked = () => {
    if (shippingAddressValidation()) {
      apiJson
        .address.addAdress({
          address: {
            country: shipping_country,
            state: state,
            address1: address_1,
            address2: address_2,
            label: label,
            first_name: first_name,
            last_name: last_name,
            latitude: "0",
            longitude: "0",
            contact_number: contact
          }
        })
        .then(response => {
          if (response.success) {
            apiJson
              .address.setDefault(response.data.id)
              .then(response => {
                if (response.success) {
                  apiJson
                    .orders.createOrder({
                      order: {
                        address_id: response.data.id,
                        is_delivery: 1
                      }
                    }, selected_country)
                    .then(response => {
                      if (response.success) {
                        setAddressSaved(true);
                        setOrder(response.data);
                        message = "Address saved successfully.";
                        showSuccess();
                      } else if (!response.status) {
                        // eslint-disable-next-line
                        message = response.message
                          ? response.message !== ""
                            ? response.message
                            : "something went wrong"
                          : "something went wrong";
                        showError();
                      } else {
                        if (response.status >= 400 && response.status < 500) {
                          message = response.message
                            ? response.message
                            : response.errors
                              ? response.errors
                              : "something went wrong";
                          showError();
                        } else {
                          message = response.message
                            ? response.message !== ""
                              ? response.message
                              : "something went wrong"
                            : "something went wrong";
                          showError();
                        }
                      }
                    });
                } else if (!response.status) {
                  // eslint-disable-next-line
                  message = response.message
                    ? response.message !== ""
                      ? response.message
                      : "something went wrong"
                    : "something went wrong";
                  showError();
                } else {
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : "something went wrong";
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : "something went wrong"
                      : "something went wrong";
                    showError();
                  }
                }
              })
          } else if (!response.status) {
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        })
    }
  }

  const onPlaceOrderBtnClicked = () => {
    if (address_saved) {
      setPlaceOrderBtnText("LOADING...");
      apiJson
        .orders.placeOrder({
          payment: {
            use_card: false,
            use_wallet: false,
            cash_on_delivery: true
          },
          is_delivery: 1,
          pickup_address_id: null,
          customer_name: null,
          customer_contact: null
        }, order.id, selected_country)
        .then(response => {
          if (response.success) {
            message = "Order placed successfully.";
            showSuccess();
          } else if (!response.status) {
            // eslint-disable-next-line
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
          setPlaceOrderBtnText("PLACE ORDER");
        })
    }
    else {
      message = "Save shipping address to place order.";
      showError();
    }
  }

  return (
    <Fragment>
      <div className="right-content order-page-container w-100">
        <div className="header-row">
          <Header
            heading="Order"
            subtext="Create Order."
            search={false}
            filters={null}
          />
        </div>
        <div className="main-row content-container-row w-100">
          <div className="card">
            <label><b>Country</b></label>
            <select name="country" className="form-control" defaultValue={selected_country} onChange={onCountryChange}>
              <option value="">---Select Country---</option>
              {
                countries.map(country => {
                  return (
                    <option value={country.id}>{country.name_en}</option>
                  )
                })
              }
            </select>
            <br />
            <label><b>Products</b></label>
            {
              products_loading ?
                <div className="category-select w-100" style={{ textAlign: "center", color: "gray" }}>Loading Products. Please wait...</div>
                :
                // <Select
                //   value={
                //     productsOption?.length === 0
                //       ? []
                //       : productsOption
                //   }
                //   options={products}
                //   className="category-select w-100"
                //   name="selected_products"
                //   classNamePrefix="control"
                //   isSearchable={true}
                //   backspaceRemovesValue={false}
                //   isMulti
                //   onChange={(value) =>
                //     handleProductChange(value)
                //   }
                //   filterOption={filterOption}
                // />
                <div>
                  <input
                    type="search"
                    onChange={(e) => onSeacrhChange(e.target.value)}
                    className="form-control"
                    placeholders="Search products"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    value={recentvalue}
                  // onClick={handleSearchClcik}
                  />
                  <div className="searched-products-div">
                    {searched_products.length > 0 && (
                      <SearchPopover
                        recent={recentSearch}
                        recentValue={recentvalue}
                        clearHistory={() => {
                          setLoader(true);
                          clearRecentHistory();
                        }}
                        deleteRecent={(id) => deleteRecent(id)}
                        loader={loader}
                        setRecentValue={(val) => onSeacrhChange(val)}
                        searching={searching}
                        products={searched_products}
                        onVariantClicked={handleProductClicked}
                      />
                    )}
                  </div>
                </div>
            }
            {
              adding ?
                <div className="text-center" style={{ marginTop: "10px" }}>
                  <ClipLoader color="#c78e2b" loading size={20} />
                </div>
                :
                <></>
            }
            <br />
            {
              cart.line_items?.length > 0 ?
                <>
                  <table className="table">
                    <tr>
                      <th className="product-row">Product</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                    {
                      cart.line_items?.map(product => {
                        return (
                          <tr>
                            <td className="flex">
                              <img src={product.orderable.featured_image_resized[0]} className="product-img" />
                              <div className="mx-auto">
                                <p className="blue-color">{product.orderable.product_name_en + " " + product.orderable.name_en}</p>
                                <p className="gray-color">SKU: {product.orderable.sku}</p>
                                <p className="blue-color">AED {product.price}</p>
                              </div>
                            </td>
                            <td>
                              <input name="quantity" type="number" value={product.quantity} className="qty-input" onChange={(e) => onQuantityChange(e, product)} />
                            </td>
                            <td>
                              <span>AED {product.price}</span>
                              <input type="button" value="X" onClick={() => removeItemFromCart(product)} className="remove-btn" />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                  <button className="btn-danger" onClick={clearItems}>Clear Items</button>
                </>
                :
                <></>
            }
          </div>
          <br />
          <div className="card">
            <h6>Shipping Address</h6>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <label>Full Name</label>
                <input type="text" name="first_name" className="form-control" onChange={onShippingAddressChanged} value={first_name} />
                <p className="error">{first_name_error}</p>
              </div>
              {/* <div className="col-lg-6">
                <label>Last Name</label>
                <input type="text" name="last_name" className="form-control" onChange={onShippingAddressChanged} value={last_name} />
                <p className="error">{last_name_error}</p>
              </div> */}
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <label>Building name/no.</label>
                <input type="text" name="address_1" className="form-control" onChange={onShippingAddressChanged} value={address_1} />
                <p className="error">{address_1_error}</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <label>Floor, Apartment, Villa no., etc.</label>
                <input type="text" name="address_2" className="form-control" onChange={onShippingAddressChanged} value={address_2} />
                <p className="error">{address_2_error}</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-6">
                <label>Country</label>
                <input type="text" name="country" className="form-control" onChange={onShippingAddressChanged} value={shipping_country} />
                <p className="error">{shipping_country_error}</p>
              </div>
              <div className="col-lg-6">
                <label>State</label>
                <select name="state" className="form-control" onChange={onShippingAddressChanged} value={state}>
                  <option value="" disabled>State</option>
                  <option value="Abu Dhabi">Abu Dhabi</option>
                  <option value="Ajman">Ajman</option>
                  <option value="Dubai">Dubai</option>
                  <option value="Fujairah">Fujairah</option>
                  <option value="Ras Al Khaimah">Ras al-Khaimah</option>
                  <option value="Sharjah">Sharjah</option>
                  <option value="Umm Al Quwain">Umm Al Quwain</option>
                </select>
                <p className="error">{state_error}</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <label>Contact</label>
                <input type="text" name="contact" className="form-control" onChange={onShippingAddressChanged} value={contact} />
                <p className="error">{contact_error}</p>
              </div>
              {/* <div className="col-lg-6">
                <label>Label</label>
                <input type="text" name="label" className="form-control" onChange={onShippingAddressChanged} value={label} />
                <p className="error">{label_error}</p>
              </div> */}
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <input type="button" value="Save Address" className="btn btn-save" onClick={onSaveAddressBtnClicked} />
              </div>
            </div>
          </div>
          <br />
          <div className="card">
            <h6>Payment</h6>
            <table>
              <tr>
                <th>Subtotal</th>
                <td className="text-right">
                  AED&nbsp;
                  {
                    order.sub_total ?
                      order.sub_total
                      :
                      cart.sub_total ?
                        cart.sub_total
                        :
                        "0.00"
                  }</td>
              </tr>
              <tr>
                <th>Shipping Fee</th>
                <td className="text-right">AED {order.delivery_fee ? order.delivery_fee : "0.00"}</td>
              </tr>
              <tr>
                <th>Total</th>
                <th className="text-right">
                  AED&nbsp;
                  {
                    order.grand_total ?
                      order.grand_total
                      :
                      cart.sub_total ?
                        cart.sub_total
                        :
                        "0.00"
                  }
                </th>
              </tr>
            </table>
            <br />
            <input type="button" value={place_order_btn_text} className="btn btn-save" onClick={onPlaceOrderBtnClicked} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    pagination: state.order.pagination,
    orders: state.order.orders,
  };
};

const mapDispatchToProps = { set_orders };

export default connect(mapStateToProsps, mapDispatchToProps)(Orders);
