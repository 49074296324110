import React, { Fragment } from "react";
import { GrFormClose } from "react-icons/gr";
import { FaLessThan } from "react-icons/fa";

import "./forgetmodal.scss";

const ForgetModal = (props) => {
  return (
    <Fragment>
      <div className="forget-modal">
        <div className="header-row w-100 d-flex justify-content-between align-items-center">
          <div className="back-col d-flex align-items-center">
            <FaLessThan />
            <span className="back-text">Back</span>
          </div>
          <div className="close-col" onClick={props.onClose}>
            <GrFormClose />
          </div>
        </div>
        <div className="boday-row w-100">
          <div className="title-row">{props.title}</div>
          <div className="guide-text-row">
            Enter your email address and we'll send you a link to reset your
            password
          </div>
          <div className="input-row">
            <div className="label">Email</div>
            <input type="email" name="email" className="email-input" />
          </div>
        </div>
        <div className="button-row w-100 d-flex align-items-center justify-content-center">
          <button
            className="submit-button"
            type="button"
            onClick={props.onSubmit}
          >
            Submit Email
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgetModal;
