import { useEffect, useState } from "react";
import "assets/styles/layout/adminlayoutrenderer.scss";
import Sidebar from "components/sidebar/Sidebar";
import ProtectedRoutes from "ProtectedRoutes";
import { Redirect, useHistory } from "react-router-dom";
import $ from "jquery";
import { set_user } from "redux/actions/AuthAction";
import { connect } from "react-redux";
import { domain } from "api/Constants";
import { apiJson } from "../../api/Api";
import { useRoutes } from "./useRoutes";

const AdminLayoutRenderer = (props) => {
  const history = useHistory();
  const [goBack, setGoBack] = useState(false);
  var authenticated = localStorage.getItem("admin_access_token");
  const { routes } = useRoutes(props.user);

  $.auth.configure({
    apiUrl: domain,

    signOutPath: "/auth/sign_out",

    tokenValidationPath: "/auth/validate_token",
    authProviderPaths: {
      facebook: "/auth/facebook",
      google: "/auth/google_oauth2",
    },
  });

  useEffect(() => {
    if (localStorage?.getItem("admin_access_token")) {
      apiJson.auth.validateToken().then((response) => {
        if (response.success) {
          localStorage.setItem(
            "admin_access_token",
            response.headers["access-token"]
          );
          localStorage.setItem("admin_client", response.headers.client);
          localStorage.setItem("admin_uid", response.headers.uid);
          props.set_user({
            isSignedIn: true,
            user: response.data.data,
          });
        } else {
          if (parseInt(response.status) === 401) {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.clear();
            document.cookie =
              "authHeaders=undefined; expires= Thu, 21 Aug 2014 20:00:00 UTC";
            setGoBack(true);
          }
        }
      });
    } else {
      document.cookie =
        "authHeaders=undefined; expires= Thu, 21 Aug 2014 20:00:00 UTC";
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    <div className="page-layout">
      {authenticated ? (
        <div className="wrapper d-flex justify-content-start">
          <div className="left">
            <Sidebar />
          </div>
          <div
            id="rightPanel"
            className="right d-flex flex-column justify-content-start"
          >
            {routes
              .filter((route) => !route.hidden)
              .map((route) => (
                <ProtectedRoutes
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
          </div>
        </div>
      ) : (
        <Redirect to="/login" />
      )}
      {goBack && <Redirect to="/login" />}
    </div>
  );
};

const mapDispatchToProps = {
  set_user,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayoutRenderer);
