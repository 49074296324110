import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import "./bannerform.scss";
import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import { apiJson } from "../../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { FileUploadPromise } from "../../../../utils/aws_s3/aws_s3";
import { useParams } from "react-router-dom";
import DropzoneElement from "components/Dropzone/DropzoneElement";
import { getClass, getRandomItem } from "utils/utils";

const BannerForm = (props) => {
  const param = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageMobileLoading, setImageMobileLoading] = useState(false);
  const [goToView, setGoToView] = useState(false);
  const [data, setData] = useState(false);
  const [goToUpdate, setGoToUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [countries, setCountries] = useState([]);
  const [bannerType, setBannerType] = useState();
  const [currentCountry, setCurrentCountry] = useState();

  const bannerTypes = [
    { label: "Platform Primary", value: "platform_primary" },
    { label: "Platform Secondary", value: "platform_secondary" },
    { label: "Checkout Primary", value: "checkout_primary" },
    { label: "Checkout Secondary", value: "checkout_secondary" },
    { label: "Order Primary", value: "order_primary" },
    { label: "Order Secondary", value: "order_secondary" },
    { label: "Mobile All Categories", value: "mobile_all_categories" },
  ];

  const { readOnly } = props;

  const bannerSchema = Yup.object().shape({
    banner_type: Yup.string().required("Required"),
    country_id: Yup.string().required("Required"),
    redirects_to_link: Yup.string().url("must be a url").required("Required"),
    image: Yup.string().required("Required"),
  });

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (props.state === "view" || props.state === "update") {
      setData(true);
      apiJson.banner.getBannerDetails(parseInt(param.id)).then((response) => {
        if (response.success) {
          setInitialValues(response.data);
          setBannerType({
            label: response.data.banner_type,
            value: response.data.banner_type,
          });
          setCurrentCountry({
            label: response.data.country_name_en,
            value: response.data.country_id,
          });
          setData(false);
        } else if (!response.status) {
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
          setData(false);
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
            setData(false);
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    } else {
      setInitialValues({
        banner_type: "",
        country_id: "",
        redirects_to_link: "",
        image: "",
        image_mobile: "",
      });
    }
    apiJson.country.getCountries().then((response) => {
      if (response.success) {
        let extra = [];
        response.data.map((c) => {
          extra.push({
            label: c.name_en,
            value: c.id,
          });
          return c;
        });
        setCountries(extra);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const createBanner = (values) => {
    setLoading(true);
    apiJson.banner
      .createBanner({
        banner: values,
      })
      .then((response) => {
        if (response.success) {
          message = "Banner Created";
          showSuccess();
          setLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const editBanner = (values) => {
    setGoToView(false);
    setLoading(true);
    apiJson.banner
      .updateBanner(param.id, {
        banner: values,
      })
      .then((response) => {
        if (response.success) {
          message = "Banner Updated";
          showSuccess();
          setLoading(false);
          setGoToView(true);
        } else if (!response.status) {
          setLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
  };

  const deleteBanner = () => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      setDeleteLoading(true);
      apiJson.banner.deleteBanner(param.id).then((response) => {
        if (response.success) {
          message = "Banner Deleted";
          showSuccess();
          setDeleteLoading(false);
          setRedirect(true);
        } else if (!response.status) {
          setDeleteLoading(false);
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        } else {
          setDeleteLoading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : "something went wrong";
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          }
        }
      });
    }
  };

  const handleFileUpload = (files, setFieldValue) => {
    setImageLoading(true);
    const file = files[0];
    FileUploadPromise(file)
      .then((response) => {
        setFieldValue("image", response.location);
        setImageLoading(false);
      })
      .catch((err) => {
        setImageLoading(false);
        message = "File upload error";
        showError();
      });
  };

  const handleImageMobileUpload = (files, setFieldValue) => {
    setImageMobileLoading(true);
    const file = files[0];
    FileUploadPromise(file)
      .then((response) => {
        setFieldValue("image_mobile", response.location);
        setImageMobileLoading(false);
      })
      .catch((err) => {
        setImageMobileLoading(false);
        message = "File upload error";
        showError();
      });
  };
  const handleType = (val, setFieldValue) => {
    setFieldValue("banner_type", val.value);
    setBannerType(val);
  };
  const handleCurrentCountry = (val, setFieldValue) => {
    setFieldValue("country_id", val.value);
    setCurrentCountry(val);
  };

  return (
    <Fragment>
      <div className="banner-form-desktop">
        {data ? (
          <div className="d-flex justify-content-center align-item-center w-100">
            <ClipLoader color="#000" loading size={30} />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={bannerSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              if (props.state === "update") {
                editBanner(values);
              } else if (props.state === "create") {
                createBanner(values);
              }
            }}
          >
            {({ values, errors, touched, setFieldValue, handleChange }) => (
              <Form>
                <div className="form-main row g-0">
                  <div className="row g-0">
                    <div className="left-group-buttons col-lg-8 col-xl-6 col-md-11">
                      <div className="row g-0">
                        <div className="select-brand col-12 d-flex">
                          <label className="brand-label all-text col-4 padding-for-all">
                            Type
                          </label>
                          <div className="col-8">
                            <Select
                              isDisabled={readOnly}
                              name="banner_type"
                              value={bannerType}
                              onChange={(val) => handleType(val, setFieldValue)}
                              options={bannerTypes}
                              className={`category-select ${getClass(
                                touched.banner_type && errors.banner_type
                              )}`}
                              classNamePrefix="control"
                            />
                            {touched.banner_type && errors.banner_type && (
                              <div
                                className="error"
                                style={{
                                  color: "red",
                                  fontSize: "0.9rem",
                                  marginBottom: "15px",
                                }}
                              >
                                {errors.banner_type}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="select-brand col-12 d-flex">
                          <label className="brand-label all-text col-4 padding-for-all">
                            Country
                          </label>
                          <div className="col-8">
                            <Select
                              isDisabled={readOnly}
                              name="country_id"
                              value={currentCountry}
                              onChange={(val) =>
                                handleCurrentCountry(val, setFieldValue)
                              }
                              options={countries}
                              className={`category-select ${getClass(
                                touched.country_id && errors.country_id
                              )}`}
                              classNamePrefix="control"
                            />
                            {touched.country_id && errors.country_id && (
                              <div
                                className="error"
                                style={{ color: "red", fontSize: "0.9rem" }}
                              >
                                {errors.country_id}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="select-brand col-12 d-flex">
                          <label className="brand-label all-text col-4 padding-for-all">
                            Redirect Link
                          </label>
                          <div className="col-8 input">
                            <Field
                              disabled={readOnly}
                              className={`w-100 ${getClass(
                                touched.redirects_to_link &&
                                  errors.redirects_to_link
                              )}`}
                              name="redirects_to_link"
                              type="url"
                              placeholder="https://example.com"
                            />
                            {touched.redirects_to_link &&
                              errors.redirects_to_link && (
                                <div
                                  className="error"
                                  style={{ color: "red", fontSize: "0.9rem" }}
                                >
                                  {errors.redirects_to_link}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="select-brand col-12 height-100-px d-flex">
                          <label className="brand-label all-text col-4 padding-for-all">
                            Website Banner
                          </label>
                          <div className="col-8">
                            {imageLoading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <ClipLoader color="#000" loading size={35} />
                              </div>
                            ) : (
                              <>
                                {values.image !== "" ? (
                                  <div className="img-container">
                                    <img
                                      src={values.image}
                                      width="auto"
                                      height="auto"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  touched.image &&
                                  errors.image && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.9rem",
                                      }}
                                    >
                                      {errors.image}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {!readOnly && (
                          <div className="select-brand col-12 d-flex">
                            <label className="brand-label all-text col-2 padding-for-all">
                              &nbsp;
                            </label>
                            <div className="col-10 d-flex justify-content-center">
                              <DropzoneElement
                                placeholder="Click to select file"
                                updateSelectedServiceFiles={(files) =>
                                  handleFileUpload(files, setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        )}

                        <div className="select-brand col-12 height-100-px d-flex">
                          <label className="brand-label all-text col-4 padding-for-all">
                            Mobile Banner
                          </label>
                          <div className="col-8">
                            {imageMobileLoading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <ClipLoader color="#000" loading size={35} />
                              </div>
                            ) : (
                              <>
                                {values.image_mobile !== "" ? (
                                  <div className="img-container">
                                    <img
                                      src={values.image_mobile}
                                      width="auto"
                                      height="auto"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  touched.image_mobile &&
                                  errors.image_mobile && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.9rem",
                                      }}
                                    >
                                      {errors.image_mobile}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {!readOnly && (
                          <div className="select-brand col-12 d-flex">
                            <label className="brand-label all-text col-2 padding-for-all">
                              &nbsp;
                            </label>
                            <div className="col-10 d-flex justify-content-center">
                              <DropzoneElement
                                placeholder="Click to select file"
                                updateSelectedServiceFiles={(files) =>
                                  handleImageMobileUpload(files, setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row main-row g-0 d-flex flex-direction-md-revert-row">
                    <div className="right-bottom w-80 mx-auto">
                      <div className="d-flex justify-content-start">
                        {props.state === "create" ? (
                          <>
                            <div className="save-btn-div">
                              <button
                                className="save-btn d-flex justify-content-center align-items-center"
                                type="submit"
                                key={getRandomItem(1, 1000)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <ClipLoader color="#fff" loading size={20} />
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                            <div className="cancel-btn-div">
                              <button
                                className="cancel-btn d-flex justify-content-center align-items-center"
                                type="button"
                                onClick={() => setRedirect(true)}
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : props.state === "view" ? (
                          <>
                            <div className="save-btn-div">
                              <button
                                className="save-btn d-flex justify-content-center align-items-center"
                                type="button"
                                onClick={() => setGoToUpdate(true)}
                              >
                                Edit
                              </button>
                            </div>
                            <div className="delete-btn-div">
                              <button
                                className="delete-btn d-flex justify-content-center align-items-center"
                                type="button"
                                onClick={() =>
                                  deleteBanner(props.bannerDetails.id)
                                }
                                disabled={deleteLoading}
                              >
                                {deleteLoading ? (
                                  <ClipLoader color="#000" loading size={20} />
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </div>
                          </>
                        ) : (
                          props.state === "update" && (
                            <>
                              <div className="save-btn-div">
                                <button
                                  className="save-btn d-flex justify-content-center align-items-center"
                                  type="submit"
                                  key={getRandomItem(1, 1000)}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <ClipLoader color="#fff" size={20} />
                                  ) : (
                                    "Update"
                                  )}
                                </button>
                              </div>
                              <div className="cancel-btn-div">
                                <button
                                  className="cancel-btn d-flex justify-content-center align-items-center"
                                  type="button"
                                  onClick={() => setGoToView(true)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {goToUpdate && <Redirect push to={`/update-banner/${param.id}`} />}
      {goToView && <Redirect push to={`/view-banner/${param.id}`} />}
      {redirect && <Redirect push to="/banners" />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerDetails: state.banner.bannerDetails,
  };
};

export default connect(mapStateToProps)(BannerForm);
