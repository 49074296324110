import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line
import Auth from "j-toker";
import $ from "jquery";
import { set_user } from "../../../redux/actions/AuthAction";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form, Field } from "formik";
import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";

import "./loginform.scss";
import ForgetModal from "../../../components/modals/ForgetModal";

const logInSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),

  password: Yup.string()
    .required("Required")
    .min(6, "should be 6 chars minimum")
    .max(30, "should be 30 chars maximum"),
});

const LoginForm = (props) => {
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [showModal, setshowModal] = useState(false);
  const values = {
    email: "",
    password: "",
  };
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const fetch = window.fetch;
  window.fetch = (...args) =>
    (async (args) => {
      var result = await fetch(...args);
      return result;
    })(args);

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = (values) => {
    setLoading(true);
    $.auth
      .emailSignIn({ ...values, role: "admin" })
      .then((response) => {
        if ($.auth.user.roles[0] === "admin" || $.auth.user.roles.includes("sub_admin")) {
          setLoading(false);
          localStorage.setItem(
            "admin_access_token",
            $.auth.retrieveData("authHeaders")["access-token"]
          );
          localStorage.setItem(
            "admin_client",
            $.auth.retrieveData("authHeaders").client
          );
          localStorage.setItem(
            "admin_uid",
            $.auth.retrieveData("authHeaders").uid
          );

          props.set_user({ isSignedIn: true, user: $.auth.user });
          message = "Signed in successfully";
          showSuccess();
          setRedirect(true);
        } else {
          message = "Unauthorized";
          showError();
          setLoading(false);
        }
      })
      .fail((error) => {
        setLoading(false);
        if (!error.data.errors) {
          message = "Network Error!";
          showError();
        } else if (error.data.errors?.[0]) {
          message = error.data.errors[0];
          showError();
        } else {
          message = error.data.errors.full_messages[0];
          showError();
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("admin_access_token")) {
      setRedirect(true);
    } else {
      setRedirect(false);
    }
  }, []);

  return (
    <Fragment>
      <div className="login-form-desktop">
        <Formik
          initialValues={values}
          validationSchema={logInSchema}
          onSubmit={handleSubmit}
        >
          <Form className="form mx-auto">
            <Field
              style={{ width: "100%", marginBottom: "33px" }}
              as={TextField}
              name="email"
              type="email"
              label="Email"
            />
            <Field
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              as={TextField}
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="link-text d-flex justify-content-end">
              <div
                className="forget-text"
                onClick={() => setshowModal(!showModal)}
              >
                Forgot password?
              </div>
              {showModal && (
                <div className="modal-container">
                  <ForgetModal
                    onClose={() => setshowModal(false)}
                    title="Forgot Your Password?"
                    onSubmit={() => setshowModal(false)}
                  />
                </div>
              )}
            </div>
            <button type="submit" className="signin-button" disabled={loading}>
              {loading ? (
                <ClipLoader color="#fff" loading size={23} />
              ) : (
                "SIGN IN"
              )}
            </button>
          </Form>
        </Formik>
      </div>
      {redirect && <Redirect to="/dashboard" />}
    </Fragment>
  );
};
const mapDispatchToProps = {
  set_user,
};
export default connect(null, mapDispatchToProps)(LoginForm);
