import axios from "axios";
import { apiUrl } from "./Constants";

const authHeaders = () => {
  return {
    "access-token": localStorage.getItem("admin_access_token"),
    client: localStorage.getItem("admin_client"),
    uid: localStorage.getItem("admin_uid"),
  };
};

const handleResponseError = (error) => {
  if (error.response?.status === 401) {
    return {
      success: false,
      status: 401,
      message: "Unauthorized access",
    };
  }
  return responseCatch(error);
};

const responseThen = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return { success: true, data: response.data };
  } else {
    return {
      success: false,
      status: response.status,
      message: response.data.message,
    };
  }
};

const responseCatch = (error) => {
  if (error.response) {
    return {
      success: false,
      status: error.response?.status,
      message: error.response.data.message,
    };
  } else {
    return {
      success: false,
      status: 500,
      message: "something went wrong",
    };
  }
};

export const apiJson = {
  header: {
    allBrands: function allBrands(c_id) {
      return axios
        .get(apiUrl.header.allBrands + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    allCategories: function allCategories(c_id) {
      return axios
        .get(apiUrl.header.allCategories + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    getCountries: function getCountries() {
      return axios
        .get(apiUrl.header.getCountries)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    featuredCategories: function featuredCategories(c_id) {
      return axios
        .get(apiUrl.header.featuredCategories + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    search: function search(c_id, search) {
      return axios
        .get(apiUrl.header.search + c_id + "&q=" + search, {
          headers: authHeaders()
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    searchProducts: function searchProducts(c_id, search) {
      return axios
        .get(apiUrl.header.searchProducts + c_id + "&q=" + search, {
          headers: authHeaders()
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    recentSearches: function recentSearches() {
      return axios
        .get(apiUrl.header.recentSearches, {
          headers: authHeaders()
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    deleteRecent: function deleteRecent(id) {
      return axios
        .delete(apiUrl.header.deleteRecent + id, {
          headers: authHeaders()
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    clearHistory: function clearHistory() {
      return axios
        .delete(apiUrl.header.clearHistory, {
          headers: authHeaders()
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  cart: {
    getCartItems: function getCartItems(cid) {
      return axios
        .get(
          apiUrl.cart.getCartItems +
          "?country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    clearCartItems: function clearCartItems(cid) {
      return axios
        .delete(
          apiUrl.cart.clearCart +
          "?country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    clearWishlist: function clearWishlist(cid) {
      return axios
        .delete(
          apiUrl.cart.clearWishlist +
          "?country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    removeItem: function removeItem(id, cid) {
      return axios
        .delete(
          apiUrl.cart.removeItem +
          "/" +
          id +
          "?country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    updateProduct: function updateProduct(id, body, cid) {
      return axios
        .put(
          apiUrl.cart.updateProduct +
          "/" +
          id +
          "?country_id=" +
          cid,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    addCoupon: function addCoupon(coupon, cid) {
      return axios
        .get(
          apiUrl.cart.addCoupon +
          coupon +
          "&country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { success: true, data: response.data };
          } else {
            return {
              success: false,
              status: response.status,
              message: response.message,
            };
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response?.status >= 400 &&
            error.response?.status < 500
          ) {
            return {
              success: false,
              status: error.response?.status,
              message:
                error.response?.data?.message
                  ?
                  error.response?.data.message
                  : "something went wrong",
            };
          } else {
            return {
              success: false,
              status: 500,
              message: "something went wrong",
            };
          }
        });
    },

    removeCoupon: function removeCoupon(cid) {
      return axios
        .get(
          apiUrl.cart.removeCoupon +
          "country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    getWishList: function getWishList(cid) {
      return axios
        .get(
          apiUrl.cart.getWishList +
          "?country_id=" +
          cid,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    addToCart: function addToCart(body, id) {
      return axios
        .post(
          apiUrl.cart.addToCart +
          "?country_id=" +
          id,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    moveToWishlist: function moveToWishlist(body, cid) {
      return axios
        .post(
          apiUrl.cart.moveToWishList +
          "?country_id=" +
          cid,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    updateCartReset: function updateCartReset(cid) {
      return axios
        .patch(
          apiUrl.cart.updateCartReset +
          "country_id=" +
          cid,
          {},
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    discountReset: function discountReset(cid) {
      return axios
        .patch(
          apiUrl.cart.discountReset +
          "country_id=" +
          cid,
          {},
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  customers: {
    getCustomers: function getCustomers(c_id, page, records_per_page, search, date) {
      return axios
        .get(
          apiUrl.customers.getCustomers + c_id +
          "&q=" + search +
          "&date=" + date +
          "&page=" +
          page +
          "&records_per_page=" +
          records_per_page,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCustomer: function getCustomer(id) {
      return axios
        .get(
          apiUrl.customers.getCustomer + id,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCustomerCart: function getCustomerCart(id, c_id) {
      return axios
        .get(
          apiUrl.customers.getCustomerCart + id + "/cart?country_id=" + c_id,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCustomerWishListItems: function getCustomerWishListItems(id, c_id) {
      return axios
        .get(
          apiUrl.customers.getCustomerWishListItems + id + "/wishlist?country_id=" + c_id,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCustomerRecentOrders: function getCustomerRecentOrders(id) {
      return axios
        .get(
          apiUrl.customers.getCustomerRecentOrders + id + "/recent_orders",
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCustomerOrders: function getCustomerOrders(c_id, page, records_per_page) {
      return axios
        .get(
          apiUrl.customers.getCustomerOrders + c_id +
          "&page=" +
          page +
          "&records_per_page=" +
          records_per_page,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    exportCustomers: function exportCustomers(body) {
      return axios
        .post(
          apiUrl.customers.exportCustomers,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    updateCustomer: function updateCustomer(id, body) {
      return axios
        .patch(
          apiUrl.customers.getCustomer + id,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    sendAbandonedCartMail: function sendAbandonedCartMail(id, c_id) {
      return axios
        .get(
          apiUrl.customers.sendAbandonedCartMail + id + "/send_abandoned_cart_mail?country_id=" + c_id,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    }
  },
  trakingLogs: {
    getTrakingLogs: function getTrakingLogs(searchParams) {
      return axios
        .get(apiUrl.trakingLogs.getTrakingLogs +`?whodunnit=${searchParams?.whodunnit || ""}` + `&item_id=${searchParams?.item_id || ""}` , {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    search: function search(search) {
      return axios
        .get(apiUrl.trakingLogs.search + 1 + "&q=" + search, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  subadmins: {
    addSubadmin: function addSubadmin(body) {
      return axios
        .post(
          apiUrl.subadmins.addSubadmin,
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    assignSubdomainRoleToUser: function assignSubdomainRoleToUser(id, body) {
      return axios
        .patch(
          apiUrl.subadmins.assignSubdomainRoleToUser + id + "/set_subadmin_role",
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getSubadmins: function getSubadmins(page, records_per_page) {
      return axios
        .get(
          apiUrl.subadmins.getSubAdmins + "?page=" + page + "&records_per_page=" + records_per_page,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getSubadmin: function getSubadmin(id) {
      return axios
        .get(
          apiUrl.subadmins.getSubAdmins + "/" + id,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    updateSubadmins: function updateSubadmins(id, permissions) {
      return axios
        .patch(
          apiUrl.subadmins.getSubAdmins + "/" + id,
          { permissions },
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getPermissions: function getPermissions() {
      return axios
        .get(
          apiUrl.subadmins.getPermissions,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    }
  },

  address: {
    addAdress: function addAddress(body) {
      return axios
        .post(
          apiUrl.address.addAddress,
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    setDefault: function setDefault(id) {
      return axios
        .patch(
          apiUrl.address.setDefault +
          parseInt(id) +
          "/set_default",
          {},
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    }
  },

  orders: {
    createOrder: function createOrder(body, cid) {
      return axios
        .post(
          apiUrl.orders.createOrder +
          "?country_id=" +
          cid,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    placeOrder: function placeOrder(body, id, cid) {
      return axios
        .patch(
          apiUrl.orders.placeOrder +
          id +
          "/process_order_payment?country_id=" +
          cid,
          body,
          {
            headers: authHeaders()
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getOrders: function getOrders(page, remainingParams) {
      return axios
        .get(
          apiUrl.orders.getOrders +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.orders.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOrderDetails: function getOrderDetails(id) {
      return axios
        .get(apiUrl.orders.getOrderDetails + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateOrder: function updateOrder(id, body) {
      return axios
        .patch(apiUrl.orders.updateOrder + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    completeTransaction: function completeTransaction(id) {
      return axios
        .patch(
          apiUrl.orders.completeTransaction +
          id +
          "/complete_cash_on_delivery_transaction",
          {},
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    generateReceipt: function generateReceipt(id) {
      return axios
        .get(apiUrl.orders.getOrderReceipt + id + "/receipt.pdf", {
          headers: authHeaders(),
          responseType: "blob",
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    generatePackingSlip: function generatePackingSlip(id) {
      return axios
        .get(apiUrl.orders.getPackingSlip + id + "/packing_slip", {
          headers: authHeaders(),
          responseType: "blob",
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  stores: {
    getStores: function getStores() {
      return axios
        .get(apiUrl.store.getStores, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    searchStores: function searchStores(search) {
      return axios
        .get(apiUrl.store.getStores + "?q=" + search, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getSingleStore: function getSingleStore(id) {
      return axios
        .get(apiUrl.store.getSingleStore + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateSingleStore: function updateSingleStore(id, body) {
      return axios
        .patch(apiUrl.store.updateStore + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  categories: {
    getCategories: function getCategories() {
      return axios
        .get(apiUrl.categories.getCategories, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getPaginatedCategories: function getPaginatedCategories(
      page,
      remainingParams
    ) {
      return axios
        .get(
          apiUrl.categories.getPaginatedCategories +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.categories.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getLeafCategories: function getLeafCategories() {
      return axios
        .get(apiUrl.categories.getLeafCategories, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createCategory: function createCategory(body) {
      return axios
        .post(apiUrl.categories.createCategory, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editCategory: function editCategory(id, body) {
      return axios
        .patch(apiUrl.categories.editCategory + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeCategory: function removeCategory(id) {
      return axios
        .delete(apiUrl.categories.removeCategory + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getCategoriesForBrand: function getCategoriesForBrand() {
      return axios
        .get(apiUrl.categories.getCategoriesForBrand, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getCategoriesForCategories: function getCategoriesForCategories() {
      return axios
        .get(apiUrl.categories.getCategoriesforCategories, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getCategoryDetail: function getCategoryDetail(id) {
      return axios
        .get(apiUrl.categories.getCategoryDetail + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  country: {
    getCountries: function getCountries() {
      return axios
        .get(apiUrl.country.getCountries, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  offer: {
    createOffer: function createOffer(body) {
      return axios
        .post(apiUrl.offer.createOffer, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createOffersSections: function createOffersSections(c_id, body) {
      return axios
        .post(apiUrl.offer.createOffersSections + "?country_id=" + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffers: function getOffers() {
      return axios
        .get(apiUrl.offer.getOffers, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getVariantsBySku: function getVariantsBySku(body) {
      return axios
        .post(apiUrl.offer.getVariantsBySku, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersSections: function getOffersSections(c_id) {
      return axios
        .get(apiUrl.offer.getOffersSections + "?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersBanners: function getOffersBanners(c_id) {
      return axios
        .get(apiUrl.offer.getOffersBanners, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersBanner: function getOffersBanner(id) {
      return axios
        .get(apiUrl.offer.getOffersBanners + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteBanner: function deleteBanner(id, c_id) {
      return axios
        .delete(apiUrl.offer.deleteBanner + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createOffersBanners: function createOffersBanners(body) {
      return axios
        .post(apiUrl.offer.createOffersBanners, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editOffersBanners: function editOffersBanners(body, id) {
      return axios
        .put(apiUrl.offer.editOffersBanners + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  newoffer: {
    createOffer: function createOffer(body) {
      return axios
        .post(apiUrl.newoffer.createOffer, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createOffersSections: function createOffersSections(c_id, body) {
      return axios
        .post(apiUrl.newoffer.createOffersSections + "?country_id=" + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffers: function getOffers() {
      return axios
        .get(apiUrl.newoffer.getOffers, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getVariantsBySku: function getVariantsBySku(body) {
      return axios
        .post(apiUrl.newoffer.getVariantsBySku, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersSections: function getOffersSections(c_id) {
      return axios
        .get(apiUrl.newoffer.getOffersSections + "?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersBanners: function getOffersBanners(c_id) {
      return axios
        .get(apiUrl.newoffer.getOffersBanners, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getOffersBanner: function getOffersBanner(id) {
      return axios
        .get(apiUrl.newoffer.getOffersBanners + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteBanner: function deleteBanner(id, c_id) {
      return axios
        .delete(apiUrl.newoffer.deleteBanner + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createOffersBanners: function createOffersBanners(body) {
      return axios
        .post(apiUrl.newoffer.createOffersBanners, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editOffersBanners: function editOffersBanners(body, id) {
      return axios
        .put(apiUrl.newoffer.editOffersBanners + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  dashboard: {
    getDashboardData: function getDashboardData() {
      return axios
        .get(apiUrl.dashboard.getDashboardData, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  disputes: {
    getDisputes: function getDisputes(page, remainingParams) {
      return axios
        .get(
          apiUrl.dispute.getDisputes +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.dispute.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getDisputeDetails: function getDisputeDetails(id) {
      return axios
        .get(apiUrl.dispute.getDisputeDetails + id + "?role=admin", {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createDisputeReply: function createDisputeReply(id, body) {
      return axios
        .post(apiUrl.dispute.createReply + id + "/dispute_replies", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    UpdateDispute: function UpdateDispute(id, body) {
      return axios
        .patch(apiUrl.dispute.updateDispute + id + "?role=admin", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  gallery: {
    getPaginatedGallery: function getPaginatedGallery(page, remainingParams) {
      return axios
        .get(
          apiUrl.gallery.getPaginatedGallery +
          page +
          "&records_per_page=30" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createGallery: function createGallery(body) {
      return axios
        .post(apiUrl.gallery.createGallery, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateGallery: function updateGallery(id, body) {
      return axios
        .patch(apiUrl.gallery.updateGallery + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeGallery: function removeGallery(id) {
      return axios
        .delete(apiUrl.gallery.removeGallery + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getGalleryDetails: function getGalleryDetails(id) {
      return axios
        .get(apiUrl.gallery.getGalleryDetails + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  brands: {
    getBrands: function getBrands() {
      return axios
        .get(apiUrl.brands.getBrands, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getPaginatedBrands: function getPaginatedBrands(page, remainingParams) {
      return axios
        .get(
          apiUrl.brands.getPaginatedBrands +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.brands.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getBrandsByLeafCategory: function getBrandsByLeafCategory(category_id) {
      return axios
        .get(
          apiUrl.brands.getBrandsByLeafCategory + `?category_id=${category_id}`,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getBrandsByCategory: function getBrandsByCategory(category_id) {
      return axios
        .get(apiUrl.brands.getBrandByCategory + category_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createBrand: function createBrand(body) {
      return axios
        .post(apiUrl.brands.createBrand, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateBrand: function updateBrand(id, body) {
      return axios
        .patch(apiUrl.brands.updateBrand + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeBrand: function removeBrand(id) {
      return axios
        .delete(apiUrl.brands.removeBrand + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getBrandDetails: function getBrandDetails(id) {
      return axios
        .get(apiUrl.brands.getBrandDetails + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  home_offers_sections: {
    getSections: function getSections(c_id) {
      return axios
        .get(apiUrl.home_offers_sections.getSections + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createSection: function createSection(body) {
      return axios
        .post(apiUrl.home_offers_sections.createSection, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editSection: function editSection(body, id) {
      return axios
        .put(apiUrl.home_offers_sections.createSection + "/" + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getSection: function getSection(id, c_id) {
      return axios
        .get(apiUrl.home_offers_sections.createSection + "/" + id + "?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteSection: function deleteSection(sectionId, countryId) {
      return axios
        .delete(apiUrl.home_offers_sections.deleteSection + sectionId + "?country_id=" + countryId, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getSectionImages: function getSectionImages(sectionId, c_id) {
      return axios
        .get(apiUrl.home_offers_sections.getSectionImages + sectionId + "/section_images?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createSectionImage: function createSectionImage(body, id, c_id) {
      return axios
        .post(apiUrl.home_offers_sections.createSection + "/" + id + "/add_image_to_section?country_id=" + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getImage: function getImage(image_id) {
      return axios
        .get(apiUrl.home_offers_sections.getSectionImages + image_id + "/section_image", {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editSectionImage: function editSectionImage(body, id, c_id) {
      return axios
        .put(apiUrl.home_offers_sections.createSection + "/" + id + "/edit_section_image?country_id=", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  home_tabs: {
    getTabs: function getTabs(c_id) {
      return axios
        .get(apiUrl.home_tabs.getTabs + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createTab: function createTab(body, c_id) {
      return axios
        .post(apiUrl.home_tabs.createTab + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editTab: function editTab(body, id, c_id) {
      return axios
        .patch(apiUrl.home_tabs.getTab + id + "?country_id=" + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTab: function getTab(id, c_id) {
      return axios
        .get(apiUrl.home_tabs.getTab + id + "?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteTab: function deleteTab(sectionId, countryId) {
      return axios
        .delete(apiUrl.home_tabs.deleteTab + sectionId + "?country_id=" + countryId, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabProducts: function getTabProducts(sectionId, c_id) {
      return axios
        .get(apiUrl.home_tabs.getTabProducts + sectionId + "/tab_products?country_id=" + c_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createSectionImage: function createSectionImage(body, id, c_id) {
      return axios
        .post(apiUrl.home_offers_sections.createSection + "/" + id + "/add_image_to_section?country_id=" + c_id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteTabProduct: function deleteTabProduct(sectionId, countryId, product_id) {
      return axios
        .delete(apiUrl.home_tabs.deleteTabProduct + sectionId + "/remove_product?country_id=" + countryId + "&product_id=" + product_id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    addProductToTab: function addProductToTab(body, countryId, tab_id) {
      return axios
        .post(apiUrl.home_tabs.deleteTabProduct + tab_id + "/add_product?country_id=" + countryId, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  pushnotificationmessages: {
    createPushNotification: function createPushNotification(body) {
      return axios
        .post(apiUrl.pushnotificationmessages.create, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getPushNotification: function getPushNotification(page) {
      return axios
        .get(apiUrl.pushnotificationmessages.create +`?page=${page}&records_per_page=10`, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    sendPushNotification: function sendPushNotification(id) {
      return axios
        .post(
          apiUrl.pushnotificationmessages.create +
            `/${id}/send_push_notification`, {},
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  sections: {
    getSections: function getSections() {
      return axios
        .get(apiUrl.section.getSections, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getPaginatedSections: function getPaginatedSections(page, remainingParams) {
      return axios
        .get(
          apiUrl.section.getPaginatedSections +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.section.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createSection: function createSection(body) {
      return axios
        .post(apiUrl.section.createSection, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getSingleSection: function getSingleSection(id, country_id) {
      return axios
        .get(
          apiUrl.section.getSingleSection + id + "?country_id=" + country_id,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteSection: function deleteSection(id, country_id) {
      return axios
        .delete(
          apiUrl.section.removeSection + id + "?country_id=" + country_id,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    addProductToSection: function addProductToSection(id, body) {
      return axios
        .patch(apiUrl.section.addProductToSection + id + "/add_product", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    addServiceToSection: function addServiceToSection(id, body) {
      return axios
        .patch(apiUrl.section.addServiceToSection + id + "/add_service", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeServiceFromSection: function removeServiceFromSection(id, body) {
      return axios
        .patch(
          apiUrl.section.removeServiceFromSection + id + "/remove_service",
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeProductFromSection: function removeProductFromSection(id, body) {
      return axios
        .patch(
          apiUrl.section.removeProductFromSection + id + "/remove_product",
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    UpdateSection: function UpdateSection(id, country_id, body) {
      return axios
        .patch(
          apiUrl.section.updateSection + id + "?country_id=" + country_id,
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  coupons: {
    getCoupons: function getCoupons(page, remainingParams) {
      return axios
        .get(
          apiUrl.coupons.getCoupons +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.coupons.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createCoupan: function createCoupan(body) {
      return axios
        .post(apiUrl.coupons.createCoupan, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateCoupon: function updateCoupon(id, body) {
      return axios
        .put(apiUrl.coupons.updateCoupon + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  services: {
    getServices: function getServices(page, remainingParams) {
      return axios
        .get(
          apiUrl.services.getServices +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.services.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getServicesByCountry: function getServicesByCountry(id) {
      return axios
        .get(apiUrl.services.getAllServices + "?country_id=" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getServiceDetail: function getServiceDetail(id) {
      return axios
        .get(apiUrl.services.getServiceDetail + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createService: function createService(body, cid) {
      return axios
        .post(apiUrl.services.createService + "?country_id=" + cid, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    updateService: function updateService(id, body) {
      return axios
        .put(apiUrl.services.updateService + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    removeService: function removeService(id) {
      return axios
        .delete(apiUrl.services.removeService + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    deleteService: function deleteService(id, body) {
      return axios
        .patch(apiUrl.services.removeService + id + "/remove_service", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getRelatedServices: function getRelatedServices(countryID) {
      return axios
        .get(apiUrl.services.getRelatedServices + "country_id=" + countryID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  products: {
    getProductsMetrix: function getProducts(page, remainingParams) {
      return axios
        .get(
          apiUrl.products.getProductsMetrix +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getProducts: function getProducts(page, remainingParams) {
      return axios
        .get(
          apiUrl.products.getProducts +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.products.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getProductsByCountry: function getProductsByCountry(id) {
      return axios
        .get(apiUrl.products.getAllProducts + "?country_id=" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getProductsVariantsByCountry: function getProductsVariantsByCountry(id) {
      return axios
        .get(apiUrl.products.getAllProductsVariants + "?country_id=" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getProductDetail: function getProductDetail(id) {
      return axios
        .get(apiUrl.products.getProductDetail + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    createProduct: function createProduct(body) {
      return axios
        .post(apiUrl.products.createProduct, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateProduct: function updateProduct(id, body) {
      return axios
        .patch(apiUrl.products.updateProduct + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteProduct: function deleteProduct(id) {
      return axios
        .delete(apiUrl.products.removeProduct + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteProductsByBrand: function deleteProductsByBrand(id) {
      return axios
        .delete(apiUrl.products.removeProduct + "delete_products_by_brand?brand_id=" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeProduct: function removeProduct(id) {
      return axios
        .delete(apiUrl.products.removeProduct + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getRelatedProducts: function getRelatedProducts(countryID) {
      return axios
        .get(apiUrl.products.getRelatedProducts + "country_id=" + countryID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getRelatedTools: function getRelatedTools(countryID) {
      return axios
        .get(apiUrl.products.getRelatedTools + "country_id=" + countryID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  imports: {
    bulkImport: function bulkImport(body) {
      return axios
        .post(apiUrl.imports.bulkImport, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    bulkUpdateImport: function bulkImport(body) {
      return axios
        .post(apiUrl.products.getProductDetail + "update_products", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    bulkCreateImport: function bulkImport(body) {
      return axios
        .post(apiUrl.products.getProductDetail + "create_products", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  

  variants: {
    getVariants: function getVariants(id) {
      return axios
        .get(apiUrl.variants.getVariants + id + "/variants", {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createVariant: function createVariant(product_id, body) {
      return axios
        .post(apiUrl.variants.getVariants + product_id + "/variants", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateVariant: function updateVariant(product_id, variant_id, body) {
      return axios
        .put(
          apiUrl.variants.getVariants + product_id + "/variants/" + variant_id,
          body,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    removeVariant: function removeVariant(product_id, variant_id) {
      return axios
        .delete(
          apiUrl.variants.removeVariant +
          product_id +
          "/variants/" +
          variant_id,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  returns: {
    getReturns: function getReturns(page, remainingParams) {
      return axios
        .get(
          apiUrl.returns.getReturns +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.returns.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getReturnDetails: function getReturnDetails(id) {
      return axios
        .get(apiUrl.returns.getReturnDetail + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateReturns: function updateReturns(id, body) {
      return axios
        .patch(apiUrl.returns.updateReturn + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    Refund: function Refund(id, body) {
      return axios
        .patch(apiUrl.returns.refund + id + "/process_transaction", body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  profile: {
    getUsers: function getUsers() {
      return axios
        .get(apiUrl.profile.getUsers, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getUsersList: function getUsersList(page) {
      return axios
        .get(apiUrl.profile.getUsersList + page + "&records_per_page=12", {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    removeUser: function removeUser(id) {
      return axios
        .delete(apiUrl.profile.removeUser + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },

    getCustomers: function getCustomers() {
      return axios
        .get(apiUrl.profile.getCustomers, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  vendorProfile: {
    getVendorProfile: function getVendorProfile() {
      return axios
        .get(apiUrl.vendorProfile.getVendorProfile, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  settings: {
    getPlatformSettingID: function getPlatformSettingID() {
      return axios
        .get(apiUrl.settings.getPlatformSettingID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getPlatformSetting: function getPlatformSetting(id) {
      return axios
        .get(apiUrl.settings.getPlatformSetting + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updatePlatformSetting: function updatePlatformSetting(id, body) {
      return axios
        .put(apiUrl.settings.updatePlatformSetting + "/" + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  createStore: {
    createStore: function createStore(body) {
      return axios
        .post(apiUrl.createStore.createStore, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  banner: {
    getBanners: function getBanners(page, remainingParams) {
      return axios
        .get(
          apiUrl.banner.getBanners +
          page +
          "&records_per_page=10" +
          remainingParams,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTabsCount: function getTabsCount() {
      return axios
        .get(apiUrl.banner.getTabsCount, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getBanner: function getBanner(id) {
      return axios
        .get(apiUrl.banner.getBanner + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    updateBanner: function updateBanner(id, body) {
      return axios
        .put(apiUrl.banner.updateBanner + "/" + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createBanner: function createBanner(body) {
      return axios
        .post(apiUrl.banner.createBanner, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteBanner: function deleteBanner(id) {
      return axios
        .delete(apiUrl.banner.deleteBanner + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getBannerDetails: function getBannerDetails(id) {
      return axios
        .get(apiUrl.banner.getBannerDetails + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  zone: {
    getShippingZones: function getShippingZones() {
      return axios
        .get(apiUrl.zone.getShippingZones, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    createShippingZone: function createShippingZone(body) {
      return axios
        .post(apiUrl.zone.createShippingZone, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    editShippingZone: function editShippingZone(id, body) {
      return axios
        .patch(apiUrl.zone.editShippingZone + id, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getZoneDetail: function gerZoneDetail(id) {
      return axios
        .get(apiUrl.zone.getZoneDetail + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    deleteZone: function deleteZone(id) {
      return axios
        .delete(apiUrl.zone.deleteZone + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  shippingRules: {
    getShippingRules: function getShippingRules(id) {
      return axios
        .get(apiUrl.shippingRules.getShippingRules, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getShippingRule: function getShippingRule(id) {
      return axios
        .get(apiUrl.shippingRules.getShippingRules + "/" + id, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    addShippingRule: function addShippingRules(shippingRule) {
      return axios
        .put(apiUrl.shippingRules.getShippingRules + "/" + shippingRule?.id, {
          dubai_charges: shippingRule?.dubai_charges,
          default_charges: shippingRule?.default_charges,
          order_threshold_amount: shippingRule?.order_threshold_amount,
          order_threshold_weight: shippingRule?.order_threshold_weight,
          sharjah_charges: shippingRule?.sharjah_charges,
          ajman_charges: shippingRule?.ajman_charges,
          umm_al_quwain_charges: shippingRule?.umm_al_quwain_charges,
          abu_dhabi_charges: shippingRule?.abu_dhabi_charges,
          fujairah_charges: shippingRule?.fujairah_charges,
          ras_al_khaimah_charges: shippingRule?.ras_al_khaimah_charges
        }, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  emails: {
    sendEmail: function sendEmail(body) {
      return axios
        .post(apiUrl.emails.sendEmail, body, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },

  Reports: {
    getTotalSales: function getTotalSales(start_date, end_date, storeID) {
      return axios
        .get(
          apiUrl.reports.getTotalSales +
          start_date +
          "&sale[end_date]=" +
          end_date +
          "&store_id=" +
          storeID,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getTotalSalesForAllStores: function getTotalSalesForAllStores(
      start_date,
      end_date
    ) {
      return axios
        .get(
          apiUrl.reports.getTotalSales +
          start_date +
          "&sale[end_date]=" +
          end_date,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllUsers: function getAllUserss(store) {
      return axios
        .get(apiUrl.reports.getAllUsers + store, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllProducts: function getAllProducts(storeID) {
      return axios
        .get(apiUrl.reports.getAllProducts + "?store_id=" + storeID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllProductsForAllStores: function getAllProductsForAllStores() {
      return axios
        .get(apiUrl.reports.getAllProducts, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllServices: function getAllServices(storeID) {
      return axios
        .get(apiUrl.reports.getAllServices + "?store_id=" + storeID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllServicesForAllStores: function getAllServicesForAllStores() {
      return axios
        .get(apiUrl.reports.getAllServices, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getInventory: function getInventory(storeID) {
      return axios
        .get(apiUrl.reports.getInventory + "?store_id=" + storeID, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getInventoryForAllStores: function getInventoryForAllStores() {
      return axios
        .get(apiUrl.reports.getInventory, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getAllStores: function getAllStores() {
      return axios
        .get(apiUrl.reports.getAllStores, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getProfit: function getProfit(start_date, end_date) {
      return axios
        .get(
          apiUrl.reports.getProfit +
          start_date +
          "&profit[end_date]=" +
          end_date,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getVendorPayment: function getVendorPayment(start_date, end_date, storeID) {
      return axios
        .get(
          apiUrl.reports.getVendorPayment +
          start_date +
          "&vendor_payment[end_date]=" +
          end_date +
          "&store_id=" +
          storeID,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    getVendorPaymentForAllStores: function getVendorPaymentForAllStores(
      start_date,
      end_date
    ) {
      return axios
        .get(
          apiUrl.reports.getVendorPayment +
          start_date +
          "&vendor_payment[end_date]=" +
          end_date,
          {
            headers: authHeaders(),
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
  },
  auth: {
    signout: function signout() {
      return axios
        .delete(apiUrl.auth.signout, {
          headers: authHeaders(),
        })
        .then((response) => {
          return { success: true, response };
        })
        .catch((error) => {
          return { success: false, error: error };
        });
    },

    validateToken: function validateToken() {
      return axios
        .get(apiUrl.auth.validateToken, {
          headers: {
            "access-token": localStorage.getItem("admin_access_token"),
            client: localStorage.getItem("admin_client"),
            uid: localStorage.getItem("admin_uid"),
          },
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return {
              success: true,
              data: response.data,
              headers: response.headers,
            };
          } else {
            return {
              success: false,
              status: response.status,
              message: response.data.message,
            };
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  exports: {
    exportActivateProducts: function exportActivateProducts() {
      return axios
        .get(apiUrl.exports.exportActivateProducts, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    exportActiveProductsWithPriceQty: function exportActivateProducts() {
      return axios
        .get(apiUrl.exports.exportActivateProducts + "/products_with_price_qty", {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    },
    exportAllOrders: function exportAllOrders() {
      return axios
        .get(apiUrl.exports.exportAllOrders, {
          headers: authHeaders(),
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return handleResponseError(error);
        });
    }
  }
};
