import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import "./tablelist.scss";
import Dropdown from "rc-dropdown";
import { useHistory } from "react-router-dom";
import Menu, { Item as MenuItem } from "rc-menu";
import { set_single_store } from "redux/actions/SingleStoreAction";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TableList = (props) => {
  const [activePage, setActivePage] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);
  const [currentStoreforEmail, setCurrentStoreforEmail] = useState("");
  const [values, setValues] = useState("");
  const history = useHistory();

  const handlePageChange = (pageNumber) => {
    setMin(pageNumber.selected * 10);
    setMax((pageNumber.selected + 1) * 10);
    setActivePage(pageNumber.selected);
  };

  const menuItems = [
    <MenuItem
      key="1"
      onClick={() =>
        history.push({
          pathname: "/admin/send-email",
          state: {
            name: "/stores",
            email: currentStoreforEmail.contact_email,
          },
        })
      }
      style={{ cursor: "pointer" }}
    >
      Send Email
    </MenuItem>,
  ];

  const menu = <Menu className="menu">{menuItems}</Menu>;

  useEffect(() => {
    setMin(0);
    setMax(10);
    setActivePage(0);
    setValues(props.List);
  }, [props.List]);

  return (
    <div className="table-responsive">
      <Table hover className="overflow-auto borderless">
        <thead style={{ borderBottom: "#F5F5F9" }}>
          <tr className="table-head">
            <td className="header-text">Store Id</td>
            <td className="header-text">Store logo</td>
            <td className="header-text">Name</td>
            <td className="header-text">Country</td>
            <td className="header-text">Commission</td>
            <td className="header-text">Can Reject Order</td>
            <td className="header-text">Status</td>
            <td className="header-text">Contact</td>
            <td className="header-text">Actions</td>
          </tr>
        </thead>
        <tbody>
          {values !== "" ? (
            values.slice(min, max).map((store) => {
              return (
                <tr key={store.id}>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    {store.id}
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    <div className="store-image-container">
                      <img src={store.logo_image} alt="" />
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    <span style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                      {store.name_en}
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "0.9rem",
                        color: "gray",
                      }}
                    >
                      {store.vendor_email}
                    </span>
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    {store.country_name_en}
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    {store.commission}
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    {store.can_reject_order ? "Yes" : "No"}
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    <div className="status-text">
                      {store.active === true ? "Active" : "Inactive"}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      history.push({
                        pathname: `/edit-store/${store.id}`,
                        state: { name: "view", store: { ...store } },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="header-text"
                  >
                    {store.contact_email}
                    <br/>
                    {
                      store.vendor_contact && (
                        `(${store.vendor_contact})`
                      )
                    }
                  </td>
                  <td className="header-text">
                    <Dropdown
                      className="dropdown-for-send"
                      overlay={menu}
                      overlayClassName="overlay-class"
                    >
                      <button
                        style={{
                          pointerEvents: "all",
                          backgroundColor: "unset",
                        }}
                      >
                        <BsThreeDots
                          onMouseEnter={() => setCurrentStoreforEmail(store)}
                          onClick={() => setCurrentStoreforEmail(store)}
                        />
                      </button>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#000" loading size={35} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {values !== "" && values.length > 10 && (
        <div
          className="d-flex justify-content-center mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={Math.ceil(values.length / 10)}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            containerClassName="pagination"
            forcePage={activePage}
            pageClassName="ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProsps = (state) => {
  return { stores: state.stores.stores, store: state.single_store.store };
};

const mapDispatchToProps = { set_single_store };

export default connect(mapStateToProsps, mapDispatchToProps)(TableList);
