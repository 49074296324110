import { combineReducers } from "redux";
import OrderReducer from "./Orders/OrderReducer";
import CouponReducer from "./Coupons/CouponReducer";
import ServiceReducer from "./Services/ServiceReducer";
import ProductReducer from "./Products/ProductReducer";
import VariantReducer from "./Variants/VariantReducer";
import ReturnReducer from "./Returns/ReturnReducer";
import UserReducer from "./Auth/UserReducer";
import CategoryReducer from "./Categories/CategoryReducer";
import CountryReducer from "./Country/CountryReducer";
import UsersReducer from "./Users/UsersReducer";
import BrandReducer from "./Brands/BrandReducer";
import VendorReducer from "./Vendor/VendorReducer";
import StoresReducer from "./Stores/StoresReducer";
import SingleStoreReducer from "./Stores/SingleStoreReducer";
import SectionsReducer from "./Sections/SectionsReducer";
import SingleSectionReducer from "./Sections/SingleSectionReducer";
import ShippingZoneReducer from "./Zone/ShippingZoneReducer";
import SettingReducer from "./Settings/SettingReducer";
import BannerReducer from "./Banners/BannerReducer";
import DisputesReducer from "./Disputes/DisputesReducer";
import NotFound from "./NotFound/NotFoundReducer.js";

export default combineReducers({
  order: OrderReducer,
  coupon: CouponReducer,
  service: ServiceReducer,
  product: ProductReducer,
  variant: VariantReducer,
  return: ReturnReducer,
  auth: UserReducer,
  category: CategoryReducer,
  countries: CountryReducer,
  user: UsersReducer,
  brand: BrandReducer,
  vendor: VendorReducer,
  stores: StoresReducer,
  single_store: SingleStoreReducer,
  sections: SectionsReducer,
  single_section: SingleSectionReducer,
  zones: ShippingZoneReducer,
  setting: SettingReducer,
  banner: BannerReducer,
  dispute: DisputesReducer,
  notFound: NotFound,
});
