import React, { Fragment } from "react";
import User from "./User";
import ClipLoader from "react-spinners/ClipLoader";
import "./userlist.scss";
import { connect } from "react-redux";
import { set_users } from "redux/actions/UsersAction";
import { toast } from "react-toastify";
import { apiJson } from "api/Api";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const UserList = (props) => {
  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handlePageChange = (pageNumber) => {
    document.getElementById("rightPanel").scrollTop = 0;
    apiJson.profile.getUsersList(pageNumber.selected + 1).then((response) => {
      if (response.success) {
        props.set_users({
          users: response.data.customers,
          pagination: response.data.pagination,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  };

  return (
    <Fragment>
      {props.AccountDetail ? (
        <div className="use-list-dektop w-100 d-flex flex-wrap justify-content-md-center justify-content-lg-start">
          {props.AccountDetail.map((user) => {
            return (
              <div key={Math.random() + user} className="user">
                <User user={user} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100">
          <ClipLoader color="#000" loading size={35} />
        </div>
      )}
      {props.pagination?.total_pages && props.AccountDetail?.length > 0 && (
        <div
          className="d-flex justify-content-center mt-5 mb-4 w-100"
          style={{ direction: "ltr" }}
        >
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={props.pagination?.total_pages}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            forcePage={parseInt(props?.pagination?.page) - 1}
            containerClassName="pagination"
            pageClassName="ms-1 me-1 ps-3 pe-3 pt-2 pb-2"
            activeClassName={
              "ms-3 me-3 ps-1 pe-1 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white ps-3 pe-3 pt-2 pb-2"
            previousClassName="bg-white ps-3 pe-3 pt-2 pb-2"
          />
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    pagination: state.user.pagination,
  };
};

const mapDispatchTopProps = { set_users };

export default connect(mapStateToProps, mapDispatchTopProps)(UserList);
