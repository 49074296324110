import React, { Fragment, useEffect, useState } from "react";
import "./orderdetails.scss";
import OrderHeader from "./components/OrderHeader";
import Shipment from "./components/Shipment";
import PaymentMethod from "./components/PaymentMethod";
import OrderSummary from "./components/OrderSummary";
import Header from "../../../components/header/Header";
import { set_orders } from "redux/actions/OrdersAction";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import ShipmentService from "./components/ShipmentService";
import { useParams } from "react-router-dom";

const OrderDetails = (props) => {
  const param = useParams();
  let message = "";
  const [data, setData] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [shipmentList, setShipmentList] = useState([]);
  const [payment, setPayments] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Order";
  }, []);

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = () => {
    apiJson.orders.getOrderDetails(parseInt(param.id)).then((response) => {
      if (response.success) {
        props.set_orders({ orderDetails: response.data });
        setData(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }

  useEffect(() => {
    setOrderDetails(props.orderDetails);
    setShipmentList(props.orderDetails?.shipments);
    setPayments(props.orderDetails?.payment_methods);
  }, [props.orderDetails]);

  return (
    <Fragment>
      <div className="right-content order-details-page-desktop">
        <div className="header-row">
          <Header
            heading="Orders Details"
            subtext="View, edit, and manage the Order on your site."
          />
        </div>
        <div className="main-row order-details-right-content">
          {props.orderDetails && data ? (
            <div className="content-wrapper">
              {props.orderDetails && (
                <div className="order-header">
                  <OrderHeader order={orderDetails} fetchOrder={fetchOrder} />
                </div>
              )}
              {props.orderDetails && (
                <div className="shipments">
                  {shipmentList?.map((shipment, index) => (
                    <Shipment
                      orderId={orderDetails.id}
                      shipment={shipment}
                      currency={orderDetails.currency}
                      index={index}
                      key={index}
                    />
                  ))}
                </div>
              )}
              {props.orderDetails.services &&
                props.orderDetails.services.map((service) => {
                  return (
                    <div className="shipments" key={service.id}>
                      <ShipmentService
                        service={service}
                        currency={props.orderDetails?.currency}
                      />
                    </div>
                  );
                })}
              <div className="payment-method">
                {payment && <PaymentMethod paymentMethod={payment} />}
              </div>

              <div className="order-summary">
                {props.orderDetails && (
                  <OrderSummary
                    currency={props.orderDetails.currency}
                    subTotoal={props.orderDetails.sub_total}
                    shipping={props.orderDetails.delivery_fee}
                    orderTotal={props.orderDetails.grand_total}
                    estimatedVat={props.orderDetails.vat}
                    couponCode={props.orderDetails?.coupon_code}
                    discount={props.orderDetails?.discount}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <ClipLoader color="#000" loading size={35} />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return { orderDetails: state.order.orderDetails };
};

const mapDispatchToProps = { set_orders };

export default connect(mapStateToProsps, mapDispatchToProps)(OrderDetails);
