import { type } from "../../types/types";

export default function reducer(
  state = { orders: [], orderDetails: {}, pagination: {} },
  action
) {
  switch (action.type) {
    case type.SET_ORDERS:
      return { ...state, ...action.orders };
    default:
      return state;
  }
}
