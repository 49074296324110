import React, { Fragment, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import "./addserviceto.scss";
import Header from "components/header/Header";
import { connect } from "react-redux";
import { apiJson } from "../../../api/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const AddServiceTo = (props) => {
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const [section, setSection] = useState("");
  const [sectionOptions, setSectionOptions] = useState([]);
  const [addingLoader, setaddingLoader] = useState(false);
  const [values, setValues] = useState("");
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    apiJson.services.getServiceDetail(param.id).then((response) => {
      if (response.success) {
        setValues(response.data);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    apiJson.sections.getSections().then((response) => {
      if (response.success) {
        setLoading(false);
        setSectionOptions(
          response.data.map((sec) => {
            return {
              value: sec.id,
              label: `${sec.title_en} (${sec.country_name_en})`,
              country_id: sec.country_id,
            };
          })
        );
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : "something went wrong"
          : "something went wrong";
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : "something went wrong";
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : "something went wrong"
            : "something went wrong";
          showError();
        }
      }
    });
  }, []);

  const handleCreate = (values) => {
    if (section !== "") {
      setaddingLoader(true);
      apiJson.sections
        .addServiceToSection(section.value, {
          country_id: section.country_id,
          section: {
            service_id: values.id,
          },
        })
        .then((response) => {
          if (response.success) {
            message = "Add to section";
            showSuccess();
            setaddingLoader(false);
            setRedirect(true);
          } else if (!response.status) {
            setaddingLoader(false);
            message = response.message
              ? response.message !== ""
                ? response.message
                : "something went wrong"
              : "something went wrong";
            showError();
          } else {
            setaddingLoader(false);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : "something went wrong";
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : "something went wrong"
                : "something went wrong";
              showError();
            }
          }
        });
    } else {
      message = "Select Section";
      showError();
    }
  };

  const handleSection = (e) => {
    setSection(e);
  };

  return (
    <Fragment>
      <div className="right-content add-service-to-section-page-desktop">
        <div className="header-row">
          <Header
            heading="Add Service to Section"
            subtext="Create, edit, and manage the Services on your site."
          />
        </div>
        {loading || values === "" ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <ClipLoader color="#000" loading size={35} />
          </div>
        ) : (
          <div className="main-row add-service-to-page-content-container">
            <div className="form-container">
              <Formik initialValues={values} enableReinitialize>
                {({ values }) => (
                  <Form className="brand-form mx-auto">
                    <fieldset>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4">Section ID</div>
                            <div className="col-8 input">
                              <div className="select">
                                <Select
                                  value={section}
                                  onChange={handleSection}
                                  options={sectionOptions}
                                  className="category-select"
                                  classNamePrefix="select"
                                  name="category"
                                  isSearchable={false}
                                  placeholder="Select Section"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="english-col col-6">
                          <div className="row mx-0">
                            <div className="label col-4">Service Name</div>
                            <div className="col-8 input">
                              <div className="select">
                                <div>{values.name_en}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="row button-row mx-0">
                      <div className="button-col col-8 mx-auto d-flex flex-column">
                        <div className="button-group d-flex align-items-center">
                          <Fragment>
                            <button
                              className="gray-button"
                              type="button"
                              onClick={() => setRedirectBack(true)}
                            >
                              Cancel
                            </button>
                            <button
                              className="blue-button"
                              type="submit"
                              onClick={() => handleCreate(values)}
                            >
                              {addingLoader ? (
                                <div className="d-flex justify-content-center align-items-center w-100">
                                  <ClipLoader color="#fff" loading size={20} />
                                </div>
                              ) : (
                                "Save"
                              )}
                            </button>
                          </Fragment>
                        </div>
                      </div>
                    </div>
                    {redirectBack ? (
                      <Redirect push to="/service-catalog" />
                    ) : (
                      ""
                    )}
                    {redirect ? <Redirect push to="/service-catalog" /> : ""}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProsps = (state) => {
  return {
    sections: state.sections.sections,
  };
};
export default connect(mapStateToProsps, null)(AddServiceTo);
